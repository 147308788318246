"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _recordsetCommonSchemes = require("./_recordsetCommonSchemes.js");

var _validatorConstants = require("../validator/validatorConstants.js");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _default = {
  find: _objectSpread({}, _recordsetCommonSchemes.recordsetCommonFindScheme),
  findDomain: {
    domainId: _validatorConstants.INTEGER.required([1])
  },
  create: {
    domainId: _validatorConstants.INTEGER.required([1]),
    sourceBarId: _validatorConstants.INTEGER.required([1]),
    sourceRestaurantId: _validatorConstants.INTEGER.required([1]),
    targetBarId: _validatorConstants.INTEGER.required([1]),
    targetRestaurantId: _validatorConstants.INTEGER.required([1])
  },
  createBulk: _validatorConstants.ARRAY.required(_validatorConstants.OBJECT.required({
    domainId: _validatorConstants.INTEGER.required([1]),
    sourceBarId: _validatorConstants.INTEGER.required([1]),
    sourceRestaurantId: _validatorConstants.INTEGER.required([1]),
    targetBarId: _validatorConstants.INTEGER.required([1]),
    targetRestaurantId: _validatorConstants.INTEGER.required([1])
  }), [1, 1]),
  destroyBulk: _validatorConstants.ARRAY.required(_validatorConstants.OBJECT.required({
    id: _validatorConstants.INTEGER.required([1]),
    barId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  }), [2, 2])
};
exports["default"] = _default;