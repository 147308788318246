import React from "react";
import { sumBy, sum, uniq, uniqWith, isEqual, identity, get, max } from "lodash";
import {
  Measurement,
  convertAmount,
  getSkuDetails,
  getTotalRecordsSkuPortionPrice,
} from "@stktk/commons";
import { __ } from "@stktk/locales";
import { RecordsValue, RecordsSaleValue, SkuImage } from "$loadable/ui.jsx";

export const DefaultAggregated = ({ value, info }) => (
  <span className="Table-aggregated">
    <span className="Table-aggregated-value">{value}</span>
    {info && <span className="Table-aggregated-info">{"(" + info + ")"}</span>}
  </span>
);

export const UNIQUE = ({ values, separator, display }) =>
  uniqWith(
    values.map((value) => display(value)),
    isEqual
  )
    .sort()
    .join(separator);

export const getRows = (row) => {
  // console.log('getRows', row);
  // TODO: row.isAggregated?
  return row.isGrouped
    ? row.leafRows.map((row) => row.original)
    : [row.original];
};

export default {
  none: () => ({
    disableGroupBy: true,
    aggregate: null,
    Aggregated: null,
  }),

  image: ({ accessor }) => ({
    aggregate: (values, rows) => accessor(rows[0]),
    Aggregated: ({ cell: { row } }) => {
      return (
        <div className="RecordRow-td RecordRow-td-image">
          <SkuImage sku={accessor(row.subRows[0])} />
        </div>
      );
    },
  }),

  dummy: ({ dummy, display = identity }) => ({
    aggregate: (/*values, rows*/) => dummy,
    Aggregated: (/*{ cell: { values: values }}*/) => display(dummy),
  }),

  maxDate: ({ property, display = identity }) => ({
    aggregate: values => max(values.map((v) => new Date(v))),
    Aggregated: ({ row: { values } }) => display(values[property])
  }),

  listUniq: ({
    /*accessor = identity, */ display = identity,
    separator = ", ",
    info = __("table_aggregated_info_list_uniq"),
    unique = UNIQUE,
  } = {}) => {
    return {
      aggregate: (values /*, rows*/) => {
        return values;
      },
      Aggregated: ({ cell: { value } }) => {
        let values = [];
        //FIXME: aggregate subRows
        if (Array.isArray(value)) values = value;
        else values = [value];
        const uniqueValues = unique({ values, separator, display });
        return <DefaultAggregated value={uniqueValues} info={info} />;
      },
      aggregatedExporter: ({ cell: { value } }) => {
        let values = [];
        //FIXME: aggregate subRows
        if (Array.isArray(value)) values = value;
        else values = [value];
        const uniqueValues = unique({ values, separator, display });
        return uniqueValues;
      },
    };
  },

  hasUniq: () => ({
    aggregate: (values /*, rows*/) => {
      const uniqs = uniq(values);
      return uniqs.length === 1 ? uniqs[0] : null;
    },
    Aggregated: ({ cell: { value } }) => (
      <DefaultAggregated
        value={value}
        info={value ? /*__('table_aggregated_info_has_uniq')*/ null : null}
      />
    ),
  }),

  sum: ({ property, display = identity } = {}) => ({
    aggregate: (values /*, rows*/) => {
      return values.reduce((accumulator, value) => accumulator + value, 0);
    },
    Aggregated: ({ row: { values } }) => display(values[property]),
    aggregatedExporter: ({ row: { values } }) => display(values[property]),
  }),

  measurement: ({ property, display = identity } = {}) => ({
    aggregate: (values /*, rows*/) => {
      return values.reduce(
        (accumulator, measurement) =>
          new Measurement({
            amount:
              accumulator.amount +
              convertAmount(
                measurement.amount,
                measurement.unit.id,
                accumulator.unit.id
              ),
            unit: accumulator.unit,
          })
      );
    },
    Aggregated: ({ row: { values } }) => display(values[property]),
    aggregatedExporter: ({ row: { values } }) => display(values[property]),
  }),

  recordValue: ({
    recordValueProperty,
    skuValueProperty,
    display = identity,
    path,
  } = {}) => ({
    aggregate: (values /*, rows*/) => display(sum(values)),
    Aggregated: ({ row }) => {
      let records = getRows(row).map((row) => (path ? get(row, path) : row));
      return records.length ? (
        <RecordsValue
          records={records}
          sku={records[0].sku}
          recordValueProperty={recordValueProperty}
          skuValueProperty={skuValueProperty}
        />
      ) : null;
    },
    aggregatedExporter: ({ row }) => {
      let records = getRows(row).map((row) => (path ? get(row, path) : row));
      if (!records.length) return null;
      let priceValue = {
        skuValuePrice: getSkuDetails(records[0].sku, "domain")[
          skuValueProperty
        ],
        recordValuePrice: getTotalRecordsSkuPortionPrice(
          records,
          records[0].sku,
          recordValueProperty,
          skuValueProperty
        ),
      };
      return priceValue.recordValuePrice !== null
        ? priceValue.recordValuePrice
        : priceValue.skuValuePrice;
    },
  }),

  recordSaleValue: ({
    recordValueProperty,
    accessor,
    display = identity,
  } = {}) => ({
    aggregate: (values /*, rows*/) => display(sum(values)),
    Aggregated: ({ row }) => {
      let records = getRows(row);
      return records.length ? (
        <RecordsSaleValue records={records} accessor={accessor} />
      ) : null;
    },
    aggregatedExporter: ({ row }) => {
      let records = getRows(row);
      if (!records.length) return null;
      return sumBy(records, (r) => r.quantity * r[recordValueProperty]);
    },
  }),
};
