"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSkuImageUrl = exports.getSkuDefaultImageUrl = exports.getSkuCopyName = exports.getImagePreloaderUrl = exports.escapeRegExpString = void 0;

var _lodash = require("lodash");

var _commons = require("@stktk/commons");

var _locales = require("@stktk/locales");

//FIXME: move to config
var s3Path = 'https://s3-eu-west-1.amazonaws.com/stktk-public';

var getSkuImageUrl = function getSkuImageUrl(_ref) {
  var image = _ref.image,
      stocktakeMode = _ref.stocktakeMode;
  if (image) return image;
  return getSkuDefaultImageUrl({
    stocktakeMode: stocktakeMode
  });
}; // eslint-disable-next-line complexity


exports.getSkuImageUrl = getSkuImageUrl;

var getSkuDefaultImageUrl = function getSkuDefaultImageUrl(_ref2) {
  var stocktakeMode = _ref2.stocktakeMode;

  switch (stocktakeMode) {
    case _commons.SkuStocktakeModeEnum.QUANTITY:
      return "".concat(s3Path, "/img/sku-default-quantity.png");

    case _commons.SkuStocktakeModeEnum.QUANTITY_VOLUME:
      return "".concat(s3Path, "/img/sku-default-quantity-volume.png");

    case _commons.SkuStocktakeModeEnum.MASS:
      return "".concat(s3Path, "/img/sku-default-mass.png");

    case _commons.SkuStocktakeModeEnum.QUANTITY_MASS:
      return "".concat(s3Path, "/img/sku-default-quantity-mass.png");

    case _commons.SkuStocktakeModeEnum.VOLUME:
      return "".concat(s3Path, "/img/sku-default-volume.png");

    default:
      return "".concat(s3Path, "/img/sku-default.jpg");
  }
};

exports.getSkuDefaultImageUrl = getSkuDefaultImageUrl;

var getImagePreloaderUrl = function getImagePreloaderUrl() {
  return "".concat(s3Path, "/img/loading.gif");
};

exports.getImagePreloaderUrl = getImagePreloaderUrl;

var escapeRegExpString = function escapeRegExpString(string) {
  return string.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
};

exports.escapeRegExpString = escapeRegExpString;

var getSkuCopyName = function getSkuCopyName(sku, skus) {
  var pureName = sku.name.replace(copyMatch, '');
  var newName = pureName + " (".concat((0, _locales.__)('general_copy'), ")");
  var copyMatch = new RegExp(" ?\\(".concat((0, _locales.__)('general_copy'), " ?([0-9]*)?\\)"));
  var copyNameMatch = new RegExp("".concat(escapeRegExpString(pureName), " \\(").concat((0, _locales.__)('general_copy'), " ?([0-9]*)?\\)"));
  var copies = (0, _lodash.compact)(skus.map(function (_ref3) {
    var name = _ref3.name;
    return name.match(copyNameMatch);
  }));

  if (copies.length) {
    var lastCopy = (0, _lodash.maxBy)(copies, function (copy) {
      return copy[1] ? parseInt(copy[1]) : 2;
    });
    newName = lastCopy[0].replace(copyMatch, " (".concat((0, _locales.__)('general_copy'), " ").concat(lastCopy[1] ? parseInt(lastCopy[1]) + 1 : 2, ")"));
  }

  return newName;
};

exports.getSkuCopyName = getSkuCopyName;