import React, { forwardRef } from 'react';
import { Input as BSInput } from 'reactstrap';

const Input = ({ id, name, isRaw = false, ...props }, ref) => {
  const inputProps = {
    id,
    name,
    'data-testid': `Input-${(name || id)}`,
    ...props
  };
  const input = isRaw ? (
    <input ref={ref} {...inputProps} />
  ) : (
    <BSInput innerRef={ref} {...inputProps} />
  );
  return input;
};

export default forwardRef(Input);