"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultUserSettings = exports.defaultRestaurantSettings = exports.defaultLanguage = exports.defaultDomainSettings = void 0;

var _constants = require("./constants");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var defaultLanguage = 'en';
exports.defaultLanguage = defaultLanguage;
var defaultDomainSettings = {
  allowedSearchScopes: _constants.SkuSearchScopeEnum.toArray(),
  allowDomainTransfers: false,
  saveTableConfiguration: true,
  allowStockcheckMerge: false,
  skuDetailsLevel: 'domain',
  integrations: []
};
exports.defaultDomainSettings = defaultDomainSettings;

var defaultRestaurantSettings = _objectSpread({}, defaultDomainSettings);

exports.defaultRestaurantSettings = defaultRestaurantSettings;

var defaultUserSettings = _objectSpread({}, defaultRestaurantSettings);

exports.defaultUserSettings = defaultUserSettings;