"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _recordsetCommonSchemes = require("./_recordsetCommonSchemes.js");

var _validatorConstants = require("../validator/validatorConstants.js");

var _commons = require("@stktk/commons");

var _default = {
  find: Object.assign({}, _recordsetCommonSchemes.recordsetCommonFindScheme, {
    storageStandardId: _validatorConstants.INTEGER.required([1])
  }),
  create: Object.assign({}, _recordsetCommonSchemes.recordsetCommonCreateScheme, {
    comment: _validatorConstants.STRING.required(140),
    //TODO: variants
    virtualGenerationRule: _validatorConstants.STRING.required(),
    virtualStorageStandard: _validatorConstants.INTEGER.optional(),
    status: _validatorConstants.ENUM.INTEGER.required(_commons.StorageStatusEnum.toArray())
  }),
  update: Object.assign({}, _recordsetCommonSchemes.recordsetCommonUpdateScheme),
  destroy: Object.assign({}, _recordsetCommonSchemes.recordsetCommonDestroyScheme)
};
exports["default"] = _default;