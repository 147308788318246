export default (state, action) => {
  switch (action.type) {
    case 'SET_EDIT_ITEM_ID':
      return {
        ...state,
        editItemId: action.payload
      };
    case 'SET_EDIT_COLUMN_ID':
      return {
        ...state,
        editColumnId: action.payload
      };
    case 'RESET_EDIT':
      return {
        ...state,
        editColumnId: null,
        editItemId: null
      };
    default:
      return state;
  }
};