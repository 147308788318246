import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Input from '$ui/form/controls/Input';
import { times } from 'lodash';
import { __ } from '@stktk/locales';

export default function PaginationControls({
  gotoPage,
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize
}) {
  return (
    <div className="PaginationControls">
      <Pagination aria-label="Page navigation" className="PaginationControls-pagination">
        <PaginationItem tag="a">
          <PaginationLink first onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
        </PaginationItem>
        <PaginationItem tag="a">
          <PaginationLink previous onClick={() => previousPage()} disabled={!canPreviousPage} />
        </PaginationItem>
        {times(pageCount, index => (
          <PaginationItem tag="a" key={`pagination-${index}`} data-testid={`PaginationItem-${index}`} active={pageIndex === index}>
            <PaginationLink onClick={() => gotoPage(index)}>
              {index + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem tag="a">
          <PaginationLink next onClick={() => nextPage()} disabled={!canNextPage} />
        </PaginationItem>
        <PaginationItem tag="a">
          <PaginationLink last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
        </PaginationItem>
      </Pagination>
      <div className="PaginationControls-group">
        {__('app_table_pag_goto')}
        <Input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
        />
      </div>
      <div className="PaginationControls-group">
        {__('app_table_pag_show')}
        <Input
          type="select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 25, 50, 100, 200].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Input>
      </div>
    </div>
  );
}