"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.treeDataToFlat = exports.sortTreeData = exports.isDescendant = exports.getItemWithDescedants = exports.getItemHierarchyLevel = exports.getItemDescedants = exports.getIndentation = exports.flatDataToTree = exports.flatDataToSortedTreeWithState = exports.flatDataToSortedTree = exports.findTreeNodeById = exports.addTreeStatePropertiesToFlatData = void 0;

var _lodash = require("lodash");

var _reactSortableTree = require("react-sortable-tree");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var getIndentation = function getIndentation() {
  var level = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var character = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '\xa0\xa0\xa0\xa0\xa0';
  var indentation = '';
  (0, _lodash.times)(level, function () {
    return indentation += character;
  });
  return indentation;
};

exports.getIndentation = getIndentation;

var findTreeNodeById = function findTreeNodeById(treeData, id) {
  var _find = (0, _reactSortableTree.find)({
    treeData: treeData,
    searchQuery: id,
    searchMethod: function searchMethod(_ref) {
      var node = _ref.node,
          searchQuery = _ref.searchQuery;
      return node.id === searchQuery;
    },
    getNodeKey: function getNodeKey(_ref2) {
      var node = _ref2.node;
      return node.id;
    }
  }),
      matches = _find.matches;

  return matches.length ? matches[0].node : null;
};

exports.findTreeNodeById = findTreeNodeById;

var sortTreeData = function sortTreeData(tree) {
  var sorted = (0, _lodash.sortBy)(tree, ['sort']);

  for (var index = 0; index < sorted.length; index++) {
    var item = sorted[index];

    if (Array.isArray(item.children)) {
      item.children = sortTreeData(item.children);
    }
  }

  return sorted;
};

exports.sortTreeData = sortTreeData;

var getItemWithDescedants = function getItemWithDescedants(parent, items) {
  return [parent].concat(_toConsumableArray(getItemDescedants(parent, items)));
};

exports.getItemWithDescedants = getItemWithDescedants;

var getItemDescedants = function getItemDescedants(parent, items) {
  return (0, _lodash.filter)(items, function (item) {
    return isDescendant(parent, item, (0, _lodash.keyBy)(items, 'id'));
  });
};

exports.getItemDescedants = getItemDescedants;

var getItemHierarchyLevel = function getItemHierarchyLevel(item, items) {
  var level = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var keyItems = Array.isArray(items) ? (0, _lodash.keyBy)(items, 'id') : items;
  if (item.parentId === null) return level;
  return getItemHierarchyLevel(keyItems[item.parentId], keyItems, level + 1);
};

exports.getItemHierarchyLevel = getItemHierarchyLevel;

var isDescendant = function isDescendant(older, yonger, keyItems) {
  if (yonger.parentId === older.id) return true;
  if (yonger.parentId === null) return false;
  return isDescendant(older, keyItems[yonger.parentId], keyItems);
};

exports.isDescendant = isDescendant;

var treeDataToFlat = function treeDataToFlat() {
  var treeData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

  var _ref3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref3$getNodeKey = _ref3.getNodeKey,
      getNodeKey = _ref3$getNodeKey === void 0 ? function (_ref4) {
    var node = _ref4.node;
    return node.id;
  } : _ref3$getNodeKey,
      _ref3$ignoreCollapsed = _ref3.ignoreCollapsed,
      ignoreCollapsed = _ref3$ignoreCollapsed === void 0 ? false : _ref3$ignoreCollapsed;

  return (0, _reactSortableTree.getFlatDataFromTree)({
    treeData: treeData,
    getNodeKey: getNodeKey,
    ignoreCollapsed: ignoreCollapsed
  }).map(function (_ref5) {
    var node = _ref5.node;
    return (0, _lodash.omit)(node, 'children');
  });
};

exports.treeDataToFlat = treeDataToFlat;

var flatDataToTree = function flatDataToTree() {
  var flatData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

  var _ref6 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref6$getKey = _ref6.getKey,
      getKey = _ref6$getKey === void 0 ? function (node) {
    return node.id;
  } : _ref6$getKey,
      _ref6$getParentKey = _ref6.getParentKey,
      getParentKey = _ref6$getParentKey === void 0 ? function (node) {
    return node.parentId;
  } : _ref6$getParentKey,
      _ref6$rootKey = _ref6.rootKey,
      rootKey = _ref6$rootKey === void 0 ? null : _ref6$rootKey;

  return (0, _reactSortableTree.getTreeFromFlatData)({
    flatData: flatData,
    getKey: getKey,
    getParentKey: getParentKey,
    rootKey: rootKey
  });
};

exports.flatDataToTree = flatDataToTree;

var flatDataToSortedTree = function flatDataToSortedTree(flatData, options) {
  return sortTreeData(flatDataToTree(flatData, options));
};

exports.flatDataToSortedTree = flatDataToSortedTree;

var flatDataToSortedTreeWithState = function flatDataToSortedTreeWithState(flatData, options) {
  return flatDataToSortedTree(addTreeStatePropertiesToFlatData(flatData), options);
};

exports.flatDataToSortedTreeWithState = flatDataToSortedTreeWithState;

var addTreeStatePropertiesToFlatData = function addTreeStatePropertiesToFlatData() {
  var items = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return items.map(function (item) {
    return _objectSpread({
      expanded: true,
      hierarchyLevel: item.hierarchyLevel || getItemHierarchyLevel(item, (0, _lodash.keyBy)(items, 'id'))
    }, item);
  });
};

exports.addTreeStatePropertiesToFlatData = addTreeStatePropertiesToFlatData;