"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _validatorConstants = require("../validator/validatorConstants.js");

var _schemeConstants = require("./schemeConstants.js");

var _commons = require("@stktk/commons");

var _default = {
  find: {
    domainId: _validatorConstants.INTEGER.required([1])
  },
  create: {
    domainId: _validatorConstants.INTEGER.required([1]),
    skuId: _validatorConstants.INTEGER.required([1]),
    ingredientId: _validatorConstants.INTEGER.required([1]),
    ingredientAmount: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    ingredientAmountUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.toArray().map(function (unit) {
      return unit.id;
    }))
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    ingredientAmount: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    ingredientAmountUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.toArray().map(function (unit) {
      return unit.id;
    }))
  },
  destroy: {
    id: _validatorConstants.INTEGER.required(),
    domainId: _validatorConstants.INTEGER.required([1])
  },
  bulk: _validatorConstants.ARRAY.required(_validatorConstants.OBJECT.required({
    id: _validatorConstants.INTEGER.optional([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    skuId: _validatorConstants.INTEGER.optional([1]),
    ingredientId: _validatorConstants.INTEGER.optional([1]),
    ingredientAmount: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    ingredientAmountUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.toArray().map(function (unit) {
      return unit.id;
    }))
  }))
};
exports["default"] = _default;