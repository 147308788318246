import React from 'react';
import DayPicker from 'react-day-picker';
import { Popover, PopoverBody } from 'reactstrap';
import onClickOutside from 'react-onclickoutside';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment';
import { filter, find } from 'lodash';
import { Link } from 'react-router-dom';
import { getRoutePath } from '$utils/route';

export class DashboardCalendar extends React.Component {
  state = {
    popover: false
  };

  handleClickOutside = event => {
    find(event.path, element => element.className == 'popover-body') ? null : this.setState({ popover: false });
    /*if (!this.node.contains(event.target) && event.target.id != this.state.popover)
      this.setState({ popover: null });*/
  }

  setPopover = day =>
    this.setState({ popover: 'day-' + day.getTime() });

  getEventsDates = day =>
    filter(this.props.events, event =>
      moment(event.date).isSame(day, 'day')
    );

  handleDayClick = day => {
    const eventsOnThatDay = this.getEventsDates(day);
    if (eventsOnThatDay && eventsOnThatDay.length)
      this.setPopover(day);
    if (this.state.popover && !eventsOnThatDay.length)
      this.setState({ popover: false });
  };

  render() {
    const { events, ...props } = this.props;
    return (
      <div ref={node => this.node = node}>
        <DayPicker
          {...props}
          className="DashboardCalendar"
          onDayClick={this.handleDayClick}
          canChangeMonth={true}
          onMonthChange={() => this.setState({ popover: false })}
          renderDay={day =>
            <DashboardCalendarDay
              day={day}
              popover={this.state.popover}
              eventsOnThatDay={this.getEventsDates(day)}
              domainId={this.props.domainId}
              restaurantId={this.props.restaurantId}
            />
          }
          numberOfMonths={1}
          showOutsideDays={false}
          locale={window.locale.contentLanguage}
          localeUtils={MomentLocaleUtils}
        />
      </div>
    );
  }
}

export class DashboardCalendarDay extends React.Component {
  render () {
    const { day, popover, eventsOnThatDay } = this.props;
    const dayId = 'day-' + day.getTime();
    return (
      <div>
        <div style={{ padding: 5, display: 'block' }} className={eventsOnThatDay && eventsOnThatDay.length > 0 ? 'text-primary' : ''} id={dayId}>
          {day.getDate()}
        </div>
        {eventsOnThatDay && eventsOnThatDay.length > 0 &&
          <Popover id="popover-date-picker" trigger='click' placement="top" target={dayId} isOpen={popover && popover === dayId}>
            <PopoverBody>
              {
                eventsOnThatDay.map((event, i) =>
                  <div key={i} className={'Dashboard-task'}>
                    <Link
                      to={
                        getRoutePath({
                          domainId: this.props.domainId,
                          restaurantId: this.props.restaurantId
                        }, event.type, event.id)
                      }
                    >
                      {event.title}
                    </Link>
                    <div className="text-muted">
                      <small>{moment(event.date).format('LL')}</small>
                    </div>
                  </div>
                )
              }
            </PopoverBody>
          </Popover>
        }
      </div>
    );
  }
}

export default onClickOutside(DashboardCalendar);