"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VenueTypeEnum = exports.UserRoleEnum = exports.UserModeEnum = exports.UserForgotEnum = exports.UserActiveEnum = exports.UnitTypeEnum = exports.UnitPropertyEnum = exports.UnitEnum = exports.TagContextEnum = exports.SystemNotificationTypeEnum = exports.StorageTransferStatusEnum = exports.StorageTransferRecordsetSubtypeEnum = exports.StorageTransferRecordStatusEnum = exports.StorageTransferGroupStatusEnum = exports.StorageStatusEnum = exports.StorageStandardStatusEnum = exports.StorageStandardGenerationRuleEnum = exports.StorageRecordStatusEnum = exports.StocktakeStatusEnum = exports.StocktakeRecordStatusEnum = exports.StockcheckStatusEnum = exports.SkuTypeEnum = exports.SkuStocktakeModeEnum = exports.SkuSearchScopeEnum = exports.SkuSearchModeEnum = exports.SkuMeasureEnum = exports.SkuBarcodeFormatEnum = exports.SaleStatusEnum = exports.SaleRecordStatusEnum = exports.SaleGenerationRuleEnum = exports.RestaurantUserPositionEnum = exports.ReportTypeEnum = exports.RecordsetWorkflowStepEnum = exports.RecordsetWorkflowFlowEnum = exports.RecordsetTypeEnum = exports.RecordsetSubtypeEnum = exports.RecordsetGroupTypeEnum = exports.RecordTypeEnum = exports.RecordStatusEnum = exports.RecordModeEnum = exports.RecordMeasureEnum = exports.ProductionStatusEnum = exports.ProductionRecordStatusEnum = exports.ProductionGroupStatusEnum = exports.OrderStatusEnum = exports.OrderRecordsetWorkflowStepEnum = exports.OrderRecordsetWorkflowFlowEnum = exports.OrderRecordStatusEnum = exports.OrderGroupStatusEnum = exports.OrderGroupGenerationRuleEnum = exports.LossStatusEnum = exports.LossRecordStatusEnum = exports.LossGroupStatusEnum = exports.DomainUserRoleEnum = exports.DomainModeEnum = exports.DocumentTypeEnum = exports.DocumentStatusStatusEnum = exports.DocumentStatusEnum = exports.CountryEnum = exports.CategoryPropertyEnum = exports.CategoryEnum = exports.BleStatusEnum = void 0;

var _countryData = _interopRequireDefault(require("./countryData"));

var _constructors = require("./constructors.js");

var _lodash = require("lodash");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var TagContextEnum = new _constructors.Constant({
  SKU: 'sku',
  USER: 'user'
});
exports.TagContextEnum = TagContextEnum;
var ReportTypeEnum = new _constructors.Constant({
  REPORT_1: 'report_1',
  REPORT_2: 'report_2'
});
exports.ReportTypeEnum = ReportTypeEnum;
var DocumentTypeEnum = new _constructors.Constant({
  INVOICE_ORDER: 'invoiceOrder',
  INVOICE_OTHER: 'invoiceOther',
  OTHER: 'other'
});
exports.DocumentTypeEnum = DocumentTypeEnum;
var DocumentStatusEnum = new _constructors.Constant({
  UPLOADED: 'uploaded',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  STATIC: 'static'
});
exports.DocumentStatusEnum = DocumentStatusEnum;
var DocumentStatusStatusEnum = new _constructors.Constant({
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  DONE: 'done',
  FAILED: 'failed'
});
exports.DocumentStatusStatusEnum = DocumentStatusStatusEnum;
var CountryEnum = new _constructors.CountryConstant((0, _lodash.keyBy)(_countryData["default"], 'alpha2Code'));
exports.CountryEnum = CountryEnum;
var SkuTypeEnum = new _constructors.Constant({
  COMPOUND: 'compound',
  SIMPLE: 'simple'
});
exports.SkuTypeEnum = SkuTypeEnum;
var RecordMeasureEnum = new _constructors.Constant({
  VOLUME: 'volume',
  PRODUCT_WEIGHT: 'productWeight',
  SCALE_WEIGHT: 'scaleWeight',
  PACKAGE_CLOSURE_WEIGHT: 'packageClosureWeight'
});
exports.RecordMeasureEnum = RecordMeasureEnum;
var SkuMeasureEnum = new _constructors.Constant({
  VOLUME: 'volume',
  PACKAGE_WEIGHT_NET: 'packageWeightNet',
  PACKAGE_WEIGHT_GROSS: 'packageWeightGross',
  PACKAGE_CLOSURE_WEIGHT: 'packageClosureWeight',
  PRODUCT_WEIGHT: 'productWeight'
});
exports.SkuMeasureEnum = SkuMeasureEnum;
var SkuSearchModeEnum = new _constructors.Constant({
  BARCODE: 'barcode',
  NAME: 'name'
});
exports.SkuSearchModeEnum = SkuSearchModeEnum;
var SkuSearchScopeEnum = new _constructors.Constant({
  GLOBAL: 'global',
  LOCAL: 'local'
});
exports.SkuSearchScopeEnum = SkuSearchScopeEnum;
var RecordModeEnum = new _constructors.Constant({
  QUANTITY: 'quantity',
  VOLUME: 'volume',
  MASS: 'mass',
  MASS2VOLUME: 'mass2volume',
  MASS2MASS: 'mass2mass'
});
exports.RecordModeEnum = RecordModeEnum;
var StorageStandardGenerationRuleEnum = new _constructors.Constant({
  STOCKCHECK_BASED: 'stockcheck',
  EMPTY: 'empty',
  COPY_STORAGE: 'copy'
});
exports.StorageStandardGenerationRuleEnum = StorageStandardGenerationRuleEnum;
var SkuBarcodeFormatEnum = new _constructors.SkuBarcodeFormatConstant({
  CUSTOM: {
    priority: 100,
    name: 'custom',
    regex: /^[0-9a-zA-Z]{1,32}$/
  },
  EAN8: {
    priority: 20,
    name: 'ean-8',
    regex: /^[0-9]{8}$/
  },
  EAN13: {
    priority: 40,
    name: 'ean-13',
    regex: /^[0-9]{13}$/
  },
  UPCE: {
    priority: 10,
    name: 'upc-e',
    regex: /^[0-9]{6}$/
  },
  UPCA: {
    priority: 30,
    name: 'upc-a',
    regex: /^[0-9]{12}$/
  }
});
exports.SkuBarcodeFormatEnum = SkuBarcodeFormatEnum;
var OrderGroupGenerationRuleEnum = new _constructors.Constant({
  AUTOMATIC: 'auto',
  EMPTY: 'empty',
  STORAGE_BASED: 'storage'
});
exports.OrderGroupGenerationRuleEnum = OrderGroupGenerationRuleEnum;
var SaleGenerationRuleEnum = new _constructors.Constant({
  EMPTY: 'empty'
});
exports.SaleGenerationRuleEnum = SaleGenerationRuleEnum;
var SystemNotificationTypeEnum = new _constructors.Constant({
  ONBOARDING: 'onboarding'
});
exports.SystemNotificationTypeEnum = SystemNotificationTypeEnum;
var UserActiveEnum = new _constructors.Constant({
  ACTIVE: '1',
  INACTIVE: '0'
});
exports.UserActiveEnum = UserActiveEnum;
var UserForgotEnum = new _constructors.Constant({
  NOT_FORGOT: null
});
exports.UserForgotEnum = UserForgotEnum;
var UserModeEnum = new _constructors.Constant({
  PRODUCTION: 'production',
  TEST: 'test'
});
exports.UserModeEnum = UserModeEnum;
var StocktakeRecordStatusEnum = new _constructors.Constant({
  DEFAULT: 'stocktake.default'
});
exports.StocktakeRecordStatusEnum = StocktakeRecordStatusEnum;
var SaleRecordStatusEnum = new _constructors.Constant({
  DEFAULT: 'sale.default'
});
exports.SaleRecordStatusEnum = SaleRecordStatusEnum;
var OrderRecordStatusEnum = new _constructors.Constant({
  ORDERED: 'order.ordered',
  ARRIVED: 'order.arrived',
  ESTIMATE: 'order.estimate'
});
exports.OrderRecordStatusEnum = OrderRecordStatusEnum;
var StorageRecordStatusEnum = new _constructors.Constant({
  DEFAULT: 'storage.default'
});
exports.StorageRecordStatusEnum = StorageRecordStatusEnum;
var LossRecordStatusEnum = new _constructors.Constant({
  SPILLAGE: 'loss.spillage',
  BREAKAGE: 'loss.breakage',
  RETURN: 'loss.return',
  OTHER: 'loss.other',
  MISSING: 'loss.missing',
  SPOILING: 'loss.spoiling',
  PRODUCTION_WASTE: 'loss.production_waste',
  EXPIRATION: 'loss.expiration'
});
exports.LossRecordStatusEnum = LossRecordStatusEnum;
var StorageTransferRecordStatusEnum = new _constructors.Constant({
  OUTGOING: 'storage_transfer.outgoing',
  INCOMING: 'storage_transfer.incoming'
});
exports.StorageTransferRecordStatusEnum = StorageTransferRecordStatusEnum;
var ProductionRecordStatusEnum = new _constructors.Constant({
  INPUT: 'production.input',
  OUTPUT: 'production.output'
});
exports.ProductionRecordStatusEnum = ProductionRecordStatusEnum;
var RecordStatusEnum = new _constructors.Constant({
  SALE_DEFAULT: SaleRecordStatusEnum.DEFAULT,
  STOCKTAKE_DEFAULT: StocktakeRecordStatusEnum.DEFAULT,
  STORAGE_DEFAULT: StorageRecordStatusEnum.DEFAULT,
  ORDER_ORDERED: OrderRecordStatusEnum.ORDERED,
  ORDER_ARRIVED: OrderRecordStatusEnum.ARRIVED,
  ORDER_ESTIMATE: OrderRecordStatusEnum.ESTIMATE,
  LOSS_SPILLAGE: LossRecordStatusEnum.SPILLAGE,
  LOSS_BREAKAGE: LossRecordStatusEnum.BREAKAGE,
  LOSS_MISSING: LossRecordStatusEnum.MISSING,
  LOSS_OTHER: LossRecordStatusEnum.OTHER,
  LOSS_RETURN: LossRecordStatusEnum.RETURN,
  LOSS_SPOILING: LossRecordStatusEnum.SPOILING,
  LOSS_PRODUCTION_WASTE: LossRecordStatusEnum.PRODUCTION_WASTE,
  LOSS_EXPIRATION: LossRecordStatusEnum.EXPIRATION,
  STORAGE_TRANSFER_OUTGOING: StorageTransferRecordStatusEnum.INCOMING,
  STORAGE_TRANSFER_INCOMING: StorageTransferRecordStatusEnum.OUTGOING,
  PRODUCTION_INPUT: ProductionRecordStatusEnum.INPUT,
  PRODUCTION_OUTPUT: ProductionRecordStatusEnum.OUTPUT
});
exports.RecordStatusEnum = RecordStatusEnum;
var SkuStocktakeModeEnum = new _constructors.SkuStocktakeModeConstant({
  QUANTITY: 'quantity',
  QUANTITY_VOLUME: 'quantity_volume',
  VOLUME: 'volume',
  QUANTITY_MASS: 'quantity_mass',
  MASS: 'mass'
});
exports.SkuStocktakeModeEnum = SkuStocktakeModeEnum;
var DomainModeEnum = new _constructors.Constant({
  PRODUCTION: 'production',
  TEST: 'test'
});
exports.DomainModeEnum = DomainModeEnum;
var LossGroupStatusEnum = new _constructors.Constant({
  DEFAULT: 0,
  FINALIZED: 1
});
exports.LossGroupStatusEnum = LossGroupStatusEnum;
var OrderGroupStatusEnum = new _constructors.Constant({
  DEFAULT: 0
});
exports.OrderGroupStatusEnum = OrderGroupStatusEnum;
var StorageTransferGroupStatusEnum = new _constructors.Constant({
  DEFAULT: 0
});
exports.StorageTransferGroupStatusEnum = StorageTransferGroupStatusEnum;
var ProductionGroupStatusEnum = new _constructors.Constant({
  DEFAULT: 0
});
exports.ProductionGroupStatusEnum = ProductionGroupStatusEnum;
var StockcheckStatusEnum = new _constructors.Constant({
  CLOSED: 0,
  ACTIVE: 1,
  CREATED: 2,
  FINALIZED: 3
});
exports.StockcheckStatusEnum = StockcheckStatusEnum;
var StorageStandardStatusEnum = new _constructors.Constant({
  INACTIVE: 0,
  ACTIVE: 1
});
exports.StorageStandardStatusEnum = StorageStandardStatusEnum;
var OrderStatusEnum = new _constructors.Constant({
  CREATED: 1,
  SENT: 2,

  /* shipped */
  RECEIVED: 3,
  FINISHED: 4,
  PLACED: 5
});
exports.OrderStatusEnum = OrderStatusEnum;
var LossStatusEnum = new _constructors.Constant({
  DEFAULT: 0
});
exports.LossStatusEnum = LossStatusEnum;
var StorageTransferStatusEnum = new _constructors.Constant({
  DEFAULT: 0
});
exports.StorageTransferStatusEnum = StorageTransferStatusEnum;
var ProductionStatusEnum = new _constructors.Constant({
  DEFAULT: 0
});
exports.ProductionStatusEnum = ProductionStatusEnum;
var StocktakeStatusEnum = new _constructors.Constant({
  CLOSED: 0,
  ACTIVE: 1,
  CREATED: 2
});
exports.StocktakeStatusEnum = StocktakeStatusEnum;
var SaleStatusEnum = new _constructors.Constant({
  DEFAULT: 1
});
exports.SaleStatusEnum = SaleStatusEnum;
var StorageStatusEnum = new _constructors.Constant({
  INACTIVE: 0,
  ACTIVE: 1
});
exports.StorageStatusEnum = StorageStatusEnum;
var UserRoleEnum = new _constructors.Constant({
  MEMBER: 'member',
  ADMIN: 'admin'
});
exports.UserRoleEnum = UserRoleEnum;
var DomainUserRoleEnum = new _constructors.Constant({
  MEMBER: 'member',
  SUPERVISOR: 'supervisor',
  MANAGER: 'manager',
  OWNER: 'owner'
});
exports.DomainUserRoleEnum = DomainUserRoleEnum;
var RestaurantUserPositionEnum = new _constructors.Constant({
  MEMBER: 'member',
  MANAGER: 'manager',
  OWNER: 'owner',
  SUPERVISOR: 'supervisor' //FIXME: temporary

});
exports.RestaurantUserPositionEnum = RestaurantUserPositionEnum;
var RecordTypeEnum = new _constructors.Constant({
  OPEN: 'open',
  FULL: 'full'
});
exports.RecordTypeEnum = RecordTypeEnum;
var RecordsetGroupTypeEnum = new _constructors.Constant({
  STOCKCHECK: 'stockcheck',
  STORAGE_STANDARD: 'storage_standard',
  LOSS_GROUP: 'loss_group',
  STORAGE_TRANSFER_GROUP: 'storage_transfer_group',
  PRODUCTION_GROUP: 'production_group',
  ORDER_GROUP: 'order_group'
});
exports.RecordsetGroupTypeEnum = RecordsetGroupTypeEnum;
var RecordsetTypeEnum = new _constructors.Constant({
  STORAGE: 'storage',
  LOSS: 'loss',
  SALE: 'sale',
  ORDER: 'order',
  STOCKTAKE: 'stocktake',
  STORAGE_TRANSFER: 'storage_transfer',
  PRODUCTION: 'production'
});
exports.RecordsetTypeEnum = RecordsetTypeEnum;
var StorageTransferRecordsetSubtypeEnum = new _constructors.Constant({
  INCOMING: 'incoming',
  OUTGOING: 'outgoing'
});
exports.StorageTransferRecordsetSubtypeEnum = StorageTransferRecordsetSubtypeEnum;
var RecordsetSubtypeEnum = new _constructors.Constant({
  STORAGE_TRANSFER_INCOMING: StorageTransferRecordsetSubtypeEnum.INCOMING,
  STORAGE_TRANSFER_OUTGOING: StorageTransferRecordsetSubtypeEnum.OUTGOING
});
exports.RecordsetSubtypeEnum = RecordsetSubtypeEnum;
var CategoryPropertyEnum = new _constructors.Constant({
  NAME: 'name'
});
exports.CategoryPropertyEnum = CategoryPropertyEnum;
var UnitPropertyEnum = new _constructors.Constant({
  NAME: 'name',
  SHORT_NAME: 'name_short'
});
exports.UnitPropertyEnum = UnitPropertyEnum;
var UnitTypeEnum = new _constructors.Constant({
  WEIGHT: 'weight',
  VOLUME: 'volume',
  ITEM: 'item'
});
/* eslint-disable no-multi-spaces */

exports.UnitTypeEnum = UnitTypeEnum;
var UnitEnum = new _constructors.UnitConstant({
  // WEIGHT - EU
  GRAM: {
    id: 1,
    type: UnitTypeEnum.WEIGHT,
    baseTypeUnit: true,
    baseTypeGroupUnit: true,
    typeGroupMultiplier: 1,
    typeGroup: 1,
    typeMultiplier: 1
  },
  KILOGRAM: {
    id: 9,
    type: UnitTypeEnum.WEIGHT,
    baseTypeUnit: false,
    baseTypeGroupUnit: false,
    typeGroupMultiplier: 1000,
    typeGroup: 1,
    typeMultiplier: 1000
  },
  TONNE: {
    id: 11,
    type: UnitTypeEnum.WEIGHT,
    baseTypeUnit: false,
    baseTypeGroupUnit: false,
    typeGroupMultiplier: 1000000,
    typeGroup: 1,
    typeMultiplier: 1000000
  },
  // WEIGHT - UK
  OUNCE: {
    id: 2,
    type: UnitTypeEnum.WEIGHT,
    baseTypeUnit: false,
    baseTypeGroupUnit: true,
    typeGroupMultiplier: 1,
    typeGroup: 2,
    typeMultiplier: 28.3495231
  },
  POUND: {
    id: 6,
    type: UnitTypeEnum.WEIGHT,
    baseTypeUnit: false,
    baseTypeGroupUnit: false,
    typeGroupMultiplier: 16,
    typeGroup: 2,
    typeMultiplier: 453.59237
  },
  // VOLUME -
  MILLILITER: {
    id: 3,
    type: UnitTypeEnum.VOLUME,
    baseTypeUnit: true,
    baseTypeGroupUnit: true,
    typeGroupMultiplier: 1,
    typeGroup: 1,
    typeMultiplier: 1
  },
  CENTILITER: {
    id: 10,
    type: UnitTypeEnum.VOLUME,
    baseTypeUnit: false,
    baseTypeGroupUnit: false,
    typeGroupMultiplier: 10,
    typeGroup: 1,
    typeMultiplier: 10
  },
  LITER: {
    id: 7,
    type: UnitTypeEnum.VOLUME,
    baseTypeUnit: false,
    baseTypeGroupUnit: false,
    typeGroupMultiplier: 1000,
    typeGroup: 1,
    typeMultiplier: 1000
  },
  // VOLUME - US
  PINT_US: {
    id: 4,
    type: UnitTypeEnum.VOLUME,
    baseTypeUnit: false,
    baseTypeGroupUnit: false,
    typeGroupMultiplier: 16,
    typeGroup: 2,
    typeMultiplier: 473.176473
  },
  FLUID_OUNCE_US: {
    id: 12,
    type: UnitTypeEnum.VOLUME,
    baseTypeUnit: false,
    baseTypeGroupUnit: true,
    typeGroupMultiplier: 1,
    typeGroup: 2,
    typeMultiplier: 29.57353
  },
  FLUID_GALLON_US: {
    id: 13,
    type: UnitTypeEnum.VOLUME,
    baseTypeUnit: false,
    baseTypeGroupUnit: false,
    typeGroupMultiplier: 128,
    typeGroup: 2,
    typeMultiplier: 3785.41
  },
  // VOLUME - UK
  PINT_UK: {
    id: 8,
    type: UnitTypeEnum.VOLUME,
    baseTypeUnit: false,
    baseTypeGroupUnit: true,
    typeGroupMultiplier: 1,
    typeGroup: 3,
    typeMultiplier: 568.26
  },
  // ITEM
  ITEM: {
    id: 5,
    type: UnitTypeEnum.ITEM,
    baseTypeUnit: true,
    baseTypeGroupUnit: true,
    typeGroupMultiplier: 1,
    typeGroup: 1,
    typeMultiplier: 1
  }
}); // official categories

exports.UnitEnum = UnitEnum;
var CategoryEnum = new _constructors.CategoryConstant({
  //FIXME: domainId: 0
  VODKA: {
    id: 1,
    domainId: 0,
    rgbColor: null
  },
  WHISKY_WHISKEY: {
    id: 2,
    domainId: 0,
    rgbColor: null
  },
  RUM: {
    id: 3,
    domainId: 0,
    rgbColor: null
  },
  GIN: {
    id: 4,
    domainId: 0,
    rgbColor: null
  },
  TEQUILA_MEZCAL: {
    id: 5,
    domainId: 0,
    rgbColor: null
  },
  LIQUEUR: {
    id: 6,
    domainId: 0,
    rgbColor: null
  },
  WINE: {
    id: 7,
    domainId: 0,
    rgbColor: null
  },
  SPARKLING_WINE: {
    id: 8,
    domainId: 0,
    rgbColor: null
  },
  BRANDY: {
    id: 9,
    domainId: 0,
    rgbColor: null
  },
  SPIRIT_DRINK: {
    id: 10,
    domainId: 0,
    rgbColor: null
  },
  BEER_CIDER: {
    id: 11,
    domainId: 0,
    rgbColor: null
  },
  MIXERS_SODAS: {
    id: 12,
    domainId: 0,
    rgbColor: null
  },
  ADDITION: {
    id: 13,
    domainId: 0,
    rgbColor: null
  },
  OTHER: {
    id: 14,
    domainId: 0,
    rgbColor: null
  },
  MEATS_FISH: {
    id: 15,
    domainId: 0,
    rgbColor: null
  },
  VEGETABLES_FRUITS: {
    id: 16,
    domainId: 0,
    rgbColor: null
  },
  PACKED_FOOD: {
    id: 17,
    domainId: 0,
    rgbColor: null
  },
  LOOSE_PRODUCTS: {
    id: 18,
    domainId: 0,
    rgbColor: null
  },
  HERBS_SPICES: {
    id: 19,
    domainId: 0,
    rgbColor: null
  },
  COFFEES_TEAS: {
    id: 20,
    domainId: 0,
    rgbColor: null
  },
  TOBACCO_VAPES: {
    id: 21,
    domainId: 0,
    rgbColor: null
  }
});
exports.CategoryEnum = CategoryEnum;
var BleStatusEnum = new _constructors.Constant({
  NO_DEVICE_FOUND: 'no_device_found',
  SCANNING: 'scanning',
  DISCONNECTED: 'disconnected',
  CONNECTED: 'connected',
  CONNECTING: 'connecting',
  MONITORING: 'monitoring'
});
exports.BleStatusEnum = BleStatusEnum;
var VenueTypeEnum = new _constructors.Constant({
  PUB: 'pub',
  CLUB: 'club',
  RESTAURANT: 'restaurant',
  CAFE: 'cafe',
  OTHER: 'other'
});
exports.VenueTypeEnum = VenueTypeEnum;
var OrderRecordsetWorkflowStepEnum = new _constructors.Constant({
  APPROVED: 'order.approved'
});
exports.OrderRecordsetWorkflowStepEnum = OrderRecordsetWorkflowStepEnum;
var OrderRecordsetWorkflowFlowEnum = new _constructors.Constant({
  APPROVAL: 'order.approval'
});
exports.OrderRecordsetWorkflowFlowEnum = OrderRecordsetWorkflowFlowEnum;
var RecordsetWorkflowFlowEnum = new _constructors.Constant({
  ORDER_APPROVAL: OrderRecordsetWorkflowFlowEnum.APPROVAL
});
exports.RecordsetWorkflowFlowEnum = RecordsetWorkflowFlowEnum;
var RecordsetWorkflowStepEnum = new _constructors.Constant({
  ORDER_APPROVED: OrderRecordsetWorkflowStepEnum.APPROVED
});
exports.RecordsetWorkflowStepEnum = RecordsetWorkflowStepEnum;