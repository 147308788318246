"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deepReactCompareAll = exports.deepReactCompare = exports.deepCompare = exports.compareProps = exports.compareOption = exports.compareListItem = exports.compareAllProps = void 0;

var _lodash = require("lodash");

var _fastDeepEqual = _interopRequireDefault(require("fast-deep-equal"));

var _react = _interopRequireDefault(require("fast-deep-equal/react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var deepCompare = function deepCompare(name, prevProps, nextProps) {
  return (0, _fastDeepEqual["default"])((0, _lodash.get)(prevProps, name), (0, _lodash.get)(nextProps, name));
};

exports.deepCompare = deepCompare;

var deepReactCompare = function deepReactCompare(name, prevProps, nextProps) {
  return (0, _react["default"])((0, _lodash.get)(prevProps, name), (0, _lodash.get)(nextProps, name));
};

exports.deepReactCompare = deepReactCompare;

var deepReactCompareAll = function deepReactCompareAll(prevProps, nextProps) {
  return (0, _react["default"])(prevProps, nextProps);
};

exports.deepReactCompareAll = deepReactCompareAll;

var compareOption = function compareOption(_ref, prevProps, nextProps) {
  var name = _ref.name,
      _ref$compare = _ref.compare,
      compare = _ref$compare === void 0 ? 'deep' : _ref$compare;

  switch (compare) {
    case 'deep':
      return deepCompare(name, prevProps, nextProps);

    case 'deepReact':
      return deepReactCompare(name, prevProps, nextProps);
  }
};

exports.compareOption = compareOption;

var compareAllProps = function compareAllProps(prevProps, nextProps) {
  var prevKeys = (0, _lodash.keys)(prevProps);
  var nextKeys = (0, _lodash.keys)(nextProps);
  if (!(0, _fastDeepEqual["default"])(prevKeys, nextKeys)) return false;
  return (0, _lodash.every)(prevKeys.map(function (key) {
    return deepCompare(key, prevProps, nextProps);
  }));
};

exports.compareAllProps = compareAllProps;

var compareProps = function compareProps() {
  var propNames = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return function (prevProps, nextProps) {
    return (0, _lodash.every)(propNames, function (propName) {
      return (0, _lodash.isString)(propName) ? compareOption({
        name: propName
      }, prevProps, nextProps) : compareOption(propName, prevProps, nextProps);
    });
  };
};

exports.compareProps = compareProps;

var compareListItem = function compareListItem(prevProps, nextProps) {
  return prevProps && prevProps.data && prevProps.index && prevProps.index === nextProps.index && (0, _fastDeepEqual["default"])((0, _lodash.get)(prevProps, "data.items[".concat(prevProps.index, "]")), (0, _lodash.get)(nextProps, "data.items[".concat(nextProps.index, "]")));
};

exports.compareListItem = compareListItem;