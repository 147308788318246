import 'react-contexify/dist/ReactContexify.css';
import React from 'react';
import { useItemActions } from '$hooks/item';
import { Icon, Button } from '$ui';

export default function TableActionBarButton({ id, original, disabled, index, rowActions }) {
  const actions = useItemActions(rowActions, original);
  //console.log('TableActionBarButton', actions);
  return (
    <Button
      id={id}
      size="sm"
      wrapperClassName="TableActionBarButton"
      disabled={disabled}
      variant={disabled ? 'light' : rowActions[index].color}
      onClick={() => !disabled && actions[index] && actions[index].onClick(original)}
    >
      <Icon
        style={{ display: 'inline-block', marginRight: '5px' }}
        icon={rowActions[index].icon}
      />
      {rowActions[index].label}
    </Button>
  );
}