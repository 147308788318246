import React, { Fragment, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { UiContext } from '$providers/UiProvider';
import LoadingText from '$ui/LoadingText';

export const ViewVariant = {
  LIST: 'list',
  TABLE: 'table'
};

export default function ViewVariants({ tableId, TableComponent, ListComponent, isLoading, ...props }) {
  const [{ viewVariant }] = useContext(UiContext);
  const location = useLocation();

  const returnPageNumber = () => {
    let values = queryString.parse(location.search);
    if (typeof values.page === 'undefined')
      return 1;
    return values.page;
  };
  if (isLoading)
    return <LoadingText />;
  return (
    <Fragment>
      {(ListComponent && viewVariant == ViewVariant.LIST || (viewVariant == ViewVariant.TABLE && !TableComponent)) && <ListComponent pageNumber={returnPageNumber()} {...props} />}
      {(TableComponent && viewVariant == ViewVariant.TABLE || (viewVariant == ViewVariant.LIST && !ListComponent)) && <TableComponent id={tableId} pageNumber={returnPageNumber()} {...props} />}
    </Fragment>
  );
}