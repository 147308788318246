import React from 'react';
import { Row, Col } from 'reactstrap';
import { __ } from '@stktk/locales';
import ListControls from '$ui/list/ListControls';
import ListVirtualized from '$ui/list/ListVirtualized';
import ListItem from '$ui/list/ListItem';
import ListItemSku from '$ui/list/ListItemSku';
import ListItemActions from '$ui/list/ListItemActions';

const SkuListVirtualized = ({ items, itemActions, itemHandlers = {}, itemSize = 81, disableWidth }) => (
  <ListVirtualized
    disableWidth={disableWidth}
    itemCount={items.length}
    itemSize={itemSize}
    ItemRenderer={({ key, index, style = {}}) => {
      let view, otherActions;
      const sku = items[index];
      if (itemActions)
        [view, ...otherActions] = itemActions;
      const listItemProps = itemHandlers.onItemClick || view ? { tag: 'div', onClick: () => view ? view.action(sku) : itemHandlers.onItemClick(sku) } : {};
      return (
        <ListItem key={key} style={style} className="SkuListItem pointer-event p-0 w-100" {...listItemProps}>
          <Row noGutters className="d-flex flex-row flex-wrap justify-content-between">
            <Col sm="12" md={otherActions && otherActions.length ? 10 : 12} className="d-flex flex-row flex-grow-1">
              <ListItemSku sku={sku} horisontal={!otherActions || !otherActions.length} />
            </Col>
            {otherActions && otherActions.length > 0 && (
              <Col sm="12" md="2" className="d-flex flex-row flex-wrap justify-content-end align-items-center flex-grow-1">
                <ListItemActions
                  id={sku.id}
                  item={sku}
                  itemActions={otherActions}
                />
              </Col>
            )}
          </Row>
        </ListItem>
      );
    }}
  />
);

export default SkuListVirtualized;