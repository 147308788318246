import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { UiContext } from './UiProvider.jsx';

export default function ScreenOverlay () {
  const [{ showScreenOverlay }] = useContext(UiContext);
  return (
    <motion.div
      data-testid="ScreenOverlay"
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 100000,
        display: 'none',
        opacity: 0
      }}
      animate = {{
        opacity: showScreenOverlay ? 1 : 0,
        display: 'block',
        transitionEnd: {
          display: showScreenOverlay ? 'block' : 'none'
        }
      }}
    />
  );
}