import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { __ } from '@stktk/locales';
import { Button } from '$ui';
import { useLoading } from '$hooks/ui';
import { useModal, ModalTypeEnum } from '$hooks/modal';

export default function ConfirmModal({ id = 'generalConfirmModal' }) {
  const [modal, setModal] = useModal(ModalTypeEnum.CONFIRM, id);
  const [isLoading, setLoading] = useLoading(id);

  const handleHide = () => {
    if (modal.onDismiss)
      return modal.onDismiss(() => setModal({ ...modal, isOpen: false }));
    return setModal({ ...modal, isOpen: false });
  };

  const execute = () => {
    if (modal.callback) {
      setLoading(true);
      return modal.callback(() => {
        setLoading(false);
        return setModal({ ...modal, isOpen: false });
      });
    }
    return setModal({ ...modal, isOpen: false });
  };

  let classNames = modal.variant ? `text-${modal.variant}` : '';
  return (
    <Modal id={`Modal-${modal.variant}`} isOpen={modal.isOpen} color={modal.variant} toggle={handleHide}>
      <ModalHeader toggle={handleHide} cssModule={{ 'modal-title': classNames }}>
        {modal.title}
      </ModalHeader>
      <ModalBody>
        <p>{modal.text}</p>
      </ModalBody>
      <ModalFooter>
        <Button
          id={'Modal-Button-Cancel'}
          data-testid={'ModalButtonCancel'}
          type="button"
          variant="default"
          data-dismiss="modal"
          onClick={handleHide}
        >
          {__('app_modal_remove_cancel')}
        </Button>
        {modal.callback &&
          <Button
            id={'Modal-Button-Confirm'}
            data-testid={'ModalButtonConfirm'}
            type="button"
            variant={modal.variant}
            isLoading={isLoading}
            onClick={execute}
          >
            {__('app_modal_confirm')}
          </Button>
        }
      </ModalFooter>
    </Modal>
  );
}