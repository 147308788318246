"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.flyweightI18n = void 0;

var _i18next = _interopRequireDefault(require("i18next"));

var _locales = require("./locales.js");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var defaultLanguage = 'en';
var instances = {};

var flyweightI18n = function () {
  return {
    get: function get(language) {
      if (!_locales.locales.findByCountry(language, {})) return this.get(defaultLanguage);
      if (!instances[language]) instances[language] = _i18next["default"].createInstance({
        lng: language,
        fallbackLng: defaultLanguage,
        resources: _defineProperty({}, language, {
          translation: _locales.locales.findByCountry(language, {})
        })
      });
      return instances[language];
    }
  };
}();

exports.flyweightI18n = flyweightI18n;