"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = useBarForm;

var _react = require("react");

var _reactRedux = require("react-redux");

var _lodash = require("lodash");

var _commons = require("@stktk/commons");

var _redux = require("@stktk/redux");

var _tree = require("../../utils/tree");

var _useMount = _interopRequireDefault(require("../useMount"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function useBarForm(_ref) {
  var recordsets = _ref.recordsets,
      isDomainSelector = _ref.isDomainSelector,
      filter = _ref.filter,
      _ref$handleError = _ref.handleError,
      handleError = _ref$handleError === void 0 ? function () {} : _ref$handleError;
  var bars = (0, _reactRedux.useSelector)(isDomainSelector ? _redux.barDomainSelector : _redux.barSelector);
  var domainId = (0, _reactRedux.useSelector)(_redux.domainIdSelector);
  var restaurantId = (0, _reactRedux.useSelector)(_redux.restaurantIdSelector);
  var action = isDomainSelector ? (0, _redux.findDomainBars)({
    domainId: domainId
  }) : (0, _redux.findBars)({
    restaurantId: restaurantId
  });
  var isLoading = (0, _useMount["default"])(function () {
    return dispatch(action);
  }, handleError);
  var dispatch = (0, _reactRedux.useDispatch)();
  var options = (0, _react.useMemo)(function () {
    var optionBars = recordsets ? recordsets.map(function (_ref2) {
      var bar = _ref2.bar,
          id = _ref2.id;
      return _objectSpread(_objectSpread({}, bar), {}, {
        recordsetId: id
      });
    }) : filter ? bars.filter(function (bar) {
      return filter(bar, bars);
    }) : bars;
    return (0, _tree.treeDataToFlat)((0, _tree.flatDataToSortedTreeWithState)(optionBars)).map(function (bar) {
      var recordset = recordsets ? (0, _lodash.find)(recordsets, function (recordset) {
        return recordset.bar.id == bar.id;
      }) : null;
      return {
        value: recordsets ? bar.recordsetId : bar.id,
        label: bar.name,
        indentation: (0, _tree.getIndentation)(bar.hierarchyLevel),
        hierarchyLevel: bar.hierarchyLevel,
        isDisabled: recordset && (0, _lodash.get)(recordset, 'stockcheckId', false) && (0, _lodash.get)(recordset, 'status') == _commons.StocktakeStatusEnum.CLOSED
      };
    });
  }, [bars, recordsets, filter]);
  return {
    isLoading: isLoading,
    options: options,
    bars: bars
  };
}