import React, { createContext, useReducer } from 'react';
import ConfirmModal from './ConfirmModal';
import ScreenOverlay from './ScreenOverlay';
import NoticeProvider from './NoticeProvider';
import ToastProvider from './ToastProvider';
import { ViewVariant } from '$ui/ViewVariants';

export const uiInitialState = {
  isNavbarVerticalCollapsed: window.localStorage.getItem('minimized') === 'true' ? true : false,
  isNavbarVerticalHovered: false,
  isNavbarCollapsed: false,
  showBurgerMenu: false,
  showScreenOverlay: false,
  modal: {},
  notice: {},
  isLoading: { global: false },
  viewVariant: window.localStorage.getItem('viewVariant') || ViewVariant.LIST,
  breadcrumbs: []
};

function reducer(state, action) {
  switch (action.type) {
    case 'setNavbarVerticalCollapsed':
      window.localStorage.setItem('minimized', action.value ? 'true' : 'false');
      return { ...state, isNavbarVerticalCollapsed: action.value };
    case 'setNavbarCollapsed':
      return { ...state, isNavbarCollapsed: action.value };
    case 'setShowBurgerMenu':
      return { ...state, showBurgerMenu: action.value };
    case 'setShowScreenOverlay':
      return { ...state, showScreenOverlay: action.value };
    case 'setNavbarVerticalHovered':
      return { ...state, isNavbarVerticalHovered: action.value };
    case 'setModal':
      return { ...state, modal: { ...state.modal, [action.modalType]: { ...(state.modal[action.modalType] || {}), [action.id]: action.value }}};
    case 'setNotice':
      return { ...state, notice: { ...state.notice, [action.id]: action.value }};
    case 'setLoading':
      return { ...state, isLoading: { ...state.isLoading, [action.id]: action.value }};
    case 'setViewVariant':
      window.localStorage.setItem('viewVariant', action.value);
      return { ...state, viewVariant: action.value };
    case 'updateBreadcrumbs':
      return { ...state, breadcrumbs: Array.isArray(action.value) ? action.value : [action.value] };
    default:
      return state;
  }
}

export const UiContext = createContext(uiInitialState);

export default function UiProvider({ children }) {
  return (
    <UiContext.Provider value={useReducer(reducer, uiInitialState)}>
      <ScreenOverlay />
      <ConfirmModal />
      {/* <JoyrideWalkthrough /> */}
      <NoticeProvider />
      <ToastProvider />
      {children}
    </UiContext.Provider>
  );
}