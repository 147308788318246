class CapabilitiesManager {

  user = null;
  domain = null;

  constructor(user, domain) {
    this.user = user;
    this.domain = domain;
  }

  isEligible(domainUserRoles) {
    const { domainUser } = this.user.domains.find(({ id }) => id === this.domain.id);
    let eligible = domainUserRoles.includes(domainUser.role);
    // console.log('user', this.user, domainUserRoles, eligible);
    return eligible;
  }

}

export { CapabilitiesManager };

export default CapabilitiesManager;