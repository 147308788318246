import React from 'react';
import classNames from 'classnames';
import { Container } from 'reactstrap';
import Background from './Background';

export default function Section({ fluid = false, bg, image, overlay, position, video, bgClassName, className, containerClassName, children, ...props }) {
  const bgProps = { image, overlay, position, video };
  bgClassName && (bgProps.className = bgClassName);

  return (
    <section className={classNames({ [`bg-${bg}`]: bg }, className)} {...props}>
      {image && <Background {...bgProps} />}
      <Container fluid={fluid} className={containerClassName}>{children}</Container>
    </section>
  );
}