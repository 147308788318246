"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = usePackageClosureForm;

var _formik = require("formik");

var _reactRedux = require("react-redux");

var _redux = require("@stktk/redux");

var _commons = require("@stktk/commons");

var _locales = require("@stktk/locales");

var _useMount = _interopRequireDefault(require("../useMount"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function usePackageClosureForm(_ref) {
  var handleError = _ref.handleError,
      name = _ref.name,
      nameId = _ref.nameId;

  var _useFormikContext = (0, _formik.useFormikContext)(),
      setFieldValue = _useFormikContext.setFieldValue,
      setFieldTouched = _useFormikContext.setFieldTouched,
      touched = _useFormikContext.touched,
      errors = _useFormikContext.errors,
      values = _useFormikContext.values;

  var packageClosures = (0, _reactRedux.useSelector)(_redux.packageClosureSelector);
  var domainId = (0, _reactRedux.useSelector)(_redux.domainIdSelector);
  var dispatch = (0, _reactRedux.useDispatch)();
  var isLoading = (0, _useMount["default"])(function () {
    return dispatch((0, _redux.findPackageClosures)({
      domainId: domainId
    }));
  }, handleError);
  var options = [_commons.PackageClosure.NONE, _commons.PackageClosure.DEFAULT].concat(_toConsumableArray(packageClosures.map(function (closure) {
    return new _commons.PackageClosure({
      closed: true,
      name: closure.name,
      id: closure.id
    });
  }))).map(function (packageClosure) {
    return {
      value: packageClosure,
      label: (0, _locales.__packageClosure)({
        packageClosure: packageClosure
      })
    };
  });
  var selectedOption = options.find(function (_ref2) {
    var value = _ref2.value;
    return value && value.closed === values[name] && value.id === values[nameId];
  });

  var handleChange = function handleChange(selected) {
    var _selected$value = selected.value,
        id = _selected$value.id,
        closed = _selected$value.closed;
    setFieldValue(name, closed);
    setFieldValue(nameId, id);
  };

  var handleBlur = function handleBlur() {
    setFieldTouched(name, true);
    setFieldTouched(nameId, true);
  };

  var error = errors[name] || errors[nameId];
  var touch = touched[name] || touched[nameId];
  return {
    isLoading: isLoading,
    selectedOption: selectedOption,
    options: options,
    error: error,
    touch: touch,
    handleChange: handleChange,
    handleBlur: handleBlur
  };
}