import React, { Component } from 'react';
import Icon from './Icon';

export default class InfoTooltip extends Component {
  render() {
    return (
      <span
        style={{ margin: '0.5rem', fontSize: 'small' }}
      >
        <Icon
          type="light"
          icon="info-circle"
          tooltipPlacement={'top'}
          tooltip={this.props.info}
        />
      </span>
    );
  }
}