import React from 'react';
import ImageLoader from 'react-imageloader';
import classNames from 'classnames';
import { getImagePreloaderUrl, getSkuImageUrl } from '@stktk/logic/utils/sku.js';

const SkuImagePreloader = () => <img src={getImagePreloaderUrl()} />;

const SkuImage = ({ sku, responsive, ...props }) => {
  const imgProps = {
    className: classNames({ 'w-100': responsive }),
    ...props
  };
  return (
    <div>
      <ImageLoader imgProps={imgProps} src={getSkuImageUrl(sku)} preloader={SkuImagePreloader}>
        <img src={getSkuImageUrl(sku)} />
      </ImageLoader>
    </div>
  );
};

export default SkuImage;