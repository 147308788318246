import React from 'react';
import { __ } from '@stktk/locales';

const defaultStyle = { width: '100%', height: '100%', border: 'none' };

export const IFrame = ({ style, notSupported, ...props }) => (
  <iframe
    style={(style ? ({ ...defaultStyle, ...style }) : defaultStyle)}
    {...props}
  >
    {notSupported || __('error_iframe_not_supported')}
  </iframe>
);

export default React.memo(IFrame);