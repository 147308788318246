"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTotalPrice = exports.getFindRecordsPromise = exports.countUnevaluatedRecords = exports.checkIfValue0 = exports.checkIfSkuEvaluated = exports.checkIfRecordEvaluated = exports.checkIfNullValue = exports.checkIfEvaluated = exports.PriceTypeEnum = void 0;

var _lodash = require("lodash");

var _commons = require("@stktk/commons");

var _record = require("./record");

var PriceTypeEnum = {
  stocktake: {
    purchase: 'netStocktakePurchaseValue',
    sale: 'netStocktakeSaleValue'
  },
  loss: {
    purchase: 'netLossPurchaseValue',
    sale: 'netLossSaleValue'
  },
  order: {
    purchase: 'netPurchasePrice',
    sale: 'netSalePrice'
  },
  sale: {
    purchase: 'netPurchasePrice',
    sale: 'netSalePrice'
  },
  storage_transfer: {
    purchase: 'netPurchasePrice',
    sale: 'netSalePrice'
  },
  sku: {
    purchase: 'netPurchasePrice',
    sale: 'netSalePrice'
  }
};
exports.PriceTypeEnum = PriceTypeEnum;

var getTotalPrice = function getTotalPrice(records, recordProperty, skuProperty) {
  return (0, _lodash.sumBy)(records, function (record) {
    return (0, _commons.getTotalRecordsSkuPortionPrice)([record], record.sku, recordProperty, skuProperty);
  });
};

exports.getTotalPrice = getTotalPrice;

var checkIfValue0 = function checkIfValue0(record, recordset, property) {
  return (0, _lodash.includes)((0, _lodash.map)(property, function (item) {
    if ((0, _commons.getSkuDetails)(record.sku, 'domain')[PriceTypeEnum['sku'][item]] == 0 || (0, _lodash.get)(record, "[".concat(PriceTypeEnum[recordset][item], "]"), null) == 0) return true;else return false;
  }), true);
};

exports.checkIfValue0 = checkIfValue0;

var checkIfRecordEvaluated = function checkIfRecordEvaluated(record, recordset, properties) {
  return !(0, _lodash.includes)((0, _lodash.map)(properties, function (item) {
    if ((0, _lodash.get)(record, "[".concat(PriceTypeEnum[recordset][item], "]"), null) > 0) return true;else return false;
  }), false);
};

exports.checkIfRecordEvaluated = checkIfRecordEvaluated;

var checkIfSkuEvaluated = function checkIfSkuEvaluated(record, recordset, properties) {
  return !(0, _lodash.includes)((0, _lodash.map)(properties, function (item) {
    if ((0, _commons.getSkuDetails)(record.sku, 'domain')[PriceTypeEnum['sku'][item]] > 0) return true;else return false;
  }), false);
};

exports.checkIfSkuEvaluated = checkIfSkuEvaluated;

var checkIfNullValue = function checkIfNullValue(record, recordset, properties) {
  return (0, _lodash.includes)((0, _lodash.map)(properties, function (item) {
    if ((0, _commons.getSkuDetails)(record.sku, 'domain')[PriceTypeEnum['sku'][item]] == 0 || (0, _lodash.get)(record, "[".concat(PriceTypeEnum[recordset][item], "]"), null) == 0) return true;else return false;
  }), true);
};

exports.checkIfNullValue = checkIfNullValue;

var countUnevaluatedRecords = function countUnevaluatedRecords(records, recordsetType, properties) {
  return (0, _lodash.filter)(records, function (record) {
    return checkIfEvaluated(record, recordsetType, properties);
  }).length;
};

exports.countUnevaluatedRecords = countUnevaluatedRecords;

var checkIfEvaluated = function checkIfEvaluated(record, recordsetType, properties) {
  return !(0, _lodash.includes)((0, _lodash.map)(properties, function (item) {
    if ((0, _commons.getSkuDetails)(record.sku, 'domain')[PriceTypeEnum['sku'][item]] != null || (0, _lodash.get)(record, "[".concat(PriceTypeEnum[recordsetType][item], "]"), null) != null) return true;else return false;
  }), false);
};

exports.checkIfEvaluated = checkIfEvaluated;

var getFindRecordsPromise = function getFindRecordsPromise(_ref) {
  var recordsetType = _ref.recordsetType,
      recordsetGroupId = _ref.recordsetGroupId,
      recordsetId = _ref.recordsetId,
      recordStatus = _ref.recordStatus,
      domainId = _ref.domainId,
      restaurantId = _ref.restaurantId,
      dispatch = _ref.dispatch,
      bars = _ref.bars;
  return function () {
    return new Promise(function (resolve, reject) {
      return dispatch((0, _record.getFindRecordsFunction)({
        recordsetType: recordsetType,
        recordsetGroupId: recordsetGroupId,
        recordsetId: recordsetId,
        domainId: domainId,
        restaurantId: restaurantId
      })).then(function (records) {
        var filteredRecords = recordStatus ? records.filter(function (record) {
          return record.status === recordStatus;
        }).filter(function (record) {
          if (recordsetType === _commons.RecordsetTypeEnum.STORAGE_TRANSFER && bars && Array.isArray(bars)) return bars.find(function (bar) {
            return (bar === null || bar === void 0 ? void 0 : bar.id) && (record === null || record === void 0 ? void 0 : record.barId) && (bar === null || bar === void 0 ? void 0 : bar.id) === (record === null || record === void 0 ? void 0 : record.barId);
          });
          return true;
        }) : records;
        console.log('RecordsetStatistics records', {
          records: records,
          filteredRecords: filteredRecords
        });
        return resolve({
          recordsetType: recordsetType,
          records: records,
          totalSaleData: (0, _lodash.sumBy)(filteredRecords, function (record) {
            return record.quantity * (record.netSalePrice || 0);
          }),
          totalPurchaseData: getTotalPrice(recordsetType === _commons.RecordsetTypeEnum.ORDER ? filteredRecords.filter(function (_ref2) {
            var status = _ref2.status;
            return status === _commons.OrderRecordStatusEnum.ORDERED;
          }) : filteredRecords, PriceTypeEnum[recordsetType]['purchase'], PriceTypeEnum.sku['purchase']),
          totalArrivedPurchaseData: getTotalPrice(recordsetType === _commons.RecordsetTypeEnum.ORDER ? filteredRecords.filter(function (_ref3) {
            var status = _ref3.status;
            return status === _commons.OrderRecordStatusEnum.ARRIVED;
          }) : filteredRecords, PriceTypeEnum[recordsetType]['purchase'], PriceTypeEnum.sku['purchase'])
        });
      })["catch"](reject);
    });
  };
};

exports.getFindRecordsPromise = getFindRecordsPromise;