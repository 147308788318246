import React, { Component } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import InfoTooltip from '$ui/InfoTooltip';

export default class NavSelect extends Component {
  handleSelect = value => {
    if (this.props.onChange)
      this.props.onChange(value);
  }
  render() {
    const { value, options, onChange, ...props } = this.props;
    return (
      <Nav {...props}>
        {options.map((option, i) =>
          <NavSelectItem
            key={i}
            id={`NavSelectItem-${option.label.replace(/\s/g, '-')}-${option.value}`}
            active={(option.value == value)}
            label={option.label}
            value={option.value}
            isDisabled={option.isDisabled}
            onSelect={this.handleSelect}
          />
        )}
        {this.props.info &&
        <InfoTooltip info={this.props.info}/>
        }
      </Nav>
    );
  }
}

export class NavSelectItem extends Component {
  handleClick = e => {
    e.preventDefault();
    const { value } = this.props;
    this.props.onSelect(value);
  }
  render() {
    const { label, value, active, isDisabled, onClick, onSelect, ...props } = this.props;
    return (
      <NavItem active={active} {...props}>
        <NavLink
          className={value}
          href="#"
          active={active}
          disabled={isDisabled}
          onClick={this.handleClick}
        >
          {label}
        </NavLink>
      </NavItem>
    );
  }
}