import { __ } from '@stktk/locales';
import { toast } from '$providers/ToastProvider';

export const globalHandleErrorWithContext = context => error => {
  let title = __('ec_server_error_title');
  let variant = 'error';
  console.error('error', new Date(), error, 'error.stack:', error.stack);
  // console.log('errror.errors', error.errors);
  // console.log('error.message', error.message);
  let translateWithContext = e => __(e.message, { ...e, ...context });
  let description = error.errors && error.errors.length ? error.errors.map(e => e ? translateWithContext(e) : null) : translateWithContext(error);
  toast(title, description, variant);
};

export const globalHandleError = globalHandleErrorWithContext({});

export const globalHandleErrorWithCallback = callback => {
  callback();
  return globalHandleError;
};