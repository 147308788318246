import React, { forwardRef } from 'react';
import { default as ReactSelect, Creatable, components } from 'react-select';
import { __ } from '@stktk/locales';

const getTestableContainer = dataTestId => commonProps => (
  <components.SelectContainer
    {...commonProps}
    innerProps={({ ...commonProps.innerProps, 'data-testid': dataTestId })}
  />
);

const Select = ({ isCreatable = false, id, name, disabled, components = {}, styles = {}, placeholder = `${__('placeholder_select')}`, ...props }, ref) => {
  const selectProps = {
    id,
    name,
    menuPosition: 'fixed',
    isDisabled: disabled || false,
    classNamePrefix: 'ReactSelect Select',
    styles: {
      menu: menuStyles => ({ ...menuStyles, zIndex: 1100 }),
      menuPortal: menuPortalStyles => ({ ...menuPortalStyles, zIndex: 1100 }),
      ...styles
    },
    placeholder,
    noOptionsMessage: () => __('app_select_no_options'),
    components: {
      SelectContainer: getTestableContainer(`Select-${name || id}`),
      ...components
    },
    ...props
  };
  return isCreatable ? (
    <Creatable
      ref={ref}
      createOptionPosition="first"
      formatCreateLabel={value => `${__('select_create')}: "${value}"`}
      {...selectProps}
    />
  ) : (
    <ReactSelect ref={ref} {...selectProps} />
  );
};

export default forwardRef(Select);