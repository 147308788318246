import React from 'react';
import { Instagram } from 'react-content-loader';
import loadable from '@loadable/component';

const options = { fallback: <Instagram /> };

export const Webapp = loadable(() => import('$components/Webapp'), options);

export const Login = loadable(() => import('$views/auth/Login'), options);

export const Create = loadable(() => import('$views/auth/Create'), options);

export const Activate = loadable(() => import('$views/auth/Activate'), options);

export const Password = loadable(() => import('$views/auth/Password'), options);

export const Signup = loadable(() => import('$views/auth/Signup'), options);

export const Forgot = loadable(() => import('$views/auth/Forgot'), options);

export const Details = loadable(() => import('$views/auth/Details'), options);

export const NotFound = loadable(() => import('$views/NotFound'), options);

export const Tags = loadable(() => import('$views/Tags'), options);

export const Categories = loadable(() => import('$views/Categories'), options);

export const Dashboard = loadable(() => import('$views/Dashboard'), options);

export const Documents = loadable(() => import('$views/Documents'), options);

export const Import = loadable(() => import('$views/import/Import'), options);

export const Restaurants = loadable(() => import('$views/Restaurants'), options);

export const Stocktakes = loadable(() => import('$views/Stocktakes'), options);

export const Orders = loadable(() => import('$views/Orders'), options);

export const OrderGroup = loadable(() => import('$views/OrderGroup'), options);

export const OrderGroups = loadable(() => import('$views/OrderGroups'), options);

export const Order = loadable(() => import('$views/Order'), options);

export const OrdersPricing = loadable(() => import('$views/OrdersPricing'), options);

export const Losses = loadable(() => import('$views/Losses'), options);

export const LossGroups = loadable(() => import('$views/LossGroups'), options);

export const Settings = loadable(() => import('$views/Settings'), options);

export const StorageStandards = loadable(() => import('$views/StorageStandards'), options);

export const Storage = loadable(() => import('$views/Storage'), options);

export const Sales = loadable(() => import('$views/Sales'), options);

export const Sale = loadable(() => import('$views/Sale'), options);

export const Stocktake = loadable(() => import('$views/Stocktake'), options);

export const Profile = loadable(() => import('$views/Profile'), options);

export const Sku = loadable(() => import('$views/Sku'), options);

export const Skus = loadable(() => import('$views/Skus'), options);

export const Recipes = loadable(() => import('$views/Skus'), options);

export const Bars = loadable(() => import('$views/Bars'), options);

export const Supplier = loadable(() => import('$views/Supplier'), options);

export const Suppliers = loadable(() => import('$views/Suppliers'), options);

export const Report = loadable(() => import('$views/Report'), options);

export const Reports = loadable(() => import('$views/Reports'), options);

export const Members = loadable(() => import('$views/Members'), options);

export const PackageClosures = loadable(() => import('$views/PackageClosures'), options);

export const StorageTransfer = loadable(() => import('$views/StorageTransfer'), options);

export const StorageTransferGroups = loadable(() => import('$views/StorageTransferGroups'), options);

export const DataMapping = loadable(() => import('$views/DataMapping'), options);

export const ProductionGroups = loadable(() => import('$views/ProductionGroups'), options);

export const Production = loadable(() => import('$views/Production'), options);