import React, { Fragment } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { __ } from '@stktk/locales';

const ToggleButton = ({ isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed }) => (
  <Fragment>
    <UncontrolledTooltip placement="bottom" target="toggleNavigationTooltip">
      {__('app_toggle_navigation')}
    </UncontrolledTooltip>
    <div className="toggle-icon-wrapper">
      <Button
        color="link"
        className="navbar-toggler-humburger-icon navbar-vertical-toggle"
        id="toggleNavigationTooltip"
        onClick={() => {
          setIsNavbarVerticalCollapsed(!isNavbarVerticalCollapsed);
        }}
      >
        <span className="navbar-toggle-icon">
          <span className="toggle-line" />
        </span>
      </Button>
    </div>
  </Fragment>
);

export default ToggleButton;