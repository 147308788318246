export function getRoutePath(params, page, ids) {
  let { restaurantId, domainId, path } = params;
  let elements = [domainId, restaurantId];
  let url = '/panel/';
  if (path)
    return path;
  if (page != 'dashboard')
    elements.push(page);
  if (Array.isArray(ids))
    ids.map(id => elements.push(id));
  else
    elements.push(ids);
  elements.map(element => {
    if (element)
      url += element + '/';
  });
  return url;
}

export const getPath = (router, page, ids) => getRoutePath(router.match.params, page, ids);