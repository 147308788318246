"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _commons = require("@stktk/commons");

var _validatorConstants = require("../validator/validatorConstants.js");

var _schemeConstants = require("./schemeConstants.js");

var _default = {
  find: {
    domainId: _validatorConstants.INTEGER.required([1])
  },
  create: {
    domainId: _validatorConstants.INTEGER.required([1]),
    name: _validatorConstants.STRING.required(64),
    weight: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    weightUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT))
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    name: _validatorConstants.STRING.required(64),
    weight: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    weightUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT))
  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1])
  }
};
exports["default"] = _default;