"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _recordsetCommonSchemes = require("./_recordsetCommonSchemes.js");

var _validatorConstants = require("../validator/validatorConstants.js");

var _commons = require("@stktk/commons");

var _default = {
  find: Object.assign({}, _recordsetCommonSchemes.recordsetCommonFindScheme, {
    stockcheckId: _validatorConstants.INTEGER.required([1])
  }),
  create: Object.assign({}, _recordsetCommonSchemes.recordsetCommonCreateScheme, {
    virtualPersonnel: _validatorConstants.ARRAY.required(_validatorConstants.INTEGER.required([1]), [1, 30]),
    stockcheckId: _validatorConstants.INTEGER.required([1]),
    barId: _validatorConstants.INTEGER.required([1])
  }),
  update: Object.assign({}, _recordsetCommonSchemes.recordsetCommonUpdateScheme, {
    virtualPersonnel: _validatorConstants.ARRAY.optional(_validatorConstants.INTEGER.required([1]), [0, 30]),
    status: _validatorConstants.ENUM.INTEGER.optional(_commons.StocktakeStatusEnum.toArray())
  }),
  destroy: Object.assign({}, _recordsetCommonSchemes.recordsetCommonDestroyScheme)
};
exports["default"] = _default;