import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Logo from '$ui/navbar/Logo';
import Section from '$ui/Section';
import Footer from '$ui/Footer';

const Auth = ({ children, fullsize }) => (
  <Section className="py-0 h-100 overflow-auto" containerClassName="min-vh-100 d-flex flex-column">
    <Row className="flex-center justify-content-center py-6 flex-grow-1">
      <Col sm={fullsize || 10} md={fullsize || 8} lg={fullsize || 6}>
        <Logo />
        <Card>
          <CardBody className="fs--1 font-weight-normal p-5">
            {children}
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Footer auth />
  </Section>
);

export default Auth;