import React, { Fragment, memo } from 'react';
import classNames from 'classnames';
import { Badge } from 'reactstrap';
import { get } from 'lodash';
import { Icon, CategoryBadge } from '$ui';
import { getSkuSizeMeasurement,   getSkuDetails } from '@stktk/commons';
import { __ } from '@stktk/locales';
import { SkuImage } from '$loadable/ui.jsx';
import { deepReactCompare } from '@stktk/logic/utils/memo';

export function ListItemSkuCategory({ sku }) {
  return (
    <div className="ListItemSku-category d-flex align-items-center">
      <CategoryBadge category={getSkuDetails(sku, "domain").category} />
    </div>
  );
}

export function ListItemSkuOfficial({ sku }) {
  return (
    <div className="ListItemSku-category d-flex align-items-center">
      <Badge style={{ display: 'inline-block', marginRight: 20, fontSize: '12px' }} color={sku.official ? 'success' : 'warning'}>
        {sku.official ? __('icon_sku_official') : __('icon_sku_unofficial')}
      </Badge>
    </div>
  );
}

export function ListItemSkuTags({ sku }) {
  if (!getSkuDetails(sku, "domain")?.tags?.length)
    return null;
  return (
    <div className="ListItemSku-tags d-flex align-items-center">
      <Icon className="mr-2" icon="tags" size="md" />
      {getSkuDetails(sku, "domain").tags.map(tag => tag.id && tag.name && (
        <Badge
          key={`tag-${tag.id}`}
          pill
          tag="div"
          className="d-block mr-2"
          style={{ backgroundColor: tag.rgbColor || 'gray', fontSize: '11px' }}
        >
          {tag.name}
        </Badge>
      ))}
    </div>
  );
}

export function ListItemSkuName({ sku }) {
  return (
    <div className="ListItemSku-name" data-testid='ListItemSku-name' data-name={sku.name}>
      <span>{sku.name}</span>
      {sku.barcode && <span className='text-muted ml-2 fs--1'>{`EAN: ${sku.barcode}`}</span>}
      {/* FIXME: Oh no! Don't use window for that!  */}
      {window.stktk_showSkuIds && <span className='text-muted ml-2 fs--1'>{`(id: ${sku.id})`}</span>}
    </div>
  );
}

export function ListItemSkuSize({ sku }) {
  return (
    <div className="ListItemSku-size">
      {getSkuSizeMeasurement(sku).localeDisplay({ precision: 2 })}
    </div>
  );
}

export const ListItemSkuLayout = ({ horisontal, sku }) => horisontal ? (
  <Fragment>
    <ListItemSkuName sku={sku} />
    <ListItemSkuSize sku={sku} />
    <ListItemSkuCategory sku={sku} />
    <ListItemSkuOfficial sku={sku} />
    <ListItemSkuTags sku={sku} />
  </Fragment>
) : (
  <Fragment>
    <div className="d-flex flex-row">
      <ListItemSkuCategory sku={sku} />
      <ListItemSkuOfficial sku={sku} />
      <ListItemSkuTags sku={sku} />
    </div>
    <ListItemSkuName sku={sku} />
    <ListItemSkuSize sku={sku} />
  </Fragment>
);

export function ListItemSku({ sku, horisontal, actions }, ref) {
  return (
    <div ref={ref} className={classNames('ListItemSku', { 'ListItemSku--horisontal': horisontal }, 'd-flex flex-row')}>
      <div className="ListItemSku-skuImage mr-4">
        <SkuImage sku={sku} />
      </div>
      <div className={classNames('ListItemSkuLayout d-flex', { 'flex-row align-items-center': horisontal, 'flex-column': !horisontal }, 'justify-content-center')}>
        <ListItemSkuLayout horisontal={horisontal} sku={sku} />
      </div>
      {actions && (
        <div className="d-flex flex-grow-1 justify-content-end align-items-center pr-2">
          {actions}
        </div>
      )}
    </div>
  );
}

export default memo(React.forwardRef(ListItemSku), (prev, next) => deepReactCompare('sku', prev, next));