import React, { Fragment, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Button from '$ui/Button';
import { __ } from '@stktk/locales';

const ButtonPrint = ({ PrintBody, data, item }) => {
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
      @media all {
        .page-break {
          display: none;
        }
      }

      @media print {
        html, body {
          height: initial !important;
          overflow: initial !important;
          -webkit-print-color-adjust: exact;
        }
      }

      @media print {
        .page-break {
          margin-top: 1rem;
          display: block;
          page-break-before: auto;
        }
      }

      @page {
        size: auto;
        margin: 20mm;
      }
    `
  });

  const handleClick = event => {
    event.preventDefault();
    handlePrint();
  };

  return (
    <Fragment>
      <div ref={printRef} className="d-none d-print-block">
        <PrintBody data={data} item={item} />
      </div>
      <Button text={__('action_print')} icon="print" onClick={handleClick} />
    </Fragment>
  );
};

export default ButtonPrint;