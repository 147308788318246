import React, { Component } from 'react';
import { connect, getIn } from 'formik';
import Select, { components } from 'react-select';
import { get } from 'lodash';
import { __, __countryName } from '@stktk/locales';
import { CountryEnum } from '@stktk/commons';

//FIXME: co to do kurwy nedzy tutaj robi?
const flagImg = code => 'https://s3-eu-west-1.amazonaws.com/stktk-public/img/flags/' + code.toUpperCase() + '.png';

export class FormCountry extends Component {

  constructor(props) {
    super(props);
    this.suggestedOptions = CountryEnum.toArraySuggested(props.suggested).map(this.getOption);
    this.otherOptions = CountryEnum.toArrayOthers(props.suggested).map(this.getOption);
  }

  componentDidMount() {
    const { focusOnRender } = this.props;
    if (focusOnRender) this.input.focus();
  }

  setCallingCodeByCountry = (name, country) => {
    const { formik } = this.props;
    const value = getIn(formik.values, name);
    let [callingCode] = get(CountryEnum, `[${country}].callingCodes`, []);
    if (callingCode.length && !value)
      formik.setFieldValue(name, `+${callingCode} `);
  }

  parseSelectValue = value => {
    let country = get(CountryEnum, value);
    if (country)
      return ({ value: country.alpha2Code, label: __countryName(country.alpha2Code) });
    return country;
  };

  handleSelectChange = item => {
    const { name, formik, phoneFields, submitOnBlur } = this.props;
    if (phoneFields)
      phoneFields.forEach(name => this.setCallingCodeByCountry(name, item.value));
    formik.setFieldValue(name, item.value);
    if (submitOnBlur)
      setTimeout(() => {
        formik.submitForm();
      }, 100);
  }

  handleBlur = () => {
    const { name, formik, submitOnBlur } = this.props;
    if (submitOnBlur) formik.submitForm();
    else formik.setFieldTouched(name, true);
  };

  getOption = country => ({
    value: country.alpha2Code,
    label: __countryName(country.alpha2Code),
    alpha2Code: country.alpha2Code
  });

  render() {
    let { className, formik, selectClassName, suggested, isDisabled, disabled, submitOnBlur, focusOnRender, ...props } = this.props;
    const error = getIn(formik.errors, props.name);
    const touch = getIn(formik.touched, props.name);
    const value = getIn(formik.values, props.name);
    let valid = '';
    if (touch)
      valid = error ? ' is-invalid' : ' is-valid';

    let combineClass = 'Select ' + (selectClassName ? ' ' + selectClassName : '') + valid;
    let idx = `FormCountry-select-${this.props.name}`;
    return (
      <Select
        ref={node => this.input = node}
        id={idx}
        data-testid={`Select-${props.name}`}
        cacheOptions={true}
        isDisabled={(isDisabled || disabled)}
        classNamePrefix="Select"
        className={combineClass}
        value={this.parseSelectValue(value)}
        menuPosition="fixed"
        components={{
          Option: props => {
            return (
              <components.Option {...props}>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <div><img src={flagImg(props.data.alpha2Code)} /></div>
                  <div
                    id={`${props.label.replace(/\s/g, '-')}`}
                    style={{ marginLeft: 10 }}
                  >
                    {props.label}
                  </div>
                </div>
              </components.Option>
            );
          }
        }}
        options={[
          {
            label: __('app_select_suggested'),
            options: this.suggestedOptions
          },
          {
            label: __('app_select_others'),
            options: this.otherOptions
          }
        ]}
        onChange={this.handleSelectChange}
        onBlur={this.handleBlur}
      />
    );
  }
}

export default connect(FormCountry);