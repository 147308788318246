import moment from 'moment';
import { get } from 'lodash';
import {
  getTotalRecordSkuNormalizedAmount,
  getRecordSkuNormalizedAmount,
  getSkuMeasureMeasurement,
  RecordModeEnum,
  SkuMeasureEnum,
  getRecordMeasureMeasurement,
  RecordMeasureEnum,
  getTotalRecordMeasurement
} from '@stktk/commons';

export const defaultSortMethod = (a, b) => {
  if (!a && b) return -1;
  if (a && !b) return 1;
  if (a === b) return 0;
  if (moment.isDate(a)) {
    return dateComparator(a, b);
  } else if (typeof a == 'string') {
    return textComparator(a, b);
  } else {
    return numberComparator(a, b);
  }
};

export const dateComparator = (date1, date2 /*, desc*/) => {
  if (!date1 && !date2) return 0;
  if (!date1) return -1;
  if (!date2) return 1;
  if (moment(date1).isBefore(date2)) return -1;
  else if (moment(date2).isBefore(date1)) return 1;
  return 0;
};

export const textComparator = (text1, text2 /*, desc*/) => {
  if (!text1 && !text2) return 0;
  else if (!text1) return -1;
  else if (!text2) return 1;
  return text1.localeCompare(text2);
};

export const measurementComparator = (measurement1, measurement2 /*, desc*/) =>
  measurement1.compareTo(measurement2);

export const skuMeasureComparator = (sku1, sku2, skuMeasure) =>
  measurementComparator(
    getSkuMeasureMeasurement(sku1, skuMeasure),
    getSkuMeasureMeasurement(sku2, skuMeasure)
  );

const recordComparator = (record1, sku1, record2, sku2, recordMeasure) =>
  measurementComparator(
    getRecordMeasureMeasurement(record1, sku1, recordMeasure),
    getRecordMeasureMeasurement(record2, sku2, recordMeasure)
  );

/**
 * @name skuComparator.
 * @function.
 * @param {Object} sku1.
 * @param {Object} sku2.
 * @returns {Number} the comparison result for sku1 and sku2. Result is < 0 if sku1 is lower than sku2, = 0 when SKUs are equal, > 0 if sku1 is bigger than sku2.
 * @description This method compares SKUs for table sorting purposes. Sorting orders: volume > weight > (none).
 */
export const skuComparator = (sku1, sku2 /*, desc*/) => {
  let sku1Volume = get(
    getSkuMeasureMeasurement(sku1, SkuMeasureEnum.VOLUME),
    'amount',
    ''
  );
  let sku2Volume = get(
    getSkuMeasureMeasurement(sku2, SkuMeasureEnum.VOLUME),
    'amount',
    ''
  );
  let sku1Weight = get(
    getSkuMeasureMeasurement(sku1, SkuMeasureEnum.PRODUCT_WEIGHT),
    'amount',
    ''
  );
  let sku2Weight = get(
    getSkuMeasureMeasurement(sku2, SkuMeasureEnum.PRODUCT_WEIGHT),
    'amount',
    ''
  );
  let compare = null;

  if (sku1Volume || sku2Volume) {
    compare = skuVolumeComparator(sku1, sku2);
  } else if (sku1Weight || sku2Weight) {
    compare = skuWeightComparator(sku1, sku2);
  } else return textComparator(sku1.name, sku2.name);

  if (!compare) {
    return textComparator(sku1.name, sku2.name);
  } else return -compare;
};

/**
 * @name recordTotalMeasureAmountComparator.
 * @function.
 * @param {Object} record1.
 * @param {Object} record2.
 * @returns {Number} the comparison result for record1 and record2. Result is < 0 if record1 is lower than record2, = 0 when Records are equal, > 0 if record1 is bigger than record2.
 * @description This method compares RECORDs for table sorting purposes. Sorting orders: volume > weight > quantity.
 */

export const recordTotalMeasureAmountComparator = (record1, record2) => {
  let { mode: mode1 } = record1;
  let { mode: mode2 } = record2;
  let compare = null;

  if (mode1 === mode2) {
    compare = recordTotalComparator(record1, record2);
  } else if (mode1 === RecordModeEnum.QUANTITY) {
    compare = -1;
  } else if (mode1 === RecordModeEnum.MASS) {
    if (mode2 === RecordModeEnum.QUANTITY) {
      compare = 1;
    } else if (mode2 === RecordModeEnum.VOLUME) {
      compare = -1;
    } else return compare = 0;
  } else if (mode1 === RecordModeEnum.VOLUME) {
    compare = 1;
  } else return null;//TODO: doesn't support sort cases when records mode are: MASS2VOLUME and MASS2MASS;

  if (!compare) {
    return 0;
  } else return compare;
};

export const recordTotalComparator = (record1, record2) => {
  return measurementComparator(
    getTotalRecordMeasurement(record1),
    getTotalRecordMeasurement(record2)
  );
};

export const recordTotalAmountComparator = (record1, record2, desc) => {
  const a = getTotalRecordSkuNormalizedAmount(record1, record1.sku);
  const b = getTotalRecordSkuNormalizedAmount(record2, record2.sku);
  return numberComparator(a, b, desc);
};

export const recordAmountComparator = (record1, record2, desc) => {
  const a = getRecordSkuNormalizedAmount(record1, record1.sku);
  const b = getRecordSkuNormalizedAmount(record2, record2.sku);
  return numberComparator(a, b, desc);
};

export const skuVolumeComparator = (sku1, sku2 /*, desc*/) =>
  skuMeasureComparator(sku1, sku2, SkuMeasureEnum.VOLUME);

export const skuWeightComparator = (sku1, sku2 /*, desc*/) =>
  skuMeasureComparator(sku1, sku2, SkuMeasureEnum.PRODUCT_WEIGHT);

export const recordScaleWeightComparator = (
  record1,
  sku1,
  record2,
  sku2 /*, desc*/
) =>
  recordComparator(
    record1,
    sku1,
    record2,
    sku2,
    RecordMeasureEnum.SCALE_WEIGHT
  );

export const recordProductWeightComparator = (
  record1,
  sku1,
  record2,
  sku2 /*, desc*/
) =>
  recordComparator(
    record1,
    sku1,
    record2,
    sku2,
    RecordMeasureEnum.PRODUCT_WEIGHT
  );

export const recordPackageClosureWeightComparator = (
  record1,
  sku1,
  record2,
  sku2 /*, desc*/
) =>
  recordComparator(
    record1,
    sku1,
    record2,
    sku2,
    RecordMeasureEnum.PACKAGE_CLOSURE_WEIGHT
  );

export const recordVolumeComparator = (
  record1,
  sku1,
  record2,
  sku2 /*, desc*/
) => recordComparator(record1, sku1, record2, sku2, RecordMeasureEnum.VOLUME);

export const numberComparator = (a, b /*, desc*/) => {
  if (a > b || b == null) {
    return 1;
  } else if (a < b || a == null) {
    return -1;
  } else return 0;
};

//funkcja ustalajaca szerokosc kolumny np comment, nieuzywana
export const getColumnWidth = (data, accessor, headerText) => {
  const cellLength = Math.max(
    ...data.map(row => {
      let value = '';

      if (typeof accessor === 'string') {
        value = get(row, accessor);
      } else {
        value = accessor(row);
      }

      if (typeof value === 'number') return value.toString().length;
      return (value || '').length;
    }),
    headerText.length
  );

  const magicSpacing = 12;
  return cellLength * magicSpacing;
};