import React from 'react';
import { Card, CardBody, Progress, Row, Col, Container } from 'reactstrap';
import classNames from 'classnames';

const Dot = ({ color, className, ...rest }) => (
  <span className={classNames('dot', { [`bg-${color}`]: !!color }, className)} {...rest} />
);

const StatusDot = ({ name, size, color }) => (
  <Col
    xs="auto"
    align="left"
    className='pl-2'
  >
    <Dot color={color} />
    <span>{name}</span>
    <span> ({size})</span>
  </Col>
);

const RecordsProgressBar = ({ color, percentage, isLast }) => (
  <Progress
    bar
    color={color}
    value={percentage}
    className={classNames({ 'border-right border-white border-2x': !isLast })}
  />
);

const isIterableArray = array => Array.isArray(array) && !!array.length;

const ProgressBar = ({ data, className }) => {
  const totalStorage = data.map(d => d.size).reduce((total, currentValue) => total + currentValue, 0);

  return (
    <Card className={className}>
      <CardBody
        align="center"
      >
        <div className="w-100">
          <Progress multi className="rounded-soft mb-3" style={{ height: 10 }}>
            {isIterableArray(data) &&
              data.map((d, index) => (
                <RecordsProgressBar
                  {...d}
                  percentage={(d.size * 100) / totalStorage}
                  isLast={data.length - 1 === index}
                  key={index}
                />
              ))}
          </Progress>
          <Row className="fs--1 font-weight-semi-bold text-500">
            {isIterableArray(data) &&
              data.map((d, index) => (
                <Container key={index} >
                  <StatusDot {...d} isFirst={index === 0} isLast={data.length - 1 === index} />
                </Container>
              ))}
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default ProgressBar;