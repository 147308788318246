import React from 'react';
import classNames from 'classnames';

export default function Flex ({ justify, align, inline = false, column = false, wrap = false, className, tag: Tag = 'div', children, ...props }) {
  return (
    <Tag
      className={classNames(
        {
          'd-flex': !inline,
          'd-inline-flex': inline,
          [`justify-content-${justify}`]: justify,
          [`align-items-${align}`]: align,
          'flex-column': column,
          'flex-wrap': wrap
        },
        className
      )}
      {...props}
    >
      {children}
    </Tag>
  );
}