"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTotalRecordsUnitNormalizedMeasurement = exports.getTotalRecordsUnitNormalizedAmount = exports.getTotalRecordsSkuPortionPrice = exports.getTotalRecordsSkuNormalizedAmount = exports.getTotalRecordUnitNormalizedMeasurement = exports.getTotalRecordUnitNormalizedAmount = exports.getTotalRecordSkuRecord = exports.getTotalRecordSkuQuantityRounding = exports.getTotalRecordSkuPortionPrice = exports.getTotalRecordSkuNormalizedAmount = exports.getTotalRecordSkuAmount = exports.getTotalRecordReportAmount = exports.getTotalRecordMeasurement = exports.getTotalRecordAmount = exports.getSkuMeasurementRecord = exports.getRecordsUnitNormalizedMeasurement = exports.getRecordsUnitNormalizedAmount = exports.getRecordsSumRecord = exports.getRecordsSkuPortionPrice = exports.getRecordUnitNormalizedAmount = exports.getRecordUnit = exports.getRecordSkuPortionPrice = exports.getRecordSkuNormalizedAmount = exports.getRecordSkuAmount = exports.getRecordMeasurement = exports.getRecordMeasureMeasurement = exports.getRecordAmount = exports.getDefaultRecordModeByUnitId = void 0;

var _commonUtils = require("./commonUtils");

var _Measurement = _interopRequireDefault(require("./Measurement"));

var _constants = require("./constants");

var _skuUtils = require("./skuUtils.js");

var _constructors = require("./constructors.js");

var _lodash = require("lodash");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/* eslint-disable complexity */
var getTotalRecordUnitNormalizedAmount = function getTotalRecordUnitNormalizedAmount(record, sku, unitId) {
  return record.quantity * getRecordUnitNormalizedAmount(record, sku, unitId);
};

exports.getTotalRecordUnitNormalizedAmount = getTotalRecordUnitNormalizedAmount;

var getRecordUnitNormalizedAmount = function getRecordUnitNormalizedAmount(record, sku, unitId) {
  var amount = null;
  var unit1 = null;

  var unit2 = _constants.UnitEnum.get(unitId);

  switch ((0, _lodash.get)(record, "mode", null)) {
    case _constants.RecordModeEnum.QUANTITY:
      {
        if (sku.stocktakeMode == _constants.SkuStocktakeModeEnum.QUANTITY) {
          amount = getRecordAmount(record);
          unit1 = _constants.UnitEnum.ITEM;
        } else {
          amount = getRecordAmount(record) * (0, _skuUtils.getSkuAmount)(sku);
          unit1 = (0, _skuUtils.getSkuUnit)(sku);
        }

        break;
      }

    case _constants.RecordModeEnum.MASS2MASS:
    case _constants.RecordModeEnum.MASS:
      {
        amount = getRecordAmount(record);
        unit1 = _constants.UnitEnum.get(record.productWeightUnitId);
        break;
      }

    case _constants.RecordModeEnum.MASS2VOLUME:
    case _constants.RecordModeEnum.VOLUME:
      {
        amount = getRecordAmount(record);
        unit1 = _constants.UnitEnum.get(record.volumeUnitId);
        break;
      }
  }

  if (!unit1 || !unit2 || unit1.type != unit2.type) return null;
  return (0, _commonUtils.convertAmount)(amount, unit1.id, unit2.id);
};

exports.getRecordUnitNormalizedAmount = getRecordUnitNormalizedAmount;

var getTotalRecordSkuNormalizedAmount = function getTotalRecordSkuNormalizedAmount(record, sku) {
  return getTotalRecordSkuAmount(record, sku) / (0, _skuUtils.getSkuAmount)(sku);
};

exports.getTotalRecordSkuNormalizedAmount = getTotalRecordSkuNormalizedAmount;

var getTotalRecordSkuQuantityRounding = function getTotalRecordSkuQuantityRounding(record, sku) {
  var roundMethod = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (x) {
    return (0, _lodash.round)(x, 3);
  };
  return roundMethod(getTotalRecordSkuNormalizedAmount(record, sku));
};

exports.getTotalRecordSkuQuantityRounding = getTotalRecordSkuQuantityRounding;

var getTotalRecordSkuRecord = function getTotalRecordSkuRecord(record, sku) {
  var roundMethod = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (x) {
    return (0, _lodash.round)(x, 3);
  };

  switch (sku.stocktakeMode) {
    case _constants.SkuStocktakeModeEnum.VOLUME:
      {
        var volumeMeasurement = (0, _skuUtils.getSkuMeasureMeasurement)(sku, _constants.SkuMeasureEnum.VOLUME);
        return Object.assign({}, record, {
          quantity: getTotalRecordSkuQuantityRounding(record, sku, roundMethod),
          //mode: RecordModeEnum.VOLUME
          volume: volumeMeasurement.amount,
          volumeUnitId: volumeMeasurement.unit.id,
          productWeight: null,
          productWeightUnitId: null,
          scaleWeight: null,
          scaleWeightUnitId: null
        });
      }

    case _constants.SkuStocktakeModeEnum.MASS:
      {
        var productWeightMeasurement = (0, _skuUtils.getSkuMeasureMeasurement)(sku, _constants.SkuMeasureEnum.PRODUCT_WEIGHT);
        return Object.assign({}, record, {
          quantity: getTotalRecordSkuQuantityRounding(record, sku, roundMethod),
          //mode: RecordModeEnum.MASS
          volume: null,
          volumeUnitId: null,
          productWeight: productWeightMeasurement.amount,
          productWeightUnitId: productWeightMeasurement.unit.id,
          scaleWeight: null,
          scaleWeightUnitId: null
        });
      }

    case _constants.SkuStocktakeModeEnum.QUANTITY:
    case _constants.SkuStocktakeModeEnum.QUANTITY_VOLUME:
    case _constants.SkuStocktakeModeEnum.QUANTITY_MASS:
      return Object.assign({}, record, {
        quantity: getTotalRecordSkuQuantityRounding(record, sku, roundMethod),
        mode: _constants.RecordModeEnum.QUANTITY,
        volume: null,
        volumeUnitId: null,
        productWeight: null,
        productWeightUnitId: null,
        scaleWeight: null,
        scaleWeightUnitId: null
      });

    default:
      {
        return null;
      }
  }
};

exports.getTotalRecordSkuRecord = getTotalRecordSkuRecord;

var getRecordSkuNormalizedAmount = function getRecordSkuNormalizedAmount(record, sku) {
  return getRecordSkuAmount(record, sku) / (0, _skuUtils.getSkuAmount)(sku);
};

exports.getRecordSkuNormalizedAmount = getRecordSkuNormalizedAmount;

var getTotalRecordSkuAmount = function getTotalRecordSkuAmount(record, sku) {
  return record.quantity * getRecordSkuAmount(record, sku);
};

exports.getTotalRecordSkuAmount = getTotalRecordSkuAmount;

var getRecordSkuAmount = function getRecordSkuAmount(record, sku) {
  //FIXME: cleanup
  var volumeConverter = function volumeConverter() {
    return getRecordUnitNormalizedAmount(record, sku, sku.volumeUnitId);
  };

  var quantityConverter = function quantityConverter() {
    return getRecordUnitNormalizedAmount(record, sku, _constants.UnitEnum.ITEM.id);
  };

  var quantity2volumeConverter = function quantity2volumeConverter() {
    return getRecordUnitNormalizedAmount(record, sku, sku.volumeUnitId);
  };

  var quantity2productWeightConverter = function quantity2productWeightConverter() {
    return getRecordUnitNormalizedAmount(record, sku, sku.packageWeightGrossUnitId);
  };

  var weightConverter = function weightConverter() {
    return getRecordUnitNormalizedAmount(record, sku, sku.packageWeightGrossUnitId);
  };

  var productWeightConverter = function productWeightConverter() {
    return getRecordUnitNormalizedAmount(record, sku, sku.packageWeightGrossUnitId);
  };

  switch ((0, _lodash.get)(sku, "stocktakeMode", null)) {
    case _constants.SkuStocktakeModeEnum.VOLUME:
      return volumeConverter();

    case _constants.SkuStocktakeModeEnum.MASS:
      return weightConverter();

    case _constants.SkuStocktakeModeEnum.QUANTITY:
      return quantityConverter();

    case _constants.SkuStocktakeModeEnum.QUANTITY_MASS:
      return record.mode == _constants.RecordModeEnum.QUANTITY ? quantity2productWeightConverter() : productWeightConverter();

    case _constants.SkuStocktakeModeEnum.QUANTITY_VOLUME:
      return record.mode == _constants.RecordModeEnum.QUANTITY ? quantity2volumeConverter() : volumeConverter();

    default:
      return null;
  }
};

exports.getRecordSkuAmount = getRecordSkuAmount;

var getRecordUnit = function getRecordUnit(record) {
  switch ((0, _lodash.get)(record, "mode", null)) {
    case _constants.RecordModeEnum.QUANTITY:
      return _constants.UnitEnum.ITEM;

    case _constants.RecordModeEnum.MASS:
      return _constants.UnitEnum.get(record.productWeightUnitId);

    case _constants.RecordModeEnum.VOLUME:
      return _constants.UnitEnum.get(record.volumeUnitId);

    case _constants.RecordModeEnum.MASS2VOLUME:
      return _constants.UnitEnum.get(record.volumeUnitId);

    case _constants.RecordModeEnum.MASS2MASS:
      return _constants.UnitEnum.get(record.productWeightUnitId);

    default:
      return null;
  }
};

exports.getRecordUnit = getRecordUnit;

var getRecordAmount = function getRecordAmount(record) {
  switch ((0, _lodash.get)(record, "mode", null)) {
    case _constants.RecordModeEnum.QUANTITY:
      return 1;

    case _constants.RecordModeEnum.MASS:
      return record.productWeight || null;

    case _constants.RecordModeEnum.VOLUME:
      return record.volume || null;

    case _constants.RecordModeEnum.MASS2VOLUME:
      return record.volume || null;

    case _constants.RecordModeEnum.MASS2MASS:
      return record.productWeight || null;

    default:
      return null;
  }
};

exports.getRecordAmount = getRecordAmount;

var getTotalRecordAmount = function getTotalRecordAmount(record) {
  var amount = getRecordAmount(record);
  return amount && record.quantity != null ? record.quantity * amount : null;
};

exports.getTotalRecordAmount = getTotalRecordAmount;

var getRecordMeasurement = function getRecordMeasurement(record) {
  return new _Measurement["default"]({
    amount: getRecordAmount(record),
    unit: getRecordUnit(record)
  });
};

exports.getRecordMeasurement = getRecordMeasurement;

var getTotalRecordMeasurement = function getTotalRecordMeasurement(record) {
  return new _Measurement["default"]({
    amount: getTotalRecordAmount(record),
    unit: getRecordUnit(record)
  });
};

exports.getTotalRecordMeasurement = getTotalRecordMeasurement;

var getTotalRecordsSkuNormalizedAmount = function getTotalRecordsSkuNormalizedAmount(records, sku) {
  var amount = records.reduce(function (accumulator, record) {
    return accumulator + getTotalRecordSkuNormalizedAmount(record, sku);
  }, 0);
  return amount;
};

exports.getTotalRecordsSkuNormalizedAmount = getTotalRecordsSkuNormalizedAmount;

var getRecordsUnitNormalizedAmount = function getRecordsUnitNormalizedAmount(records, sku, unitId) {
  var amount = records.reduce(function (accumulator, record) {
    //let unitId = get(getSkuUnit(sku), 'id', null);
    return accumulator + getRecordUnitNormalizedAmount(record, sku, unitId);
  }, 0);
  return amount;
};

exports.getRecordsUnitNormalizedAmount = getRecordsUnitNormalizedAmount;

var getTotalRecordsUnitNormalizedAmount = function getTotalRecordsUnitNormalizedAmount(records, sku, unitId) {
  var amount = records.reduce(function (accumulator, record) {
    //let unitId = get(getSkuUnit(sku), 'id', null);
    return accumulator + getTotalRecordUnitNormalizedAmount(record, sku, unitId);
  }, 0);
  return amount;
};

exports.getTotalRecordsUnitNormalizedAmount = getTotalRecordsUnitNormalizedAmount;

var getTotalRecordUnitNormalizedMeasurement = function getTotalRecordUnitNormalizedMeasurement(record, sku) {
  return getTotalRecordsUnitNormalizedMeasurement([record], sku);
};

exports.getTotalRecordUnitNormalizedMeasurement = getTotalRecordUnitNormalizedMeasurement;

var getTotalRecordsUnitNormalizedMeasurement = function getTotalRecordsUnitNormalizedMeasurement(records, sku) {
  return new _Measurement["default"]({
    amount: getTotalRecordsUnitNormalizedAmount(records, sku, (0, _lodash.get)((0, _skuUtils.getSkuUnit)(sku), "id", null)),
    unit: (0, _skuUtils.getSkuUnit)(sku)
  });
};

exports.getTotalRecordsUnitNormalizedMeasurement = getTotalRecordsUnitNormalizedMeasurement;

var getRecordsUnitNormalizedMeasurement = function getRecordsUnitNormalizedMeasurement(records, sku) {
  return new _Measurement["default"]({
    amount: getRecordsUnitNormalizedAmount(records, sku, (0, _lodash.get)((0, _skuUtils.getSkuUnit)(sku), "id", null)),
    unit: (0, _skuUtils.getSkuUnit)(sku)
  });
};

exports.getRecordsUnitNormalizedMeasurement = getRecordsUnitNormalizedMeasurement;

var getRecordMeasureMeasurement = function getRecordMeasureMeasurement(record, sku, recordMeasure) {
  switch (recordMeasure) {
    case _constants.RecordMeasureEnum.VOLUME:
      {
        if (record.mode === _constants.RecordModeEnum.QUANTITY) return (0, _skuUtils.getSkuMeasureMeasurement)(sku, _constants.SkuMeasureEnum.VOLUME); //.multiply(record.quantity)

        return new _Measurement["default"]({
          amount: record.volume || null,
          unit: _constants.UnitEnum.get(record.volumeUnitId)
        });
      }

    case _constants.RecordMeasureEnum.PRODUCT_WEIGHT:
      {
        if (record.mode === _constants.RecordModeEnum.QUANTITY) return (0, _skuUtils.getSkuMeasureMeasurement)(sku, _constants.SkuMeasureEnum.PRODUCT_WEIGHT); //.multiply(record.quantity);
        else return new _Measurement["default"]({
          amount: record.productWeight || null,
          unit: _constants.UnitEnum.get(record.productWeightUnitId)
        });
      }

    case _constants.RecordMeasureEnum.SCALE_WEIGHT:
      {
        return new _Measurement["default"]({
          amount: record.scaleWeight || null,
          unit: _constants.UnitEnum.get(record.scaleWeightUnitId)
        });
      }

    case _constants.RecordMeasureEnum.PACKAGE_CLOSURE_WEIGHT:
      {
        var packageClosureId = record.packageClosureId,
            closed = record.closed;

        if (!packageClosureId) {
          //FIXME: ?
          if (closed === _constructors.PackageClosure.NONE.closed) return new _Measurement["default"]({
            amount: null,
            unit: null
          });
          return new _Measurement["default"]({
            amount: sku.capWeight,
            unit: _constants.UnitEnum.get(sku.capWeightUnitId)
          });
        } else {
          if (!record.packageClosure) //FIXME: latency compensation workaround
            return new _Measurement["default"]({
              amount: null,
              unit: null
            });
          var _record$packageClosur = record.packageClosure,
              weight = _record$packageClosur.weight,
              weightUnitId = _record$packageClosur.weightUnitId;
          return new _Measurement["default"]({
            amount: weight || null,
            unit: _constants.UnitEnum.get(weightUnitId)
          });
        }
      }

    default:
      return new _Measurement["default"]({
        amount: null,
        unit: null
      });
  }
}; // eslint-disable-next-line complexity


exports.getRecordMeasureMeasurement = getRecordMeasureMeasurement;

var getSkuMeasurementRecord = function getSkuMeasurementRecord(sku, measurement) {
  var skuId = sku.id,
      stocktakeMode = sku.stocktakeMode;
  var amount = measurement.amount,
      unit = measurement.unit;

  switch (stocktakeMode) {
    case _constants.SkuStocktakeModeEnum.QUANTITY:
      if (_constants.UnitEnum.isUnitType(unit, _constants.UnitTypeEnum.ITEM)) return {
        skuId: skuId,
        quantity: measurement.toBaseUnitMeasurement().amount,
        mode: _constants.RecordModeEnum.QUANTITY,
        volume: null,
        volumeUnitId: null,
        productWeight: null,
        productWeightUnitId: null
      };
      return null;

    case _constants.SkuStocktakeModeEnum.QUANTITY_VOLUME:
      if (_constants.UnitEnum.isUnitType(unit, _constants.UnitTypeEnum.VOLUME)) return {
        skuId: skuId,
        quantity: 1,
        mode: _constants.RecordModeEnum.VOLUME,
        volume: amount,
        volumeUnitId: unit.id,
        productWeight: null,
        productWeightUnitId: null
      };else if (_constants.UnitEnum.isUnitType(unit, _constants.UnitTypeEnum.ITEM)) {
        var _getSkuMeasureMeasure = (0, _skuUtils.getSkuMeasureMeasurement)(sku, _constants.SkuMeasureEnum.VOLUME).multiply(amount),
            volume = _getSkuMeasureMeasure.amount,
            _unit = _getSkuMeasureMeasure.unit;

        return {
          skuId: skuId,
          quantity: 1,
          mode: _constants.RecordModeEnum.VOLUME,
          volume: volume,
          volumeUnitId: _unit.id,
          productWeight: null,
          productWeightUnitId: null
        };
      }
      return null;

    case _constants.SkuStocktakeModeEnum.VOLUME:
      if (_constants.UnitEnum.isUnitType(unit, _constants.UnitTypeEnum.VOLUME)) return {
        skuId: skuId,
        quantity: 1,
        mode: _constants.RecordModeEnum.VOLUME,
        volume: amount,
        volumeUnitId: unit.id,
        productWeight: null,
        productWeightUnitId: null
      };
      return null;

    case _constants.SkuStocktakeModeEnum.QUANTITY_MASS:
      if (_constants.UnitEnum.isUnitType(unit, _constants.UnitTypeEnum.WEIGHT)) return {
        skuId: skuId,
        quantity: 1,
        mode: _constants.RecordModeEnum.MASS,
        volume: null,
        volumeUnitId: null,
        productWeight: amount,
        productWeightUnitId: unit.id
      };else if (_constants.UnitEnum.isUnitType(unit, _constants.UnitTypeEnum.ITEM)) {
        var _getSkuMeasureMeasure2 = (0, _skuUtils.getSkuMeasureMeasurement)(sku, _constants.SkuMeasureEnum.PRODUCT_WEIGHT).multiply(amount),
            productWeight = _getSkuMeasureMeasure2.amount,
            _unit2 = _getSkuMeasureMeasure2.unit;

        return {
          skuId: skuId,
          quantity: 1,
          mode: _constants.RecordModeEnum.MASS,
          volume: null,
          volumeUnitId: null,
          productWeight: productWeight,
          productWeightUnitId: _unit2.id
        };
      }
      return null;

    case _constants.SkuStocktakeModeEnum.MASS:
      if (_constants.UnitEnum.isUnitType(unit, _constants.UnitTypeEnum.WEIGHT)) return {
        skuId: skuId,
        quantity: 1,
        mode: _constants.RecordModeEnum.MASS,
        volume: null,
        volumeUnitId: null,
        productWeight: amount,
        productWeightUnitId: unit.id
      };
      return null;

    default:
      return null;
  }
};

exports.getSkuMeasurementRecord = getSkuMeasurementRecord;

var getRecordsSumRecord = function getRecordsSumRecord(records, sku) {
  var stocktakeMode = sku.stocktakeMode;

  switch (stocktakeMode) {
    case _constants.SkuStocktakeModeEnum.QUANTITY:
      // sum quantity
      return records[0] ? Object.assign({}, records[0], {
        quantity: getTotalRecordsSkuNormalizedAmount(records, sku)
      }) : null;

    case _constants.SkuStocktakeModeEnum.QUANTITY_VOLUME:
      {
        // sum volume + normalize
        var amount = records.reduce(function (accumulator, record) {
          return accumulator + getTotalRecordUnitNormalizedAmount(record, sku, sku.volumeUnitId);
        }, 0);
        return records[0] ? Object.assign({}, records[0], {
          quantity: 1,
          volume: amount,
          volumeUnitId: sku.volumeUnitId,
          mode: _constants.RecordModeEnum.VOLUME
        }) : null;
      }

    case _constants.SkuStocktakeModeEnum.VOLUME:
      {
        // sum volume + normalize
        var _amount = records.reduce(function (accumulator, record) {
          return accumulator + getTotalRecordUnitNormalizedAmount(record, sku, sku.volumeUnitId);
        }, 0);

        return records[0] ? Object.assign({}, records[0], {
          quantity: 1,
          volume: _amount,
          volumeUnitId: sku.volumeUnitId,
          mode: _constants.RecordModeEnum.VOLUME
        }) : null;
      }

    case _constants.SkuStocktakeModeEnum.QUANTITY_MASS:
      {
        // sum mass + normalize
        var _amount2 = records.reduce(function (accumulator, record) {
          return accumulator + getTotalRecordUnitNormalizedAmount(record, sku, sku.packageWeightGrossUnitId);
        }, 0);

        return records[0] ? Object.assign({}, records[0], {
          quantity: 1,
          productWeight: _amount2,
          productWeightUnitId: sku.packageWeightGrossUnitId,
          mode: _constants.RecordModeEnum.MASS
        }) : null;
      }

    case _constants.SkuStocktakeModeEnum.MASS:
      {
        // sum mass + normalize
        var _amount3 = records.reduce(function (accumulator, record) {
          return accumulator + getTotalRecordUnitNormalizedAmount(record, sku, sku.packageWeightGrossUnitId);
        }, 0);

        return records[0] ? Object.assign({}, records[0], {
          quantity: 1,
          productWeight: _amount3,
          productWeightUnitId: sku.packageWeightGrossUnitId,
          mode: _constants.RecordModeEnum.MASS
        }) : null;
      }

    default:
      return null;
  }
};

exports.getRecordsSumRecord = getRecordsSumRecord;

var getTotalRecordSkuPortionPrice = function getTotalRecordSkuPortionPrice(record, sku, recordValueProperty, skuValueProperty) {
  var skuPortion = getTotalRecordSkuNormalizedAmount(record, sku);

  if (record[recordValueProperty] == null) {
    return skuPortion * (0, _skuUtils.getSkuDetails)(sku, "domain")[skuValueProperty];
  } else return skuPortion * record[recordValueProperty];
};

exports.getTotalRecordSkuPortionPrice = getTotalRecordSkuPortionPrice;

var getRecordSkuPortionPrice = function getRecordSkuPortionPrice(record, sku, recordValueProperty, skuValueProperty) {
  var skuPortion = getRecordSkuNormalizedAmount(record, sku);

  if (record[recordValueProperty] == null) {
    return skuPortion * (0, _skuUtils.getSkuDetails)(sku, "domain")[skuValueProperty];
  } else return skuPortion * record[recordValueProperty];
};

exports.getRecordSkuPortionPrice = getRecordSkuPortionPrice;

var getRecordsSkuPortionPrice = function getRecordsSkuPortionPrice(records, sku, recordValueProperty, skuValueProperty) {
  return records.reduce(function (accumulator, record) {
    return accumulator + getRecordSkuPortionPrice(record, sku, recordValueProperty, skuValueProperty);
  }, 0);
};

exports.getRecordsSkuPortionPrice = getRecordsSkuPortionPrice;

var getTotalRecordsSkuPortionPrice = function getTotalRecordsSkuPortionPrice(records, sku, recordValueProperty, skuValueProperty) {
  var amount = records.reduce(function (accumulator, record) {
    var price = getTotalRecordSkuPortionPrice(record, sku, recordValueProperty, skuValueProperty);
    if (accumulator === null || price === null) return null;
    return accumulator + price;
  }, 0);
  return amount;
};

exports.getTotalRecordsSkuPortionPrice = getTotalRecordsSkuPortionPrice;

var getDefaultRecordModeByUnitId = function getDefaultRecordModeByUnitId(unitId) {
  var unitType = _constants.UnitEnum.getUnitType(unitId);

  switch (unitType) {
    case _constants.UnitTypeEnum.ITEM:
      return _constants.RecordModeEnum.QUANTITY;

    case _constants.UnitTypeEnum.WEIGHT:
      return _constants.RecordModeEnum.MASS;

    case _constants.UnitTypeEnum.VOLUME:
      return _constants.RecordModeEnum.VOLUME;
  }

  return null;
};

exports.getDefaultRecordModeByUnitId = getDefaultRecordModeByUnitId;

var getTotalRecordReportAmount = function getTotalRecordReportAmount(record, sku) {
  var skuMeasurement = (0, _skuUtils.getReportSkuMeasurement)(sku); // if (record.mode === RecordModeEnum.QUANTITY)
  //   skuMeasurement = getSkuSizeMeasurement(sku);

  if (skuMeasurement.unit.id === _constants.UnitEnum.ITEM.id) return getTotalRecordSkuNormalizedAmount(record, sku) / skuMeasurement.amount || 0;
  return getTotalRecordUnitNormalizedAmount(record, sku, skuMeasurement.unit.id) / skuMeasurement.amount || 0;
};

exports.getTotalRecordReportAmount = getTotalRecordReportAmount;