import React, { useState, useEffect, useRef } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { useField, useFormikContext } from 'formik';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import { colors } from '@stktk/commons';
import Input from '$ui/form/controls/Input';

const colorsArray = ['#FA8072', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#FF0000', '#800000', '#795548', '#607d8b', '#696969'];

const FormColor = ({ name, className, submitOnBlur, focusOnRender, hidePrepend, ...props }) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(name);
  const { submitForm } = useFormikContext();
  const [popover, setPopover] = useState(false);
  const [convert, setConvert] = useState(true);
  const [focus, setFocus] = useState(false);
  const input = useRef(null);

  useEffect(() => {
    if (focusOnRender)
      input.current.focus();
  }, []);

  const toHexString = value => {
    if (value && value.length && value[0] != '#')
      return '#' + value;
    return value;
  };

  const handleOutsideClick = () =>
    setPopover(false);

  const handleChange = () => {
    let hex = value ? toHexString(value) : null;
    setValue(hex);
  };

  const handlePick = (e, hex) => {
    e.preventDefault();
    e.stopPropagation();
    setPopover(false);
    setConvert(true);
    setValue(hex);
  };

  const handleFocus = () => {
    setPopover(true);
    setConvert(false);
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
    setConvert(!error);
    setTouched(true);
    if (submitOnBlur)
      setTimeout(() => submitForm(), 800);
  };

  const parseValue = value =>
    value == null ? '' : convert ? colors.name(value)[1] : value.substring(1);

  let combineClass = classNames('ColorInput', { 'is-invalid': touched && error, 'is-valid': touched && !error }, className);

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div className="input-group position-relative">
        {!hidePrepend &&
          <div className="input-group-prepend">
            <div className="input-group-text d-flex justify-content-center align-items-center">
              <div style={{ width: 20, height: 20, borderRadius: '50%', backgroundColor: (value && value.length && !error ? value : '#00000000') }} />
            </div>
          </div>
        }
        {focus &&
          <div className='text-muted d-flex align-items-center' style={{ position: 'absolute', fontSize: 24, top: 0, left: hidePrepend ? 10 : 60, height: '100%', zIndex: 5 }}>{'#'}</div>
        }
        <Input
          ref={input}
          data-testid={`Input-${props.name}`}
          style={{ paddingLeft: (focus ? 40 : '0.75rem') }}
          id="color-input"
          autoComplete="off"
          value={parseValue(value)}
          className={combineClass}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...props}
        />
        <Popover id="popover-form-color" target="color-input" placement="bottom-start" isOpen={popover}>
          <PopoverBody>
            <div className="popover-form-color-body d-flex flex-row flex-wrap align-items-start align-content-end justify-content-center">
              {
                colorsArray.map(color =>
                  <a key={`color-${color}`} href='#' style={{ margin: 5, display: 'block', width: 25, height: 25, borderRadius: '50%', backgroundColor: color }} onClick={e => handlePick(e, color)} />
                )
              }
            </div>
          </PopoverBody>
        </Popover>
      </div>
    </OutsideClickHandler>
  );
};

export default FormColor;