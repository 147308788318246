"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SCALAR_PRECISION = exports.QUANTITY_PRECISION = exports.MONETARY_PRECISION = exports.BULK_AMOUNT_MIN = exports.BULK_AMOUNT_MAX = void 0;
var SCALAR_PRECISION = 0.0001;
exports.SCALAR_PRECISION = SCALAR_PRECISION;
var MONETARY_PRECISION = 0.01;
exports.MONETARY_PRECISION = MONETARY_PRECISION;
var QUANTITY_PRECISION = [10, 3]; // decimal(10,3)

exports.QUANTITY_PRECISION = QUANTITY_PRECISION;
var BULK_AMOUNT_MIN = 1;
exports.BULK_AMOUNT_MIN = BULK_AMOUNT_MIN;
var BULK_AMOUNT_MAX = 20;
exports.BULK_AMOUNT_MAX = BULK_AMOUNT_MAX;