import { isFinite, sum, sumBy, forIn, get, set, setWith, map } from "lodash";
import {
  getTotalRecordReportAmount,
  RecordModeEnum,
  RecipeHelper,
  ProductionRecordStatusEnum,
  StorageTransferRecordStatusEnum,
  ReportTypeEnum,
  getSkuDetails,
  getTotalRecordSkuNormalizedAmount,
  getTotalRecordSkuPortionPrice,
} from "@stktk/commons";

export const recordsToTableData = ({
  recipeHelper,
  startStockcheckRecords = [],
  endStockcheckRecords = [],
  lossRecords = [],
  saleRecords = [],
  orderRecords = [],
  storageTransferRecords = [],
  productionRecords = [],
}) => {
  let map = {},
    pricing = {};
  productionRecords
    .filter((record) => record.status === ProductionRecordStatusEnum.INPUT)
    .forEach((record) => {
      map[record.refRecordId]
        ? map[record.refRecordId].push[record]
        : (map[record.refRecordId] = [record]);
    });

  productionRecords
    .filter((record) => record.status === ProductionRecordStatusEnum.OUTPUT)
    .forEach((outputRecord) => {
      let inputRecords = map[outputRecord.id];
      let totalRecordValue = 0;
      inputRecords.forEach((inputRecord) => {
        let amount = getTotalRecordSkuNormalizedAmount(
          inputRecord,
          inputRecord.sku
        );
        let price = getSkuDetails(inputRecord.sku, "domain").netPurchasePrice || 0;
        totalRecordValue += amount * price;
      });
      set(
        pricing,
        `[${outputRecord.skuId}].netPurchasePrice`,
        totalRecordValue
      );
      set(pricing, `[${outputRecord.skuId}].netSalePrice`, null);
    });

  return {
    startStockcheckRecords: recipeHelper.expandRecords({
      records: startStockcheckRecords,
    }),
    endStockcheckRecords: recipeHelper.expandRecords({
      records: endStockcheckRecords,
    }),
    lossRecords: recipeHelper.expandRecords({ records: lossRecords }),
    saleRecords: recipeHelper.expandRecords({
      records: saleRecords,
      valueProperties: ["netSalePrice", "netPurchasePrice"],
      normalizeValue: true,
    }),
    orderRecords: recipeHelper.expandRecords({ records: orderRecords }),
    storageTransferRecords: recipeHelper.expandRecords({
      records: storageTransferRecords,
    }),
    productionRecords: recipeHelper.expandRecords({
      records: productionRecords,
    }),
    pricing,
  };
};

export const getCategoryProductCost = (category) =>
  (getCategoryTotalUsedValue(category) / getCategoryTotalSaleValue(category)) *
  100;

export const getCategoryTotalUsedValue = (category) =>
  sum([
    ...category.data.map((row) =>
      get(row, "startStockcheckRecords.netStartStocktakePurchaseValue", 0)
    ),
    ...category.data.map((row) => get(row, "orderRecords.netPurchasePrice", 0)),
  ]) -
  sum([
    ...category.data.map((row) =>
      get(row, "endStockcheckRecords.netEndStocktakePurchaseValue", 0)
    ),
  ]);

export const getCategoryTotalSaleValue = (category) =>
  sum(category.data.map((row) => get(row, "saleRecords.netSalePrice", 0)));

export const getEndAmountTotal = (records) => sumBy(records, "endAmount");

export const getExpectedAmountTotal = (records) =>
  sumBy(records, "expectedAmount");

export const getDifferencePurchaseValueTotal = (records) =>
  sumBy(records, "differencePurchaseValue");

export const getDifferenceSaleValueTotal = (records) =>
  sumBy(records, "differenceSaleValue");

export const getDifferenceAmountTotal = (records) => {
  let expectedAmountTotal = getExpectedAmountTotal(records);
  let endAmountTotal = getEndAmountTotal(records);
  return endAmountTotal - expectedAmountTotal;
};

export const getDifferencePercentageTotal = (records) => {
  let expectedAmountTotal = getExpectedAmountTotal(records);
  let differenceAmountTotal = getDifferenceAmountTotal(records);
  return Math.abs((100 * differenceAmountTotal) / expectedAmountTotal);
};

export const getReportableAmount = (amount, precision = 2, error = 0) => {
  return isFinite(amount)
    ? Math.round(amount) !== amount
      ? parseFloat(amount.toFixed(precision))
      : amount
    : error;
};

export const prepareData = (data, recipes, reportType) => {
  const recipeHelper = new RecipeHelper({ recipes });
  recipeHelper.computePurchaseValues();
  return {
    [ReportTypeEnum.REPORT_1]: prepareData1,
    [ReportTypeEnum.REPORT_2]: prepareData2,
  }[reportType]({ recipeHelper, ...data });
};

const mapRecords = ({
  startStockcheckRecords,
  endStockcheckRecords,
  lossRecords,
  saleRecords,
  orderRecords,
  storageTransferRecords = [],
  productionRecords,
}) => ({
  ["startStockcheckRecords"]: {
    records: startStockcheckRecords,
    mapper: (record) => ({
      ...record,
      netStartStocktakePurchaseValue: getTotalRecordSkuPortionPrice(
        record,
        record.sku,
        "netStocktakePurchaseValue",
        "netPurchasePrice"
      ),
      netStartStocktakeSaleValue: getTotalRecordSkuPortionPrice(
        record,
        record.sku,
        "netStocktakeSaleValue",
        "netSalePrice"
      ),
    }),
  },
  ["endStockcheckRecords"]: {
    records: endStockcheckRecords,
    mapper: (record) => ({
      ...record,
      netEndStocktakePurchaseValue: getTotalRecordSkuPortionPrice(
        record,
        record.sku,
        "netStocktakePurchaseValue",
        "netPurchasePrice"
      ),
      netEndStocktakeSaleValue: getTotalRecordSkuPortionPrice(
        record,
        record.sku,
        "netStocktakeSaleValue",
        "netSalePrice"
      ),
    }),
  },
  ["lossRecords"]: {
    records: lossRecords,
    mapper: (record) => ({
      ...record,
      netLossPurchaseValue: getTotalRecordSkuPortionPrice(
        record,
        record.sku,
        "netLossPurchaseValue",
        "netPurchasePrice"
      ),
      netLossSaleValue: getTotalRecordSkuPortionPrice(
        record,
        record.sku,
        "netLossSaleValue",
        "netSalePrice"
      ),
    }),
  },
  ["saleRecords"]: {
    records: saleRecords,
    mapper: (record) => ({
      ...record,
      netSalePrice: record.netSalePrice
        ? record.quantity * record.netSalePrice
        : 0,
      netPurchasePrice: getTotalRecordSkuPortionPrice(
        record,
        record.sku,
        "netPurchasePrice",
        "netPurchasePrice"
      ),
    }),
  },
  ["orderRecords"]: {
    records: orderRecords,
    mapper: (record) => ({
      ...record,
      netPurchasePrice: getTotalRecordSkuPortionPrice(
        record,
        record.sku,
        "netPurchasePrice",
        "netPurchasePrice"
      ),
    }),
  },
  ["incomingStorageTransferRecords"]: {
    records: storageTransferRecords.filter(
      ({ status }) => status === StorageTransferRecordStatusEnum.INCOMING
    ),
    mapper: (record) => record,
  },
  ["outgoingStorageTransferRecords"]: {
    records: storageTransferRecords.filter(
      ({ status }) => status === StorageTransferRecordStatusEnum.OUTGOING
    ),
    mapper: (record) => record,
  },
  ["inputProductionRecords"]: {
    records: productionRecords.filter(
      ({ status }) => status === ProductionRecordStatusEnum.INPUT
    ),
    mapper: (record) => record,
  },
  ["outputProductionRecords"]: {
    records: productionRecords.filter(
      ({ status }) => status === ProductionRecordStatusEnum.OUTPUT
    ),
    mapper: (record) => record,
  },
});

const prepareData1 = ({ records, recipeHelper }) => {
  let {
    pricing,
    startStockcheckRecords,
    endStockcheckRecords,
    lossRecords,
    saleRecords,
    orderRecords,
    storageTransferRecords,
    productionRecords,
  } = recordsToTableData({
    ...records,
    recipeHelper,
  });

  const mappedRecords = mapRecords({
    startStockcheckRecords,
    endStockcheckRecords,
    lossRecords,
    saleRecords,
    orderRecords,
    storageTransferRecords,
    productionRecords,
    pricing,
  });

  const summary = {};

  forIn(mappedRecords, (value, key) => {
    const { records, mapper } = value;
    records
      .map((record) => {
        let plainRecord = mapper(record);
        let {
          netStartStocktakePurchaseValue = 0,
          netStartStocktakeSaleValue = 0,
          netEndStocktakePurchaseValue = 0,
          netEndStocktakeSaleValue = 0,
          netLossPurchaseValue = 0,
          netLossSaleValue = 0,
          netSalePrice = 0,
          netPurchasePrice = 0,
          _plannedProductCost = 0,
        } = plainRecord;
        const amount =
          getTotalRecordSkuNormalizedAmount(plainRecord, plainRecord.sku) *
          +(record.mode != RecordModeEnum.QUANTITY);
        const fullSkus =
          record.quantity * +(record.mode == RecordModeEnum.QUANTITY);
        return {
          fullSkus,
          amount,
          reportAmount: getTotalRecordReportAmount(
            plainRecord,
            plainRecord.sku
          ),
          sku: plainRecord.sku,
          netStartStocktakePurchaseValue,
          netStartStocktakeSaleValue,
          netEndStocktakePurchaseValue,
          netEndStocktakeSaleValue,
          netLossPurchaseValue,
          netLossSaleValue,
          netSalePrice,
          netPurchasePrice,
          _weightedPlannedProductCost:
            (fullSkus + amount) * _plannedProductCost,
        };
      })
      .reduce((accumulator, mappedRecord) => {
        let currentRecord = get(
          accumulator,
          `[${mappedRecord.sku.id}][${key}]`,
          null
        );
        let {
          fullSkus /*, openSkus*/,
          amount,
          reportAmount,
          netStartStocktakePurchaseValue,
          netStartStocktakeSaleValue,
          netEndStocktakePurchaseValue,
          netEndStocktakeSaleValue,
          netLossPurchaseValue,
          netLossSaleValue,
          netSalePrice,
          netPurchasePrice,
          _weightedPlannedProductCost,
        } = mappedRecord;
        if (!currentRecord) {
          set(accumulator, `[${mappedRecord.sku.id}][${key}]`, {
            fullSkus,
            amount,
            reportAmount,
            netStartStocktakePurchaseValue,
            netStartStocktakeSaleValue,
            netEndStocktakePurchaseValue,
            netEndStocktakeSaleValue,
            netLossPurchaseValue,
            netLossSaleValue,
            netSalePrice,
            netPurchasePrice,
            _weightedPlannedProductCost,
          });
          accumulator[mappedRecord.sku.id].sku = mappedRecord.sku;
          accumulator[mappedRecord.sku.id].sku.pricing = pricing[
            mappedRecord.sku.id
          ] || { netPurchasePrice: null, netSalePrice: null };
        } else {
          accumulator[mappedRecord.sku.id][key] = {
            fullSkus: currentRecord.fullSkus + fullSkus,
            amount: currentRecord.amount + amount,
            reportAmount: currentRecord.reportAmount + reportAmount,
            netStartStocktakePurchaseValue:
              currentRecord.netStartStocktakePurchaseValue +
              netStartStocktakePurchaseValue,
            netStartStocktakeSaleValue:
              currentRecord.netStartStocktakeSaleValue +
              netStartStocktakeSaleValue,
            netEndStocktakePurchaseValue:
              currentRecord.netEndStocktakePurchaseValue +
              netEndStocktakePurchaseValue,
            netEndStocktakeSaleValue:
              currentRecord.netEndStocktakeSaleValue + netEndStocktakeSaleValue,
            netLossPurchaseValue:
              currentRecord.netLossPurchaseValue + netLossPurchaseValue,
            netLossSaleValue: currentRecord.netLossSaleValue + netLossSaleValue,
            netSalePrice: currentRecord.netSalePrice + netSalePrice,
            netPurchasePrice: currentRecord.netPurchasePrice + netPurchasePrice,
            _weightedPlannedProductCost:
              currentRecord._weightedPlannedProductCost +
              _weightedPlannedProductCost,
          };
        }
        return accumulator;
      }, summary);
  });

  forIn(summary, (entry) => {
    let { sku } = entry;
    const details = getSkuDetails(sku, "domain");
    let netPurchasePrice = details.netPurchasePrice || 0;
    let netSalePrice = details.netSalePrice || 0;
    let startAmount =
      get(entry, '["startStockcheckRecords"].fullSkus', 0) +
      get(entry, '["startStockcheckRecords"].amount', 0);
    let endAmount =
      get(entry, '["endStockcheckRecords"].fullSkus', 0) +
      get(entry, '["endStockcheckRecords"].amount', 0);
    let saleAmount =
      get(entry, '["saleRecords"].fullSkus', 0) +
      get(entry, '["saleRecords"].amount', 0);
    let lossAmount =
      get(entry, '["lossRecords"].fullSkus', 0) +
      get(entry, '["lossRecords"].amount', 0);
    let orderAmount =
      get(entry, '["orderRecords"].fullSkus', 0) +
      get(entry, '["orderRecords"].amount', 0);
    let productionInputAmount =
      get(entry, '["inputProductionRecords"].fullSkus', 0) +
      get(entry, '["inputProductionRecords"].amount', 0);
    let productionOutputAmount =
      get(entry, '["outputProductionRecords"].fullSkus', 0) +
      get(entry, '["outputProductionRecords"].amount', 0);
    let startReportAmount = get(
      entry,
      '["startStockcheckRecords"].reportAmount',
      0
    );
    let endReportAmount = get(
      entry,
      '["endStockcheckRecords"].reportAmount',
      0
    );
    let saleReportAmount = get(entry, '["saleRecords"].reportAmount', 0);
    let incomingAmount =
      get(entry, '["incomingStorageTransferRecords"].fullSkus', 0) +
      get(entry, '["incomingStorageTransferRecords"].amount', 0);
    let outgoingAmount =
      get(entry, '["outgoingStorageTransferRecords"].fullSkus', 0) +
      get(entry, '["outgoingStorageTransferRecords"].amount', 0);
    let incomingPurchaseValue = incomingAmount * netPurchasePrice;
    let outgoingPurchaseValue = outgoingAmount * netPurchasePrice;
    let incomingSaleValue = incomingAmount * netSalePrice;
    let outgoingSaleValue = outgoingAmount * netSalePrice;
    let productionInputReportAmount = get(
      entry,
      '["inputProductionRecords"].reportAmount',
      0
    );
    let productionOutputReportAmount = get(
      entry,
      '["outputProductionRecords"].reportAmount',
      0
    );
    let incomingReportAmount = get(
      entry,
      '["incomingStorageTransferRecords"].reportAmount',
      0
    );
    let outgoingReportAmount = get(
      entry,
      '["outgoingStorageTransferRecords"].reportAmount',
      0
    );
    let lossReportAmount = get(entry, '["lossRecords"].reportAmount', 0);
    let orderReportAmount = get(entry, '["orderRecords"].reportAmount', 0);
    let totalSaleValue = get(entry, '["saleRecords"].netSalePrice', 0);
    let totalOrderValue = get(entry, '["orderRecords"].netPurchasePrice', 0);
    let totalUsedValue =
      get(
        entry,
        '["startStockcheckRecords"].netStartStocktakePurchaseValue',
        0
      ) +
      get(entry, '["orderRecords"].netPurchasePrice', 0) -
      get(entry, '["endStockcheckRecords"].netEndStocktakePurchaseValue', 0) +
      incomingPurchaseValue -
      outgoingPurchaseValue;
    let weightedSalePlannedProductCost = get(
      entry,
      '["saleRecords"]._weightedPlannedProductCost',
      0
    );
    let totalProfit = totalSaleValue - totalUsedValue;
    let expectedReportAmount =
      startReportAmount -
      saleReportAmount -
      lossReportAmount +
      orderReportAmount +
      incomingReportAmount -
      outgoingReportAmount -
      productionInputReportAmount +
      productionOutputReportAmount;
    let differenceReportAmount = endReportAmount - expectedReportAmount;
    let expectedAmount =
      startAmount -
      saleAmount -
      lossAmount +
      orderAmount +
      incomingAmount -
      outgoingAmount -
      productionInputAmount +
      productionOutputAmount;
    let differenceAmount = endAmount - expectedAmount;
    let differencePercentage = Math.abs(
      (100 * differenceAmount) / expectedAmount
    );
    let differencePurchaseValue =
      differenceAmount *
      (productionOutputAmount
        ? sku.pricing.netPurchasePrice / productionOutputAmount
        : netPurchasePrice);
    let differenceSaleValue = differenceAmount * netSalePrice;
    return Object.assign(entry, {
      startAmount,
      endAmount,
      saleAmount,
      lossAmount,
      orderAmount,
      productionInputAmount,
      productionOutputAmount,
      expectedAmount,
      differenceAmount,
      sku,
      differencePercentage,
      differencePurchaseValue,
      differenceSaleValue,
      startReportAmount,
      endReportAmount,
      saleReportAmount,
      productionInputReportAmount,
      productionOutputReportAmount,
      lossReportAmount,
      orderReportAmount,
      expectedReportAmount,
      differenceReportAmount,
      totalSaleValue,
      totalUsedValue,
      totalOrderValue,
      totalProfit,
      incomingAmount,
      outgoingAmount,
      incomingReportAmount,
      outgoingReportAmount,
      incomingPurchaseValue,
      outgoingPurchaseValue,
      incomingSaleValue,
      outgoingSaleValue,
      weightedSalePlannedProductCost,
    });
  });
  let result = map(summary);
  console.log({ result });
  return result;
};

const prepareData2 = ({ records, bars, recipeHelper }) => {
  let {
    pricing,
    startStockcheckRecords,
    endStockcheckRecords,
    lossRecords,
    saleRecords,
    orderRecords,
    storageTransferRecords,
    productionRecords,
  } = recordsToTableData({
    ...records,
    recipeHelper,
  });

  const mappedRecords = mapRecords({
    startStockcheckRecords,
    endStockcheckRecords,
    lossRecords,
    saleRecords,
    orderRecords,
    storageTransferRecords,
    productionRecords,
    pricing,
  });

  const summary = {};

  forIn(mappedRecords, (value, key) => {
    const { records, mapper } = value;
    records
      .map((record) => {
        let plainRecord = mapper(record);
        let {
          netStartStocktakePurchaseValue = 0,
          netStartStocktakeSaleValue = 0,
          netEndStocktakePurchaseValue = 0,
          netEndStocktakeSaleValue = 0,
          netLossPurchaseValue = 0,
          netLossSaleValue = 0,
          netSalePrice = 0,
          netPurchasePrice = 0,
          _plannedProductCost = 0,
          barId,
          sku,
        } = plainRecord;
        const amount =
          getTotalRecordSkuNormalizedAmount(plainRecord, plainRecord.sku) *
          +(record.mode != RecordModeEnum.QUANTITY);
        const fullSkus =
          record.quantity * +(record.mode == RecordModeEnum.QUANTITY);
        return {
          amount,
          fullSkus,
          reportAmount: getTotalRecordReportAmount(
            plainRecord,
            plainRecord.sku
          ),
          sku,
          netStartStocktakePurchaseValue,
          netStartStocktakeSaleValue,
          netEndStocktakePurchaseValue,
          netEndStocktakeSaleValue,
          netLossPurchaseValue,
          netLossSaleValue,
          netSalePrice,
          netPurchasePrice,
          _weightedPlannedProductCost:
            (fullSkus + amount) * _plannedProductCost,
          barId,
        };
      })
      .reduce((accumulator, mappedRecord) => {
        let {
          fullSkus /*, openSkus*/,
          amount,
          reportAmount,
          netStartStocktakePurchaseValue,
          netStartStocktakeSaleValue,
          netEndStocktakePurchaseValue,
          netEndStocktakeSaleValue,
          netLossPurchaseValue,
          netLossSaleValue,
          netSalePrice,
          netPurchasePrice,
          _weightedPlannedProductCost,
          barId,
          sku,
        } = mappedRecord;
        let currentRecord = get(
          accumulator,
          `[${sku.id}][${barId}][${key}]`,
          null
        );
        if (!currentRecord) {
          setWith(
            accumulator,
            `[${sku.id}][${barId}][${key}]`,
            {
              fullSkus,
              amount,
              reportAmount,
              netStartStocktakePurchaseValue,
              netStartStocktakeSaleValue,
              netEndStocktakePurchaseValue,
              netEndStocktakeSaleValue,
              netLossPurchaseValue,
              netLossSaleValue,
              netSalePrice,
              netPurchasePrice,
              _weightedPlannedProductCost,
              barId,
            },
            Object
          );
          accumulator[sku.id].sku = sku;
          accumulator[sku.id].sku.pricing = pricing[sku.id] || {
            netPurchasePrice: null,
            netSalePrice: null,
          };
        } else {
          setWith(
            accumulator,
            `[${sku.id}][${barId}][${key}]`,
            {
              fullSkus: currentRecord.fullSkus + fullSkus,
              amount: currentRecord.amount + amount,
              reportAmount: currentRecord.reportAmount + reportAmount,
              netStartStocktakePurchaseValue:
                currentRecord.netStartStocktakePurchaseValue +
                netStartStocktakePurchaseValue,
              netStartStocktakeSaleValue:
                currentRecord.netStartStocktakeSaleValue +
                netStartStocktakeSaleValue,
              netEndStocktakePurchaseValue:
                currentRecord.netEndStocktakePurchaseValue +
                netEndStocktakePurchaseValue,
              netEndStocktakeSaleValue:
                currentRecord.netEndStocktakeSaleValue +
                netEndStocktakeSaleValue,
              netLossPurchaseValue:
                currentRecord.netLossPurchaseValue + netLossPurchaseValue,
              netLossSaleValue:
                currentRecord.netLossSaleValue + netLossSaleValue,
              netSalePrice: currentRecord.netSalePrice + netSalePrice,
              netPurchasePrice:
                currentRecord.netPurchasePrice + netPurchasePrice,
              _weightedPlannedProductCost:
                currentRecord._weightedPlannedProductCost +
                _weightedPlannedProductCost,
              barId,
            },
            Object
          );
        }
        return accumulator;
      }, summary);
  });

  const result = [];

  forIn(summary, (e1 /*, skuId*/) => {
    const { sku, ..._e1 } = e1;
    const details = getSkuDetails(sku, "domain");
    let netPurchasePrice = details.netPurchasePrice || 0;
    let netSalePrice = details.netSalePrice || 0;
    forIn(_e1, (e2, barId) => {
      let bar = bars.find((b) => b.id.toString() === barId);
      let startAmount =
        get(e2, '["startStockcheckRecords"].fullSkus', 0) +
        get(e2, '["startStockcheckRecords"].amount', 0);
      let endAmount =
        get(e2, '["endStockcheckRecords"].fullSkus', 0) +
        get(e2, '["endStockcheckRecords"].amount', 0);
      let saleAmount =
        get(e2, '["saleRecords"].fullSkus', 0) +
        get(e2, '["saleRecords"].amount', 0);
      let lossAmount =
        get(e2, '["lossRecords"].fullSkus', 0) +
        get(e2, '["lossRecords"].amount', 0);
      let orderAmount =
        get(e2, '["orderRecords"].fullSkus', 0) +
        get(e2, '["orderRecords"].amount', 0);
      let incomingAmount =
        get(e2, '["incomingStorageTransferRecords"].fullSkus', 0) +
        get(e2, '["incomingStorageTransferRecords"].amount', 0);
      let outgoingAmount =
        get(e2, '["outgoingStorageTransferRecords"].fullSkus', 0) +
        get(e2, '["outgoingStorageTransferRecords"].amount', 0);
      let productionInputAmount =
        get(e2, '["inputProductionRecords"].fullSkus', 0) +
        get(e2, '["inputProductionRecords"].amount', 0);
      let productionOutputAmount =
        get(e2, '["outputProductionRecords"].fullSkus', 0) +
        get(e2, '["outputProductionRecords"].amount', 0);
      let startReportAmount = get(
        e2,
        '["startStockcheckRecords"].reportAmount',
        0
      );
      let endReportAmount = get(e2, '["endStockcheckRecords"].reportAmount', 0);
      let saleReportAmount = get(e2, '["saleRecords"].reportAmount', 0);
      let productionInputReportAmount = get(
        e2,
        '["inputProductionRecords"].reportAmount',
        0
      );
      let productionOutputReportAmount = get(
        e2,
        '["outputProductionRecords"].reportAmount',
        0
      );
      let incomingReportAmount = get(
        e2,
        '["incomingStorageTransferRecords"].reportAmount',
        0
      );
      let outgoingReportAmount = get(
        e2,
        '["outgoingStorageTransferRecords"].reportAmount',
        0
      );
      let incomingPurchaseValue = incomingAmount * netPurchasePrice;
      let outgoingPurchaseValue = outgoingAmount * netPurchasePrice;
      let incomingSaleValue = incomingAmount * netSalePrice;
      let outgoingSaleValue = outgoingAmount * netSalePrice;
      let lossReportAmount = get(e2, '["lossRecords"].reportAmount', 0);
      let orderReportAmount = get(e2, '["orderRecords"].reportAmount', 0);
      let weightedSalePlannedProductCost = get(
        e2,
        '["saleRecords"]._weightedPlannedProductCost',
        0
      );
      let expectedReportAmount =
        startReportAmount -
        saleReportAmount -
        lossReportAmount +
        orderReportAmount +
        incomingReportAmount -
        outgoingReportAmount -
        productionInputReportAmount +
        productionOutputReportAmount;
      let differenceReportAmount = endReportAmount - expectedReportAmount;
      let expectedAmount =
        startAmount -
        saleAmount -
        lossAmount +
        orderAmount +
        incomingAmount -
        outgoingAmount -
        productionInputAmount +
        productionOutputAmount;
      let differenceAmount = endAmount - expectedAmount;
      let differencePercentage = Math.abs(
        (100 * differenceAmount) / expectedAmount
      );
      let differencePurchaseValue =
        differenceAmount *
        (productionOutputAmount
          ? sku.pricing.netPurchasePrice / productionOutputAmount
          : netPurchasePrice);
      let differenceSaleValue = differenceAmount * netSalePrice;
      result.push(
        Object.assign(e2, {
          startAmount,
          endAmount,
          saleAmount,
          lossAmount,
          orderAmount,
          expectedAmount,
          incomingAmount,
          outgoingAmount,
          differenceAmount,
          productionInputAmount,
          productionOutputAmount,
          bar: bar || e2.bar,
          sku,
          differencePercentage,
          differencePurchaseValue,
          differenceSaleValue,
          startReportAmount,
          endReportAmount,
          saleReportAmount,
          productionInputReportAmount,
          productionOutputReportAmount,
          lossReportAmount,
          orderReportAmount,
          expectedReportAmount,
          differenceReportAmount,
          incomingReportAmount,
          outgoingReportAmount,
          incomingPurchaseValue,
          outgoingPurchaseValue,
          incomingSaleValue,
          outgoingSaleValue,
          weightedSalePlannedProductCost,
        })
      );
    });
  });
  console.log({ result });
  return result;
};

export const groupReportDataByCategories = (
  data,
  categories,
  filterOutEmpty = true
) => {
  let groups = categories
    .filter(({ parentId }) => parentId !== null)
    .map((category) => ({
      ...category,
      parent: categories.find(({ id }) => category.parentId === id),
      data: data.filter(
        ({ sku }) => get(getSkuDetails(sku, "domain"), "category.id") === category.id
      ),
    }));
  return filterOutEmpty ? groups.filter(({ data }) => data.length > 0) : groups;
};
