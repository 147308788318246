import React, { useEffect, useState } from "react";
import { getSkuDetails, getTotalRecordsSkuPortionPrice } from "@stktk/commons";
import { __ } from "@stktk/locales";
import { Icon } from "$ui";

export default function RecordsValue({
  records,
  sku,
  recordValueProperty,
  skuValueProperty,
}) {
  const [price, setPrice] = useState(null);

  const getPriceValue = () => {
    let priceValue = {
      skuValuePrice: getSkuDetails(sku, "domain")[skuValueProperty],
      recordValuePrice: getTotalRecordsSkuPortionPrice(
        records,
        sku,
        recordValueProperty,
        skuValueProperty
      ),
    };
    if (priceValue.recordValuePrice !== null)
      return setPrice(priceValue.recordValuePrice);
    return setPrice(priceValue.skuValuePrice);
  };

  useEffect(() => {
    getPriceValue();
  }, [records]);

  return (
    <div className="text-right">
      {price !== null ? (
        price.toFixed(2)
      ) : (
        <Icon
          type="light"
          style={{ opacity: 0.5, marginLeft: "5px", display: "inline-block" }}
          icon="exclamation-square"
          tooltip={__("tooltip_record_null_price")}
        />
      )}
    </div>
  );
}
