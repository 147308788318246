import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import Icon from '$ui/Icon';
import { __ } from '@stktk/locales';

const CollapsableSection = ({ title, defaultOpen = false, className, children, ...props }) => {
  const [isOpen, setOpen] = useState(defaultOpen);
  return (
    <div className={classNames('CollapsableSection', className)} {...props}>
      <h4 className="d-flex justify-content-between align-items-baseline form-header">
        <span>{title}</span>
        <span className="CollapsableSection-action" onClick={() => setOpen(!isOpen)}>
          {isOpen ? __('action_hide') : __('action_show')}
          {' '}
          <Icon type="solid" icon={isOpen ? 'caret-up' : 'caret-down'} />
        </span>
      </h4>
      <Collapse isOpen={isOpen}>
        {children}
      </Collapse>
    </div>
  );
};

export default CollapsableSection;