"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseFields = parseFields;
exports.parseLocalFields = parseLocalFields;
exports.parseVirtualFields = parseVirtualFields;
exports.stripFields = stripFields;
exports.stripLocalFields = stripLocalFields;
exports.stripVirtualFields = stripVirtualFields;
exports.stripVirtualParseLocalFields = stripVirtualParseLocalFields;

var _lodash = require("lodash");

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

var ensureLocalPrefix = function ensureLocalPrefix(prefix) {
  return prefix || 'local';
};

var ensureVirtualPrefix = function ensureVirtualPrefix(prefix) {
  return prefix || 'virtual';
};

var ensurePrefixes = function ensurePrefixes(config) {
  return {
    local: ensureLocalPrefix((0, _lodash.get)(config, 'localPrefix', null)),
    virtual: ensureVirtualPrefix((0, _lodash.get)(config, 'virtualPrefix', null))
  };
};

function stripFields() {
  var c = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var data = arguments.length > 1 ? arguments[1] : undefined;
  var prefix = arguments.length > 2 ? arguments[2] : undefined;
  var config = ensurePrefixes(c);

  if ((0, _lodash.isArray)(data)) {
    return (0, _lodash.map)(data, function (value) {
      return stripFields(config, value, prefix);
    });
  } else if (_typeof(data) == 'object' && data !== null) {
    var o = (0, _lodash.mapValues)(data, function (value, key) {
      return (0, _lodash.startsWith)(key, config[prefix]) ? null : stripFields(config, value, prefix);
    });
    return (0, _lodash.omitBy)(o, function (value, key) {
      return (0, _lodash.startsWith)(key, config[prefix]);
    });
  }

  return data;
}

function parseFields(config, data, prefix) {
  var prefixes = ensurePrefixes(config);
  return (0, _lodash.mapKeys)(data, function (val, key) {
    return (0, _lodash.startsWith)(key, prefixes[prefix]) ? (0, _lodash.lowerFirst)((0, _lodash.replace)(key, prefixes[prefix], '')) : key;
  });
}

function stripVirtualFields(config, data) {
  return stripFields(config, data, 'virtual');
}

function stripLocalFields(config, data) {
  return stripFields(config, data, 'local');
}

function parseVirtualFields(config, data) {
  return parseFields(config, data, 'virtual');
}

function parseLocalFields(config, data) {
  return parseFields(config, data, 'local');
}

function stripVirtualParseLocalFields(config, data) {
  // console.log('parseFields', parseFields(config, stripFields(config, data, 'virtual'), 'local'));
  return parseFields(config, stripFields(config, data, 'virtual'), 'local');
}