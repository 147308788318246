import React from 'react';
import { Row, Col } from 'reactstrap';
import EvaluatedRecordsBar from '$ui/chart/EvaluatedRecordsBar';
import EvaluatedRecordsChart from '$ui/chart/EvaluatedRecordsChart';
import CardSummary from '$ui/chart/CardSummary';
import { __ } from '@stktk/locales';

export default function EvaluatedRecordsStatistics({ records, recordsetType, properties, totalSaleData, totalPurchaseData, totalArrivedPurchaseData }) {
  return (
    <Row>
      <Col lg={8} className="pl-lg-2">
        <Row className="mb-4">
          {
            properties.includes('sale') &&
            <Col
              style={{
                display: 'flex',
                minWidth: '12rem',
                flex: 1,
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'stretch'
              }}
            >
              <CardSummary title={__('general_total_sale_value')} color="warning" icon="search-dollar" value={totalSaleData} />
            </Col>
          }
          {
            properties.includes('purchase') &&
            <Col
              style={{
                display: 'flex',
                minWidth: '12rem',
                flex: 1,
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'stretch'
              }}
            >
              <CardSummary title={__('general_total_purchase_value')} color="success" icon='hand-holding-usd' value={totalPurchaseData} />
            </Col>
          }
          {
            properties.includes('purchaseArrived') &&
            <Col
              style={{
                display: 'flex',
                minWidth: '12rem',
                flex: 1,
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'stretch'
              }}
            >
              <CardSummary title={__('general_total_arrived_value')} color="success" icon='search-dollar' value={totalArrivedPurchaseData} />
            </Col>
          }
        </Row>
        <EvaluatedRecordsBar records={records} recordsetType={recordsetType} properties={properties} />
      </Col>
      <Col lg={4} className="pr-lg-2">
        <EvaluatedRecordsChart records={records} recordsetType={recordsetType} properties={properties} />
      </Col>
    </Row>
  );
}