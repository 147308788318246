"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.recordsetCommonUpdateScheme = exports.recordsetCommonFindScheme = exports.recordsetCommonDestroyScheme = exports.recordsetCommonCreateScheme = void 0;

var _validatorConstants = require("../validator/validatorConstants.js");

var recordsetCommonFindScheme = {
  restaurantId: _validatorConstants.INTEGER.required([1]),
  id: _validatorConstants.INTEGER.optional([1])
};
exports.recordsetCommonFindScheme = recordsetCommonFindScheme;
var recordsetCommonCreateScheme = {
  restaurantId: _validatorConstants.INTEGER.required([1]),
  comment: _validatorConstants.STRING.optional(140)
};
exports.recordsetCommonCreateScheme = recordsetCommonCreateScheme;
var recordsetCommonUpdateScheme = {
  id: _validatorConstants.INTEGER.required([1]),
  restaurantId: _validatorConstants.INTEGER.required([1]),
  comment: _validatorConstants.STRING.optional(140)
};
exports.recordsetCommonUpdateScheme = recordsetCommonUpdateScheme;
var recordsetCommonDestroyScheme = {
  id: _validatorConstants.INTEGER.required([1]),
  restaurantId: _validatorConstants.INTEGER.required([1])
};
exports.recordsetCommonDestroyScheme = recordsetCommonDestroyScheme;