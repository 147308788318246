import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Col, Row, Dropdown, DropdownToggle, DropdownMenu, UncontrolledTooltip } from 'reactstrap';
import onClickOutside from 'react-onclickoutside';
import { map } from 'lodash';
import { __ } from '@stktk/locales';
import { Icon } from '$ui';

const isArrayEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 4,
  margin: '0 0 6px 0',
  borderRadius: 5,
  fontSize: 14,
  height: 28,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  // change background colour if dragging
  background: isDragging ? '#cbced0' : '#e6e7e7',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#f6f6f6' : '#ffffff',
  padding: grid,
  width: 149,
  height: '100%'
});

class TableColumnsDnd extends Component {

  constructor(props) {
    super(props);
    this.columnPropsMap = {};
    this.state = {
      items: props.visibleColumns,
      selected: props.hiddenColumns,
      dropdownOpen: false,
      isDraggedColumnHideable: true
    };
    props.visibleColumns.forEach(col => this.columnPropsMap[col.id] = col);
    props.hiddenColumns.forEach(col => this.columnPropsMap[col.id] = col);

  }

  shouldComponentUpdate(nextProps, nextState) {
    return (!isArrayEqual(nextState.items, this.state.items)
      || !isArrayEqual(nextState.selected, this.state.selected)
      || !isArrayEqual(nextProps.visibleColumns, this.props.visibleColumns)
      || !isArrayEqual(nextProps.hiddenColumns, this.props.hiddenColumns)
      || nextState.dropdownOpen !== this.state.dropdownOpen)
      || nextState.isDraggedColumnHideable == false;
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.state);
    if (!isArrayEqual(prevProps.visibleColumns, this.props.visibleColumns) || !isArrayEqual(prevProps.hiddenColumns, this.props.hiddenColumns))
      this.setState({ items: this.props.visibleColumns, selected: this.props.hiddenColumns });
    if (!isArrayEqual(prevState.items, this.state.items) || !isArrayEqual(prevState.selected, this.state.selected))
      this.props.onChange(
        map(this.state.items, 'id'),
        map(this.state.selected, 'id')
      );
  }

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    droppable: 'items',
    droppable2: 'selected'
  };

  handleClickOutside = event => {
    find(event.path, element => element.className == 'dropdown-menu') ? null : this.setState({ dropdownOpen: false });
  }

  toggle() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  getList = id => this.state[this.id2List[id]];

  onDragStart = event => {
    const { isHideable } = this.columnPropsMap[event.draggableId];
    if (!isHideable) {
      this.setState({ isDraggedColumnHideable: false });
    }
  }

  onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items, isDraggedColumnHideable: true };

      if (source.droppableId === 'droppable2') {
        state = { selected: items };
      }

      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        items: result.droppable,
        selected: result.droppable2,
        isDraggedColumnHideable: true
      });
    }
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <Dropdown className="TableColumnsDnd" isOpen={this.state.dropdownOpen} style={{ marginBottom: 5 }} toggle={() => { }}>
        <DropdownToggle caret className="btn btn-light btn-sm" onClick={() => this.toggle()}>
          <Icon icon={'cog'} type="light" />
          {' '}
          {__('table_column_management')}
        </DropdownToggle>
        <DropdownMenu
          persist
          className="TableColumnsDnd-menu"
          modifiers={{
            computeStyle: {
              gpuAcceleration: false
            }
          }}
        >
          <DragDropContext
            onDragEnd={this.onDragEnd}
            onDragStart={this.onDragStart}
          >
            <Row noGutters>
              <Col><h5>{__('table_column_visible')}</h5></Col>
              <Col><h5>{__('table_column_hidden')}</h5></Col>
            </Row>
            <Row noGutters>
              <Col>
                <Droppable
                  droppableId="droppable"
                  type="column"
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.items.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                          type={'column'}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              id={`col-d1-${index}`}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <span className="TableColumnsDnd-item-content">
                                <Icon icon="ellipsis-v" />
                                <span>{item.label}</span>
                              </span>
                              <UncontrolledTooltip boundariesElement="body" delay={{ show: 0, hide: 0 }} placement={'bottom'} target={`col-d1-${index}`}>
                                {item.label}
                              </UncontrolledTooltip>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Col>
              <Col>
                <Droppable
                  droppableId="droppable2"
                  isDropDisabled={!this.state.isDraggedColumnHideable}
                  type="column"
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.selected.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                          type={'column'}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              id={`col-d2-${index}`}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <span className="TableColumnsDnd-item-content">
                                <Icon icon="ellipsis-v" />
                                <span>{item.label}</span>
                              </span>
                              <UncontrolledTooltip boundariesElement="body" delay={{ show: 0, hide: 0 }} placement={'bottom'} target={`col-d2-${index}`}>
                                {item.label}
                              </UncontrolledTooltip>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Col>
            </Row>
          </DragDropContext>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default onClickOutside(TableColumnsDnd);