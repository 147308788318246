import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { Button } from '$ui';
import { __ } from '@stktk/locales';
import { getSkuImageUrl } from '@stktk/logic/utils/sku';
import Input from '$ui/form/controls/Input';

//FIXME: extension != mimeType
const acceptedFileTypes = ['image/jpeg', 'image/png'];
const reader = new FileReader();

const FormSkuImage = ({ name, sku, stocktakeMode }) => {
  const [propImage, setPropImage] = useState(true);
  const [fileImage, setFileImage] = useState(null);
  const { setFieldValue } = useFormikContext();
  const inputRef = useRef(null);

  const loadEventListener = useCallback(() => {
    // console.log('loadEventListener', reader.result);
    if (!reader.result.split(';')[0] || !reader.result.split(';')[0].split(':')[1] || acceptedFileTypes.indexOf(reader.result.split(';')[0].split(':')[1]) < 0) {
      window.alert(__('error_unnaccepted_file_type', { extensions: 'jpg, jpeg, png' }));
    } else setFileImage(reader.result);
  }, []);

  useEffect(() => {
    reader.addEventListener('load', loadEventListener, false);
    return () => {
      reader.removeEventListener('load', loadEventListener);
    };
  }, []);

  const getPropImage = () =>
    propImage && sku && sku.image;

  const handleAddFile = e => {
    e.preventDefault();
    if (inputRef.current)
      inputRef.current.click();
  };

  const handleRemoveFile = () => {
    setFieldValue(name, null);
    setPropImage(false);
    setFileImage(false);
  };

  const handleFileSelect = e => {
    const [file] = e.target.files;
    if (file)
      reader.readAsDataURL(file);
  };

  return (
    <div>
      <Input
        ref={inputRef}
        isRaw
        id="skuImageFileInput"
        type="file"
        name={name}
        style={{ display: 'none' }}
        accept={/*acceptedFileTypes.join(', ')*/'.jpg, .jpeg, .png'}
        onChange={handleFileSelect}
      />
      <div className="Add-file form-group">
        <label className="control-label">
          {__('general_image')}
        </label>
        <div className="Add-file-body">
          <div
            className="Add-file-preview"
            style={{
              backgroundImage: `url("${(fileImage || getPropImage() || getSkuImageUrl({ stocktakeMode }))}")`,
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
          />
          <div className="Add-file-overlay">
            {fileImage || getPropImage() ?
              <Button
                size="sm"
                variant="outline-danger"
                icon="trash"
                text={__('button_delete_photo')}
                onClick={handleRemoveFile}
              />
              :
              <Button
                size="sm"
                variant="outline-light"
                icon="file-image"
                text={__('button_add_photo')}
                onClick={handleAddFile}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSkuImage;