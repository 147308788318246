import React, { Fragment } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { get } from 'lodash';

const FormArray = ({
  pushHandler,
  componentRenderer,
  name,
  ...props
}) => {
  const { values } = useFormikContext();
  return (
    <FieldArray
      {...props}
      name={name}
      render={arrayHelpers => (
        <Fragment>
          {get(values, name, []).map((value, index) =>
            componentRenderer(index, value, () => arrayHelpers.remove(index))
          )}
          {pushHandler && (
            pushHandler(arrayHelpers)
          )}
        </Fragment>
      )}
    />
  );

};

export default FormArray;