import React, { useState } from 'react';
import { InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { SkuSearchModeEnum } from '@stktk/commons';
import { __ } from '@stktk/locales';

const __seachSkuMode = mode => mode === SkuSearchModeEnum.BARCODE ? __('general_barcode') : __('general_name');

const SkuSearchModeDropdown = ({ mode, onModeChange }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <InputGroupButtonDropdown data-testid="SkuSearchModeDropdown" addonType="append" isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
      <DropdownToggle caret outline>
        {__seachSkuMode(mode)}
      </DropdownToggle>
      <DropdownMenu>
        {SkuSearchModeEnum.toArray().map(modeName =>
          <DropdownItem
            key={`mode-${modeName}`}
            data-testid={`SkuSearchModeDropdown-${modeName}`}
            tag="button"
            onClick={() => onModeChange(modeName)}
          >
            {__seachSkuMode(modeName)}
          </DropdownItem>
        )}
      </DropdownMenu>
    </InputGroupButtonDropdown>
  );
};

export default SkuSearchModeDropdown;