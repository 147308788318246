import React from 'react';
import { ToastContainer, toast as toastApi } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function getNotificationIcon(variant) {
  switch (variant) {
    case 'error': return 'times-octagon';
    case 'success': return 'check-circle';
    case 'info': return 'info-square';
    case 'warning': return 'exclamation-triangle';
    default: return null;
  }
}

function ToastBody({ title, description, variant }) {
  const notificationIcon = getNotificationIcon(variant);
  return (
    <div className="d-flex">
      {notificationIcon &&
        <div className="ToastBodyIcon mr-2">
          <i className={`fas fa-${notificationIcon}`} />
        </div>
      }
      <div>
        <h5>{title}</h5>
        <p>{description}</p>
      </div>
    </div>
  );
}

export const showToast = ({ title, description, variant = 'info' }) =>
  toastApi[variant](<ToastBody title={title} description={description} variant={variant} />);

export const toast = (title, description, variant) => showToast({ title, description, variant });

export default function ToastProvider() {
  return <ToastContainer />;
}