//import '$lib/wdyr';
import 'react-day-picker/lib/style.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import * as Cookies from 'js-cookie';
import renderApp from '$lib/renderApp';
//import 'react-joyride/lib/react-joyride-compiled.css';
if (window.domain.BRAND == 'barincontrol') //FIXME: use enum name
  require('$styles/webapp-barincontrol.scss');
else
  require('$styles/webapp-stktk.scss');

window.socket = io(window.location.origin, {
  autoConnect: false,
  query: {
    client: 'web',
    jwt: Cookies.get('jwt')
  }
});

window.socket.on('connect_error', error => {
  console.log(error);
});

renderApp();