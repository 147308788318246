"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _validatorConstants = require("../validator/validatorConstants.js");

var _commons = require("@stktk/commons");

var _default = {
  find: {
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  create: {
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    type: _validatorConstants.ENUM.STRING.required(_commons.DocumentTypeEnum.toArray()),
    status: _validatorConstants.ENUM.STRING.required(_commons.DocumentStatusEnum.toArray()),
    comment: _validatorConstants.STRING.required([1, 140]),
    file: _validatorConstants.NULL.optional(),
    recordsetId: _validatorConstants.INTEGER.optional([1]),
    properties: _validatorConstants.OBJECT.INPART.optional({}),
    number: _validatorConstants.STRING.optional([0, 80])
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    status: _validatorConstants.ENUM.STRING.optional(_commons.DocumentStatusEnum.toArray()),
    comment: _validatorConstants.STRING.required([1, 140]),
    recordsetId: _validatorConstants.INTEGER.optional([1]),
    properties: _validatorConstants.OBJECT.INPART.optional({}),
    manualEntryResult: _validatorConstants.OBJECT.INPART.optional({}),
    number: _validatorConstants.STRING.optional([0, 80])
  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  }
};
exports["default"] = _default;