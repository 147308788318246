import { isString, isBoolean, find, get, compact } from 'lodash';
import { getInitialValues } from '@stktk/logic/utils/form';
import { getSkuCopyName } from '@stktk/logic/utils/sku';
import { useModal, ModalTypeEnum } from '$hooks/modal';
import { useConfirmModal } from '$hooks/ui';
import { globalHandleErrorWithCallback } from '$utils/error';

//FIXME: Flexible action props / configuration
const getActionClickHandler = ({ name, item, props, action, setModal, confirmModal }) => event => {
  console.debug({ name, item, props, action });
  if (event && event.stopPropagation)
    event.stopPropagation();
  if (name === 'edit')
    return setModal({
      isOpen: true,
      scheme: isString(props.editScheme) ? props.editScheme : props.editScheme(item),
      item,
      data: props.data,
      onSubmit: action
    }, props.modalId, ModalTypeEnum.FORM);
  if (name === 'shelfToSheet')
    return setModal({
      isOpen: true,
      data: { storageStandard: get(props, 'data.item', item) }
    }, props.modalId, ModalTypeEnum.DATA);
  if (name === 'stocktakeStatus')
    return setModal({
      isOpen: true,
      data: { stockcheck: get(props, 'data.item', item) }
    }, props.modalId, ModalTypeEnum.DATA);
  if (name === 'parashiftEdit')
    return setModal({
      isOpen: true,
      scheme: props.scheme,
      item,
      data: props.data,
      onSubmit: action
    }, props.modalId, ModalTypeEnum.FORM);
  if (name === 'remove' || name === 'approve')
    return confirmModal(
      'danger',
      props.confirm[0],
      props.confirm[1],
      callback => action(item).then(callback).catch(globalHandleErrorWithCallback(callback))
    );
  if (name === 'merge')
    return confirmModal(
      'danger',
      props.confirm[0],
      props.confirm[1],
      callback => action(item).then(callback).catch(globalHandleErrorWithCallback(callback))
    );
  if (name === 'copy')
    return confirmModal(
      'info',
      props.confirm[0],
      props.confirm[1],
      callback => action({ ...getInitialValues(props.scheme(item), { ...props.formValues, isCopy: true }, item), name: getSkuCopyName(item, props.formValues.skus) })
        .then(callback).catch(globalHandleErrorWithCallback(callback))
    );
  return action(item, event);
};

export const useItemActions = (actions, item) => {
  const [/*modal*/, setModal] = useModal();
  const confirmModal = useConfirmModal();

  return compact(actions.map(({ name, icon, label, color, disabled = false, action, ...props }) => {
    if (!item) return null;
    return {
      name,
      icon,
      label,
      color,
      disabled: isBoolean(disabled) ? disabled : disabled(item),
      onClick: getActionClickHandler({ name, item, props, action, setModal, confirmModal })
    };
  }));
};