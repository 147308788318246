import { identity } from 'lodash';
import {
  dateComparator,
  measurementComparator,
  numberComparator,
  // recordAmountComparator,
  // recordPackageClosureWeightComparator,
  // recordProductWeightComparator,
  recordScaleWeightComparator,
  // recordTotalAmountComparator,
  skuComparator,
  skuMeasureComparator,
  textComparator
} from '$utils/table.js';
import { UNIQUE } from '$ui/table/columnAggregators';

export default {

  none: () => ({
    disableSortBy: true
  }),

  text: ({ property, display = identity, separator, unique = UNIQUE }) => ({
    sortType: (row1, row2) => {
      const value1 = row1.values[property];
      const value2 = row2.values[property];
      if (Array.isArray(value1) && Array.isArray(value2)) {
        return textComparator(unique({ values: value1, display, separator }), unique({ values: value2, display, separator }));
      } else return textComparator(display(row1.values[property]), display(row2.values[property]));
    },
    sortDescFirt: true
  }),

  number: ({ property, display = identity }) => ({
    sortType: (row1, row2) => numberComparator(display(row1.values[property]), display(row2.values[property])),
    sortDescFirst: true
  }),

  date: ({ property }) => ({
    sortType: (row1, row2) => dateComparator(row1.values[property], row2.values[property]),
    sortDescFirst: true
  }),

  sku: () => ({
    sortType: (row1, row2) =>
      skuComparator(row1.original, row2.original),
    sortDescFirst: true
  }),

  skuMeasure: ({ skuMeasure }) => ({
    sortType: (row1, row2) =>
      skuMeasureComparator(row1.original, row2.original, skuMeasure)
  }),

  // recordAmount: ({ nestedPath }) => ({
  //   sortType: (row1, row2) =>
  //     recordAmountComparator(row1.original, row1.original.sku, row2.original, row2.original.sku),
  //   sortDescFirst: true
  // }),

  recordScaleWeight: () => ({
    sortType: (row1, row2) =>
      recordScaleWeightComparator(row1.original, row1.original.sku, row2.original, row2.original.sku),
    sortDescFirst: true
  }),

  // recordVolume: () => ({
  //   sortType: (row1, row2) =>
  //     recordVolumeComparator(row1.original, row1.original.sku, row2.original, row2.original.sku),
  //   sortDescFirst: true
  // }),

  measurement: ({ property }) => ({
    sortType: (row1, row2) => measurementComparator(row1.values[property], row2.values[property]),
    sortDescFirst: true
  })

};