import React, { memo } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import classNames from 'classnames';
import { getInitialValues } from '@stktk/logic/utils/form';
import { __ } from '@stktk/locales';
import { compareAllProps } from '@stktk/logic/utils/memo';
import { useDispatch } from 'react-redux';
import {
  findOrders
} from '@stktk/redux';
import Form from '$ui/form/Form';
import ButtonModalClose from '$ui/ButtonModalClose';
import ButtonPrint from '$ui/ButtonPrint';
import FormButtonSubmit from '$ui/form/FormButtonSubmit';
import { globalHandleError } from '$utils/error';
import { useLoading, useConfirmModal } from '$hooks/ui';
import { useModal, ModalTypeEnum } from '$hooks/modal';

function getTitleContext(scheme, titleContextKey) {
  if (!scheme)
    return '';
  const schemeArray = scheme.split('.');
  const schemeType = schemeArray[schemeArray.length - 1];
  return __(`${titleContextKey}_${schemeType}`);
}

export const FormModalConstants = {
  DEFAULT_ID: 'generalFormModal'
};

function FormModal({ id = 'generalFormModal', className, data, title, titleContextKey, onSubmit, size = 'lg', FormBody, PrintBody, submitButtonProps = {}, children, ...props }) {
  const dispatch = useDispatch();
  const confirmModal = useConfirmModal();
  const [/*isLoading*/, setLoading] = useLoading(id);
  const [modal, setModal] = useModal(ModalTypeEnum.FORM, id);
  const titleContext = getTitleContext(modal.scheme, titleContextKey);
  console.count(id);

  const handleHide = () => {
    setModal({ ...modal, isOpen: false });
  };

  const submit = data => modal.onSubmit(data, modal.scheme).then(() => {
    if (onSubmit)
      onSubmit(data);
    setLoading(false);
    handleHide();
  }).catch(error => {
    setLoading(false);
    return globalHandleError(error);
  });

  const handleSubmit = data => {
    setLoading(true);
    if (id === 'OrdersForm' && modal.scheme === 'order.create')
      dispatch(findOrders({ restaurantId: data.restaurantId })).then(orders => {
        const hasOrder = data.comment.trim().length > 0 && orders.find(order => order.comment && order.comment.trim().toLowerCase() === data.comment.trim().toLowerCase());
        if (hasOrder)
          confirmModal('warning', __('app_modal_orders_comment_warning_title'), __('app_modal_orders_comment_warning_text'), cb => { submit(data); cb(); }, cb => { setLoading(false); cb(); });
        else
          submit(data);
      });
    else submit(data);
  };

  return (
    <Modal
      id={id}
      data-testid="FormModal"
      data-modalid={id}
      className={classNames('modal-fullscreen-sm', className)}
      isOpen={modal.isOpen}
      size={size}
      toggle={handleHide}
      {...props}
    >
      {modal.isOpen &&
        <Form
          className="d-flex flex-column flex-grow-1"
          scheme={modal.scheme}
          initialValues={getInitialValues(modal.scheme, { ...modal.data, ...data }, modal.item)}
          onSubmit={handleSubmit}
          {...props}
        >
          <ModalHeader toggle={handleHide} close={<ButtonModalClose modalId={id} onClick={handleHide} />}>
            {title || titleContext}
          </ModalHeader>
          <ModalBody>
            <FormBody scheme={modal.scheme} data={{ ...modal.data, ...data }} item={modal.item} />
          </ModalBody>
          <ModalFooter>
            {PrintBody && (
              <ButtonPrint PrintBody={PrintBody} data={modal.data} item={modal.item} />
            )}
            <FormButtonSubmit formId={id} {...submitButtonProps} />
          </ModalFooter>
        </Form>
      }
    </Modal>
  );
}

export default memo(FormModal, compareAllProps);