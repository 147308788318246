"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTechnicalExportSheetname = exports.getTechnicalExportFilename = exports.getExportTypeConfig = exports.getExportSheetname = exports.getExportFilename = exports.ExportType = void 0;

var _lodash = require("lodash");

var _moment = _interopRequireDefault(require("moment"));

var _ExportTypeConfig;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var ExportType = {
  XLSX: 'xlsx',
  CSV: 'csv',
  TXT: 'txt',
  PDF: 'pdf'
};
exports.ExportType = ExportType;
var ExportTypeConfig = (_ExportTypeConfig = {}, _defineProperty(_ExportTypeConfig, ExportType.XLSX, {
  mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  extension: 'xlsx'
}), _defineProperty(_ExportTypeConfig, ExportType.CSV, {
  mimeType: 'text/csv',
  extension: 'csv'
}), _defineProperty(_ExportTypeConfig, ExportType.TXT, {
  mimeType: 'text/csv',
  extension: 'txt'
}), _defineProperty(_ExportTypeConfig, ExportType.PDF, {
  mimeType: 'application/pdf',
  extension: 'pdf'
}), _ExportTypeConfig);

var getExportTypeConfig = function getExportTypeConfig(exportType) {
  return ExportTypeConfig[exportType];
};

exports.getExportTypeConfig = getExportTypeConfig;

var getExportFilename = function getExportFilename(description, id, venue, date) {
  return "".concat(description, "-").concat(venue && venue.name ? (0, _lodash.deburr)(venue.name) + '-' : '').concat(id ? (0, _lodash.deburr)(id) + '-' : '').concat((0, _moment["default"])(date ? date : new Date()).format('YYYYMMDD'));
};

exports.getExportFilename = getExportFilename;

var getExportSheetname = function getExportSheetname(description, id, venue, date) {
  return "".concat(description.substring(0, 22), "-").concat((0, _moment["default"])(date ? date : new Date()).format('YYYYMMDD'));
};

exports.getExportSheetname = getExportSheetname;

var getTechnicalExportFilename = function getTechnicalExportFilename(description, id, venue, date) {
  return "t_".concat(description, "-").concat((0, _moment["default"])(date ? date : new Date()).format('YYYYMMDD'));
};

exports.getTechnicalExportFilename = getTechnicalExportFilename;

var getTechnicalExportSheetname = function getTechnicalExportSheetname(description, id, venue, date) {
  return "".concat(description.substring(0, 22), "-").concat((0, _moment["default"])(date ? date : new Date()).format('YYYYMMDD'));
};

exports.getTechnicalExportSheetname = getTechnicalExportSheetname;