import { set, get } from 'lodash';

export const getParsedLocalStorage = (key, fallback = null) => {
  let result = fallback;
  try {
    let item = localStorage.getItem(key);
    let json = JSON.parse(item);
    result = json || fallback;
  } catch (error) {
    console.error(error);
  }
  return result;
};

export const addLocalStorageArrayElement = (key, element) =>
  localStorage.setItem(key, JSON.stringify([...getParsedLocalStorage(key, []), element]));

export const resetTableDisplayConfiguration = () =>
  localStorage.removeItem('tableDisplayConfiguration');

export const setTableDisplayConfiguration = ({ tableId, domainId, key, data }) => {
  let tableConfiguration = getParsedLocalStorage('tableDisplayConfiguration', {});
  set(tableConfiguration, `${domainId}.${tableId}.${key}`, data);
  return localStorage.setItem('tableDisplayConfiguration', JSON.stringify(tableConfiguration));
};

export const getTableDisplayConfiguration = ({ tableId, domainId }) => {
  let tableConfiguration = getParsedLocalStorage('tableDisplayConfiguration', {});
  return get(tableConfiguration, `${domainId}.${tableId}`, {});
};