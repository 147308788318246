import React from 'react';
import Button from '$ui/Button';
import Icon from '$ui/Icon';

const ButtonModalClose = ({ modalId, ...props }) => (
  <Button
    iconOnly
    data-testid="ButtonModalClose"
    data-modalid={modalId}
    variant="none"
    size="sm"
    {...props}
  >
    <Icon icon="times" />
  </Button>
);

export default ButtonModalClose;