import React from 'react';
import Flex from '$ui/Flex';

const NavbarVerticalMenuItem = ({ route }) => (
  <Flex align="center">
    {route.icon && (
      <span className="nav-link-icon">
        <i className={`fas fa-${route.icon}`} />
      </span>
    )}
    <span className="nav-link-text">{route.name}</span>
  </Flex>
);

export default React.memo(NavbarVerticalMenuItem);