"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userLanguageSelector = exports.userIdSelector = exports.userDomainRoleSelector = exports.userDataSelector = exports.tagSelector = exports.supplierSelector = exports.supplierSelectedSelector = exports.storageTransferSelector = exports.storageTransferRecordsSelector = exports.storageTransferGroupSelector = exports.storageTransferGroupSelectedSelector = exports.storageTransferGroupDomainSelector = exports.storageStandardSelector = exports.storageStandardSelectedSelector = exports.storageRecordSelector = exports.stocktakeRecordsSelector = exports.stockcheckSelector = exports.stockcheckSelectedSelector = exports.skuUpdatedItemSelector = exports.skuSuppliersSelector = exports.skuSelector = exports.skuSelectedSelector = exports.skuSearchSelector = exports.skuListSelector = exports.saleSelector = exports.saleSelectedSelector = exports.saleRecordSelector = exports.restaurantSelector = exports.restaurantSelectedSelector = exports.restaurantNameSelector = exports.restaurantIdSelector = exports.reportSelector = exports.reportSelectedSelector = exports.recipeSelector = exports.propertySelector = exports.productionRecordsSelector = exports.productionGroupSelector = exports.productionGroupSelectedSelector = exports.packageClosureSelector = exports.orderSelector = exports.orderSelectedSelector = exports.orderRecordSelector = exports.orderArrivedRecordSelector = exports.notificationSelector = exports.memberSelector = exports.lossRecordSelector = exports.lossGroupSelector = exports.lossGroupSelectedSelector = exports.integrationInstancesSelectedSelector = exports.integrationInstancesRootSelector = exports.integrationInstancesBasicSelector = exports.integrationInstanceContextsSelector = exports.integrationInstanceContextsRootSelector = exports.integrationEntriesSelector = exports.integrationEntriesRootSelector = exports.domainIdSelector = exports.documentSelector = exports.categorySelector = exports.barSelector = exports.barDomainSelector = void 0;

var _reduxViews = require("redux-views");

var _commons = require("@stktk/commons");

// Root selectors TODO: audit code for import
var getRootIntegrationInstances = function getRootIntegrationInstances(state) {
  return state.integrationInstances;
};

var getRootIntegrationEntries = function getRootIntegrationEntries(state) {
  return state.integrationEntries;
};

var getRootIntegrationInstanceContexts = function getRootIntegrationInstanceContexts(state) {
  return state.integrationInstanceContexts;
}; // Item Selectors


var getBarItems = function getBarItems(state) {
  return state.bars.items;
};

var getRestaurantItems = function getRestaurantItems(state) {
  return state.restaurants.items;
};

var getCategoriesItems = function getCategoriesItems(state) {
  return state.categories.items;
};

var getSkuItems = function getSkuItems(state) {
  return state.skus.items;
};

var getSkuSuppliersItems = function getSkuSuppliersItems(state) {
  return state.skuSuppliers.items;
};

var getSkuListItems = function getSkuListItems(state) {
  return state.skus.listItems;
};

var getSkuSearchItems = function getSkuSearchItems(state) {
  return state.skus.searchItems;
};

var getTagItems = function getTagItems(state) {
  return state.tags.items;
};

var getNotificationItems = function getNotificationItems(state) {
  return state.notifications.items;
};

var getPropertyItems = function getPropertyItems(state) {
  return state.properties.items;
};

var getSupplierItems = function getSupplierItems(state) {
  return state.suppliers.items;
};

var getMemberItems = function getMemberItems(state) {
  return state.members.items;
};

var getStockcheckItems = function getStockcheckItems(state) {
  return state.stockchecks.items;
};

var getOrderItems = function getOrderItems(state) {
  return state.orders.items;
};

var getSaleItems = function getSaleItems(state) {
  return state.sales.items;
};

var getRecipeItems = function getRecipeItems(state) {
  return state.recipes.items;
};

var getProductionRecordsItems = function getProductionRecordsItems(state) {
  return state.productionRecords.items;
};

var getProductionGroupItems = function getProductionGroupItems(state) {
  return state.productionGroups.items;
};

var getDocumentItems = function getDocumentItems(state) {
  return state.documents.items;
};

var getStorageRecordsItems = function getStorageRecordsItems(state) {
  return state.storageRecords.items;
};

var getOrderRecordsItems = function getOrderRecordsItems(state) {
  return state.orderRecords.items;
};

var getSaleRecordsItems = function getSaleRecordsItems(state) {
  return state.saleRecords.items;
};

var getStocktakeRecordsItems = function getStocktakeRecordsItems(state) {
  return state.stocktakeRecords.items;
};

var getLossRecordsItems = function getLossRecordsItems(state) {
  return state.lossRecords.items;
};

var getStorageTransferRecordsItems = function getStorageTransferRecordsItems(state) {
  return state.storageTransferRecords.items;
};

var getLossGroupItems = function getLossGroupItems(state) {
  return state.lossGroups.items;
};

var getStorageStandardItems = function getStorageStandardItems(state) {
  return state.storageStandards.items;
};

var getStorageTransferGroupItems = function getStorageTransferGroupItems(state) {
  return state.storageTransferGroups.items;
};

var getStorageTransfersItems = function getStorageTransfersItems(state) {
  return state.storageTransfers.items;
};

var getPackageClosureItems = function getPackageClosureItems(state) {
  return state.packageClosures.items;
};

var getReportItems = function getReportItems(state) {
  return state.reports.items;
};

var getIntegrationInstanceContexts = function getIntegrationInstanceContexts(state) {
  return state.integrationInstanceContexts.items;
};

var getIntegrationInstances = function getIntegrationInstances(state) {
  return state.integrationInstances.items;
};

var getIntegrationEntries = function getIntegrationEntries(state) {
  return state.integrationEntries.items;
}; // Selected selectors


var getRestaurantSelected = function getRestaurantSelected(state) {
  return state.restaurants.selected;
};

var getLossGroupSelected = function getLossGroupSelected(state) {
  return state.lossGroups.selected;
};

var getOrderSelected = function getOrderSelected(state) {
  return state.orders.selected;
};

var getSkuSelected = function getSkuSelected(state) {
  return state.skus.selected;
};

var getReportSelected = function getReportSelected(state) {
  return state.reports.selected;
};

var getStorageTransferGroupSelected = function getStorageTransferGroupSelected(state) {
  return state.storageTransferGroups.selected;
};

var getStorageStandardSelected = function getStorageStandardSelected(state) {
  return state.storageStandards.selected;
};

var getStockcheckSelected = function getStockcheckSelected(state) {
  return state.stockchecks.selected;
};

var getSaleSelected = function getSaleSelected(state) {
  return state.sales.selected;
};

var getProductionGroupSelected = function getProductionGroupSelected(state) {
  return state.productionGroups.selected;
};

var getSupplierSelected = function getSupplierSelected(state) {
  return state.suppliers.selected;
};

var getIntegrationInstancesSelected = function getIntegrationInstancesSelected(state) {
  return state.integrationInstances.selected;
}; // User data selector


var getUserData = function getUserData(state) {
  return state.user.data;
};

var getSkuUpdatedItem = function getSkuUpdatedItem(state) {
  return state.skus.updatedItem;
}; // Item Memoized Selectors


var barSelector = (0, _reduxViews.createSelector)([getBarItems, getRestaurantSelected], function (items, restaurant) {
  return items.filter(function (_ref) {
    var restaurantId = _ref.restaurantId;
    return restaurant && restaurant.id === restaurantId;
  });
});
exports.barSelector = barSelector;
var barDomainSelector = (0, _reduxViews.createSelector)([getBarItems], function (items) {
  return items;
});
exports.barDomainSelector = barDomainSelector;
var restaurantSelector = (0, _reduxViews.createSelector)([getRestaurantItems], function (items) {
  return items;
});
exports.restaurantSelector = restaurantSelector;
var integrationInstanceContextsSelector = (0, _reduxViews.createSelector)([getIntegrationInstanceContexts], function (items) {
  return items;
});
exports.integrationInstanceContextsSelector = integrationInstanceContextsSelector;
var integrationEntriesSelector = (0, _reduxViews.createSelector)([getIntegrationEntries], function (items) {
  return items;
});
exports.integrationEntriesSelector = integrationEntriesSelector;
var categorySelector = (0, _reduxViews.createSelector)([getCategoriesItems], function (items) {
  return items;
});
exports.categorySelector = categorySelector;
var skuSelector = (0, _reduxViews.createSelector)([getSkuItems], function (items) {
  return items;
});
exports.skuSelector = skuSelector;
var skuSuppliersSelector = (0, _reduxViews.createSelector)([getSkuSuppliersItems], function (items) {
  return items;
});
exports.skuSuppliersSelector = skuSuppliersSelector;
var skuListSelector = (0, _reduxViews.createSelector)([getSkuListItems], function (items) {
  return items;
});
exports.skuListSelector = skuListSelector;
var skuSearchSelector = (0, _reduxViews.createSelector)([getSkuSearchItems], function (items) {
  return items;
});
exports.skuSearchSelector = skuSearchSelector;
var tagSelector = (0, _reduxViews.createSelector)([getTagItems], function (items) {
  return items;
});
exports.tagSelector = tagSelector;
var reportSelector = (0, _reduxViews.createSelector)([getReportItems], function (items) {
  return items;
});
exports.reportSelector = reportSelector;
var propertySelector = (0, _reduxViews.createSelector)([getPropertyItems], function (items) {
  return items;
});
exports.propertySelector = propertySelector;
var supplierSelector = (0, _reduxViews.createSelector)([getSupplierItems], function (items) {
  return items;
});
exports.supplierSelector = supplierSelector;
var memberSelector = (0, _reduxViews.createSelector)([getMemberItems], function (items) {
  return items;
});
exports.memberSelector = memberSelector;
var stockcheckSelector = (0, _reduxViews.createSelector)([getStockcheckItems], function (items) {
  return items;
});
exports.stockcheckSelector = stockcheckSelector;
var stocktakeRecordsSelector = (0, _reduxViews.createSelector)([getStocktakeRecordsItems], function (items) {
  return items;
});
exports.stocktakeRecordsSelector = stocktakeRecordsSelector;
var orderSelector = (0, _reduxViews.createSelector)([getOrderItems], function (items) {
  return items;
});
exports.orderSelector = orderSelector;
var lossGroupSelector = (0, _reduxViews.createSelector)([getLossGroupItems], function (items) {
  return items;
});
exports.lossGroupSelector = lossGroupSelector;
var storageStandardSelector = (0, _reduxViews.createSelector)([getStorageStandardItems], function (items) {
  return items;
});
exports.storageStandardSelector = storageStandardSelector;
var documentSelector = (0, _reduxViews.createSelector)([getDocumentItems], function (items) {
  return items;
});
exports.documentSelector = documentSelector;
var storageRecordSelector = (0, _reduxViews.createSelector)([getStorageRecordsItems], function (items) {
  return items;
});
exports.storageRecordSelector = storageRecordSelector;
var orderRecordSelector = (0, _reduxViews.createSelector)([getOrderRecordsItems], function (items) {
  return items;
});
exports.orderRecordSelector = orderRecordSelector;
var orderArrivedRecordSelector = (0, _reduxViews.createSelector)([getOrderRecordsItems], function (items) {
  return items.filter(function (_ref2) {
    var status = _ref2.status;
    return status === _commons.RecordStatusEnum.ORDER_ARRIVED;
  });
});
exports.orderArrivedRecordSelector = orderArrivedRecordSelector;
var saleRecordSelector = (0, _reduxViews.createSelector)([getSaleRecordsItems], function (items) {
  return items;
});
exports.saleRecordSelector = saleRecordSelector;
var saleSelector = (0, _reduxViews.createSelector)([getSaleItems], function (items) {
  return items;
});
exports.saleSelector = saleSelector;
var recipeSelector = (0, _reduxViews.createSelector)([getRecipeItems], function (items) {
  return items;
});
exports.recipeSelector = recipeSelector;
var lossRecordSelector = (0, _reduxViews.createSelector)([getLossRecordsItems], function (items) {
  return items;
});
exports.lossRecordSelector = lossRecordSelector;
var packageClosureSelector = (0, _reduxViews.createSelector)([getPackageClosureItems], function (items) {
  return items;
});
exports.packageClosureSelector = packageClosureSelector;
var storageTransferRecordsSelector = (0, _reduxViews.createSelector)([getStorageTransferRecordsItems], function (items) {
  return items;
});
exports.storageTransferRecordsSelector = storageTransferRecordsSelector;
var productionRecordsSelector = (0, _reduxViews.createSelector)([getProductionRecordsItems], function (items) {
  return items;
});
exports.productionRecordsSelector = productionRecordsSelector;
var productionGroupSelector = (0, _reduxViews.createSelector)([getProductionGroupItems], function (items) {
  return items;
});
exports.productionGroupSelector = productionGroupSelector;
var storageTransferGroupSelector = (0, _reduxViews.createSelector)([getStorageTransferGroupItems], function (items) {
  return items;
});
exports.storageTransferGroupSelector = storageTransferGroupSelector;
var storageTransferSelector = (0, _reduxViews.createSelector)([getStorageTransfersItems], function (items) {
  return items;
});
exports.storageTransferSelector = storageTransferSelector;
var storageTransferGroupDomainSelector = (0, _reduxViews.createSelector)([getStorageTransferGroupItems, getStorageTransferGroupSelected], function (items, selected) {
  return selected && items.filter(function (_ref3) {
    var rangeFromDate = _ref3.rangeFromDate,
        rangeToDate = _ref3.rangeToDate;
    return rangeFromDate === selected.rangeFromDate && rangeToDate === selected.rangeToDate;
  });
}); //TODO: filteredNotificationSelector

exports.storageTransferGroupDomainSelector = storageTransferGroupDomainSelector;
var notificationSelector = (0, _reduxViews.createSelector)([getNotificationItems], function (items) {
  return items;
}); // Selected Memoized Selectors

exports.notificationSelector = notificationSelector;
var restaurantSelectedSelector = (0, _reduxViews.createSelector)([getRestaurantSelected], function (selected) {
  return selected;
});
exports.restaurantSelectedSelector = restaurantSelectedSelector;
var skuSelectedSelector = (0, _reduxViews.createSelector)([getSkuSelected], function (selected) {
  return selected;
});
exports.skuSelectedSelector = skuSelectedSelector;
var lossGroupSelectedSelector = (0, _reduxViews.createSelector)([getLossGroupSelected], function (selected) {
  return selected;
});
exports.lossGroupSelectedSelector = lossGroupSelectedSelector;
var orderSelectedSelector = (0, _reduxViews.createSelector)([getOrderSelected], function (selected) {
  return selected;
});
exports.orderSelectedSelector = orderSelectedSelector;
var reportSelectedSelector = (0, _reduxViews.createSelector)([getReportSelected], function (selected) {
  return selected;
});
exports.reportSelectedSelector = reportSelectedSelector;
var stockcheckSelectedSelector = (0, _reduxViews.createSelector)([getStockcheckSelected], function (selected) {
  return selected;
});
exports.stockcheckSelectedSelector = stockcheckSelectedSelector;
var storageTransferGroupSelectedSelector = (0, _reduxViews.createSelector)([getStorageTransferGroupSelected], function (selected) {
  return selected;
});
exports.storageTransferGroupSelectedSelector = storageTransferGroupSelectedSelector;
var storageStandardSelectedSelector = (0, _reduxViews.createSelector)([getStorageStandardSelected], function (selected) {
  return selected;
});
exports.storageStandardSelectedSelector = storageStandardSelectedSelector;
var saleSelectedSelector = (0, _reduxViews.createSelector)([getSaleSelected], function (selected) {
  return selected;
});
exports.saleSelectedSelector = saleSelectedSelector;
var supplierSelectedSelector = (0, _reduxViews.createSelector)([getSupplierSelected], function (selected) {
  return selected;
});
exports.supplierSelectedSelector = supplierSelectedSelector;
var productionGroupSelectedSelector = (0, _reduxViews.createSelector)([getProductionGroupSelected], function (selected) {
  return selected;
});
exports.productionGroupSelectedSelector = productionGroupSelectedSelector;
var integrationInstancesSelectedSelector = (0, _reduxViews.createSelector)([getIntegrationInstancesSelected], function (selected) {
  return selected;
}); // User data Memoized Selector

exports.integrationInstancesSelectedSelector = integrationInstancesSelectedSelector;
var userDataSelector = (0, _reduxViews.createSelector)([getUserData], function (data) {
  return data || {};
});
exports.userDataSelector = userDataSelector;
var userLanguageSelector = (0, _reduxViews.createSelector)([getUserData], function (data) {
  return data ? data.language : null;
}); // ID Memoized Selectors

exports.userLanguageSelector = userLanguageSelector;
var restaurantIdSelector = (0, _reduxViews.createSelector)([getRestaurantSelected], function (selected) {
  return selected ? selected.id : null;
});
exports.restaurantIdSelector = restaurantIdSelector;
var domainIdSelector = (0, _reduxViews.createSelector)([getUserData], function (data) {
  return data ? data.domainId : null;
});
exports.domainIdSelector = domainIdSelector;
var userIdSelector = (0, _reduxViews.createSelector)([getUserData], function (data) {
  return data ? data.id : null;
}); // Miscellanous Selectors

exports.userIdSelector = userIdSelector;
var userDomainRoleSelector = (0, _reduxViews.createSelector)([getUserData], function (data) {
  if (!data) return null;
  var domain = (data.domains || []).find(function (_ref4) {
    var id = _ref4.id;
    return id === data.domainId;
  });
  return domain && domain.domainUser && domain.domainUser.role;
});
exports.userDomainRoleSelector = userDomainRoleSelector;
var restaurantNameSelector = (0, _reduxViews.createSelector)([getRestaurantSelected], function (selected) {
  return selected ? selected.name : null;
});
exports.restaurantNameSelector = restaurantNameSelector;
var skuUpdatedItemSelector = (0, _reduxViews.createSelector)([getSkuUpdatedItem], function (data) {
  return data || {};
});
exports.skuUpdatedItemSelector = skuUpdatedItemSelector;
var integrationInstancesBasicSelector = (0, _reduxViews.createSelector)([getIntegrationInstances], function (items) {
  return (0, _commons.getBasicIntegrationInstance)(items);
}); // Root Memoized selectors TODO: audit code for import

exports.integrationInstancesBasicSelector = integrationInstancesBasicSelector;
var integrationInstancesRootSelector = (0, _reduxViews.createSelector)([getRootIntegrationInstances], function (root) {
  return root;
});
exports.integrationInstancesRootSelector = integrationInstancesRootSelector;
var integrationEntriesRootSelector = (0, _reduxViews.createSelector)([getRootIntegrationEntries], function (root) {
  return root;
});
exports.integrationEntriesRootSelector = integrationEntriesRootSelector;
var integrationInstanceContextsRootSelector = (0, _reduxViews.createSelector)([getRootIntegrationInstanceContexts], function (root) {
  return root;
});
exports.integrationInstanceContextsRootSelector = integrationInstanceContextsRootSelector;