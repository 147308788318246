import React from 'react';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import { round } from 'lodash';
import Input from '$ui/form/controls/Input';
import { Button } from '$ui';

const NumericInput = ({ disabled, name, placeholder, value, onChange, roundPrecision = 3, hideControls }) => {

  const handleAddition = e => {
    e.preventDefault();
    let quantity = parseFloat(value) + 1;
    onChange(round(quantity, roundPrecision));
  };

  const handleSubstraction = e => {
    e.preventDefault();
    let quantity = parseFloat(value) - 1;
    if (quantity < 0) quantity = 0;
    onChange(round(quantity, roundPrecision));
  };

  const handleChange = e => {
    e.preventDefault();
    let quantity = parseFloat(e.target.value);
    onChange(quantity);
  };

  if (hideControls)
    return (
      <Input
        name={name}
        disabled={disabled}
        className="border-0 text-center"
        value={value}
        placeholder={placeholder}
        type="number"
        step={0.01}
        onChange={e => handleChange(e)}
      />
    );

  return (
    <InputGroup>
      <InputGroupAddon addonType="prepend">
        <Button
          iconOnly
          disabled={disabled}
          icon="minus"
          variant="muted"
          onClick={handleSubstraction}
        />
      </InputGroupAddon>
      <Input
        name={name}
        disabled={disabled}
        className="border-0 text-center"
        value={value}
        placeholder={placeholder}
        type="number"
        step={0.01}
        onChange={e => handleChange(e)}
      />
      <InputGroupAddon addonType="append">
        <Button
          iconOnly
          disabled={disabled}
          icon="plus"
          variant="muted"
          onClick={handleAddition}
        />
      </InputGroupAddon>
    </InputGroup>
  );
};

export default NumericInput;