import React, { useEffect } from 'react';
import { components } from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import gravatar from 'gravatar';
import { findMembers, memberSelector, domainIdSelector, restaurantIdSelector } from '@stktk/redux';
import { FormGroup } from '$ui';
import Badges from '$ui/Badges';
import { globalHandleError } from '$utils/error';
import { useLoading } from '$hooks/ui';

export default function FormPersonnelGroup({ id = 'FormPersonnelGroup', allowSelectAll, ...props }) {
  const members = useSelector(memberSelector);
  const domainId = useSelector(domainIdSelector);
  const restaurantId = useSelector(restaurantIdSelector);
  const [isLoading, setLoading] = useLoading(id);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(findMembers({ domainId, restaurantId })).then(() => setLoading(false)).catch(globalHandleError);
  }, []);

  return (
    <FormGroup
      isMulti
      allowSelectAll={allowSelectAll}
      isLoading={isLoading}
      isDisabled={members.length === 1}
      element="select"
      options={
        members.map(member => ({
          value: member.id,
          label: (member.firstName + ' ' + member.lastName),
          tags: member.tags,
          email: member.email
        }))
      }
      components={{ Option: ({ data, ...props }) => (
        <components.Option {...props}>
          { data.isTechOption ? //FIXME: distinguish select all option (via params?)
            <div className="d-flex flex-row align-items-center">
              <span className="PersonnelOption-name">{props.label}</span>
            </div>
            :
            <div className="d-flex flex-row align-items-center">
              <img className="PersonnelOption-avatar" src={gravatar.url(data.email, { s: 26, d: 'mm' })} />
              <span className="PersonnelOption-name">{data.label}</span>
              <span className="d-inline-block ml-2">
                <Badges items={(data.tags || []).map(({ name, ...tag }) => ({ ...tag, label: name }))} />
              </span>
            </div>
          }
        </components.Option>
      ) }}
      {...props}
    />
  );
}