import classNames from 'classnames';
import React from 'react';

const ListGroupItemWrapper = ({ indent, indentColor, children, ...props }) => {
  if (!indent)
    return children;
  return (
    <div className="d-flex flex-row w-100" {...props}>
      <div style={{ width: indent, backgroundColor: indentColor, borderTopRightRadius: 3, borderBottomRightRadius: 3 }} />
      {children}
    </div>
  );
};

export default React.forwardRef(({ indent, indentColor, className, children, ...props }, ref) => {
  return (
    <li ref={ref} data-testid="ListGroupItem" className={classNames(className, 'list-group-item', 'overflow-hidden', { 'p-0': indent })} {...props}>
      <ListGroupItemWrapper indent={indent} indentColor={indentColor}>
        {children}
      </ListGroupItemWrapper>
    </li>
  );
});