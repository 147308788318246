import React, { Component } from 'react';
import { connect } from 'formik';
import { map, find, sum } from 'lodash';
import { Pie } from 'react-chartjs-2';
import { getSkuIngredientSkuPortionPrice } from '@stktk/commons';

const colorsArray = ['#2196f3', '#FA8072', '#9c27b0', '#e91e63', '#03a9f4', '#00bcd4', '#ffeb3b', '#ff5722', '#673ab7', '#3f51b5', '#ff9800', '#FF0000', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffc107', '#800000', '#795548', '#607d8b', '#696969'];

class FormCalcChart extends Component {

  render() {
    const { formik, ingredients, skus } = this.props;
    const labels = map(ingredients, 'name');
    const prices = map(formik.values.ingredients, value => {
      const ingredient = { ...value, ingredient: find(ingredients, { id: value.ingredientId }) };
      //FIXME: components tries to rerender a removed item, doesn't find it in ingredients and throws an error
      // return getSkuIngredientSkuPortionPrice(ingredient, ingredient.ingredient, 'netPurchasePrice', skus)
      return ingredient.ingredient ? getSkuIngredientSkuPortionPrice(ingredient, ingredient.ingredient, 'netPurchasePrice', skus) : 0;
    });
    const total = sum(prices);
    const data = map(prices, price => Math.round(price / total * 100));
    const backgroundColor = colorsArray.slice(0, prices.length);
    return (
      <div>
        <Pie data={{
          labels,
          datasets: [{
            data,
            backgroundColor
          }]
        }}
        />
      </div>
    );
  }

}

export default connect(FormCalcChart);