import React from 'react';
import moment from 'moment';
import FormButtonGroupSelect from '$ui/form/FormButtonGroupSelect';

const getMomentWeekdays = dayNameLength => {
  switch (dayNameLength) {
    case 'min':
      return moment.weekdaysMin();
    case 'short':
      return moment.weekdaysShort();
    default:
      return moment.weekdays();
  }
};

const FormWeekdayPicker = ({ dayNameLength = 'full', ...props }) => {
  return (
    <FormButtonGroupSelect
      options={getMomentWeekdays(dayNameLength).map((label, value) => ({ label, value }))}
      {...props}
    />
  );
};

export default FormWeekdayPicker;