"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatDate = void 0;

var _moment = _interopRequireDefault(require("moment"));

var _locales = require("@stktk/locales");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var formatDate = function formatDate(date) {
  var language = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : (0, _locales.getLanguage)();
  var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'L LT';
  var fallback = arguments.length > 3 ? arguments[3] : undefined;
  return date ? (0, _moment["default"])(date).locale(language).format(format) : fallback && null;
};

exports.formatDate = formatDate;