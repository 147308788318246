"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ValidationPlatformEnum = void 0;
exports.parseWithScheme = parseWithScheme;
exports.stripUnknownWithScheme = stripUnknownWithScheme;
exports.validate = validate;

var _validatorConstants = require("../validator/validatorConstants.js");

var _lodash = require("lodash");

var ValidationPlatformEnum = {
  SERVER: 'server',
  CLIENT: 'client',
  REDUX: 'redux'
}; // eslint-disable-next-line complexity

exports.ValidationPlatformEnum = ValidationPlatformEnum;

function validate(data, platform, scheme) {
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  options.context = Object.assign({
    validatePlatform: platform
  }, options.context);

  try {
    return {
      value: scheme.validateSync ? scheme.validateSync(data, options) : _validatorConstants.OBJECT.schema(scheme).validateSync(data, options),
      error: null
    };
  } catch (error) {
    if (JSON.stringify(error) == '{}') return {
      error: error.toString()
    };
    return {
      error: error,
      value: data
    };
  }
}

function stripUnknownWithScheme(data, platform, scheme) {
  if (Array.isArray(data)) return data;
  return (0, _lodash.omitBy)(data, function (value, key) {
    return !scheme.fields[key];
  });
}

function parseWithScheme(data, platform, scheme, options) {
  if (Array.isArray(data)) {
    return data;
  } else if ((0, _lodash.isString)(data)) {
    return scheme ? validate(data, platform, scheme, Object.assign({
      strict: false
    }, options)).value : data;
  }

  return (0, _lodash.mapValues)(data, function (value, key) {
    var parsedValue = scheme.fields[key] ? validate(value, platform, scheme.fields[key], Object.assign({
      strict: false
    }, options)).value : value;
    if (parsedValue === 'null') parsedValue = null;else if (parsedValue === 'undefined') parsedValue = undefined;else if (parsedValue === 'NaN') parsedValue = NaN;
    return parsedValue;
  });
}