import React, { memo, Fragment, useMemo, useReducer, useEffect } from 'react';
import { useTable, useGroupBy, useExpanded, useRowSelect, useSortBy, useFilters, useGlobalFilter, usePagination, useFlexLayout, useResizeColumns, useColumnOrder } from 'react-table';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { get, difference } from 'lodash';
import stickybits from 'stickybits';
import { useSettings } from '@stktk/logic/hooks/settings';
import { getExportTypeConfig } from '@stktk/logic/utils/export';
import { compareProps } from '@stktk/logic/utils/memo';
import { __ } from '@stktk/locales';
import { getFilter } from '@stktk/logic/utils/filter';
import { setTableDisplayConfiguration, getTableDisplayConfiguration } from '$lib/localStorage';
import TableTh from '$ui/table/TableTh';
import TableTr from '$ui/table/TableTr';
import TableTd from '$ui/table/TableTd';
import TableActionBar from '$ui/table/TableActionBar';
import TableIndeterminateCheckbox from '$ui/table/TableIndeterminateCheckbox';
import TableReducer from '$ui/table/TableReducer';
import Pagination from '$ui/Pagination';

const Table = ({
  id,
  columns,
  data,
  defaultSorted = [],
  defaultGrouped = [],
  rowActions = [],
  rowGroupActions = [],
  defaultVisibleColums,
  hiddenColumns = [],
  cellEdit,
  filename,
  sheetname,
  technicalFilename,
  technicalSheetname,
  technicalExport,
  technicalXlsxExporter,
  xlsxExporter,
  csvExporter,
  txtExporter,
  pdfExporter,
  renderDataSummary,
  disableRowSelect,
  rowUniqKey = 'id',
  autoReset = false
}) => {
  const [{ editColumnId, editItemId }, tableDispatch] = useReducer(TableReducer, { editItemId: null, editColumnId: null });

  const memoColumns = useMemo(() => columns, [id]);
  const defaultColumnOrder = useMemo(() => columns.map(({ id }) => id), [id]);
  const { saveTableConfiguration } = useSettings();
  const visibleColumnCount = defaultVisibleColums ? defaultVisibleColums.length : columns.length - hiddenColumns.length;

  const fixColumnOrder = (items, path) => {
    let selection = items.filter(item => get(item, path) === 'selection');
    let draggable = items.filter(item => get(item, path) === 'draggable');
    let others = items.filter(item => !['selection', 'draggable'].includes(get(item, path)));
    return [...selection, ...draggable, ...others];
  };

  const filterTypes = useMemo(() => ({
    default: (rows, [id], [filterType, filterValue]) =>
      getFilter({ itemValueExtractor: (row => row.values[id]), items: rows, filterType, filterValue })
  }), []);

  const defaultColumn = useMemo(() => ({
    Filter: null,
    minWidth: 50,
    width: 200,
    maxWidth: 500
  }), [visibleColumnCount]);

  const {
    allColumns,
    visibleColumns,
    getTableBodyProps,
    setHiddenColumns,
    setAllFilters,
    setColumnOrder,
    setGroupBy,
    setSortBy,
    headerGroups,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { /*groupBy, sortBy, expanded, selectedRowIds, */filters, globalFilter, pageIndex, pageSize/*, columnOrder*/ }
  } = useTable({
    columns: memoColumns,
    data,
    defaultColumn,
    filterTypes,
    initialState: { pageIndex: 0, pageSize: 25, sortBy: defaultSorted, groupBy: defaultGrouped, hiddenColumns },
    autoResetPage: autoReset,
    autoResetExpanded: autoReset,
    autoResetGroupBy: autoReset,
    autoResetSelectedRows: autoReset,
    autoResetSortBy: autoReset,
    autoResetFilters: autoReset,
    autoResetRowState: autoReset
  },
  useFilters,
  useGlobalFilter,
  useColumnOrder,
  useGroupBy,
  useSortBy,
  useExpanded,
  usePagination,
  useFlexLayout,
  useResizeColumns,
  useRowSelect,
  hooks => {
    if (!disableRowSelect)
      hooks.visibleColumns.push(columns => [{
        id: 'selection',
        disableFilters: true,
        disableResizing: true,
        disableExport: true,
        disableSortBy: true,
        minWidth: 35,
        width: 35,
        maxWidth: 35,
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <TableIndeterminateCheckbox {...getToggleAllRowsSelectedProps({ title: __('app_table_toggle_rows') })} />
        ),
        Cell: ({ row }) => (
          <TableIndeterminateCheckbox {...row.getToggleRowSelectedProps({ title: __('app_table_toggle_row') })} />
        )
      },
      ...columns
      ]);
  }
  );

  const exportTableConfig = (filename, sheetname) => {
    rows.forEach(row => prepareRow(row));
    return (exportType, exporter) => {
      const { extension, mimeType } = getExportTypeConfig(exportType);
      exporter.prepare({ workbook: new ExcelJS.Workbook(), sheetname, allColumns, rows });
      //FIXME: move to exporter
      exporter.export().then(file => {
        return saveAs(
          new Blob([file], { type: mimeType }),
          `${filename}.${extension}`
        );
      });
    };
  };

  const handleColumnOrderChange = visibleColumns => {
    if (saveTableConfiguration)
      setTableDisplayConfiguration({ tableId: id, domainId: window.user.domainId, key: 'visibleColumns', data: visibleColumns });
    setColumnOrder(visibleColumns);
  };

  const handleColumnHide = hiddenColumns => {
    if (saveTableConfiguration)
      setTableDisplayConfiguration({ tableId: id, domainId: window.user.domainId, key: 'hiddenColumns', data: hiddenColumns });
    setHiddenColumns(hiddenColumns);
  };

  useEffect(() => {
    // console.log({ hiddenColumns, defaultVisibleColums });
    if (defaultVisibleColums) {
      // console.log({ allColumns: allColumns.map(({ id }) => id), defaultVisibleColums, difference: difference(allColumns.map(({ id }) => id), ['selection', ...defaultVisibleColums]) });
      setColumnOrder(['selection', ...defaultVisibleColums]);
      setHiddenColumns(difference(allColumns.map(({ id }) => id), ['selection', ...defaultVisibleColums]));
    } else if (saveTableConfiguration) {
      let { visibleColumns, hiddenColumns } = getTableDisplayConfiguration({ tableId: id, domainId: window.user.domainId });
      if (visibleColumns)
        setColumnOrder(visibleColumns);
      if (hiddenColumns)
        setHiddenColumns(hiddenColumns);
    } else {
      setColumnOrder(['selection', ...defaultColumnOrder]);
      setHiddenColumns(hiddenColumns);
    }
    setGroupBy(defaultGrouped);
    setSortBy(defaultSorted);
  }, [JSON.stringify(hiddenColumns), JSON.stringify(defaultVisibleColums)]);

  // useEffect(() => {
  //   setGroupBy(defaultGrouped);
  // }, [defaultGrouped]);

  //FIXME: necessary? logic move to [JSON.stringify(hiddenColumns), JSON.stringify(defaultVisibleColums)] memo
  useEffect(() => {
    stickybits('.Table-thead');
    if (saveTableConfiguration) {
      let { visibleColumns, hiddenColumns } = getTableDisplayConfiguration({ tableId: id, domainId: window.user.domainId });
      if (visibleColumns)
        setColumnOrder(visibleColumns);
      if (hiddenColumns)
        setHiddenColumns(hiddenColumns);
    }
  }, []);

  return (
    <Fragment>
      {renderDataSummary ? renderDataSummary(rows) : null}
      <TableActionBar
        filters={filters}
        setAllFilters={setAllFilters}
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        selectedFlatRows={selectedFlatRows}
        rowActions={rowActions}
        rowGroupActions={rowGroupActions}
        allColumns={allColumns}
        visibleColumns={visibleColumns}
        setHiddenColumns={handleColumnHide}
        exportTable={exportTableConfig(filename, sheetname)}
        technicalExport={technicalExport}
        technicalExportTable={technicalExport && exportTableConfig(technicalFilename, technicalSheetname)}
        setColumnOrder={handleColumnOrderChange}
        technicalXlsxExporter={technicalXlsxExporter}
        xlsxExporter={xlsxExporter}
        csvExporter={csvExporter}
        txtExporter={txtExporter}
        pdfExporter={pdfExporter}
        rowUniqKey={rowUniqKey}
        defaultVisibleColumns={defaultVisibleColums}
      />
      <div className="TableContent">
        <div className="Table">
          <div id="Table-thead" className="Table-thead">
            {headerGroups.map((headerGroup, i) => (
              <div key={i} className="Table-tr" {...headerGroup.getHeaderGroupProps()}>
                {fixColumnOrder(headerGroup.headers, 'id').map((column, j) =>
                  <TableTh key={j} column={column} />
                )}
              </div>
            ))}
          </div>
          <div id="Table-tbody" className="Table-tbody" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableTr
                  key={`row-${i}`}
                  id={`row-${i}`}
                  row={row}
                  rowActions={rowActions}
                  rowGroupActions={rowGroupActions}
                >
                  {fixColumnOrder(row.cells, 'column.id').map((cell, j) =>
                    <TableTd
                      key={`row-${i}-cell${j}`}
                      index={`row${i}cell${j}`}
                      cell={cell}
                      row={row}
                      editColumnId={editColumnId}
                      editItemId={editItemId}
                      tableDispatch={tableDispatch}
                      cellEdit={cellEdit}
                    />
                  )}
                </TableTr>
              );
            })}
          </div>
        </div>
      </div>
      <Pagination
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </Fragment>
  );
};

export default memo(Table, compareProps(['id', 'data', 'hiddenColumns', 'defaultVisibleColums']));