import { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { get } from 'lodash';
import { setNotice, setLoading, setNavbarVerticalCollapsed, setShowBurgerMenu, setShowScreenOverlay, setNavbarVerticalHovered, setNavbarCollapsed, updateBreadcrumbs } from '$hooks/uiActions';
import { UiContext } from '$providers/UiProvider';
import { useModal, ModalTypeEnum } from '$hooks/modal';
import { globalHandleError } from '$utils/error';

export const useShowBurgerMenu = () => {
  const [{ showBurgerMenu }, uiDispatch] = useContext(UiContext);
  return [showBurgerMenu, value => {
    uiDispatch(setShowBurgerMenu(value));
  }];
};

export const useNavbarVerticalHovered = () => {
  const [{ isNavbarVerticalHovered }, uiDispatch] = useContext(UiContext);
  return [isNavbarVerticalHovered, value => {
    uiDispatch(setNavbarVerticalHovered(value));
  }];
};

export const useNavbarCollapsed = () => {
  const [{ isNavbarCollapsed }, uiDispatch] = useContext(UiContext);
  return [isNavbarCollapsed, value => {
    uiDispatch(setNavbarCollapsed(value));
  }];
};

export const useNavbarVerticalCollapsed = () => {
  const [{ isNavbarVerticalCollapsed }, uiDispatch] = useContext(UiContext);
  return [isNavbarVerticalCollapsed, value => {
    uiDispatch(setNavbarVerticalCollapsed(value));
  }];
};

export const useConfirmModal = (id = 'generalConfirmModal') => {
  const [/*modal*/, setModal] = useModal(ModalTypeEnum.CONFIRM, id);
  return (variant, title, text, callback, onDismiss) => {
    setModal({ variant, title, text, callback, onDismiss, isOpen: true });
  };
};

export const useScreenOverlay = () => {
  const [, uiDispatch] = useContext(UiContext);
  return value => {
    uiDispatch(setShowScreenOverlay(value));
  };
};

export const useNotice = id => {
  const [, uiDispatch] = useContext(UiContext);
  return (value, idOverwrite = null) => {
    uiDispatch(setNotice(idOverwrite || id, value));
  };
};

export const useLoading = (id = 'global') => {
  const [{ isLoading }, uiDispatch] = useContext(UiContext);
  const currentIsLoading = get(isLoading, id, false);
  const memoizedValue = useMemo(() => currentIsLoading, [currentIsLoading]);
  const memoizedCallback = useCallback(
    value => uiDispatch(setLoading(id, value)),
    [id]
  );
  return [memoizedValue, memoizedCallback];
};

export const useMount = handleMount => {
  const [isMounting, setMounting] = useState(true);
  useEffect(() => {
    handleMount().catch(globalHandleError).finally(() => setMounting(false));
  }, []);
  return isMounting;
};

export const useBreadcrumbs = () => {
  const [{ breadcrumbs }, uiDispatch] = useContext(UiContext);
  return [breadcrumbs, value => uiDispatch(updateBreadcrumbs(value))];
};