"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.recordCommonUpdateScheme = exports.recordCommonFindScheme = exports.recordCommonDestroyScheme = exports.recordCommonDestroyGroupScheme = exports.recordCommonDestroyBulkScheme = exports.recordCommonDestroyBulkGroupScheme = exports.recordCommonCreateScheme = exports.makeRecordModeScheme = void 0;

var _validatorConstants = require("../validator/validatorConstants.js");

var _schemeConstants = require("./schemeConstants.js");

var _commons = require("@stktk/commons");

// non schema-related
var recordCommonCreateScheme = {
  recordsetId: _validatorConstants.INTEGER.required([1]),
  restaurantId: _validatorConstants.INTEGER.required([1]),
  domainId: _validatorConstants.INTEGER.required([1]),
  skuId: _validatorConstants.INTEGER.required([1]),
  // FIXME: localSku seperate scheme
  localSku: _validatorConstants.OBJECT.INPART.optional({
    stocktakeMode: _validatorConstants.ENUM.STRING.optional(_commons.SkuStocktakeModeEnum.toArray())
  })
};
exports.recordCommonCreateScheme = recordCommonCreateScheme;
var recordCommonUpdateScheme = {
  id: _validatorConstants.INTEGER.required([1]),
  recordsetId: _validatorConstants.INTEGER.required([1]),
  restaurantId: _validatorConstants.INTEGER.required([1]),
  localSku: _validatorConstants.OBJECT.INPART.optional({
    stocktakeMode: _validatorConstants.ENUM.STRING.optional(_commons.SkuStocktakeModeEnum.toArray())
  }),
  domainId: _validatorConstants.INTEGER.optional([1]) //FIXME: mobile fallback

};
exports.recordCommonUpdateScheme = recordCommonUpdateScheme;
var recordCommonFindScheme = {
  recordsetId: _validatorConstants.INTEGER.required([1]),
  restaurantId: _validatorConstants.INTEGER.required([1]),
  domainId: _validatorConstants.INTEGER.optional([1]) //FIXME: mobile fallback

};
exports.recordCommonFindScheme = recordCommonFindScheme;
var recordCommonDestroyScheme = {
  id: _validatorConstants.INTEGER.required([1]),
  recordsetId: _validatorConstants.INTEGER.optional([1]),
  restaurantId: _validatorConstants.INTEGER.required([1])
};
exports.recordCommonDestroyScheme = recordCommonDestroyScheme;
var recordCommonDestroyGroupScheme = {
  skuId: _validatorConstants.INTEGER.required([1]),
  recordsetId: _validatorConstants.INTEGER.optional([1]),
  restaurantId: _validatorConstants.INTEGER.required([1])
};
exports.recordCommonDestroyGroupScheme = recordCommonDestroyGroupScheme;

var recordCommonDestroyBulkGroupScheme = _validatorConstants.ARRAY.required(_validatorConstants.OBJECT.required(recordCommonDestroyGroupScheme), [_schemeConstants.BULK_AMOUNT_MIN, _schemeConstants.BULK_AMOUNT_MAX]);

exports.recordCommonDestroyBulkGroupScheme = recordCommonDestroyBulkGroupScheme;

var recordCommonDestroyBulkScheme = _validatorConstants.ARRAY.required(_validatorConstants.OBJECT.required(recordCommonDestroyScheme), [_schemeConstants.BULK_AMOUNT_MIN, _schemeConstants.BULK_AMOUNT_MAX]);

exports.recordCommonDestroyBulkScheme = recordCommonDestroyBulkScheme;

var makeRecordModeScheme = function makeRecordModeScheme(scheme, mode) {
  return Object.assign({}, scheme[mode], {
    createBulk: _validatorConstants.ARRAY.required(_validatorConstants.OBJECT.required(scheme[mode].create), [_schemeConstants.BULK_AMOUNT_MIN, _schemeConstants.BULK_AMOUNT_MAX])
  });
};

exports.makeRecordModeScheme = makeRecordModeScheme;