import React from 'react';
import { FormGroup } from 'reactstrap';
import Form from '$ui/form/Form';
import FormButtonSubmit from '$ui/form/FormButtonSubmit';

export default function FormBlock({ disabled, scheme, initialValues, data, item, FormBody, onSubmit, ...props }) {
  return (
    <Form scheme={scheme} initialValues={initialValues} onSubmit={onSubmit} {...props}>
      <FormBody item={item} data={data} />
      <FormGroup>
        <FormButtonSubmit disabled={disabled} />
      </FormGroup>
    </Form>
  );
}