import { getFilter } from '@stktk/logic/utils/filter';
import { identity } from 'lodash';

const numberWrapper = value => (value || value === 0) ? '' + value : null;

export default {

  none: () => ({
    disableFilters: true,
    filter: null
  }),

  default: () => ({
    disableFilters: false,
    filterType: 'empty',
    filter: 'default'
  }),

  number: ({ property, display = identity, itemValueExtractor = row => numberWrapper(display(row.values[property])) }) => ({
    disableFilters: false,
    filterType: 'text',
    filter: (rows, id, [filterType, filterValue]) => {
      return getFilter({ itemValueExtractor, items: rows, filterType, filterValue });
    }
  }),

  text: ({ property, display = identity, itemValueExtractor = row => display(row.values[property]) } = {}) => ({
    disableFilters: false,
    filterType: 'text',
    filter: (rows, id, [filterType, filterValue]) => {
      return getFilter({ itemValueExtractor, items: rows, filterType, filterValue });
    }
  })

};