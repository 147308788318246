"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _recordsetCommonSchemes = require("./_recordsetCommonSchemes.js");

var _validatorConstants = require("../validator/validatorConstants.js");

var _default = {
  find: Object.assign({}, _recordsetCommonSchemes.recordsetCommonFindScheme, {
    lossGroupId: _validatorConstants.INTEGER.required()
  }),
  create: Object.assign({}, _recordsetCommonSchemes.recordsetCommonCreateScheme),
  update: Object.assign({}, _recordsetCommonSchemes.recordsetCommonUpdateScheme),
  destroy: Object.assign({}, _recordsetCommonSchemes.recordsetCommonDestroyScheme)
};
exports["default"] = _default;