"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIntegrationInstance = exports.getBasicIntegrationInstance = exports.IntegrationConfigKeyEnum = exports.IntegrationConfigEnum = void 0;

var _constructors = require("./constructors");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var BasicIntegrationConfigKey = 'basic';
var ParashiftIntegrationConfigKey = 'parashift';
var FakirIntegrationConfigKey = 'fakir';
var IntegrationConfigKeyEnum = new _constructors.Constant({
  BASIC: BasicIntegrationConfigKey,
  PARASHIFT: ParashiftIntegrationConfigKey,
  FAKIR: FakirIntegrationConfigKey
});
exports.IntegrationConfigKeyEnum = IntegrationConfigKeyEnum;
var IntegrationConfigEnum = new _constructors.Constant({
  BASIC: _defineProperty({}, '1.0', {
    id: 1,
    key: IntegrationConfigKeyEnum.BASIC,
    majorVersion: 1,
    minorVersion: 0
  }),
  PARASHIFT: _defineProperty({}, '1.0', {
    id: 2,
    key: IntegrationConfigKeyEnum.PARASHIFT,
    majorVersion: 1,
    minorVersion: 0
  }),
  FAKIR: _defineProperty({}, '1.0', {
    id: 3,
    key: IntegrationConfigKeyEnum.FAKIR,
    majorVersion: 1,
    minorVersion: 0
  })
});
exports.IntegrationConfigEnum = IntegrationConfigEnum;

var getIntegrationInstance = function getIntegrationInstance(instances, key) {
  if (!instances || !Array.isArray(instances)) return null;
  return instances.filter(function (entry) {
    return entry.integrationConfig.key === key;
  }).sort(function (e1, e2) {
    if (e1.integrationConfig.majorVersion < e2.integrationConfig.majorVersion) return 1;else if (e1.integrationConfig.majorVersion > e2.integrationConfig.majorVersion) return -1;
    if (e1.integrationConfig.minorVersion < e2.integrationConfig.minorVersion) return 1;else if (e1.integrationConfig.minorVersion > e2.integrationConfig.minorVersion) return -1;
    return 0;
  })[0] || null;
};

exports.getIntegrationInstance = getIntegrationInstance;

var getBasicIntegrationInstance = function getBasicIntegrationInstance(instances) {
  return getIntegrationInstance(instances, IntegrationConfigKeyEnum.BASIC);
};

exports.getBasicIntegrationInstance = getBasicIntegrationInstance;