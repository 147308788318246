"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [{
  alpha2Code: 'AF',
  alpha3Code: 'AFG',
  callingCodes: ['93']
}, {
  alpha2Code: 'AX',
  alpha3Code: 'ALA',
  callingCodes: ['358']
}, {
  alpha2Code: 'AL',
  alpha3Code: 'ALB',
  callingCodes: ['355']
}, {
  alpha2Code: 'DZ',
  alpha3Code: 'DZA',
  callingCodes: ['213']
}, {
  alpha2Code: 'AS',
  alpha3Code: 'ASM',
  callingCodes: ['1684']
}, {
  alpha2Code: 'AD',
  alpha3Code: 'AND',
  callingCodes: ['376']
}, {
  alpha2Code: 'AO',
  alpha3Code: 'AGO',
  callingCodes: ['244']
}, {
  alpha2Code: 'AI',
  alpha3Code: 'AIA',
  callingCodes: ['1264']
}, {
  alpha2Code: 'AQ',
  alpha3Code: 'ATA',
  callingCodes: ['672']
}, {
  alpha2Code: 'AG',
  alpha3Code: 'ATG',
  callingCodes: ['1268']
}, {
  alpha2Code: 'AR',
  alpha3Code: 'ARG',
  callingCodes: ['54']
}, {
  alpha2Code: 'AM',
  alpha3Code: 'ARM',
  callingCodes: ['374']
}, {
  alpha2Code: 'AW',
  alpha3Code: 'ABW',
  callingCodes: ['297']
}, {
  alpha2Code: 'AU',
  alpha3Code: 'AUS',
  callingCodes: ['61']
}, {
  alpha2Code: 'AT',
  alpha3Code: 'AUT',
  callingCodes: ['43']
}, {
  alpha2Code: 'AZ',
  alpha3Code: 'AZE',
  callingCodes: ['994']
}, {
  alpha2Code: 'BS',
  alpha3Code: 'BHS',
  callingCodes: ['1242']
}, {
  alpha2Code: 'BH',
  alpha3Code: 'BHR',
  callingCodes: ['973']
}, {
  alpha2Code: 'BD',
  alpha3Code: 'BGD',
  callingCodes: ['880']
}, {
  alpha2Code: 'BB',
  alpha3Code: 'BRB',
  callingCodes: ['1246']
}, {
  alpha2Code: 'BY',
  alpha3Code: 'BLR',
  callingCodes: ['375']
}, {
  alpha2Code: 'BE',
  alpha3Code: 'BEL',
  callingCodes: ['32']
}, {
  alpha2Code: 'BZ',
  alpha3Code: 'BLZ',
  callingCodes: ['501']
}, {
  alpha2Code: 'BJ',
  alpha3Code: 'BEN',
  callingCodes: ['229']
}, {
  alpha2Code: 'BM',
  alpha3Code: 'BMU',
  callingCodes: ['1441']
}, {
  alpha2Code: 'BT',
  alpha3Code: 'BTN',
  callingCodes: ['975']
}, {
  alpha2Code: 'BO',
  alpha3Code: 'BOL',
  callingCodes: ['591']
}, {
  alpha2Code: 'BQ',
  alpha3Code: 'BES',
  callingCodes: ['5997']
}, {
  alpha2Code: 'BA',
  alpha3Code: 'BIH',
  callingCodes: ['387']
}, {
  alpha2Code: 'BW',
  alpha3Code: 'BWA',
  callingCodes: ['267']
}, {
  alpha2Code: 'BV',
  alpha3Code: 'BVT',
  callingCodes: ['']
}, {
  alpha2Code: 'BR',
  alpha3Code: 'BRA',
  callingCodes: ['55']
}, {
  alpha2Code: 'IO',
  alpha3Code: 'IOT',
  callingCodes: ['246']
}, {
  alpha2Code: 'UM',
  alpha3Code: 'UMI',
  callingCodes: ['']
}, {
  alpha2Code: 'VG',
  alpha3Code: 'VGB',
  callingCodes: ['1284']
}, {
  alpha2Code: 'VI',
  alpha3Code: 'VIR',
  callingCodes: ['1 340']
}, {
  alpha2Code: 'BN',
  alpha3Code: 'BRN',
  callingCodes: ['673']
}, {
  alpha2Code: 'BG',
  alpha3Code: 'BGR',
  callingCodes: ['359']
}, {
  alpha2Code: 'BF',
  alpha3Code: 'BFA',
  callingCodes: ['226']
}, {
  alpha2Code: 'BI',
  alpha3Code: 'BDI',
  callingCodes: ['257']
}, {
  alpha2Code: 'KH',
  alpha3Code: 'KHM',
  callingCodes: ['855']
}, {
  alpha2Code: 'CM',
  alpha3Code: 'CMR',
  callingCodes: ['237']
}, {
  alpha2Code: 'CA',
  alpha3Code: 'CAN',
  callingCodes: ['1']
}, {
  alpha2Code: 'CV',
  alpha3Code: 'CPV',
  callingCodes: ['238']
}, {
  alpha2Code: 'KY',
  alpha3Code: 'CYM',
  callingCodes: ['1345']
}, {
  alpha2Code: 'CF',
  alpha3Code: 'CAF',
  callingCodes: ['236']
}, {
  alpha2Code: 'TD',
  alpha3Code: 'TCD',
  callingCodes: ['235']
}, {
  alpha2Code: 'CL',
  alpha3Code: 'CHL',
  callingCodes: ['56']
}, {
  alpha2Code: 'CN',
  alpha3Code: 'CHN',
  callingCodes: ['86']
}, {
  alpha2Code: 'CX',
  alpha3Code: 'CXR',
  callingCodes: ['61']
}, {
  alpha2Code: 'CC',
  alpha3Code: 'CCK',
  callingCodes: ['61']
}, {
  alpha2Code: 'CO',
  alpha3Code: 'COL',
  callingCodes: ['57']
}, {
  alpha2Code: 'KM',
  alpha3Code: 'COM',
  callingCodes: ['269']
}, {
  alpha2Code: 'CG',
  alpha3Code: 'COG',
  callingCodes: ['242']
}, {
  alpha2Code: 'CD',
  alpha3Code: 'COD',
  callingCodes: ['243']
}, {
  alpha2Code: 'CK',
  alpha3Code: 'COK',
  callingCodes: ['682']
}, {
  alpha2Code: 'CR',
  alpha3Code: 'CRI',
  callingCodes: ['506']
}, {
  alpha2Code: 'HR',
  alpha3Code: 'HRV',
  callingCodes: ['385']
}, {
  alpha2Code: 'CU',
  alpha3Code: 'CUB',
  callingCodes: ['53']
}, {
  alpha2Code: 'CW',
  alpha3Code: 'CUW',
  callingCodes: ['599']
}, {
  alpha2Code: 'CY',
  alpha3Code: 'CYP',
  callingCodes: ['357']
}, {
  alpha2Code: 'CZ',
  alpha3Code: 'CZE',
  callingCodes: ['420']
}, {
  alpha2Code: 'DK',
  alpha3Code: 'DNK',
  callingCodes: ['45']
}, {
  alpha2Code: 'DJ',
  alpha3Code: 'DJI',
  callingCodes: ['253']
}, {
  alpha2Code: 'DM',
  alpha3Code: 'DMA',
  callingCodes: ['1767']
}, {
  alpha2Code: 'DO',
  alpha3Code: 'DOM',
  callingCodes: ['1809', '1829', '1849']
}, {
  alpha2Code: 'EC',
  alpha3Code: 'ECU',
  callingCodes: ['593']
}, {
  alpha2Code: 'EG',
  alpha3Code: 'EGY',
  callingCodes: ['20']
}, {
  alpha2Code: 'SV',
  alpha3Code: 'SLV',
  callingCodes: ['503']
}, {
  alpha2Code: 'GQ',
  alpha3Code: 'GNQ',
  callingCodes: ['240']
}, {
  alpha2Code: 'ER',
  alpha3Code: 'ERI',
  callingCodes: ['291']
}, {
  alpha2Code: 'EE',
  alpha3Code: 'EST',
  callingCodes: ['372']
}, {
  alpha2Code: 'ET',
  alpha3Code: 'ETH',
  callingCodes: ['251']
}, {
  alpha2Code: 'FK',
  alpha3Code: 'FLK',
  callingCodes: ['500']
}, {
  alpha2Code: 'FO',
  alpha3Code: 'FRO',
  callingCodes: ['298']
}, {
  alpha2Code: 'FJ',
  alpha3Code: 'FJI',
  callingCodes: ['679']
}, {
  alpha2Code: 'FI',
  alpha3Code: 'FIN',
  callingCodes: ['358']
}, {
  alpha2Code: 'FR',
  alpha3Code: 'FRA',
  callingCodes: ['33']
}, {
  alpha2Code: 'GF',
  alpha3Code: 'GUF',
  callingCodes: ['594']
}, {
  alpha2Code: 'PF',
  alpha3Code: 'PYF',
  callingCodes: ['689']
}, {
  alpha2Code: 'TF',
  alpha3Code: 'ATF',
  callingCodes: ['']
}, {
  alpha2Code: 'GA',
  alpha3Code: 'GAB',
  callingCodes: ['241']
}, {
  alpha2Code: 'GM',
  alpha3Code: 'GMB',
  callingCodes: ['220']
}, {
  alpha2Code: 'GE',
  alpha3Code: 'GEO',
  callingCodes: ['995']
}, {
  alpha2Code: 'DE',
  alpha3Code: 'DEU',
  callingCodes: ['49']
}, {
  alpha2Code: 'GH',
  alpha3Code: 'GHA',
  callingCodes: ['233']
}, {
  alpha2Code: 'GI',
  alpha3Code: 'GIB',
  callingCodes: ['350']
}, {
  alpha2Code: 'GR',
  alpha3Code: 'GRC',
  callingCodes: ['30']
}, {
  alpha2Code: 'GL',
  alpha3Code: 'GRL',
  callingCodes: ['299']
}, {
  alpha2Code: 'GD',
  alpha3Code: 'GRD',
  callingCodes: ['1473']
}, {
  alpha2Code: 'GP',
  alpha3Code: 'GLP',
  callingCodes: ['590']
}, {
  alpha2Code: 'GU',
  alpha3Code: 'GUM',
  callingCodes: ['1671']
}, {
  alpha2Code: 'GT',
  alpha3Code: 'GTM',
  callingCodes: ['502']
}, {
  alpha2Code: 'GG',
  alpha3Code: 'GGY',
  callingCodes: ['44']
}, {
  alpha2Code: 'GN',
  alpha3Code: 'GIN',
  callingCodes: ['224']
}, {
  alpha2Code: 'GW',
  alpha3Code: 'GNB',
  callingCodes: ['245']
}, {
  alpha2Code: 'GY',
  alpha3Code: 'GUY',
  callingCodes: ['592']
}, {
  alpha2Code: 'HT',
  alpha3Code: 'HTI',
  callingCodes: ['509']
}, {
  alpha2Code: 'HM',
  alpha3Code: 'HMD',
  callingCodes: ['']
}, {
  alpha2Code: 'VA',
  alpha3Code: 'VAT',
  callingCodes: ['379']
}, {
  alpha2Code: 'HN',
  alpha3Code: 'HND',
  callingCodes: ['504']
}, {
  alpha2Code: 'HK',
  alpha3Code: 'HKG',
  callingCodes: ['852']
}, {
  alpha2Code: 'HU',
  alpha3Code: 'HUN',
  callingCodes: ['36']
}, {
  alpha2Code: 'IS',
  alpha3Code: 'ISL',
  callingCodes: ['354']
}, {
  alpha2Code: 'IN',
  alpha3Code: 'IND',
  callingCodes: ['91']
}, {
  alpha2Code: 'ID',
  alpha3Code: 'IDN',
  callingCodes: ['62']
}, {
  alpha2Code: 'CI',
  alpha3Code: 'CIV',
  callingCodes: ['225']
}, {
  alpha2Code: 'IR',
  alpha3Code: 'IRN',
  callingCodes: ['98']
}, {
  alpha2Code: 'IQ',
  alpha3Code: 'IRQ',
  callingCodes: ['964']
}, {
  alpha2Code: 'IE',
  alpha3Code: 'IRL',
  callingCodes: ['353']
}, {
  alpha2Code: 'IM',
  alpha3Code: 'IMN',
  callingCodes: ['44']
}, {
  alpha2Code: 'IL',
  alpha3Code: 'ISR',
  callingCodes: ['972']
}, {
  alpha2Code: 'IT',
  alpha3Code: 'ITA',
  callingCodes: ['39']
}, {
  alpha2Code: 'JM',
  alpha3Code: 'JAM',
  callingCodes: ['1876']
}, {
  alpha2Code: 'JP',
  alpha3Code: 'JPN',
  callingCodes: ['81']
}, {
  alpha2Code: 'JE',
  alpha3Code: 'JEY',
  callingCodes: ['44']
}, {
  alpha2Code: 'JO',
  alpha3Code: 'JOR',
  callingCodes: ['962']
}, {
  alpha2Code: 'KZ',
  alpha3Code: 'KAZ',
  callingCodes: ['76', '77']
}, {
  alpha2Code: 'KE',
  alpha3Code: 'KEN',
  callingCodes: ['254']
}, {
  alpha2Code: 'KI',
  alpha3Code: 'KIR',
  callingCodes: ['686']
}, {
  alpha2Code: 'KW',
  alpha3Code: 'KWT',
  callingCodes: ['965']
}, {
  alpha2Code: 'KG',
  alpha3Code: 'KGZ',
  callingCodes: ['996']
}, {
  alpha2Code: 'LA',
  alpha3Code: 'LAO',
  callingCodes: ['856']
}, {
  alpha2Code: 'LV',
  alpha3Code: 'LVA',
  callingCodes: ['371']
}, {
  alpha2Code: 'LB',
  alpha3Code: 'LBN',
  callingCodes: ['961']
}, {
  alpha2Code: 'LS',
  alpha3Code: 'LSO',
  callingCodes: ['266']
}, {
  alpha2Code: 'LR',
  alpha3Code: 'LBR',
  callingCodes: ['231']
}, {
  alpha2Code: 'LY',
  alpha3Code: 'LBY',
  callingCodes: ['218']
}, {
  alpha2Code: 'LI',
  alpha3Code: 'LIE',
  callingCodes: ['423']
}, {
  alpha2Code: 'LT',
  alpha3Code: 'LTU',
  callingCodes: ['370']
}, {
  alpha2Code: 'LU',
  alpha3Code: 'LUX',
  callingCodes: ['352']
}, {
  alpha2Code: 'MO',
  alpha3Code: 'MAC',
  callingCodes: ['853']
}, {
  alpha2Code: 'MK',
  alpha3Code: 'MKD',
  callingCodes: ['389']
}, {
  alpha2Code: 'MG',
  alpha3Code: 'MDG',
  callingCodes: ['261']
}, {
  alpha2Code: 'MW',
  alpha3Code: 'MWI',
  callingCodes: ['265']
}, {
  alpha2Code: 'MY',
  alpha3Code: 'MYS',
  callingCodes: ['60']
}, {
  alpha2Code: 'MV',
  alpha3Code: 'MDV',
  callingCodes: ['960']
}, {
  alpha2Code: 'ML',
  alpha3Code: 'MLI',
  callingCodes: ['223']
}, {
  alpha2Code: 'MT',
  alpha3Code: 'MLT',
  callingCodes: ['356']
}, {
  alpha2Code: 'MH',
  alpha3Code: 'MHL',
  callingCodes: ['692']
}, {
  alpha2Code: 'MQ',
  alpha3Code: 'MTQ',
  callingCodes: ['596']
}, {
  alpha2Code: 'MR',
  alpha3Code: 'MRT',
  callingCodes: ['222']
}, {
  alpha2Code: 'MU',
  alpha3Code: 'MUS',
  callingCodes: ['230']
}, {
  alpha2Code: 'YT',
  alpha3Code: 'MYT',
  callingCodes: ['262']
}, {
  alpha2Code: 'MX',
  alpha3Code: 'MEX',
  callingCodes: ['52']
}, {
  alpha2Code: 'FM',
  alpha3Code: 'FSM',
  callingCodes: ['691']
}, {
  alpha2Code: 'MD',
  alpha3Code: 'MDA',
  callingCodes: ['373']
}, {
  alpha2Code: 'MC',
  alpha3Code: 'MCO',
  callingCodes: ['377']
}, {
  alpha2Code: 'MN',
  alpha3Code: 'MNG',
  callingCodes: ['976']
}, {
  alpha2Code: 'ME',
  alpha3Code: 'MNE',
  callingCodes: ['382']
}, {
  alpha2Code: 'MS',
  alpha3Code: 'MSR',
  callingCodes: ['1664']
}, {
  alpha2Code: 'MA',
  alpha3Code: 'MAR',
  callingCodes: ['212']
}, {
  alpha2Code: 'MZ',
  alpha3Code: 'MOZ',
  callingCodes: ['258']
}, {
  alpha2Code: 'MM',
  alpha3Code: 'MMR',
  callingCodes: ['95']
}, {
  alpha2Code: 'NA',
  alpha3Code: 'NAM',
  callingCodes: ['264']
}, {
  alpha2Code: 'NR',
  alpha3Code: 'NRU',
  callingCodes: ['674']
}, {
  alpha2Code: 'NP',
  alpha3Code: 'NPL',
  callingCodes: ['977']
}, {
  alpha2Code: 'NL',
  alpha3Code: 'NLD',
  callingCodes: ['31']
}, {
  alpha2Code: 'NC',
  alpha3Code: 'NCL',
  callingCodes: ['687']
}, {
  alpha2Code: 'NZ',
  alpha3Code: 'NZL',
  callingCodes: ['64']
}, {
  alpha2Code: 'NI',
  alpha3Code: 'NIC',
  callingCodes: ['505']
}, {
  alpha2Code: 'NE',
  alpha3Code: 'NER',
  callingCodes: ['227']
}, {
  alpha2Code: 'NG',
  alpha3Code: 'NGA',
  callingCodes: ['234']
}, {
  alpha2Code: 'NU',
  alpha3Code: 'NIU',
  callingCodes: ['683']
}, {
  alpha2Code: 'NF',
  alpha3Code: 'NFK',
  callingCodes: ['672']
}, {
  alpha2Code: 'KP',
  alpha3Code: 'PRK',
  callingCodes: ['850']
}, {
  alpha2Code: 'MP',
  alpha3Code: 'MNP',
  callingCodes: ['1670']
}, {
  alpha2Code: 'NO',
  alpha3Code: 'NOR',
  callingCodes: ['47']
}, {
  alpha2Code: 'OM',
  alpha3Code: 'OMN',
  callingCodes: ['968']
}, {
  alpha2Code: 'PK',
  alpha3Code: 'PAK',
  callingCodes: ['92']
}, {
  alpha2Code: 'PW',
  alpha3Code: 'PLW',
  callingCodes: ['680']
}, {
  alpha2Code: 'PS',
  alpha3Code: 'PSE',
  callingCodes: ['970']
}, {
  alpha2Code: 'PA',
  alpha3Code: 'PAN',
  callingCodes: ['507']
}, {
  alpha2Code: 'PG',
  alpha3Code: 'PNG',
  callingCodes: ['675']
}, {
  alpha2Code: 'PY',
  alpha3Code: 'PRY',
  callingCodes: ['595']
}, {
  alpha2Code: 'PE',
  alpha3Code: 'PER',
  callingCodes: ['51']
}, {
  alpha2Code: 'PH',
  alpha3Code: 'PHL',
  callingCodes: ['63']
}, {
  alpha2Code: 'PN',
  alpha3Code: 'PCN',
  callingCodes: ['64']
}, {
  alpha2Code: 'PL',
  alpha3Code: 'POL',
  callingCodes: ['48']
}, {
  alpha2Code: 'PT',
  alpha3Code: 'PRT',
  callingCodes: ['351']
}, {
  alpha2Code: 'PR',
  alpha3Code: 'PRI',
  callingCodes: ['1787', '1939']
}, {
  alpha2Code: 'QA',
  alpha3Code: 'QAT',
  callingCodes: ['974']
}, {
  alpha2Code: 'XK',
  alpha3Code: 'KOS',
  callingCodes: ['383']
}, {
  alpha2Code: 'RE',
  alpha3Code: 'REU',
  callingCodes: ['262']
}, {
  alpha2Code: 'RO',
  alpha3Code: 'ROU',
  callingCodes: ['40']
}, {
  alpha2Code: 'RU',
  alpha3Code: 'RUS',
  callingCodes: ['7']
}, {
  alpha2Code: 'RW',
  alpha3Code: 'RWA',
  callingCodes: ['250']
}, {
  alpha2Code: 'BL',
  alpha3Code: 'BLM',
  callingCodes: ['590']
}, {
  alpha2Code: 'SH',
  alpha3Code: 'SHN',
  callingCodes: ['290']
}, {
  alpha2Code: 'KN',
  alpha3Code: 'KNA',
  callingCodes: ['1869']
}, {
  alpha2Code: 'LC',
  alpha3Code: 'LCA',
  callingCodes: ['1758']
}, {
  alpha2Code: 'MF',
  alpha3Code: 'MAF',
  callingCodes: ['590']
}, {
  alpha2Code: 'PM',
  alpha3Code: 'SPM',
  callingCodes: ['508']
}, {
  alpha2Code: 'VC',
  alpha3Code: 'VCT',
  callingCodes: ['1784']
}, {
  alpha2Code: 'WS',
  alpha3Code: 'WSM',
  callingCodes: ['685']
}, {
  alpha2Code: 'SM',
  alpha3Code: 'SMR',
  callingCodes: ['378']
}, {
  alpha2Code: 'ST',
  alpha3Code: 'STP',
  callingCodes: ['239']
}, {
  alpha2Code: 'SA',
  alpha3Code: 'SAU',
  callingCodes: ['966']
}, {
  alpha2Code: 'SN',
  alpha3Code: 'SEN',
  callingCodes: ['221']
}, {
  alpha2Code: 'RS',
  alpha3Code: 'SRB',
  callingCodes: ['381']
}, {
  alpha2Code: 'SC',
  alpha3Code: 'SYC',
  callingCodes: ['248']
}, {
  alpha2Code: 'SL',
  alpha3Code: 'SLE',
  callingCodes: ['232']
}, {
  alpha2Code: 'SG',
  alpha3Code: 'SGP',
  callingCodes: ['65']
}, {
  alpha2Code: 'SX',
  alpha3Code: 'SXM',
  callingCodes: ['1721']
}, {
  alpha2Code: 'SK',
  alpha3Code: 'SVK',
  callingCodes: ['421']
}, {
  alpha2Code: 'SI',
  alpha3Code: 'SVN',
  callingCodes: ['386']
}, {
  alpha2Code: 'SB',
  alpha3Code: 'SLB',
  callingCodes: ['677']
}, {
  alpha2Code: 'SO',
  alpha3Code: 'SOM',
  callingCodes: ['252']
}, {
  alpha2Code: 'ZA',
  alpha3Code: 'ZAF',
  callingCodes: ['27']
}, {
  alpha2Code: 'GS',
  alpha3Code: 'SGS',
  callingCodes: ['500']
}, {
  alpha2Code: 'KR',
  alpha3Code: 'KOR',
  callingCodes: ['82']
}, {
  alpha2Code: 'SS',
  alpha3Code: 'SSD',
  callingCodes: ['211']
}, {
  alpha2Code: 'ES',
  alpha3Code: 'ESP',
  callingCodes: ['34']
}, {
  alpha2Code: 'LK',
  alpha3Code: 'LKA',
  callingCodes: ['94']
}, {
  alpha2Code: 'SD',
  alpha3Code: 'SDN',
  callingCodes: ['249']
}, {
  alpha2Code: 'SR',
  alpha3Code: 'SUR',
  callingCodes: ['597']
}, {
  alpha2Code: 'SJ',
  alpha3Code: 'SJM',
  callingCodes: ['4779']
}, {
  alpha2Code: 'SZ',
  alpha3Code: 'SWZ',
  callingCodes: ['268']
}, {
  alpha2Code: 'SE',
  alpha3Code: 'SWE',
  callingCodes: ['46']
}, {
  alpha2Code: 'CH',
  alpha3Code: 'CHE',
  callingCodes: ['41']
}, {
  alpha2Code: 'SY',
  alpha3Code: 'SYR',
  callingCodes: ['963']
}, {
  alpha2Code: 'TW',
  alpha3Code: 'TWN',
  callingCodes: ['886']
}, {
  alpha2Code: 'TJ',
  alpha3Code: 'TJK',
  callingCodes: ['992']
}, {
  alpha2Code: 'TZ',
  alpha3Code: 'TZA',
  callingCodes: ['255']
}, {
  alpha2Code: 'TH',
  alpha3Code: 'THA',
  callingCodes: ['66']
}, {
  alpha2Code: 'TL',
  alpha3Code: 'TLS',
  callingCodes: ['670']
}, {
  alpha2Code: 'TG',
  alpha3Code: 'TGO',
  callingCodes: ['228']
}, {
  alpha2Code: 'TK',
  alpha3Code: 'TKL',
  callingCodes: ['690']
}, {
  alpha2Code: 'TO',
  alpha3Code: 'TON',
  callingCodes: ['676']
}, {
  alpha2Code: 'TT',
  alpha3Code: 'TTO',
  callingCodes: ['1868']
}, {
  alpha2Code: 'TN',
  alpha3Code: 'TUN',
  callingCodes: ['216']
}, {
  alpha2Code: 'TR',
  alpha3Code: 'TUR',
  callingCodes: ['90']
}, {
  alpha2Code: 'TM',
  alpha3Code: 'TKM',
  callingCodes: ['993']
}, {
  alpha2Code: 'TC',
  alpha3Code: 'TCA',
  callingCodes: ['1649']
}, {
  alpha2Code: 'TV',
  alpha3Code: 'TUV',
  callingCodes: ['688']
}, {
  alpha2Code: 'UG',
  alpha3Code: 'UGA',
  callingCodes: ['256']
}, {
  alpha2Code: 'UA',
  alpha3Code: 'UKR',
  callingCodes: ['380']
}, {
  alpha2Code: 'AE',
  alpha3Code: 'ARE',
  callingCodes: ['971']
}, {
  alpha2Code: 'GB',
  alpha3Code: 'GBR',
  callingCodes: ['44']
}, {
  alpha2Code: 'US',
  alpha3Code: 'USA',
  callingCodes: ['1']
}, {
  alpha2Code: 'UY',
  alpha3Code: 'URY',
  callingCodes: ['598']
}, {
  alpha2Code: 'UZ',
  alpha3Code: 'UZB',
  callingCodes: ['998']
}, {
  alpha2Code: 'VU',
  alpha3Code: 'VUT',
  callingCodes: ['678']
}, {
  alpha2Code: 'VE',
  alpha3Code: 'VEN',
  callingCodes: ['58']
}, {
  alpha2Code: 'VN',
  alpha3Code: 'VNM',
  callingCodes: ['84']
}, {
  alpha2Code: 'WF',
  alpha3Code: 'WLF',
  callingCodes: ['681']
}, {
  alpha2Code: 'EH',
  alpha3Code: 'ESH',
  callingCodes: ['212']
}, {
  alpha2Code: 'YE',
  alpha3Code: 'YEM',
  callingCodes: ['967']
}, {
  alpha2Code: 'ZM',
  alpha3Code: 'ZMB',
  callingCodes: ['260']
}, {
  alpha2Code: 'ZW',
  alpha3Code: 'ZWE',
  callingCodes: ['263']
}];
exports["default"] = _default;