import React from 'react';
import { Item } from 'react-contexify';
import { Icon, Flex } from '$ui';

export default function TableContextMenuItem({ disabled, icon, color, label, onClick, ...props }) {
  return (
    <Item disabled={disabled} onClick={onClick} {...props}>
      {icon && (
        <Flex align="center" style={{ width: 36 }}>
          <Icon icon={icon} variant={color} />
        </Flex>
      )}
      <span>{label}</span>
    </Item>
  );
}