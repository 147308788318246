import React from 'react';
import { useField } from 'formik';
import ButtonGroupSelect from '$ui/form/controls/ButtonGroupSelect';

const FormButtonGroupSelect = ({ name, onChange, ...props }) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(name);
  // console.log({'FormButtonGroupSelect': value})
  const handleChange = value => {
    if (onChange)
      onChange(value);
    setValue(value);
    setTouched(true);
  };

  return (
    <ButtonGroupSelect
      name={name}
      value={value}
      onChange={handleChange}
      {...props}
    />
  );
};

export default FormButtonGroupSelect;