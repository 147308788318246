"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.S3PATH = void 0;
exports.__ = __;
exports.__bcgMatrixClass = __bcgMatrixClass;
exports.__boolean = __boolean;
exports.__category = __category;
exports.__countryName = __countryName;
exports.__delimiter = __delimiter;
exports.__documentLabel = __documentLabel;
exports.__documentStatus = __documentStatus;
exports.__documentType = __documentType;
exports.__domainUserRole = __domainUserRole;
exports.__eol = __eol;
exports.__filterType = __filterType;
exports.__image = __image;
exports.__imageAlt = __imageAlt;
exports.__integrationForm = __integrationForm;
exports.__integrationName = __integrationName;
exports.__l = __l;
exports.__link = __link;
exports.__lossGroupStatus = __lossGroupStatus;
exports.__notificationActionText = __notificationActionText;
exports.__notificationText = __notificationText;
exports.__orderGroupGenerationRule = __orderGroupGenerationRule;
exports.__orderRecordsetWorkflowStep = __orderRecordsetWorkflowStep;
exports.__orderStatus = __orderStatus;
exports.__packageClosure = __packageClosure;
exports.__recordMode = __recordMode;
exports.__recordStatus = __recordStatus;
exports.__reportType = __reportType;
exports.__saleGenerationRule = __saleGenerationRule;
exports.__skuSearchScope = __skuSearchScope;
exports.__skuStocktakeMode = __skuStocktakeMode;
exports.__stockcheckStatus = __stockcheckStatus;
exports.__stocktakeStatus = __stocktakeStatus;
exports.__storageStandardStatus = __storageStandardStatus;
exports.__tagContext = __tagContext;
exports.__tooltip = __tooltip;
exports.__unit = __unit;
exports.__userActive = __userActive;
exports.__userMode = __userMode;
exports.__validatorError = __validatorError;
exports.__venueType = __venueType;
exports.__yesNo = __yesNo;
exports.__yup = __yup;
exports.changeLanguage = changeLanguage;
exports.getLanguage = getLanguage;
exports.getLocaleProp = getLocaleProp;

var _i18next = _interopRequireDefault(require("i18next"));

var _locales = require("./locales.js");

var _lodash = require("lodash");

var _excluded = ["message"];

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var defaultLanguage = 'en'; //FIXME: move to commons

var S3PATH = 'https://s3-eu-west-1.amazonaws.com/stktk-public';
exports.S3PATH = S3PATH;

_i18next["default"].init({
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
  debug: false,
  resources: _locales.locales.mapKeysContentLanguage()
});

function __enum(enumName, id, property) {
  var count = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
  return _i18next["default"].t("enum_".concat(enumName, "_").concat(id, "_").concat(property), {
    count: count
  });
}

function __tooltip(prop) {
  return _i18next["default"].t("tooltip_".concat(prop));
}

function changeLanguage(language) {
  return _i18next["default"].changeLanguage(language);
}

function getLanguage() {
  return _i18next["default"].language;
}

function getLocaleProp(prop) {
  var language = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _i18next["default"].language;
  return _i18next["default"].getResourceBundle(language, prop);
}

function __(keys, options) {
  return _i18next["default"].t(keys, options);
}

function __l(language, key, options) {
  return _i18next["default"].getFixedT(language)(key, options);
}

function __unit(unitId) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  var count = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return __enum('unit', unitId, property, count);
}

function __category(category) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  //FIXME:
  if (!category || !category.name) return __('general_uncategorized');
  if (category.domainId === 0) //FIXME:
    return __enum('category', category.id, property);else return category.name;
}

function __venueType(type) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('venue_type', type, property);
}

function __skuStocktakeMode(mode) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('sku_stocktake_mode', mode, property);
}

function __userActive(active) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('user_active', active, property);
}

function __userMode(mode) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('user_mode', mode, property);
}

function __domainUserRole(mode) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('domain_user_role', mode, property);
}

function __recordMode(mode) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('record_mode', mode, property);
}

function __recordStatus(status) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('record_status', status.replace('.', '_'), property);
}

function __orderStatus(status) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('order_status', status, property);
}

function __stocktakeStatus(status) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('stocktake_status', status, property);
}

function __stockcheckStatus(status) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('stockcheck_status', status, property);
}

function __lossGroupStatus(status) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('loss_group_status', status, property);
}

function __storageStandardStatus(status) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('storage_standard_status', status, property);
}

function __orderGroupGenerationRule(generationRule) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('order_group_generation_rule', generationRule, property);
}

function __saleGenerationRule(generationRule) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('sale_generation_rule', generationRule, property);
}

function __skuSearchScope(scope) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('sku_search_scope', scope, property);
}

function __reportType(type) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('report_type', type, property);
}

function __documentType(type) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('document_type', type, property);
}

function __documentStatus(type) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('document_status', type, property);
}

function __documentLabel(type) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('document_label', type, property);
}

function __integrationName(name) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('integration_name', name, property);
}

function __integrationForm(name) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('integration_form', name, property);
}

function __bcgMatrixClass(name) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('bcg_matrix_class', name, property);
}

function __validatorError(error, context) {
  var message = error.message,
      options = _objectWithoutProperties(error, _excluded);

  return _i18next["default"].t(message, Object.assign({}, options, context));
}

function __yup(error, context) {
  var errors = error.errors;
  return errors.map(function (error) {
    return _i18next["default"].t(error.message, Object.assign({}, error, context));
  }).join(', ');
}

function __notificationText(key, context) {
  return _i18next["default"].t("ntf_".concat(key).replace('.', '_'), context);
}

function __notificationActionText(key, action, context) {
  return _i18next["default"].t("ntf_".concat(key, "_").concat(action).replace('.', '_'), context);
}

function __image(src, multilang) {
  return S3PATH + '/img/' + (multilang ? _i18next["default"].getResourceBundle(_i18next["default"].language, 'country') + '/' : '') + src;
}

function __imageAlt(src) {
  return __("image_alt_".concat((0, _lodash.snakeCase)(src)));
}

function __link(link) {
  return _i18next["default"].language != defaultLanguage ? '/' + _i18next["default"].getResourceBundle(_i18next["default"].language, 'url') + link : link;
}

function __countryName(code) {
  if (!code) return '';
  return __("country.".concat(code, ".name"));
}

function __packageClosure(_ref) {
  var packageClosure = _ref.packageClosure;
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  //FIXME:
  if (packageClosure.id) return packageClosure.name;
  return __enum('package_closure', packageClosure.name, property);
}

function __delimiter(key) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('delimiter', key, property);
}

function __eol(key) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('eol', key, property);
}

function __tagContext(type) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('tag_context', type, property);
}

function __filterType(type) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('filter_type', type, property);
}

function __orderRecordsetWorkflowStep(step) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __enum('order_recordset_workflow_step', step.replace('.', '_'), property);
}

function __yesNo(value) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __("yes_no_".concat(value, "_").concat(property));
}

function __boolean(value) {
  var property = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
  return __("boolean_".concat(value, "_").concat(property));
}