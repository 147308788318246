"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _commons = require("@stktk/commons");

var _validatorConstants = require("../validator/validatorConstants.js");

var _default = {
  find: {
    domainId: _validatorConstants.INTEGER.required([1]),
    context: _validatorConstants.ARRAY.required(_validatorConstants.ENUM.STRING.required(_commons.TagContextEnum.toArray()), [1])
  },
  create: {
    domainId: _validatorConstants.INTEGER.required([1]),
    name: _validatorConstants.STRING.required([1, 30]),
    rgbColor: _validatorConstants.REGEX.optional(/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/, 'validator_error_stktk_rgb'),
    context: _validatorConstants.ENUM.STRING.required(_commons.TagContextEnum.toArray())
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    name: _validatorConstants.STRING.optional([1, 30]),
    context: _validatorConstants.ENUM.STRING.optional(_commons.TagContextEnum.toArray()),
    rgbColor: _validatorConstants.REGEX.optional(/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/, 'validator_error_stktk_rgb')
  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1])
  }
};
exports["default"] = _default;