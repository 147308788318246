"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectedUpdate = exports.getUpdateState = exports.getUpdateFailedState = exports.getUpdateCompletedState = exports.getCreateState = exports.getCreateFailedState = exports.getCreateCompletedState = void 0;

var _lodash = require("lodash");

var _fieldsOperations = require("./fieldsOperations.js");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var selectedUpdate = function selectedUpdate(config, state, items) {
  var update = {};
  if (state.selected && state.selected[config.keyName]) update.selected = (0, _lodash.find)(items, function (item) {
    return item[config.keyName] == state.selected[config.keyName];
  }) || {};
  return update;
};

exports.selectedUpdate = selectedUpdate;

var getCreateState = function getCreateState(options, state, action) {
  var item = Object.assign({}, (0, _fieldsOperations.stripVirtualParseLocalFields)(options, action.data), {
    _temp: true
  });
  return Object.assign({}, state, {
    isWriting: true,
    items: [].concat(_toConsumableArray(state.items), [item])
  });
};

exports.getCreateState = getCreateState;

var getCreateFailedState = function getCreateFailedState(options, state, action) {
  var items = _toConsumableArray(state.items);

  if (action._tempId) {
    items = (0, _lodash.filter)(items, function (item) {
      return item[options.keyName] != action._tempId;
    });
  }

  return Object.assign({}, state, {
    isWriting: false,
    items: items,
    writeError: action.error || null,
    validationError: action.validationError || null
  });
};

exports.getCreateFailedState = getCreateFailedState;

var getCreateCompletedState = function getCreateCompletedState(options, state, action) {
  var items = _toConsumableArray(state.items);

  if (action._tempId) {
    items = (0, _lodash.filter)(items, function (item) {
      return item[options.keyName] != action._tempId;
    });
  }

  return Object.assign({}, state, {
    isWriting: false,
    writeError: null,
    validationError: null,
    items: [].concat(_toConsumableArray(items), [action.data])
  });
};

exports.getCreateCompletedState = getCreateCompletedState;

var getUpdateState = function getUpdateState(options, state, action) {
  var update = {
    isWriting: true
  };
  var data = (0, _fieldsOperations.stripVirtualParseLocalFields)(options, action.data);

  if ((0, _lodash.isObject)(data) && data[options.keyName]) {
    var items = _toConsumableArray(state.items);

    var updatedItem = (0, _lodash.find)(items, function (item) {
      return item[options.keyName] == data[options.keyName];
    });
    items.splice((0, _lodash.findIndex)(items, function (item) {
      return item[options.keyName] == data[options.keyName];
    }), 1, Object.assign({}, updatedItem, data));
    update.updatedItem = updatedItem;
    update.items = items;
  }

  return Object.assign({}, state, update);
};

exports.getUpdateState = getUpdateState;

var getUpdateFailedState = function getUpdateFailedState(options, state, action) {
  var update = {
    isWriting: false,
    updateError: action.error || null,
    validationError: action.validationError || null,
    updatedItem: null
  };

  if (state.updatedItem && state.updatedItem[options.keyName]) {
    var items = _toConsumableArray(state.items);

    items.splice((0, _lodash.findIndex)(items, function (item) {
      return item[options.keyName] == state.updatedItem[options.keyName];
    }), 1, state.updatedItem);
    update.items = items;
  }

  return Object.assign({}, state, update);
};

exports.getUpdateFailedState = getUpdateFailedState;

var getUpdateCompletedState = function getUpdateCompletedState(options, state, action) {
  var update = {
    isWriting: false,
    updateError: null,
    validationError: null,
    updatedItem: null
  };

  if (action._rewrite || options.rewriteOnUpdate && action._rewrite !== false) {
    var items = _toConsumableArray(state.items);

    var data = action.data;
    if (!(0, _lodash.isArray)(data)) data = [data];
    update.items = (0, _lodash.unionBy)(data, items, options.keyName);
  }

  var selected = update.items ? selectedUpdate(options, state, update.items) : {};
  return Object.assign({}, state, update, selected);
};

exports.getUpdateCompletedState = getUpdateCompletedState;