import React, { Component } from 'react';
import { connect, getIn } from 'formik';
import Input from '$ui/form/controls/Input';

export class FormCheckbox extends Component {

  handleChange = () => {
    // e.preventDefault();
    const { name, formik, onChange } = this.props;
    const value = getIn(formik.values, name);
    const touch = getIn(formik.touched, name);
    if (!touch)
      formik.setFieldTouched(name, true);
    formik.setFieldValue(name, !value);
    if (onChange)
      onChange(!value);
  }

  render() {
    const {
      name,
      type,
      description,
      className,
      style,
      formik,
      onChange,
      ...props
    } = this.props;

    const value = getIn(formik.values, name);

    return (
      <div className={(className || 'form-check')} style={style}>
        <Input
          {...props}
          name={name}
          type="checkbox"
          className="form-check-input"
          id={name}
          checked={value}
          onChange={this.handleChange}
        />
        {description &&
          <label className="form-check-label" htmlFor={name}>{description}</label>
        }
      </div>
    );
  }
}

export default connect(FormCheckbox);