import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Auth from '$layouts/Auth';
import { Webapp, Login, Create, Activate, Password, Signup, Forgot, Details, NotFound } from '$loadable/routes.jsx';
import UiProvider from '$providers/UiProvider.jsx';
import { setLocale } from '$utils/i18n.js';

export default function AppRouter() {
  return (
    <Switch>
      <WebappRoute path="/panel" />
      <Route path="/:lang?/create/:hash" component={renderPage(Create)} />
      <Route path="/:lang?/activate/:hash" component={renderPage(Activate)} />
      <Route path="/:lang?/newpassword/:hash" component={renderPage(Password)} />
      <Route path="/:lang?/signup" component={renderPage(Signup)} />
      <Route path="/:lang?/login" component={renderPage(Login)} />
      <Route path="/:lang?/forgot" component={renderPage(Forgot)} />
      <Route path="/:lang?/details" component={renderPage(Details, 12)} />
      <Route path="/" exact render={() => <WebappRedirect /> } />
      <Route component={NotFound} />
    </Switch>
  );
}

const renderPage = (Component, fullsize) => props => {
  setLocale(window.locale);
  return (
    <Auth fullsize={fullsize}>
      <Component {...props} />
    </Auth>
  );
};

const WebappRoute = props =>
  <Route
    {...props}
    render={routeProps =>
      window.user ? (
        <UiProvider>
          <Webapp {...routeProps} />
        </UiProvider>
      ) : (
        <LoginRedirect from={routeProps.location} />
      )
    }
  />;

const LoginRedirect = props =>
  <Redirect
    to={{
      pathname: '/login',
      state: { from: props.from }
    }}
  />;

const WebappRedirect = props =>
  <Route
    {...props}
    render={routeProps =>
      window.user ? (
        <Redirect
          to={{
            pathname: '/panel',
            state: { from: '/' }
          }}
        />
      ) : (
        <LoginRedirect from={routeProps.location} />
      )
    }
  />;