import React, { memo, useCallback } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ListGroup } from 'reactstrap';
import { FixedSizeList } from 'react-window';
import isReactEqual from 'fast-deep-equal/react';

const ListVirtual = ({ itemData, itemCount, itemKey, ItemRenderer, itemSize = 51 }) => {
  const autoSize = useCallback(
    ({ height, width }) => {
      return (
        <FixedSizeList
          itemData={itemData}
          height={height}
          itemCount={itemCount}
          itemSize={itemSize}
          width={width}
          itemKey={itemKey}
        >
          {ItemRenderer}
        </FixedSizeList>
      ); },
    [itemData, itemCount]
  );

  return (
    <ListGroup className="List List--virtualized d-flex flex-grow-1">
      <AutoSizer>
        {autoSize}
      </AutoSizer>
    </ListGroup>
  );
};

export default memo(ListVirtual, isReactEqual);