import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import Icon from '$ui/Icon';
import ProfileDropdown from '$ui/navbar/ProfileDropdown';
import { __ } from '@stktk/locales';
// import NotificationDropdown from './NotificationDropdown';

const TopNavRightSideNavItem = () => {
  return (
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
      <NavItem className="mr-2">
        <NavLink target="_blank" rel="noopener noreferrer" href={window.domain.URL_HELP}>
          <Icon className="d-inline-block mr-0 mr-sm-1" tooltip={__('app_help_stktk')} type="light" icon="question-circle" />
          <span className="d-none d-sm-inline">{__('app_help_stktk')}</span>
        </NavLink>
      </NavItem>
      {/* <NotificationDropdown /> */}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;