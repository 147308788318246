"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _validatorConstants = require("../validator/validatorConstants.js");

var _commons = require("@stktk/commons");

var _schemeConstants = require("./schemeConstants.js");

var _find$listOrder$listO;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var createCommon = {
  domainId: _validatorConstants.INTEGER.required([1]),
  stocktakeMode: _validatorConstants.ENUM.STRING.required(_commons.SkuStocktakeModeEnum.toArray()),
  name: _validatorConstants.STRING.required(120),
  categoryId: _validatorConstants.INTEGER.optional([1]),
  image: _validatorConstants.NULL.optional(),
  type: _validatorConstants.ENUM.STRING.required(_commons.SkuTypeEnum.toArray()),
  localSupplierIds: _validatorConstants.ARRAY.optional(_validatorConstants.INTEGER.optional([1])),
  barcode: _validatorConstants.BARCODE.optional(32),
  barcodeFormat: (0, _validatorConstants.WHEN)('barcode', {
    is: function is(barcode) {
      return barcode !== null;
    },
    then: _validatorConstants.ENUM.STRING.required(_commons.SkuBarcodeFormatEnum.toArray().map(function (skuBarcodeFormat) {
      return skuBarcodeFormat.name;
    })),
    otherwise: _validatorConstants.ENUM.STRING.optional(_commons.SkuBarcodeFormatEnum.toArray().map(function (skuBarcodeFormat) {
      return skuBarcodeFormat.name;
    }))
  }),
  details: _validatorConstants.ARRAY.optional(_validatorConstants.OBJECT.INPART.required({
    /* FIXME: mobile fallback */
    restaurantId: _validatorConstants.INTEGER.optional([1]),
    reportAmount: _validatorConstants.INTEGER.optional([1]),
    reportUnitId: _validatorConstants.ENUM.INTEGER.optional(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.toArray())),
    netPurchasePrice: _validatorConstants.NUMBER.optional([_schemeConstants.MONETARY_PRECISION]),
    netSalePrice: _validatorConstants.NUMBER.optional([_schemeConstants.MONETARY_PRECISION])
  })),
  ingredients: (0, _validatorConstants.WHEN)('type', {
    is: function is(type) {
      return type === _commons.SkuTypeEnum.COMPOUND;
    },
    then: _validatorConstants.ARRAY.optional(_validatorConstants.OBJECT.INPART.required({
      ingredientId: _validatorConstants.INTEGER.required([1]),
      ingredientAmount: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
      ingredientAmountUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.toArray().map(function (unit) {
        return unit.id;
      }))
    })),
    otherwise: (0, _validatorConstants.FORBIDDEN)()
  })
};
var updateCommon = {
  id: _validatorConstants.INTEGER.required(),
  domainId: _validatorConstants.INTEGER.required([1]),
  name: _validatorConstants.STRING.optional(120),
  categoryId: _validatorConstants.INTEGER.optional([1]),
  image: _validatorConstants.NULL.optional(),
  type: _validatorConstants.ENUM.STRING.required(_commons.SkuTypeEnum.toArray()),
  barcode: _validatorConstants.BARCODE.optional(32),
  barcodeFormat: (0, _validatorConstants.WHEN)('barcode', {
    is: function is(barcode) {
      return barcode !== null;
    },
    then: _validatorConstants.ENUM.STRING.required(_commons.SkuBarcodeFormatEnum.toArray().map(function (skuBarcodeFormat) {
      return skuBarcodeFormat.name;
    })),
    otherwise: _validatorConstants.ENUM.STRING.optional(_commons.SkuBarcodeFormatEnum.toArray().map(function (skuBarcodeFormat) {
      return skuBarcodeFormat.name;
    }))
  }),
  details: _validatorConstants.ARRAY.optional(_validatorConstants.OBJECT.INPART.optional({
    /* FIXME: mobile fallback */
    restaurantId: _validatorConstants.INTEGER.optional([1]),
    reportAmount: _validatorConstants.INTEGER.optional([1]),
    reportUnitId: _validatorConstants.ENUM.INTEGER.optional(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.toArray())),
    netPurchasePrice: _validatorConstants.NUMBER.optional([0]),
    netSalePrice: _validatorConstants.NUMBER.optional([0])
  })),
  ingredients: (0, _validatorConstants.WHEN)('type', {
    is: function is(type) {
      return type === _commons.SkuTypeEnum.COMPOUND;
    },
    then: _validatorConstants.ARRAY.optional(_validatorConstants.OBJECT.INPART.optional({
      id: _validatorConstants.INTEGER.required([1]),
      ingredientAmount: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
      ingredientAmountUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.toArray().map(function (unit) {
        return unit.id;
      }))
    })),
    otherwise: (0, _validatorConstants.FORBIDDEN)()
  })
};

var _default = (_find$listOrder$listO = {
  find: _validatorConstants.OBJECT.INPART.required({
    domainId: _validatorConstants.INTEGER.required([1]),
    types: _validatorConstants.ARRAY.required(_validatorConstants.ENUM.STRING.required(_commons.SkuTypeEnum.toArray()), [1, _commons.SkuTypeEnum.toArray().length], true)
  }),
  listOrder: {
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    supplierId: _validatorConstants.INTEGER.optional([1]),
    recordsetId: _validatorConstants.INTEGER.optional([1]),
    orderGroupId: _validatorConstants.INTEGER.optional([1])
  },
  listOrderGroup: {
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    //supplierId: INTEGER.required([1]), //FIXME: ?
    orderGroupId: _validatorConstants.INTEGER.required([1])
  },
  listLoss: {
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    lossGroupId: _validatorConstants.INTEGER.required([1])
  },
  listSale: {
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    recordsetId: _validatorConstants.INTEGER.required([1])
  },
  listStorage: {
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    storageStandardId: _validatorConstants.INTEGER.required([1])
  },
  listStocktake: {
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    barId: _validatorConstants.INTEGER.required([1])
  },
  search: {
    //FIXME: begin - mobile fallbacks
    type: _validatorConstants.ENUM.STRING.optional(_commons.SkuSearchModeEnum.toArray()),
    mode: _validatorConstants.ENUM.STRING.optional(_commons.SkuSearchModeEnum.toArray()),
    scopes: _validatorConstants.ARRAY.required(_validatorConstants.ENUM.STRING.required(_commons.SkuSearchScopeEnum.toArray()), [1, 2], true),
    types: _validatorConstants.ARRAY.optional(_validatorConstants.ENUM.STRING.required(_commons.SkuTypeEnum.toArray()), [1, _commons.SkuTypeEnum.toArray().length], true),
    // FIXME: end - mobile fallbacks
    domainId: _validatorConstants.INTEGER.required([1]),
    query: _validatorConstants.STRING.required([3]),
    barcodeFormat: (0, _validatorConstants.WHEN)('mode', {
      is: function is(mode) {
        return mode == _commons.SkuSearchModeEnum.BARCODE;
      },
      then: _validatorConstants.ENUM.STRING.required(_commons.SkuBarcodeFormatEnum.toArray().map(function (skuBarcodeFormat) {
        return skuBarcodeFormat.name;
      })),
      otherwise: (0, _validatorConstants.FORBIDDEN)()
    })
  }
}, _defineProperty(_find$listOrder$listO, _commons.SkuStocktakeModeEnum.QUANTITY, {
  update: Object.assign({}, updateCommon, {
    stocktakeMode: _validatorConstants.ENUM.STRING.required([_commons.SkuStocktakeModeEnum.QUANTITY]),
    // keys
    volume: _validatorConstants.NUMBER.optional([1]),
    volumeUnitId: _validatorConstants.ENUM.INTEGER.optional(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.VOLUME)),
    packageWeightGross: _validatorConstants.NUMBER.optional([1]),
    packageWeightGrossArray: _validatorConstants.NULL.optional(),
    //ARRAY.optional(NUMBER.optional([1]), [0, 10]),
    packageWeightGrossUnitId: _validatorConstants.ENUM.INTEGER.optional(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT))
  }),
  create: Object.assign({}, createCommon, {
    stocktakeMode: _validatorConstants.ENUM.STRING.required([_commons.SkuStocktakeModeEnum.QUANTITY]),
    // keys
    volume: _validatorConstants.NUMBER.optional([1]),
    volumeUnitId: _validatorConstants.ENUM.INTEGER.optional(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.VOLUME)),
    packageWeightGross: _validatorConstants.NUMBER.optional([1]),
    packageWeightGrossArray: _validatorConstants.NULL.optional(),
    //ARRAY.optional(NUMBER.optional([1]), [0, 10]),
    packageWeightGrossUnitId: _validatorConstants.ENUM.INTEGER.optional(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT))
  })
}), _defineProperty(_find$listOrder$listO, _commons.SkuStocktakeModeEnum.QUANTITY_VOLUME, {
  update: Object.assign({}, updateCommon, {
    stocktakeMode: _validatorConstants.ENUM.STRING.required([_commons.SkuStocktakeModeEnum.QUANTITY_VOLUME]),
    // keys
    volume: _validatorConstants.NUMBER.required([1]),
    volumeUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.VOLUME)),
    packageWeightNet: _validatorConstants.CONVERTED_WEIGHT.required(_validatorConstants.CONVERTED_WEIGHT_TYPE.NET, ['packageWeightGross', 'packageWeightGrossUnitId', 'packageWeightNetUnitId', 'capWeight', 'capWeightUnitId'], [_schemeConstants.SCALAR_PRECISION, _validatorConstants.MAX_NUMBER]),
    packageWeightNetArray: _validatorConstants.NULL.optional(),
    //ARRAY.optional(NUMBER.optional([1]), [0, 10]),
    packageWeightGross: _validatorConstants.CONVERTED_WEIGHT.required(_validatorConstants.CONVERTED_WEIGHT_TYPE.GROSS, ['packageWeightNet', 'packageWeightGrossUnitId', 'packageWeightNetUnitId', 'capWeight', 'capWeightUnitId'], [_schemeConstants.SCALAR_PRECISION, _validatorConstants.MAX_NUMBER]),
    packageWeightGrossArray: _validatorConstants.NULL.optional(),
    //ARRAY.optional(NUMBER.optional([1]), [0, 10]),
    packageWeightNetUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    packageWeightGrossUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    capWeight: _validatorConstants.CONVERTED_WEIGHT.optional(_validatorConstants.CONVERTED_WEIGHT_TYPE.CAP, ['packageWeightNet', 'packageWeightGross', 'packageWeightGrossUnitId', 'packageWeightNetUnitId', 'capWeightUnitId'], [_schemeConstants.SCALAR_PRECISION, _validatorConstants.MAX_NUMBER]),
    capWeightUnitId: _validatorConstants.ENUM.INTEGER.optional(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    density: _validatorConstants.INTEGER.optional([1, 3000]),
    percentage: _validatorConstants.NUMBER.optional(100)
  }),
  create: Object.assign({}, createCommon, {
    stocktakeMode: _validatorConstants.ENUM.STRING.required([_commons.SkuStocktakeModeEnum.QUANTITY_VOLUME]),
    // keys
    volume: _validatorConstants.NUMBER.required([1]),
    volumeUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.VOLUME)),
    packageWeightNet: _validatorConstants.CONVERTED_WEIGHT.required(_validatorConstants.CONVERTED_WEIGHT_TYPE.NET, ['packageWeightGross', 'packageWeightGrossUnitId', 'packageWeightNetUnitId', 'capWeight', 'capWeightUnitId'], [_schemeConstants.SCALAR_PRECISION, _validatorConstants.MAX_NUMBER]),
    packageWeightNetArray: _validatorConstants.NULL.optional(),
    //ARRAY.optional(NUMBER.optional([1]), [0, 10]),
    packageWeightGross: _validatorConstants.CONVERTED_WEIGHT.required(_validatorConstants.CONVERTED_WEIGHT_TYPE.GROSS, ['packageWeightNet', 'packageWeightGrossUnitId', 'packageWeightNetUnitId', 'capWeight', 'capWeightUnitId'], [_schemeConstants.SCALAR_PRECISION, _validatorConstants.MAX_NUMBER]),
    packageWeightGrossArray: _validatorConstants.NULL.optional(),
    //ARRAY.optional(NUMBER.optional([1]), [0, 10]),
    packageWeightNetUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    packageWeightGrossUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    capWeight: _validatorConstants.CONVERTED_WEIGHT.optional(_validatorConstants.CONVERTED_WEIGHT_TYPE.CAP, ['packageWeightNet', 'packageWeightGross', 'packageWeightGrossUnitId', 'packageWeightNetUnitId', 'capWeightUnitId'], [_schemeConstants.SCALAR_PRECISION, _validatorConstants.MAX_NUMBER]),
    capWeightUnitId: _validatorConstants.ENUM.INTEGER.optional(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    density: _validatorConstants.INTEGER.optional([1, 3000]),
    percentage: _validatorConstants.NUMBER.optional(100)
  })
}), _defineProperty(_find$listOrder$listO, _commons.SkuStocktakeModeEnum.VOLUME, {
  update: Object.assign({}, updateCommon, {
    stocktakeMode: _validatorConstants.ENUM.STRING.required([_commons.SkuStocktakeModeEnum.VOLUME]),
    // keys
    volume: _validatorConstants.NUMBER.required([1]),
    volumeUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.VOLUME))
  }),
  create: Object.assign({}, createCommon, {
    stocktakeMode: _validatorConstants.ENUM.STRING.required([_commons.SkuStocktakeModeEnum.VOLUME]),
    // keys
    volume: _validatorConstants.NUMBER.required([1]),
    volumeUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.VOLUME))
  })
}), _defineProperty(_find$listOrder$listO, _commons.SkuStocktakeModeEnum.MASS, {
  update: Object.assign({}, updateCommon, {
    stocktakeMode: _validatorConstants.ENUM.STRING.required([_commons.SkuStocktakeModeEnum.MASS]),
    // keys
    packageWeightGross: _validatorConstants.NUMBER.required([1]),
    packageWeightGrossArray: _validatorConstants.NULL.optional(),
    //ARRAY.optional(NUMBER.optional([1]), [0, 10]),
    packageWeightGrossUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT))
  }),
  create: Object.assign({}, createCommon, {
    stocktakeMode: _validatorConstants.ENUM.STRING.required([_commons.SkuStocktakeModeEnum.MASS]),
    // keys
    packageWeightGross: _validatorConstants.NUMBER.required([1]),
    packageWeightGrossArray: _validatorConstants.NULL.optional(),
    //ARRAY.optional(NUMBER.optional([1]), [0, 10]),
    packageWeightGrossUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT))
  })
}), _defineProperty(_find$listOrder$listO, _commons.SkuStocktakeModeEnum.QUANTITY_MASS, {
  update: Object.assign({}, updateCommon, {
    stocktakeMode: _validatorConstants.ENUM.STRING.required([_commons.SkuStocktakeModeEnum.QUANTITY_MASS]),
    // keys
    packageWeightNet: _validatorConstants.CONVERTED_WEIGHT.required(_validatorConstants.CONVERTED_WEIGHT_TYPE.NET, ['packageWeightGross', 'packageWeightGrossUnitId', 'packageWeightNetUnitId', 'capWeight', 'capWeightUnitId'], [_schemeConstants.SCALAR_PRECISION, _validatorConstants.MAX_NUMBER]),
    packageWeightNetArray: _validatorConstants.NULL.optional(),
    //ARRAY.optional(NUMBER.optional([1]), [0, 10]),
    packageWeightGross: _validatorConstants.CONVERTED_WEIGHT.required(_validatorConstants.CONVERTED_WEIGHT_TYPE.GROSS, ['packageWeightNet', 'packageWeightGrossUnitId', 'packageWeightNetUnitId', 'capWeight', 'capWeightUnitId'], [_schemeConstants.SCALAR_PRECISION, _validatorConstants.MAX_NUMBER]),
    packageWeightGrossArray: _validatorConstants.NULL.optional(),
    //ARRAY.optional(NUMBER.optional([1]), [0, 10]),
    packageWeightNetUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    packageWeightGrossUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    capWeight: _validatorConstants.CONVERTED_WEIGHT.optional(_validatorConstants.CONVERTED_WEIGHT_TYPE.CAP, ['packageWeightNet', 'packageWeightGross', 'packageWeightGrossUnitId', 'packageWeightNetUnitId', 'capWeightUnitId'], [_schemeConstants.SCALAR_PRECISION, _validatorConstants.MAX_NUMBER]),
    capWeightUnitId: _validatorConstants.ENUM.INTEGER.optional(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT))
  }),
  create: Object.assign({}, createCommon, {
    stocktakeMode: _validatorConstants.ENUM.STRING.required([_commons.SkuStocktakeModeEnum.QUANTITY_MASS]),
    // keys
    packageWeightNet: _validatorConstants.CONVERTED_WEIGHT.required(_validatorConstants.CONVERTED_WEIGHT_TYPE.NET, ['packageWeightGross', 'packageWeightGrossUnitId', 'packageWeightNetUnitId', 'capWeight', 'capWeightUnitId'], [_schemeConstants.SCALAR_PRECISION, _validatorConstants.MAX_NUMBER]),
    packageWeightNetArray: _validatorConstants.NULL.optional(),
    //ARRAY.optional(NUMBER.optional([1]), [0, 10]),
    packageWeightGross: _validatorConstants.CONVERTED_WEIGHT.required(_validatorConstants.CONVERTED_WEIGHT_TYPE.GROSS, ['packageWeightNet', 'packageWeightGrossUnitId', 'packageWeightNetUnitId', 'capWeight', 'capWeightUnitId'], [_schemeConstants.SCALAR_PRECISION, _validatorConstants.MAX_NUMBER]),
    packageWeightGrossArray: _validatorConstants.NULL.optional(),
    //ARRAY.optional(NUMBER.optional([1]), [0, 10]),
    packageWeightNetUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    packageWeightGrossUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    capWeight: _validatorConstants.CONVERTED_WEIGHT.optional(_validatorConstants.CONVERTED_WEIGHT_TYPE.CAP, ['packageWeightNet', 'packageWeightGross', 'packageWeightGrossUnitId', 'packageWeightNetUnitId', 'capWeightUnitId'], [_schemeConstants.SCALAR_PRECISION, _validatorConstants.MAX_NUMBER]),
    capWeightUnitId: _validatorConstants.ENUM.INTEGER.optional(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT))
  })
}), _defineProperty(_find$listOrder$listO, "destroy", {
  id: _validatorConstants.INTEGER.required([1]),
  domainId: _validatorConstants.INTEGER.required([1])
}), _find$listOrder$listO);

exports["default"] = _default;