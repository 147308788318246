"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _validatorConstants = require("../validator/validatorConstants.js");

var _default = {
  find: _validatorConstants.OBJECT.INPART.required({
    domainId: _validatorConstants.INTEGER.required([1])
  })
};
exports["default"] = _default;