"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WHEN = exports.STRING = exports.REGEX = exports.REF = exports.OBJECT = exports.NUMBER = exports.NULL = exports.MAX_NUMBER = exports.INTEGER = exports.FORBIDDEN = exports.ENUM = exports.EMAIL = exports.DECIMAL = exports.DATE_RANGE = exports.DATE = exports.CONVERTED_WEIGHT_TYPE = exports.CONVERTED_WEIGHT = exports.BOOLEAN = exports.BARCODE = exports.ARRAY = void 0;

var _lodash = require("lodash");

var _ExtendedValidator = _interopRequireDefault(require("./ExtendedValidator.js"));

var _templateObject, _templateObject2;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var MAX_NUMBER = 999999999999999;
exports.MAX_NUMBER = MAX_NUMBER;
var CONVERTED_WEIGHT_TYPE = {
  NET: 'net',
  GROSS: 'gross',
  CAP: 'cap'
};
exports.CONVERTED_WEIGHT_TYPE = CONVERTED_WEIGHT_TYPE;

var constructScheme = function constructScheme(type, validation, length) {
  var min = 0;
  var max;

  if (Array.isArray(length)) {
    var _length = _slicedToArray(length, 2);

    min = _length[0];
    max = _length[1];
  } else max = length;

  if (max) return validation.min(min).max(max);else return validation.min(min).max(MAX_NUMBER);
};

var _required = function required(validator) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (_ref) {
    var type = _ref.type;
    return "validator_error_not_".concat(type);
  };
  return validator.required(message).typeError(message);
};

var _optional = function optional(validator) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (_ref2) {
    var type = _ref2.type;
    return {
      message: "validator_error_not_".concat(type)
    };
  };
  return validator.notRequired(message).nullable().typeError(message);
};

var FORBIDDEN = function FORBIDDEN(message) {
  return _ExtendedValidator["default"].mixed().forbidden({
    message: message
  });
};

exports.FORBIDDEN = FORBIDDEN;

var WHEN = function WHEN(path, options) {
  return _ExtendedValidator["default"].mixed().when(path, options);
};

exports.WHEN = WHEN;

var REF = function REF(path, options) {
  return _ExtendedValidator["default"].ref(path, options);
};

exports.REF = REF;

var weightConverter = function weightConverter(type, paths, length, message) {
  var values = (0, _lodash.reduce)(paths, function (result, path) {
    result[path] = _ExtendedValidator["default"].ref(path);
    return result;
  }, {});
  var min = MAX_NUMBER,
      max = -1;

  if (Array.isArray(length)) {
    var _length2 = _slicedToArray(length, 2);

    min = _length2[0];
    max = _length2[1];
  } else max = length;

  switch (type) {
    case CONVERTED_WEIGHT_TYPE.NET:
      return _ExtendedValidator["default"].number({
        message: message
      }).min(min).max(max).convertedNetWeight(Object.assign({
        message: message
      }, values));

    case CONVERTED_WEIGHT_TYPE.GROSS:
      return _ExtendedValidator["default"].number({
        message: message
      }).min(min).max(max).convertedGrossWeight(Object.assign({
        message: message
      }, values));

    case CONVERTED_WEIGHT_TYPE.CAP:
      return _ExtendedValidator["default"].number({
        message: message
      }).min(min).max(max).convertedCapWeight(Object.assign({
        message: message
      }, values));
  }
};

var dateRangeValidator = function dateRangeValidator(paths, duration, message) {
  var values = {
    rangeFromDate: _ExtendedValidator["default"].ref(paths[0]),
    rangeToDate: _ExtendedValidator["default"].ref(paths[1])
  };
  return _ExtendedValidator["default"].string().dateString().min(0).dateRangeDuration(_objectSpread({
    message: message,
    duration: duration
  }, values));
};

var DATE_RANGE = {
  optional: function optional(paths, duration, message) {
    return _optional(dateRangeValidator(paths, duration, message));
  },
  required: function required(paths, duration, message) {
    return _required(dateRangeValidator(paths, duration, message));
  }
};
exports.DATE_RANGE = DATE_RANGE;
var CONVERTED_WEIGHT = {
  optional: function optional(type, paths, precision, message) {
    return _optional(weightConverter(type, paths, precision, message));
  },
  required: function required(type, paths, precision, message) {
    return _required(weightConverter(type, paths, precision, message));
  }
};
exports.CONVERTED_WEIGHT = CONVERTED_WEIGHT;
var NULL = {
  optional: function optional(message) {
    return _optional(_ExtendedValidator["default"].mixed().nullable().oneOf([null]), message);
  },
  required: function required(message) {
    return _required(_ExtendedValidator["default"].mixed().nullable().oneOf([null]), message);
  }
};
exports.NULL = NULL;
var NUMBER = {
  // double
  optional: function optional(length) {
    return constructScheme('NUMBER', _optional(_ExtendedValidator["default"].number()), length);
  },
  required: function required(length) {
    return constructScheme('NUMBER', _required(_ExtendedValidator["default"].number()), length);
  }
}; //FIXME: locale awareness

exports.NUMBER = NUMBER;
var DECIMAL = {
  optional: function optional(_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
        precision = _ref4[0],
        scale = _ref4[1];

    var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'validator_error_stktk_decimal';
    return _optional(_ExtendedValidator["default"].number().test('decimal.optional', function (_ref5) {
      var path = _ref5.path;
      return {
        message: message,
        integerDigits: precision - scale,
        fractionalDigits: scale,
        path: path
      };
    }, function (value) {
      var result = (value + '').match(new RegExp(String.raw(_templateObject || (_templateObject = _taggedTemplateLiteral(["^(d{1,", "}.d{1,", "}|[.,]d{1,", "}|d{1,", "})$"], ["^(\\d{1,", "}\\.\\d{1,", "}|[.,]\\d{1,", "}|\\d{1,", "})$"])), precision - scale, scale, scale, precision - scale), 'g'));
      if (!result) return this.createError({
        message: message
      });
      return result;
    }));
  },
  required: function required(_ref6) {
    var _ref7 = _slicedToArray(_ref6, 2),
        precision = _ref7[0],
        scale = _ref7[1];

    var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'validator_error_stktk_decimal';
    return _required(_ExtendedValidator["default"].number().test('decimal.required', function (_ref8) {
      var path = _ref8.path;
      return {
        message: message,
        integerDigits: precision - scale,
        fractionalDigits: scale,
        path: path
      };
    }, function (value) {
      var result = (value + '').match(new RegExp(String.raw(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["^(d{1,", "}.d{1,", "}|[.,]d{1,", "}|d{1,", "})$"], ["^(\\d{1,", "}\\.\\d{1,", "}|[.,]\\d{1,", "}|\\d{1,", "})$"])), precision - scale, scale, scale, precision - scale), 'g'));
      if (!result) return this.createError({
        message: message
      });
      return result;
    }));
  }
};
exports.DECIMAL = DECIMAL;
var INTEGER = {
  optional: function optional(length) {
    return constructScheme('INTEGER', _optional(_ExtendedValidator["default"].number().integer()), length);
  },
  required: function required(length) {
    return constructScheme('INTEGER', _required(_ExtendedValidator["default"].number().integer()), length);
  }
};
exports.INTEGER = INTEGER;
var REGEX = {
  optional: function optional(regex, message) {
    return _optional(_ExtendedValidator["default"].string().matches(regex, {
      message: message
    }), message);
  },
  required: function required(regex, message) {
    return _required(_ExtendedValidator["default"].string().matches(regex, {
      message: message
    }), message);
  }
};
exports.REGEX = REGEX;
var STRING = {
  optional: function optional(length) {
    return constructScheme('STRING', _optional(_ExtendedValidator["default"].string()), length);
  },
  required: function required(length) {
    return constructScheme('STRING', _required(_ExtendedValidator["default"].string()), length);
  }
};
exports.STRING = STRING;
var EMAIL = {
  optional: function optional(length) {
    return constructScheme('EMAIL', _optional(_ExtendedValidator["default"].string().email()), length);
  },
  required: function required(length) {
    return constructScheme('EMAIL', _required(_ExtendedValidator["default"].string().email()), length);
  }
};
exports.EMAIL = EMAIL;
var DATE = {
  optional: function optional() {
    return _optional(_ExtendedValidator["default"].string().dateString());
  },
  required: function required() {
    return _required(_ExtendedValidator["default"].string().dateString());
  }
};
exports.DATE = DATE;
var BARCODE = {
  optional: function optional(length) {
    return constructScheme('BARCODE', _optional(_ExtendedValidator["default"].string().barcode()), length);
  },
  required: function required(length) {
    return constructScheme('BARCODE', _required(_ExtendedValidator["default"].string().barcode()), length);
  }
};
exports.BARCODE = BARCODE;
var BOOLEAN = {
  optional: function optional() {
    return _optional(_ExtendedValidator["default"]["boolean"]());
  },
  required: function required() {
    return _required(_ExtendedValidator["default"]["boolean"]());
  }
}; // export const ENUM = (ref) => ({
//   optional: (array, message) => optional(ref.oneOf(Array.isArray(array) ? array : [array], { message }), message),
//   required: (array, message) => required(ref.oneOf(Array.isArray(array) ? array : [array], { message }), message)
// });

exports.BOOLEAN = BOOLEAN;
var ENUM = {
  STRING: {
    optional: function optional(array, message) {
      var opts = message ? {
        message: message
      } : null;
      return _optional(_ExtendedValidator["default"].string(opts).oneOf(Array.isArray(array) ? [].concat(_toConsumableArray(array), [null]) : [array, null], opts), message);
    },
    required: function required(array, message) {
      var opts = message ? {
        message: message
      } : null;
      return _required(_ExtendedValidator["default"].string(opts).oneOf(Array.isArray(array) ? array : [array], opts), message);
    }
  },
  INTEGER: {
    optional: function optional(array, message) {
      var opts = message ? {
        message: message
      } : null;
      return _optional(_ExtendedValidator["default"].number(opts).integer(opts).oneOf(Array.isArray(array) ? [].concat(_toConsumableArray(array), [null]) : [array, null], opts), message);
    },
    required: function required(array, message) {
      var opts = message ? {
        message: message
      } : null;
      return _required(_ExtendedValidator["default"].number(opts).integer(opts).oneOf(Array.isArray(array) ? array : [array], opts), message);
    }
  },
  BOOLEAN: {
    optional: function optional(array, message) {
      var opts = message ? {
        message: message
      } : null;
      return _optional(_ExtendedValidator["default"]["boolean"](opts).oneOf(Array.isArray(array) ? [].concat(_toConsumableArray(array), [null]) : [array, null], opts), message);
    },
    required: function required(array, message) {
      var opts = message ? {
        message: message
      } : null;
      return _required(_ExtendedValidator["default"]["boolean"](opts).oneOf(Array.isArray(array) ? array : [array], opts), message);
    }
  }
};
exports.ENUM = ENUM;
var ARRAY = {
  optional: function optional(items, length) {
    return _optional(constructScheme('ARRAY', Array.isArray(items) ? _ExtendedValidator["default"].array().items(items) : _ExtendedValidator["default"].array().of(items), length));
  },
  required: function required(items, length) {
    return _required(constructScheme('ARRAY', Array.isArray(items) ? _ExtendedValidator["default"].array().items(items) : _ExtendedValidator["default"].array().of(items), length));
  }
};
exports.ARRAY = ARRAY;
var OBJECT = {
  optional: function optional(keys) {
    return _optional(_ExtendedValidator["default"].object(keys).defined().noUnknown(true));
  },
  required: function required(keys) {
    return _required(_ExtendedValidator["default"].object(keys).defined().noUnknown(true));
  },
  schema: function schema(keys) {
    return _ExtendedValidator["default"].object(keys).defined().noUnknown(true);
  },
  INPART: {
    optional: function optional(keys) {
      return _optional(_ExtendedValidator["default"].object(keys).defined().noUnknown(false));
    },
    required: function required(keys) {
      return _required(_ExtendedValidator["default"].object(keys).defined().noUnknown(false));
    }
  }
};
exports.OBJECT = OBJECT;