import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import AppRouter from '$routers/AppRouter.jsx';
import store from '$lib/store';
import { CapabilitiesManager } from '$ui/CapabilitiesManager.js';

const App = () => {

  // FIXME: No Globals!
  window.store = store;
  window.getStoreState = function () {
    return store.getState();
  };
  if (window.user)
    window.capabilitiesManager = new CapabilitiesManager(window.user, { id: window.user.domainId });

  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </DndProvider>
    </Provider>
  );
};

export default App;