import React from 'react';
import { __ } from '@stktk/locales';
import { CircleProgressChart } from '$loadable/ui.jsx';
import { countUnevaluatedRecords } from '@stktk/logic/utils/statistics';

export default function EvaluatedRecordsChart({ records, recordsetType, property }) {
  return (
    <CircleProgressChart
      total={records.length}
      fragment={countUnevaluatedRecords(records, recordsetType, property)}
      text={__('evaluated_records')}
      text2={__('total_records_quantity')}
    />
  );
}