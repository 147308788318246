import React from 'react';
import { useField } from 'formik';
import MDEditor from '@uiw/react-md-editor';

const FormMarkdown = ({ id, name, onChange, submitOnBlur, focusOnRender = false, preview = 'edit', ...props }) => {
  const [{ value }, { error, touched }, { setValue }] = useField(name);

  const handleChange = value => {
    if (onChange)
      onChange(value);
    setValue(value);
  };

  return (
    <MDEditor
      textareaProps={{
        'data-testid': `Input-${name || id}`
      }}
      value={value || ''}
      autoFocus={focusOnRender}
      preview={preview}
      config={{
        view: { menu: true, md: true, html: false }
      }}
      onChange={handleChange}
      {...props}
    />
  );
};

export default FormMarkdown;