import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Tooltip, Popover, PopoverBody } from 'reactstrap';
import classNames from 'classnames';
import { Icon } from '$ui';

/* eslint-disable complexity */
export default function TableTd({ index, cell, row, editColumnId, editItemId, tableDispatch, cellEdit, ...props }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isOverflowing, setOverflowing] = useState(false);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const spanRef = useRef(null);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    setOverflowing(spanRef.current.clientWidth < spanRef.current.scrollWidth);
  }, [cell.column.totalWidth, !!spanRef.current]);

  let tooltipContent = () => {
    return (
      <div>
        <div>
          {cell.isAggregated ? cell.render('Aggregated') : cell.render('Cell')}
        </div>
        <div>
          {cell.column.tooltipAdditionalInfo && cell.column.tooltipAdditionalInfo}
        </div>
      </div>
    );

  };

  return (
    <div
      className={classNames('Table-td', 'd-flex flex-row justify-content-between min-w-0', { 'Table-td--expanded': row.isExpanded, 'Table-td--edit': cell.column.isEditable && row.original && cell.column.isCellEditable(row.original) && cell.column.id === editColumnId && row.original && row.original.id === editItemId })}
      {...props}
      {...cell.getCellProps()}
      onClick={() => {
        if (cell.column.isEditable && row.original && cell.column.isCellEditable(row.original)) {
          tableDispatch({ type: 'SET_EDIT_COLUMN_ID', payload: cell.column.id });
          tableDispatch({ type: 'SET_EDIT_ITEM_ID', payload: row.original.id });
        }
      }}
    >
      <span
        ref={spanRef}
        className={classNames('d-inline-block text-overflow-ellipsis', index.toString())}
      >
        {cell.column.tooltip &&
        <Tooltip
          target={'.' + index.toString()}
          placement="top"
          isOpen={tooltipOpen}
          trigger={'hover'}
          autohide={false}
          toggle={toggle}
        >
          {
            tooltipContent()
          }
        </Tooltip>}
        {cell.column.subRowExpander && row.original.subRows && (
          <span
            {...row.getToggleRowExpandedProps()}
          >
            {row.isExpanded ? (
              <Icon
                style={{ display: 'inline-block', marginRight: '5px' }}
                icon="caret-down"
                type="solid"
                variant="mute"
              />
            ) : (
              <Icon
                style={{ display: 'inline-block', marginRight: '5px' }}
                icon="caret-right"
                type="solid"
                variant="mute"
              />
            )}
          </span>
        )}
        {cell.isGrouped ? (
        // If it's a grouped cell, add an expander and row count
          <Fragment>
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <Icon
                  style={{ display: 'inline-block', marginRight: '5px' }}
                  icon="caret-down"
                  type="solid"
                  variant="mute"
                />) : (
                <Icon
                  style={{ display: 'inline-block', marginRight: '5px' }}
                  icon="caret-right"
                  type="solid"
                  variant="mute"
                />)
              }
            </span>
            {' '}
            {cell.render('Cell')}
            {' '}
(
            {row.subRows.length}
)
          </Fragment>
        ) : cell.isAggregated ? (
        // If the cell is aggregated, use the Aggregated
        // renderer for cell
          cell.render('Aggregated')
        ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
        // Otherwise, just render the regular cell
          cell.column.isEditable && row.original && cell.column.isCellEditable(row.original) && cell.column.id === editColumnId && row.original.id === editItemId ?
            cell.render('Edited', { editItemId, tableDispatch, ...cellEdit }) :
            <Fragment>
              {cell.render('Cell')}
              {cell.column.isEditable && row.original && cell.column.isCellEditable(row.original) && <Icon icon={'edit'} className="Table-td-editable" />}
            </Fragment>
        )}
      </span>
      {isOverflowing && (
        <Fragment>
          <Icon
            id={`${index.toString()}-icon`}
            className="d-inline-block ml-2"
            style={{ display: 'block' }}
            icon="eye"
            type={isPopoverOpen ? 'solid' : 'duotone'}
            variant="mute"
            onClick={() => setPopoverOpen(isOpen => !isOpen)}
          />
          <Popover placement="bottom" isOpen={isPopoverOpen} target={`${index.toString()}-icon`} toggle={() => setPopoverOpen(isOpen => !isOpen)}>
            <Icon
              className="px-2 pt-1"
              style={{ display: 'block', textAlign: 'right' }}
              icon="times"
              type="regular"
              variant="mute"
              onClick={() => setPopoverOpen(isOpen => !isOpen)}
            />
            <PopoverBody style={{ overflow: 'auto', maxHeight: 300 }} >
              {cell.column.Popover ? cell.render('Popover') : cell.render('Cell')}
            </PopoverBody>
          </Popover>
        </Fragment>
      )}
    </div>
  );
}