import React from 'react';
import { connect, getIn } from 'formik';
import FormInput from '$ui/form/FormInput';

export class FormInputGroup extends React.Component {
  render() {
    let {
      label,
      element,
      className,
      name,
      validateEarly = true,
      ...props
    } = this.props;

    const error = getIn(props.formik.errors, name);
    const touch = getIn(props.formik.touched, name);

    let valid = '';
    if (touch)
      valid = error ? ' has-success' : ' has-error';

    let combineClass = 'InputGroup form-group' + (className ? ' ' + className : '') + valid;

    return (
      <div className={combineClass}>
        {label &&
          <label className="control-label" htmlFor={name}>{label}</label>
        }
        <FormInput name={name} {...props} />
        {validateEarly && error && touch &&
          <div className="InputGroup-error text-danger invalid-feedback d-block">{error}</div>
        }
      </div>
    );
  }
}

export default connect(FormInputGroup);