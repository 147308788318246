import React from 'react';
import { connect, getIn } from 'formik';

const OnSubmitValidationError = props => {
  const { callback, formik, name } = props;
  const effect = () => {
    if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
      const errors = getIn(formik.errors, name);
      const values = getIn(formik.values, name);
      callback(formik, errors, values);
    }
  };
  React.useEffect(effect, [formik.submitCount, formik.isSubmitting]);
  return null;
};

export default connect(OnSubmitValidationError);