"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _validatorConstants = require("../validator/validatorConstants.js");

var _default = {
  find: {
    restaurantId: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1])
  },
  //FIXME:
  synchronizeBulk: {
    restaurantId: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    payload: _validatorConstants.ARRAY.required(_validatorConstants.OBJECT.optional({
      skuId: _validatorConstants.INTEGER.required([1]),
      supplierIds: _validatorConstants.ARRAY.optional(_validatorConstants.INTEGER.optional())
    }), [1, 999])
  }
};
exports["default"] = _default;