/* eslint-disable complexity */
import React from 'react';
import ListPaginated from '$ui/list/ListPaginated';
import ListVirtual from '$ui/list/ListVirtual';
import { sortItemsByProperty, sortGroupedRecordsBySkuProperty } from '@stktk/logic/utils/list.js';
import { __ } from '@stktk/locales';

const List = ({ items = [], keyExtractor, sortBy = 'name', sortOrder = 'asc', noItemsText, virtualized = false, grouped = false, itemActions, itemHandlers = {}, itemValues = {}, itemGroupActions, ItemRenderer, itemSize, pageNumber }) => {
  const ListComponent = virtualized ? ListVirtual : ListPaginated;
  const sortMethod = grouped ? sortGroupedRecordsBySkuProperty : sortItemsByProperty;
  const typeActions = grouped ? itemGroupActions : itemActions;
  const sortedItems = sortMethod(items, sortBy, sortOrder);

  const itemData = { items: sortedItems, itemValues, itemActions: typeActions, itemHandlers };
  const itemKey = (index, data) => {
    if (Array.isArray(data))
      return keyExtractor(data[index]);
    return keyExtractor(data.items[index]);
  };
  if (!items.length)
    return (<h3 className="List--noResults">{noItemsText || __('alert_error_no_results_found')}</h3>);
  return (
    <ListComponent
      ItemRenderer={ItemRenderer}
      itemData={itemData}
      itemCount={items.length}
      itemKey={itemKey}
      pageNumber={pageNumber}
      itemSize={itemSize}
    />
  );
};

export default List;