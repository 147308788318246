"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSettings = exports.useRestaurantSettings = exports.useDomainSettings = void 0;

var _reactRedux = require("react-redux");

var _commons = require("@stktk/commons");

var _redux = require("@stktk/redux");

var useSettings = function useSettings() {
  var userId = (0, _reactRedux.useSelector)(_redux.userIdSelector);
  var domainId = (0, _reactRedux.useSelector)(_redux.domainIdSelector);
  var restaurantId = (0, _reactRedux.useSelector)(_redux.restaurantIdSelector);
  var properties = (0, _reactRedux.useSelector)(_redux.propertySelector);
  var settings = (0, _commons.getUserSettings)({
    userId: userId,
    domainId: domainId,
    restaurantId: restaurantId
  }, properties);
  return settings;
};

exports.useSettings = useSettings;

var useRestaurantSettings = function useRestaurantSettings() {
  var domainId = (0, _reactRedux.useSelector)(_redux.domainIdSelector);
  var restaurantId = (0, _reactRedux.useSelector)(_redux.restaurantIdSelector);
  var properties = (0, _reactRedux.useSelector)(_redux.propertySelector);
  var settings = (0, _commons.getRestaurantSettings)({
    domainId: domainId,
    restaurantId: restaurantId
  }, properties);
  return settings;
};

exports.useRestaurantSettings = useRestaurantSettings;

var useDomainSettings = function useDomainSettings() {
  var domainId = (0, _reactRedux.useSelector)(_redux.domainIdSelector);
  var properties = (0, _reactRedux.useSelector)(_redux.propertySelector);
  var settings = (0, _commons.getDomainSettings)({
    domainId: domainId
  }, properties);
  return settings;
};

exports.useDomainSettings = useDomainSettings;