import React, { useState } from 'react';
import { InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { without, union } from 'lodash';
import CheckboxLabeled from '$ui/form/controls/CheckboxLabeled';
import { __skuSearchScope } from '@stktk/locales';

const SkuSearchScopeDropdown = ({ scopes, allowedSearchScopes, onScopeChange }) => {
  const [isOpen, setOpen] = useState(false);

  const handleChangeScope = scope => {
    let change = scopes.indexOf(scope) >= 0 && scopes.length > 1 ? without(scopes, scope) : union(scopes, [scope]);
    onScopeChange(change);
  };

  const currentScopes = scopes.sort().map(scope => __skuSearchScope(scope)).join(', ');
  return (
    <InputGroupButtonDropdown addonType="append" isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
      <DropdownToggle caret outline>
        {currentScopes}
      </DropdownToggle>
      <DropdownMenu>
        {allowedSearchScopes.map(scope =>
          <DropdownItem
            key={`scope-${scope}`}
            tag="div"
            toggle={false}
            onClick={() => handleChangeScope(scope)}
          >
            <CheckboxLabeled
              readOnly
              checked={scopes.indexOf(scope) >= 0}
              label={__skuSearchScope(scope)}
              labelProps={{ onClick: e => e.preventDefaut() }}
            />
          </DropdownItem>
        )}
      </DropdownMenu>
    </InputGroupButtonDropdown>
  );
};

export default SkuSearchScopeDropdown;