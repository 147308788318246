import React from 'react';
import FormGroup from '$ui/form/FormGroup';

export default class FormMultiSelectGroup extends React.Component {
  render() {
    const {
      items,
      ...props
    } = this.props;
    return (
      <FormGroup
        {...props}
        isMulti
        element="select"
        options={
          items.map(item => {
            item.value = item.id;
            item.label = item.name;
            return item;
          })
        }
      />
    );
  }
}