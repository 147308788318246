import React, { Component } from 'react';
import { connect, getIn } from 'formik';
import Select, { components } from 'react-select';
import { filter, flatten } from 'lodash';
import classNames from 'classnames';
import { __, __countryName } from '@stktk/locales';
import { CountryEnum } from '@stktk/commons';
import Input from '$ui/form/controls/Input';

const flagImg = code => 'https://s3-eu-west-1.amazonaws.com/stktk-public/img/flags/' + code.toUpperCase() + '.png';

export class FormPhone extends Component {
  constructor(props) {
    super(props);
    this.suggestedOptions = this.mapByCallingCodes(CountryEnum.toArraySuggested(props.suggested)).map(this.getOption);
    this.otherOptions = this.mapByCallingCodes(CountryEnum.toArrayOthers(props.suggested)).map(this.getOption);
    this.node = React.createRef();
    this.nodeWidth = 300;
  }

  componentDidMount() {
    this.nodeWidth = this.node.current.offsetWidth;
  }

  parseInputValue = value => {
    if (!value || !value.length)
      return '';
    return value.replace(/\+[0-9]* /, '');
  }

  parseSelectValue = value => {
    let ext = this.parseExtValue(value);
    if (!ext)
      return null;
    return { value: ext.substring(1), label: ext };
  }

  parseExtValue = value => {
    if (!value || !value.length)
      return '';
    let ext = value.match(/\+[0-9]* /);
    return ext && ext.length ? ext[0] : '';
  }

  mapByCallingCodes = countries =>
    flatten(
      filter(countries, country => country.callingCodes[0].length)
        .map(country =>
          country.callingCodes.length > 1 ?
            country.callingCodes.map(callingCode => ({ ...country, callingCode }))
            :
            ({ ...country, callingCode: country.callingCodes[0] })
        ));

  handleSelectChange = selectedItem => {
    const { name, formik, reletedField } = this.props;
    const prevValue = getIn(formik.values, name);
    const inputValue = this.parseInputValue(prevValue);
    const value = `+${selectedItem.value} ${inputValue}`;
    if (reletedField) {
      let reletedValue = getIn(formik.values, reletedField);
      if (!reletedValue)
        formik.setFieldValue(reletedField, `+${selectedItem.value}`);
    }
    formik.setFieldValue(name, value);
  }

  handleInputChange = e => {
    const { name, formik } = this.props;
    const prevValue = getIn(formik.values, name);
    const ext = this.parseExtValue(prevValue);
    const value = ext ? `${ext}${e.target.value}` : e.target.value;
    formik.setFieldValue(name, value);
  }

  handleBlur = () => {
    const { name, formik, submitOnBlur } = this.props;
    if (submitOnBlur) formik.submitForm();
    else formik.setFieldTouched(name, true);
  };

  getOption = ({ callingCode, alpha2Code }) => ({
    value: callingCode,
    label: '+' + callingCode + ' ' + __countryName(alpha2Code),
    alpha2Code
  });

  render() {
    let { className, formik, selectClassName, suggested, isDisabled, disabled, reletedField, submitOnBlur, ...props } = this.props;
    const error = getIn(formik.errors, props.name);
    const touch = getIn(formik.touched, props.name);
    const value = getIn(formik.values, props.name);

    let formClasses = classNames({
      'is-invalid': touch && error,
      'is-valid': touch && !error
    });
    let combineClass = classNames('Input', 'form-control', formClasses, className);
    let combineSelectClass = classNames('Select', formClasses, selectClassName);

    let idx = `FormPhone-select-${props.name}`;
    return (
      <div ref={this.node} className="FormPhone d-flex flex-row">
        <Select
          id={idx}
          data-testid={`Select-${props.name}`}
          cacheOptions={true}
          isDisabled={(isDisabled || disabled)}
          styles={{
            container: provided => ({ ...provided, maxHeight: 36 }),
            control: provided => ({ ...provided, width: 100, borderBottomRightRadius: 0, borderTopRightRadius: 0 }),
            menu: provided => ({ ...provided, minWidth: this.nodeWidth })
          }}
          classNamePrefix="Select"
          className={combineSelectClass}
          value={this.parseSelectValue(value)}
          instanceId={`${props.name}`}
          menuPosition="fixed"
          components={{
            Option: props => {
              const [callingCode, ...name] = props.label.split(' ');
              return (
                <components.Option {...props}>
                  <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <div><img src={flagImg(props.data.alpha2Code)} /></div>
                    <div style={{ marginLeft: 10 }}>{callingCode}</div>
                    <div className="text-muted" style={{ marginLeft: 'auto' }}>{name.join(' ')}</div>
                  </div>
                </components.Option>
              );
            }
          }}
          options={[
            {
              label: __('app_select_suggested'),
              options: this.suggestedOptions
            },
            {
              label: __('app_select_others'),
              options: this.otherOptions
            }
          ]}
          onChange={this.handleSelectChange}
        />
        <Input
          disabled={(isDisabled || disabled)}
          style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0, borderLeftColor: '#fff', display: 'flex', pointerEvents: 'auto', height: 39 }}
          value={this.parseInputValue(value)}
          className={combineClass}
          onChange={this.handleInputChange}
          onBlur={this.handleBlur}
          {...props}
        />
      </div>
    );
  }
}

export default connect(FormPhone);