"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _recordCommonSchemes = require("./_recordCommonSchemes.js");

var _validatorConstants = require("../validator/validatorConstants");

var _commons = require("@stktk/commons");

var _schemeConstants = require("./schemeConstants.js");

var _lossRecordModeScheme, _SkuStocktakeModeEnum2, _SkuStocktakeModeEnum5, _find$destroy$findLos;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint-disable new-cap */
var lossRecordModeSchemes = (_lossRecordModeScheme = {}, _defineProperty(_lossRecordModeScheme, _commons.RecordModeEnum.QUANTITY, {
  update: Object.assign({}, _recordCommonSchemes.recordCommonUpdateScheme, {
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.QUANTITY]),
    // updatable
    status: _validatorConstants.ENUM.STRING.required(_commons.LossRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.FULL]),
    quantity: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
    eventDate: _validatorConstants.DATE.required(),
    comment: _validatorConstants.STRING.optional(140),
    netStocktakePurchaseValue: _validatorConstants.NUMBER.optional([0]),
    netStocktakeSaleValue: _validatorConstants.NUMBER.optional([0])
  }),
  create: Object.assign({}, _recordCommonSchemes.recordCommonCreateScheme, {
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.QUANTITY]),
    // attributes
    status: _validatorConstants.ENUM.STRING.required(_commons.LossRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.FULL]),
    quantity: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
    eventDate: _validatorConstants.DATE.required(),
    comment: _validatorConstants.STRING.optional(140),
    localCreator: _validatorConstants.OBJECT.INPART.optional({
      firstName: _validatorConstants.STRING.required(50),
      lastName: _validatorConstants.STRING.required(50)
    }),
    netStocktakePurchaseValue: _validatorConstants.NUMBER.optional([0]),
    netStocktakeSaleValue: _validatorConstants.NUMBER.optional([0])
  })
}), _defineProperty(_lossRecordModeScheme, _commons.RecordModeEnum.VOLUME, {
  update: Object.assign({}, _recordCommonSchemes.recordCommonUpdateScheme, {
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.VOLUME]),
    // updatable
    status: _validatorConstants.ENUM.STRING.required(_commons.LossRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    quantity: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
    eventDate: _validatorConstants.DATE.required(),
    comment: _validatorConstants.STRING.optional(140),
    volumeUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.VOLUME)),
    volume: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    netStocktakePurchaseValue: _validatorConstants.NUMBER.optional([0]),
    netStocktakeSaleValue: _validatorConstants.NUMBER.optional([0])
  }),
  create: Object.assign({}, _recordCommonSchemes.recordCommonCreateScheme, {
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.VOLUME]),
    // attributes
    status: _validatorConstants.ENUM.STRING.required(_commons.LossRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    quantity: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
    eventDate: _validatorConstants.DATE.required(),
    comment: _validatorConstants.STRING.optional(140),
    volumeUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.VOLUME)),
    volume: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    localCreator: _validatorConstants.OBJECT.INPART.optional({
      firstName: _validatorConstants.STRING.required(50),
      lastName: _validatorConstants.STRING.required(50)
    }),
    netStocktakePurchaseValue: _validatorConstants.NUMBER.optional([0]),
    netStocktakeSaleValue: _validatorConstants.NUMBER.optional([0])
  })
}), _defineProperty(_lossRecordModeScheme, _commons.RecordModeEnum.MASS, {
  update: Object.assign({}, _recordCommonSchemes.recordCommonUpdateScheme, {
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.MASS]),
    // updatable
    status: _validatorConstants.ENUM.STRING.required(_commons.LossRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    quantity: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
    eventDate: _validatorConstants.DATE.required(),
    comment: _validatorConstants.STRING.optional(140),
    scaleWeightUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    scaleWeight: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    netStocktakePurchaseValue: _validatorConstants.NUMBER.optional([0]),
    netStocktakeSaleValue: _validatorConstants.NUMBER.optional([0])
  }),
  create: Object.assign({}, _recordCommonSchemes.recordCommonCreateScheme, {
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.MASS]),
    // attributes
    status: _validatorConstants.ENUM.STRING.required(_commons.LossRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    quantity: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
    eventDate: _validatorConstants.DATE.required(),
    comment: _validatorConstants.STRING.optional(140),
    scaleWeightUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    scaleWeight: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    localCreator: _validatorConstants.OBJECT.INPART.optional({
      firstName: _validatorConstants.STRING.required(50),
      lastName: _validatorConstants.STRING.required(50)
    }),
    netStocktakePurchaseValue: _validatorConstants.NUMBER.optional([0]),
    netStocktakeSaleValue: _validatorConstants.NUMBER.optional([0])
  })
}), _defineProperty(_lossRecordModeScheme, _commons.RecordModeEnum.MASS2VOLUME, {
  update: Object.assign({}, _recordCommonSchemes.recordCommonUpdateScheme, {
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.MASS2VOLUME]),
    // updatable
    status: _validatorConstants.ENUM.STRING.required(_commons.LossRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    quantity: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
    eventDate: _validatorConstants.DATE.required(),
    comment: _validatorConstants.STRING.optional(140),
    scaleWeightUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    scaleWeight: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    closed: _validatorConstants.ENUM.BOOLEAN.required([true, false], 'validator_error_stktk_non_empty'),
    packageClosureId: _validatorConstants.INTEGER.optional([1]),
    netStocktakePurchaseValue: _validatorConstants.NUMBER.optional([0]),
    netStocktakeSaleValue: _validatorConstants.NUMBER.optional([0])
  }),
  create: Object.assign({}, _recordCommonSchemes.recordCommonCreateScheme, {
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.MASS2VOLUME]),
    // attributes
    status: _validatorConstants.ENUM.STRING.required(_commons.LossRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    quantity: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
    eventDate: _validatorConstants.DATE.required(),
    comment: _validatorConstants.STRING.optional(140),
    scaleWeightUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    scaleWeight: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    closed: _validatorConstants.ENUM.BOOLEAN.required([true, false], 'validator_error_stktk_non_empty'),
    packageClosureId: _validatorConstants.INTEGER.optional([1]),
    localCreator: _validatorConstants.OBJECT.INPART.optional({
      firstName: _validatorConstants.STRING.required(50),
      lastName: _validatorConstants.STRING.required(50)
    }),
    netStocktakePurchaseValue: _validatorConstants.NUMBER.optional([0]),
    netStocktakeSaleValue: _validatorConstants.NUMBER.optional([0])
  })
}), _lossRecordModeScheme);

var _default = (_find$destroy$findLos = {
  // non schema-related
  find: _recordCommonSchemes.recordCommonFindScheme,
  destroy: _recordCommonSchemes.recordCommonDestroyScheme,
  findLossGroupRecords: {
    domainId: _validatorConstants.INTEGER.optional([1]),

    /* FIXME:!!! mobile fallback */
    restaurantId: _validatorConstants.INTEGER.required([1]),
    lossGroupId: _validatorConstants.INTEGER.required([1])
  },
  destroyBulkGroup: _recordCommonSchemes.recordCommonDestroyBulkGroupScheme
}, _defineProperty(_find$destroy$findLos, _commons.SkuStocktakeModeEnum.QUANTITY, _defineProperty({}, _commons.RecordModeEnum.QUANTITY, lossRecordModeSchemes[_commons.RecordModeEnum.QUANTITY])), _defineProperty(_find$destroy$findLos, _commons.SkuStocktakeModeEnum.QUANTITY_VOLUME, (_SkuStocktakeModeEnum2 = {}, _defineProperty(_SkuStocktakeModeEnum2, _commons.RecordModeEnum.QUANTITY, lossRecordModeSchemes[_commons.RecordModeEnum.QUANTITY]), _defineProperty(_SkuStocktakeModeEnum2, _commons.RecordModeEnum.VOLUME, lossRecordModeSchemes[_commons.RecordModeEnum.VOLUME]), _defineProperty(_SkuStocktakeModeEnum2, _commons.RecordModeEnum.MASS2VOLUME, lossRecordModeSchemes[_commons.RecordModeEnum.MASS2VOLUME]), _SkuStocktakeModeEnum2)), _defineProperty(_find$destroy$findLos, _commons.SkuStocktakeModeEnum.VOLUME, _defineProperty({}, _commons.RecordModeEnum.VOLUME, lossRecordModeSchemes[_commons.RecordModeEnum.VOLUME])), _defineProperty(_find$destroy$findLos, _commons.SkuStocktakeModeEnum.MASS, _defineProperty({}, _commons.RecordModeEnum.MASS, lossRecordModeSchemes[_commons.RecordModeEnum.MASS])), _defineProperty(_find$destroy$findLos, _commons.SkuStocktakeModeEnum.QUANTITY_MASS, (_SkuStocktakeModeEnum5 = {}, _defineProperty(_SkuStocktakeModeEnum5, _commons.RecordModeEnum.QUANTITY, lossRecordModeSchemes[_commons.RecordModeEnum.QUANTITY]), _defineProperty(_SkuStocktakeModeEnum5, _commons.RecordModeEnum.MASS, lossRecordModeSchemes[_commons.RecordModeEnum.MASS]), _SkuStocktakeModeEnum5)), _find$destroy$findLos);

exports["default"] = _default;