import React, { Component } from 'react';
import { Creatable } from 'react-select';
import { pull } from 'lodash';
import { connect, getIn } from 'formik';
import { __ } from '@stktk/locales';

class ContextSelect extends Component {

  constructor(props) {
    super(props);
    const { formik, name, integrationInstanceContexts } = props;
    const value = getIn(formik.values, 'integrationInstanceContextId');
    const selected = integrationInstanceContexts.find(({ id }) => id === value);
    // console.log({ value, name, selected, integrationInstanceContexts });
    this.state = {
      isLoading: this.props.isLoading,
      selected: selected ? [{
        label: selected.context,
        value: selected.id.toString()
      }] : [],
      intIds: []
    };
  }

  onCreateOption = value => {
    const { formik } = this.props;
    this.setState({ isLoading: true });
    Promise.resolve(this.props.createContext(value).then(newContext => {
      if (this.props.multiple) {
        formik.setFieldValue('integrationInstanceContextId', [...this.state.intIds, newContext.id]);
        this.setState({ intIds: [...this.state.intIds, newContext.id], isLoading: false, selected: [...this.state.selected, { label: newContext.context, value: newContext.id.toString() }] });
      } else {
        formik.setFieldValue('integrationInstanceContextId', newContext.id);
        this.setState({ isLoading: false, selected: { label: newContext.context, value: newContext.id.toString() }});
      }
    }));
  }

  handleChange = (newValue, actionMeta) => {
    const { formik } = this.props;
    if (this.props.multiple)
      switch (actionMeta.action) {
        case 'select-option': {
          formik.setFieldValue('integrationInstanceContextId', [...this.state.intIds, parseInt(actionMeta.option.value)]);
          this.setState({ intIds: [...this.state.intIds, parseInt(actionMeta.option.value)], selected: [...this.state.selected, actionMeta.option] });
        }
          break;
        case 'remove-value': {
          formik.setFieldValue('integrationInstanceContextId', pull([...this.state.intIds], parseInt(actionMeta.removedValue.value)));
          this.setState({ intIds: pull([...this.state.intIds], parseInt(actionMeta.removedValue.value)), selected: pull([...this.state.selected], actionMeta.removedValue) });
        }
          break;
      }
    else {
      formik.setFieldValue('integrationInstanceContextId', parseInt(newValue.value));
      this.setState({ selected: newValue });
    }
  };

  render() {
    const { multiple, ...props } = this.props;
    const { isLoading, selected } = this.state;
    return (
      <Creatable
        isLoading={isLoading}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        options={this.props.integrationInstanceContexts.map(context => ({
          label: context.context,
          value: context.id.toString()
        }))}
        value={selected}
        isMulti={multiple}
        allowCreateWhileLoading={false}
        placeholder={__('app_ctx_select_placeholder')}
        noOptionsMessage={() => __('app_ctx_select_no_opts')}
        formatCreateLabel={contextName => __('app_ctx_select_create', { contextName })}
        onChange={this.handleChange}
        onCreateOption={this.onCreateOption}
        {...props}
      />
    );
  }
}

export default connect(ContextSelect);