import React from 'react';

export default function ListItemStat({ label, value, color }) {
  const textColor = color ? ` text-${color}` : '';
  return (
    <div className="ListItemStat" data-testid="ListItemStat">
      <div className="ListItemStat-label">{label}</div>
      <div className={`ListItemStat-value${textColor}`}>{value}</div>
    </div>
  );
}