import React from 'react';
import { Col, Row } from 'reactstrap';
import FormBarGroup from './FormBarGroup.jsx';
import FormPackagesClosureGroup from './FormPackagesClosureGroup.jsx';
import { FormGroup, Icon } from '$ui';
import { UnitEnum } from '@stktk/commons';
import { __unit } from '@stktk/locales';

export default class FormUnitGroup extends React.Component {

  validate = () => this.input.validate();

  // eslint-disable-next-line complexity
  render() {
    const {
      name,
      bar,
      amount,
      unit,
      quantity,
      closure,
      remove,
      ingredientId,
      netPurchasePrice,
      info,
      prefixGroups,
      sourceRecordset,
      suffixGroups,
      targetRecordset,
      vertical,
      outputRecordset,
      inputRecordset,
      ...props
    } = this.props;
    const Block = vertical ? props => <div {...props} /> : Row;
    const BlockItem = vertical ? props => <div {...props} /> : Col;
    return (
      <Block {...props}>
        {prefixGroups && prefixGroups.map(({ label, Element, ...props }, i) =>
          <BlockItem key={`prefixGroups-${i}`} {...props}>
            {label && <label>{label}</label>}
            {Element}
          </BlockItem>
        )}
        {name &&
          <BlockItem>
            <FormGroup
              element="ingredientLabel"
              name={name.name}
              label={name.label}
              placeholder={name.placeholder}
              value={name.value}
              disabled
              onLabelClick={name.onLabelClick}
            />
          </BlockItem>
        }
        {bar &&
          <BlockItem>
            <FormBarGroup
              {...bar}
            />
          </BlockItem>
        }
        {sourceRecordset &&
          <BlockItem>
            <FormBarGroup
              {...sourceRecordset}
            />
          </BlockItem>
        }
        {quantity &&
          <BlockItem>
            <FormGroup
              element="input"
              type="number"
              {...quantity}
            />
          </BlockItem>
        }
        {amount &&
          <BlockItem>
            <FormGroup
              element="input"
              type="number"
              step={0.01}
              {...amount}
            />
          </BlockItem>
        }
        {unit &&
          <BlockItem>
            <FormGroup
              disabled={unit.disabled}
              element="select"
              name={unit.name}
              label={unit.label}
              placeholder={unit.placeholder}
              options={
                UnitEnum.listTypeUnitsIds(unit.types).map(unitId => ({
                  value: unitId,
                  label: __unit(unitId)
                }))
              }
              onChange={unit.onChange}
            />
          </BlockItem>
        }
        {netPurchasePrice &&
          <BlockItem>
            <FormGroup
              element="input"
              type="number"
              name={netPurchasePrice.name}
              label={netPurchasePrice.label}
              placeholder={netPurchasePrice.placeholder}
            />
          </BlockItem>
        }
        {closure &&
          <BlockItem>
            <FormPackagesClosureGroup
              name={closure.name}
              nameId={closure.nameId}
            />
          </BlockItem>
        }
        {targetRecordset &&
          <BlockItem>
            <FormBarGroup
              recordsets={targetRecordset.recordsets}
              name={targetRecordset.name}
              label={targetRecordset.label}
              placeholder={targetRecordset.placeholder}
            />
          </BlockItem>
        }
        {outputRecordset &&
          <BlockItem>
            <FormBarGroup
              recordsets={outputRecordset.recordsets}
              name={outputRecordset.name}
              label={outputRecordset.label}
              placeholder={outputRecordset.placeholder}
            />
          </BlockItem>
        }
        {inputRecordset &&
          <BlockItem>
            <FormBarGroup
              bars={inputRecordset.bars}
              recordsets={inputRecordset.recordsets}
              name={inputRecordset.name}
              label={inputRecordset.label}
              placeholder={inputRecordset.placeholder}
            />
          </BlockItem>
        }
        {info &&
          <BlockItem style={{ flexGrow: 0, display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingLeft: '0px' }}>
            <label style={{ visibility: 'hidden' }}>{info.label}</label>
            <div style={{ marginBottom: '1rem', height: '100%', display: 'flex' }}>
              <span style={{ alignSelf: 'center' }}>
                <Icon icon="fas fa-info-circle" onClick={() => info.showInfo(info.value)} />
              </span>
            </div>
          </BlockItem>
        }
        {suffixGroups && suffixGroups.map(({ label, Element, ...props }, i) =>
          <BlockItem key={`suffixGroup-${i}`} {...props}>
            {label && <label>{label}</label>}
            {Element}
          </BlockItem>
        )}
        {remove &&
          <BlockItem style={{ flexGrow: 0, display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingLeft: '0px' }}>
            <label style={{ visibility: 'hidden' }}>{remove.label}</label>
            <div style={{ marginBottom: '1rem', height: '100%', display: 'flex' }}>
              <span style={{ alignSelf: 'center' }}>
                <Icon icon="fas fa-times" onClick={() => remove.removeItem()} />
              </span>
            </div>
          </BlockItem>
        }
      </Block>
    );
  }

}