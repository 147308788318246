import React, { Component } from 'react';
import { connect } from 'formik';
import { map, find } from 'lodash';
import { Col, Row, FormGroup } from 'reactstrap';
import { getTotalSkuIngredientsSkuPortionPrice } from '@stktk/commons';
import { __ } from '@stktk/locales';
import { Icon } from '$ui';
import Input from '$ui/form/controls/Input';

class FormCalcIngredientsTotal extends Component {
  componentDidUpdate() {
    this.calculateTotal();
  }

  calculateTotal = () => {
    const { formik, ingredients, skus, dispatchCalculations, value } = this.props;
    let ingredientsTotal = null;
    try {
      ingredientsTotal = getTotalSkuIngredientsSkuPortionPrice(
        map(formik.values.ingredients, ingredient =>
          ({ ...ingredient, ingredient: find(ingredients, { id: ingredient.ingredientId }) })
        ),
        skus,
        'netPurchasePrice'
      );
    } catch (error) {
      // Ignore error
    }
    if (value !== ingredientsTotal)
      dispatchCalculations(() => ({ ingredientsTotal }));
  }

  display = () => {
    const { value } = this.props;
    return value !== null ? value.toFixed(2) : '-';
  }

  render() {
    return (
      <Row>
        <Col />
        <Col />
        <Col />
        <Col>
          <FormGroup>
            <label>{__('general_price_total')}</label>
            <Input disabled value={this.display()} />
          </FormGroup>
        </Col>
        <Col style={{ flexGrow: 0, display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingLeft: '0px' }}>
          <div style={{ marginBottom: '1rem', height: '100%', display: 'flex', visibility: 'hidden' }}>
            <span style={{ alignSelf: 'center' }}>
              <Icon icon="fas fa-times" />
            </span>
          </div>
        </Col>
      </Row>
    );
  }
}

export default connect(FormCalcIngredientsTotal);