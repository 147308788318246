"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _validatorConstants = require("../validator/validatorConstants.js");

var _commons = require("@stktk/commons");

var _default = {
  find: {
    restaurantId: _validatorConstants.INTEGER.required([1]),
    id: _validatorConstants.INTEGER.optional([1])
  },
  create: {
    restaurantId: _validatorConstants.INTEGER.required([1]),
    comment: _validatorConstants.STRING.optional(140),
    stockcheck1Id: _validatorConstants.INTEGER.required([1]),
    stockcheck2Id: _validatorConstants.INTEGER.required([1]),
    type: _validatorConstants.ENUM.STRING.required(_commons.ReportTypeEnum.toArray())
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    comment: _validatorConstants.STRING.required(140)
  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  getData: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1])
  },
  getPricingOrderRecords: {
    restaurantId: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    rangeFromDate: _validatorConstants.DATE_RANGE.required(['rangeFromDate', 'rangeToDate'], 30 * 1000 * 24 * 60 * 60),
    // 30 days
    rangeToDate: _validatorConstants.DATE_RANGE.required(['rangeFromDate', 'rangeToDate'], 30 * 1000 * 24 * 60 * 60) // 30 days

  }
};
exports["default"] = _default;