import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import CountUp from 'react-countup';
import { Icon } from '$ui';

const CardSummary = ({ title, color, value, icon, suffix, prefix }) => {
  return (
    <Card style={{}} className="h-100">
      <CardBody className="position-relative" style={{ display: 'flex' }}>
        <Row style={{ alignSelf: 'center', width: '100%' }}>
          <Col className="col-sm-8">
            <div className='display-4 fs-2 font-weight-normal text-sans-serif'>
              {title}
            </div>
            <span className={`display-4 fs-3 font-weight-normal text-sans-serif text-${color}`}>
              <CountUp
                start={0}
                end={value}
                duration={1}
                separator=" "
                decimals={2}
                decimal="."
                suffix={suffix}
                prefix={prefix}
              />
            </span>
          </Col>
          <Col className="col-sm-4" style={{ textAlign: 'right', alignSelf: 'center' }}>
            <Icon icon={icon} className='fa-3x' />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CardSummary;