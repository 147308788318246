import React, { Fragment, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { __ } from '@stktk/locales';
import { Button, Icon } from '$ui';
import Input from '$ui/form/controls/Input';

const FileDropzoneFile = ({ file, onChange }) => {
  const getFileExtensionIcon = name => {
    let split = name.split('.');
    let extension = split[split.length - 1];
    if (extension === 'xlsx')
      return 'file-excel';
    return 'file-code';
  };

  let fileName = file.path || file.name;
  return (
    <p className="FileDropzone-files">
      <Icon icon={getFileExtensionIcon(fileName)} />
      {' '}
      {fileName}
      {' '}
      <span style={{ position: 'absolute', right: '3rem' }} ><Icon icon="fas fa-times" onClick={() => onChange(null)} /></span>
    </p>
  );
};

export default function FileDropzone({ files = [], accept, onChange, multiple = false, onDropRejected, inputProps = {}}) {
  const { /*acceptedFiles,*/
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject } = useDropzone({
    accept,
    onDrop: onChange,
    multiple,
    onDropRejected
  });

  const className = useMemo(() => classNames('FileDropzone', {
    'FileDropzone--active': isDragActive,
    'FileDropzone--accept': isDragAccept,
    'FileDropzone--reject': isDragReject
  }), [
    isDragActive,
    isDragAccept,
    isDragReject
  ]);

  return (
    <div {...getRootProps({ className })}>
      <Input isRaw {...getInputProps()} {...inputProps} />
      {files.map((file, index) =>
        <FileDropzoneFile key={`file-${index}`} file={file} onChange={onChange} />
      )}
      {(multiple || files.length === 0) && (
        <Fragment>
          <p>
            {isDragActive ?
              __('app_file_dropzone_drag') :
              __('app_file_dropzone_instructions')
            }
          </p>
          <Button
            variant="light"
            type="button"
            icon="file-upload"
            text={__('app_file_dropzone_button')}
          />
        </Fragment>
      )}
    </div>
  );
}