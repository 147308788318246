"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _validatorConstants = require("../validator/validatorConstants.js");

var _default = {
  exitTestMode: {
    domainId: _validatorConstants.INTEGER.required([1]),
    userId: _validatorConstants.INTEGER.required([1])
  },
  enterTestMode: {
    domainId: _validatorConstants.INTEGER.required([1]),
    userId: _validatorConstants.INTEGER.required([1])
  },
  find: {
    id: _validatorConstants.INTEGER.required([1])
  },
  forgot: {
    email: _validatorConstants.EMAIL.required(100)
  },
  login: {
    email: _validatorConstants.EMAIL.required(100),
    password: _validatorConstants.STRING.required(255)
  },
  signup: {
    terms: _validatorConstants.ENUM.BOOLEAN.required([true], 'validator_error_stktk_terms'),
    marketing: _validatorConstants.ENUM.BOOLEAN.required([true, false]),
    email: _validatorConstants.EMAIL.required(100),
    password: _validatorConstants.STRING.required([5]),
    cpassword: _validatorConstants.ENUM.STRING.required([(0, _validatorConstants.REF)('password')], 'validator_error_stktk_password_match')
  },
  updateInvitee: {
    id: _validatorConstants.INTEGER.required([1]),
    hash: _validatorConstants.STRING.required([1]),
    firstName: _validatorConstants.STRING.required(50),
    lastName: _validatorConstants.STRING.required(75),
    password: _validatorConstants.STRING.required(255),
    cpassword: _validatorConstants.ENUM.STRING.required([(0, _validatorConstants.REF)('password')], 'validator_error_stktk_password_match'),
    terms: _validatorConstants.ENUM.BOOLEAN.required([true], 'validator_error_stktk_terms'),
    marketing: _validatorConstants.ENUM.BOOLEAN.required([true, false])
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    firstName: _validatorConstants.STRING.optional(50),
    lastName: _validatorConstants.STRING.optional(75),
    language: _validatorConstants.STRING.optional(10),
    tags: _validatorConstants.ARRAY.optional(_validatorConstants.INTEGER.required([1]))
  },
  changePassword: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    virtualOldPassword: _validatorConstants.STRING.required(),
    virtualNewPassword: _validatorConstants.STRING.required([5]),
    virtualNewCPassword: _validatorConstants.ENUM.STRING.required([(0, _validatorConstants.REF)('virtualNewPassword')], 'validator_error_stktk_password_match')
  },
  changePasswordHash: {
    hash: _validatorConstants.STRING.required([1]),
    password: _validatorConstants.STRING.required([5]),
    cpassword: _validatorConstants.ENUM.STRING.required([(0, _validatorConstants.REF)('password')], 'validator_error_stktk_password_match')
  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1])
  }
};
exports["default"] = _default;