import React, { useEffect, useRef, useContext } from 'react';
import { Collapse, Nav, Navbar } from 'reactstrap';
import classNames from 'classnames';
import { get, pickBy, keys } from 'lodash';
import Flex from '$ui/Flex';
import Logo from '$ui/navbar/Logo';
import NavbarVerticalMenu from '$ui/navbar/NavbarVerticalMenu';
import ToggleButton from '$ui/navbar/ToggleButton';
import { useNavbarVerticalHovered, useNavbarVerticalCollapsed } from '$hooks/ui';
import { useRoutes } from '$hooks/routes';
import { UiContext } from '$providers/UiProvider';

const topNavbarBreakpoint = 'lg';

const navbarBreakPoint = 'xl';

const navbarStyle = 'transparent';

const NavbarVertical = () => {
  const navBarRef = useRef(null);
  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useNavbarVerticalCollapsed();
  const [isNavbarVerticalHovered, setNavbarVerticalHovered] = useNavbarVerticalHovered();
  const [{ showBurgerMenu, notice }] = useContext(UiContext);
  const routes = useRoutes();
  const noticeHeight = get(keys(pickBy(notice, show => show === true)), 'length', 0) * 44;

  useEffect(() => {
    return () => {
      setNavbarVerticalHovered(false);
    };
  }, [isNavbarVerticalCollapsed]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        setNavbarVerticalHovered(true);
      }, 100);
    }
  };
  return (
    <Navbar
      light
      style={{ top: noticeHeight }}
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical', 'navbar-glass')}
    >
      <Flex align="center">
        <ToggleButton
          isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
          setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
        />
        <Logo at="navbar-vertical" />
      </Flex>

      <Collapse
        navbar
        isOpen={showBurgerMenu}
        innerRef={navBarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          clearTimeout(time);
          setNavbarVerticalHovered(false);
        }}
      >
        <Nav navbar vertical>
          <NavbarVerticalMenu routes={routes} />
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarVertical;