"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ValidationErrorEnum = void 0;
Object.defineProperty(exports, "ValidationPlatformEnum", {
  enumerable: true,
  get: function get() {
    return _index.ValidationPlatformEnum;
  }
});
exports.getValidationScheme = getValidationScheme;
exports.getWrappedArrayValidationScheme = getWrappedArrayValidationScheme;
exports.isAllowed = isAllowed;
exports.parseWithScheme = parseWithScheme;
exports.stripUnknownWithScheme = stripUnknownWithScheme;
exports.validate = validate;
exports.verifySchemeName = void 0;

var _validatorConstants = require("./validator/validatorConstants.js");

var _utils = require("./utils.js");

var _index = require("./validation/index.js");

var _index2 = require("./schemes/index.js");

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

//TODO: curry exported functions?
var ValidationErrorEnum = {
  MISSING_VALIDATION: 'Missing validation',
  UNSPECIFIED_VALIDATION: 'Unspecified validation'
}; // eslint-disable-next-line complexity

exports.ValidationErrorEnum = ValidationErrorEnum;

var verifySchemeName = function verifySchemeName(platform, schemeName) {
  var error = null,
      scheme = null;
  if (!_typeof(schemeName) == 'string' || !schemeName) error = ValidationErrorEnum.UNSPECIFIED_VALIDATION;
  scheme = (0, _utils.getValidationScheme)((0, _index2.getSchemes)(), platform, schemeName);
  if (!scheme) error = ValidationErrorEnum.MISSING_VALIDATION;
  return {
    error: error,
    scheme: scheme
  };
};

exports.verifySchemeName = verifySchemeName;

function isAllowed(platform, schemeName) {
  var _verifySchemeName = verifySchemeName(platform, schemeName),
      scheme = _verifySchemeName.scheme;

  return !!scheme;
}

function getValidationScheme(platform, schemeName) {
  var _verifySchemeName2 = verifySchemeName(platform, schemeName),
      error = _verifySchemeName2.error,
      scheme = _verifySchemeName2.scheme;

  return error || scheme;
}

function validate(data, platform, schemeName) {
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  var _verifySchemeName3 = verifySchemeName(platform, schemeName),
      error = _verifySchemeName3.error,
      scheme = _verifySchemeName3.scheme;

  return error || (0, _index.validate)(data, platform, scheme, Object.assign({
    strict: true,
    stripUnknown: false
  }, options));
}

function parseWithScheme(data, platform, schemeName) {
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  var _verifySchemeName4 = verifySchemeName(platform, schemeName),
      error = _verifySchemeName4.error,
      scheme = _verifySchemeName4.scheme;

  return error || (0, _index.parseWithScheme)(data, platform, scheme, options);
}

function stripUnknownWithScheme(data, platform, schemeName) {
  var _verifySchemeName5 = verifySchemeName(platform, schemeName),
      error = _verifySchemeName5.error,
      scheme = _verifySchemeName5.scheme;

  return error || (0, _index.stripUnknownWithScheme)(data, platform, scheme);
}

function getWrappedArrayValidationScheme(platform, schemeName) {
  var _verifySchemeName6 = verifySchemeName(platform, schemeName),
      error = _verifySchemeName6.error,
      scheme = _verifySchemeName6.scheme;

  return error || _validatorConstants.OBJECT.schema({
    array: scheme
  });
}