"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTotalSkuIngredientsSkuPortionPrice = exports.getTotalSkuIngredientSkuPortionPrice = exports.getTotalSkuIngredientSkuNormalizedAmount = exports.getSkuPortionUnitTypes = exports.getSkuIngredientSkuPortionPrice = exports.getSkuIngredientSkuNormalizedAmount = void 0;

var _constants = require("./constants");

var _skuUtils = require("./skuUtils");

var _commonUtils = require("./commonUtils.js");

var _lodash = require("lodash");

// eslint-disable-next-line complexity
var getSkuIngredientSkuNormalizedAmount = function getSkuIngredientSkuNormalizedAmount(skuIngredient, sku) {
  var stocktakeMode = sku.stocktakeMode;
  var ingredientAmount = skuIngredient.ingredientAmount,
      ingredientAmountUnitId = skuIngredient.ingredientAmountUnitId;

  var ingredientUnitType = _constants.UnitEnum.getUnitType(ingredientAmountUnitId);

  var measurement = null;

  switch (ingredientUnitType) {
    case _constants.UnitTypeEnum.ITEM:
      return ingredientAmount;

    case _constants.UnitTypeEnum.WEIGHT:
      if ([_constants.SkuStocktakeModeEnum.QUANTITY_MASS, _constants.SkuStocktakeModeEnum.MASS].includes(stocktakeMode)) {
        measurement = (0, _skuUtils.getSkuMeasureMeasurement)(sku, _constants.SkuMeasureEnum.PRODUCT_WEIGHT);
        return (0, _commonUtils.convertAmount)(ingredientAmount, ingredientAmountUnitId, measurement.unit.id) / measurement.amount;
      } else return null;

    case _constants.UnitTypeEnum.VOLUME:
      if ([_constants.SkuStocktakeModeEnum.QUANTITY_VOLUME, _constants.SkuStocktakeModeEnum.VOLUME].includes(stocktakeMode)) {
        measurement = (0, _skuUtils.getSkuMeasureMeasurement)(sku, _constants.SkuMeasureEnum.VOLUME);
        return (0, _commonUtils.convertAmount)(ingredientAmount, ingredientAmountUnitId, measurement.unit.id) / measurement.amount;
      } else return null;

    default:
      return null;
  }
};

exports.getSkuIngredientSkuNormalizedAmount = getSkuIngredientSkuNormalizedAmount;

var getTotalSkuIngredientSkuNormalizedAmount = function getTotalSkuIngredientSkuNormalizedAmount(skuIngredient, sku) {
  return getSkuIngredientSkuNormalizedAmount(skuIngredient, sku);
};

exports.getTotalSkuIngredientSkuNormalizedAmount = getTotalSkuIngredientSkuNormalizedAmount;

var getSkuIngredientSkuPortionPrice = function getSkuIngredientSkuPortionPrice(skuIngredient, sku, skuValueProperty) {
  var skus = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  var price = null;

  if (sku.type === _constants.SkuTypeEnum.COMPOUND) {
    price = 0;

    var _sku = (0, _lodash.find)(skus, {
      id: sku.id
    });

    _sku.ingredients.forEach(function (_ingredient) {
      price += getSkuIngredientSkuPortionPrice(_ingredient, _ingredient.ingredient, skuValueProperty, skus);
    });
  } else price = (0, _skuUtils.getSkuDetails)(sku, "domain")[skuValueProperty];

  var amount = getSkuIngredientSkuNormalizedAmount(skuIngredient, sku);
  return amount * price;
};

exports.getSkuIngredientSkuPortionPrice = getSkuIngredientSkuPortionPrice;

var getTotalSkuIngredientsSkuPortionPrice = function getTotalSkuIngredientsSkuPortionPrice(ingredients, skus, skuValueProperty) {
  return (0, _lodash.sum)(ingredients.map(function (ingredient) {
    return getSkuIngredientSkuPortionPrice(ingredient, ingredient.ingredient, skuValueProperty, skus);
  }));
};

exports.getTotalSkuIngredientsSkuPortionPrice = getTotalSkuIngredientsSkuPortionPrice;

var getTotalSkuIngredientSkuPortionPrice = function getTotalSkuIngredientSkuPortionPrice(skuIngredient, sku, skuValueProperty, skus) {
  return getSkuIngredientSkuPortionPrice(skuIngredient, sku, skuValueProperty, skus);
}; // eslint-disable-next-line complexity


exports.getTotalSkuIngredientSkuPortionPrice = getTotalSkuIngredientSkuPortionPrice;

var getSkuPortionUnitTypes = function getSkuPortionUnitTypes(_ref) {
  var stocktakeMode = _ref.stocktakeMode;

  switch (stocktakeMode) {
    case _constants.SkuStocktakeModeEnum.QUANTITY:
      return [_constants.UnitTypeEnum.ITEM];

    case _constants.SkuStocktakeModeEnum.QUANTITY_VOLUME:
      return [_constants.UnitTypeEnum.VOLUME, _constants.UnitTypeEnum.ITEM];

    case _constants.SkuStocktakeModeEnum.VOLUME:
      return [_constants.UnitTypeEnum.VOLUME];

    case _constants.SkuStocktakeModeEnum.QUANTITY_MASS:
      return [_constants.UnitTypeEnum.WEIGHT, _constants.UnitTypeEnum.ITEM];

    case _constants.SkuStocktakeModeEnum.MASS:
      return [_constants.UnitTypeEnum.WEIGHT];

    default:
      return null;
  }
};

exports.getSkuPortionUnitTypes = getSkuPortionUnitTypes;