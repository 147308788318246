import React from 'react';
import { __ } from '@stktk/locales';
import { Icon, InfoTooltip } from '$ui';
import TableFilterInput from '$ui/table/TableFilterInput';

export default function TableTh({ column, ...props }) {
  return (
    <div className="Table-th" {...props} {...column.getHeaderProps()}>
      <div
        className="d-flex flex-row justify-content-between min-w-0"
        {...(column.canSort ? { ...column.getSortByToggleProps({ title: __('general_sort_by'), 'data-testid': column.sortTestId }) } : {})}
      >
        <span className="d-inline-block text-overflow-ellipsis">
          {column.render('Header')}
          {column.headerTooltip &&
            <InfoTooltip info={column.headerTooltip} />
          }
        </span>
        <div className="d-inline-block">
          {column.filterValue && (
            <Icon
              className="d-inline-block ml-2"
              icon="filter"
              type="regular"
              variant="mute"
              size="sm"
            />
          )}
          {column.canSort && (
            <Icon
              className="d-inline-block ml-2"
              icon={column.isSorted ? column.isSortedDesc ? 'sort-down' : 'sort-up' : 'sort'}
              type="duotone"
              variant="mute"
            />
          )}
        </div>
      </div>
      {column.canFilter && column.filterType === 'text' && (<div><TableFilterInput column={column} /></div>)}
      {column.canResize && (
        <div
          {...column.getResizerProps()}
          className={`resizer ${
            column.isResizing ? 'isResizing' : ''
          }`}
        />
      )}
    </div>
  );
}