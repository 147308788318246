"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _recordsetCommonSchemes = require("./_recordsetCommonSchemes.js");

var _validatorConstants = require("../validator/validatorConstants.js");

var _commons = require("@stktk/commons");

var _default = {
  find: Object.assign({}, _recordsetCommonSchemes.recordsetCommonFindScheme, {
    orderGroupId: _validatorConstants.INTEGER.optional([1])
  }),
  create: Object.assign({}, _recordsetCommonSchemes.recordsetCommonCreateScheme, {
    orderGroupId: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    supplierId: _validatorConstants.INTEGER.required([1]),
    barId: _validatorConstants.INTEGER.required([1]),
    status: _validatorConstants.ENUM.INTEGER.required(_commons.OrderStatusEnum.toArray()),
    placedDate: (0, _validatorConstants.WHEN)('status', {
      is: function is(status) {
        return status >= _commons.OrderStatusEnum.PLACED;
      },
      then: _validatorConstants.DATE.optional(),
      otherwise: _validatorConstants.DATE.optional()
    }),
    shippedDate: (0, _validatorConstants.WHEN)('status', {
      is: function is(status) {
        return status >= _commons.OrderStatusEnum.SENT;
      },
      then: _validatorConstants.DATE.required(),
      otherwise: _validatorConstants.DATE.optional()
    }),
    receivedDate: (0, _validatorConstants.WHEN)('status', {
      is: function is(status) {
        return status >= _commons.OrderStatusEnum.RECEIVED;
      },
      then: _validatorConstants.DATE.required(),
      otherwise: _validatorConstants.DATE.optional()
    }),
    finishedDate: (0, _validatorConstants.WHEN)('status', {
      is: function is(status) {
        return status >= _commons.OrderStatusEnum.FINISHED;
      },
      then: _validatorConstants.DATE.optional(),
      otherwise: _validatorConstants.DATE.optional()
    }),
    approved: _validatorConstants.BOOLEAN.optional()
  }),
  update: Object.assign({}, _recordsetCommonSchemes.recordsetCommonUpdateScheme, {
    orderGroupId: _validatorConstants.INTEGER.required([1]),
    barId: _validatorConstants.INTEGER.optional([1]),
    status: _validatorConstants.ENUM.INTEGER.optional(_commons.OrderStatusEnum.toArray()),
    placedDate: _validatorConstants.DATE.optional(),
    shippedDate: _validatorConstants.DATE.optional(),
    receivedDate: _validatorConstants.DATE.optional(),
    finishedDate: _validatorConstants.DATE.optional(),
    approved: _validatorConstants.BOOLEAN.optional()
  }),
  destroy: Object.assign({}, _recordsetCommonSchemes.recordsetCommonDestroyScheme),
  approve: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  }
};
exports["default"] = _default;