import React from 'react';
import { useDrag } from 'react-dnd';
import classNames from 'classnames';
import Icon from '$ui/Icon';

export default function TableDraggableCell({ item, type }) {
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type,
    item,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  }), [JSON.stringify(item)]);
  return (
    <div ref={drag} className={classNames({ 'bg-100': isDragging, 'overflow-hidden': !isDragging })}>
      <Icon
        icon={'ellipsis-v'}
        type="solid"
      />
      <span ref={dragPreview} className="d-inline-block ml-5" style={{ width: 800 }}>drag</span>
    </div>
  );
}