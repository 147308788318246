import classNames from 'classnames';
import React from 'react';
import { Badge } from 'reactstrap';

export default function Badges({ items = [], className, ...props }) {
  return (
    <div className="d-flex flex-row">
      {items.map(({ id, label, rgbColor }, index) => (
        <Badge
          key={`badge-${id || index}`}
          pill
          tag="div"
          className={classNames('d-block mr-2', className)}
          style={{ backgroundColor: rgbColor || 'gray', fontSize: '11px' }}
          {...props}
        >
          {label}
        </Badge>
      ))}
    </div>
  );
}