import React from 'react';
import Button from '$ui/Button';
import { useLoading } from '$hooks/ui';
import { globalHandleError } from '$utils/error';
import { useModal, ModalTypeEnum } from '$hooks/modal';

export default function ButtonDataModal({ modalId = 'generalDataModal', data, dataPromise, ...props }) {
  const [/*modal*/, setModal] = useModal(ModalTypeEnum.DATA, modalId);
  const [isLoading, setLoading] = useLoading(modalId);
  const handleClick = () => {
    if (dataPromise) {
      setLoading(true);
      return dataPromise().then(result => {
        setLoading(false);
        setModal({ isOpen: true, data: { ...result, ...data }});
      }).catch(error => {
        setLoading(false);
        globalHandleError(error);
      });
    }
    return setModal({ isOpen: true, data });
  };
  return (
    <Button isLoading={isLoading} onClick={handleClick} {...props} />
  );
}