import React from 'react';
import Button from '$ui/Button';
import { useLoading } from '$hooks/ui';
import { useModal, ModalTypeEnum } from '$hooks/modal';
import { globalHandleError } from '$utils/error';

export default function ButtonFormModal({ formId = 'GlobalFormModal', scheme, onSubmit, data = {}, item, dataPromise, onClick, ...props }) {
  const [/*modal*/, setModal] = useModal(ModalTypeEnum.FORM, formId);
  const [isLoading, setLoading] = useLoading(formId);
  const handleClick = () => {
    if (onClick)
      onClick();
    if (dataPromise) {
      setLoading(true);
      return dataPromise().then(result => {
        setLoading(false);
        setModal({ isOpen: true, scheme, data: { ...data, ...result }, ...(item ? { item } : {}), onSubmit });
      }).catch(error => {
        setLoading(false);
        globalHandleError(error);
      });
    }
    return setModal({ isOpen: true, scheme, data, ...(item ? { item } : {}), onSubmit });
  };
  return (
    <Button isLoading={isLoading} onClick={handleClick} {...props} />
  );
}