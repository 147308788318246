"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertAmount = void 0;

var _constants = require("./constants.js");

var convertAmount = function convertAmount(amount, unit1Id, unit2Id) {
  return unit1Id && unit2Id ? amount * (_constants.UnitEnum.get(unit1Id).typeMultiplier / _constants.UnitEnum.get(unit2Id).typeMultiplier) : null;
};

exports.convertAmount = convertAmount;