import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import React from 'react';
import { each, filter, map, flatten, uniqBy } from 'lodash';
import { __ } from '@stktk/locales';
import { ExportType } from '@stktk/logic/utils/export';
import Input from '$ui/form/controls/Input';
import Select from '$ui/form/controls/Select';
import TableFilterDropdown from '$ui/table/TableFilterDropdown';
import TableColumnsDnd from '$ui/table/TableColumnsDnd';
import TableActionBarButton from '$ui/table/TableActionBarButton';
import { Icon } from '$ui';

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter
}) {
  const count = preGlobalFilteredRows.length;

  return (
    <InputGroup id={'TableActionBar-GlobalFilter-InputGroup'} size="sm" className="GlobalFilter">
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <Icon icon="search" />
        </InputGroupText>
      </InputGroupAddon>
      <Input
        id={'TableActionBar-GlobalFilter-Input'}
        bsSize="sm"
        value={globalFilter || ''}
        placeholder={__('placeholder_search_records', { count })}
        onChange={e => {
          setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    </InputGroup>
  );
}

function getDataFromFlatRows (rows) {
  return flatten(rows.map(row => row.leafRows ? getDataFromFlatRows(row.leafRows) : row.original));
}

const TableActionBar = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  selectedFlatRows,
  rowActions,
  setAllFilters,
  filters,
  /*rowGroupActions,*/
  allColumns,
  visibleColumns,
  setHiddenColumns,
  setColumnOrder,
  technicalExport,
  technicalExportTable,
  exportTable,
  technicalXlsxExporter,
  xlsxExporter,
  csvExporter,
  txtExporter,
  pdfExporter,
  rowUniqKey,
  defaultVisibleColumns
}) => {

  let selectedData = uniqBy(getDataFromFlatRows(selectedFlatRows), rowUniqKey);
  return (
    <div className="TableActionBar">
      <div id={'TableActionBar-select'} className="TableActionBar-select">
        {rowActions.filter(({ hide = [] }) => !hide.includes('table')).map(({ name, label, icon, color, action, minItems = 1, maxItems = 1, disabled: disable }, i) => {
          let disabled = true, data = null;
          let idx = `TableActionBar-Button-${icon}`;
          if (selectedData.length <= maxItems && selectedData.length >= minItems) {
            data = selectedData.length === 1 ? selectedData[0] : selectedData;
            disabled = disable(data);
          }
          return (
            <TableActionBarButton
              key={i}
              id={idx}
              original={data}
              disabled={disabled}
              index={i}
              rowActions={rowActions}
            />
          );
        }
        )}
      </div>
      <div id={'TableActionBar-search'} className="TableActionBar-search">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <div id={'TableActionBar-settings'} className="TableActionBar-settings">
        {
          filter(allColumns, 'canGroupBy').length > 0 &&
            <UncontrolledDropdown id={'TableActionBar-GroupBy-Dropdown'}>
              <DropdownToggle
                caret
                color="light"
                size="sm"
              >
                <span>
                  <Icon
                    type="light"
                    icon="object-group"
                    style={{ marginRight: '5px' }}
                  />
                  {' '}
                  {__('general_group_by')}
                </span>
              </DropdownToggle>
              <DropdownMenu right style={{ padding: 10, overflow: 'visible' }}>
                <Select
                  isMulti
                  id={'TableActionBar-GroupBy-Select'}
                  styles={{
                    container: provided => ({
                      ...provided,
                      minWidth: '200px',
                      fontSize: '0.875rem'
                    }),
                    menu: provided => ({ ...provided, margin: 0 })
                  }}
                  value={map(filter(allColumns, 'isGrouped'), column => ({ label: column.DropdownHeader || column.Header, value: column.id }))} // FIXME: create DropdownHeader prop in outer objects
                  options={map(filter(allColumns, 'canGroupBy'), column => ({ label: column.DropdownHeader || column.Header, value: column.id }))}// FIXME: create DropdownHeader prop in outer objects
                  onChange={selectedColumns => {
                    const selectedColumnsIds = map(selectedColumns, 'value');
                    each(filter(allColumns, 'canGroupBy'), column => {
                      if ((column.isGrouped && selectedColumnsIds.indexOf(column.id) < 0) || (!column.isGrouped && selectedColumnsIds.indexOf(column.id) > -1))
                        column.toggleGroupBy();
                    });
                  }}
                />
              </DropdownMenu>
            </UncontrolledDropdown>
        }
        {filter(allColumns, 'canFilter').length > 0 &&
          <TableFilterDropdown allColumns={allColumns} filters={filters} setAllFilters={setAllFilters} />
        }
        {
          !defaultVisibleColumns &&
            <TableColumnsDnd
              hiddenColumns={map(filter(allColumns, ({ isVisible }) => !isVisible), ({ id, label, DropdownHeader, Header, isHideable }) => ({ id, label: label || DropdownHeader || Header, isHideable }))} // FIXME: create DropdownHeader prop in outer objects (use label?)
              visibleColumns={map(filter(visibleColumns, ({ id, isVisible }) => isVisible && id !== 'selection'), ({ id, label, DropdownHeader, Header, isHideable }) => ({ id, label: label || DropdownHeader || Header, isHideable }))} // FIXME: create DropdownHeader prop in outer objects (user label?)
              onChange={(visibleColumns, hiddenColumns) => {
                setColumnOrder(visibleColumns);
                setHiddenColumns(hiddenColumns);
              }}
            />
        }
        {
          technicalExport &&
            <UncontrolledDropdown className="exportDataButton" data-testid="TechnicalExportDataButton">
              <DropdownToggle caret color="success" size="sm">
                <span>
                  <Icon type="light" icon="download" />
                  {' '}
                  {__('general_technical_export_data')}
                </span>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem style={{ cursor: 'pointer' }} onClick={() => technicalExportTable(ExportType.XLSX, technicalXlsxExporter)}>
                  <Icon style={{ marginRight: 5 }} type="light" icon="file-excel" />
                  {__('file_xlsx')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
        }
        <UncontrolledDropdown className="exportDataButton" data-testid="ExportDataButton">
          <DropdownToggle caret color="success" size="sm">
            <span>
              <Icon type="light" icon="download" />
              {' '}
              {__('general_export_data')}
            </span>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem data-testid="ExportItemXLSX" style={{ cursor: 'pointer' }} onClick={() => exportTable(ExportType.XLSX, xlsxExporter)}>
              <Icon style={{ marginRight: 5 }} type="light" icon="file-excel" />
              {__('file_xlsx')}
            </DropdownItem>
            <DropdownItem data-testid="ExportItemCSV" style={{ cursor: 'pointer' }} onClick={() => exportTable(ExportType.CSV, csvExporter)}>
              <Icon style={{ marginRight: 5 }} type="light" icon="file-code" />
              {__('file_csv')}
            </DropdownItem>
            <DropdownItem data-testid="ExportItemTXT" style={{ cursor: 'pointer' }} onClick={() => exportTable(ExportType.TXT, txtExporter)}>
              <Icon style={{ marginRight: 5 }} type="light" icon="file-alt" />
              {__('file_txt')}
            </DropdownItem>
            {pdfExporter && (
              <DropdownItem data-testid="ExportItemPDF" style={{ cursor: 'pointer' }} onClick={() => exportTable(ExportType.PDF, pdfExporter)}>
                <Icon style={{ marginRight: 5 }} type="light" icon="file-pdf" />
                {__('file_pdf')}
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </div>
  );
};

export default TableActionBar;