import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';

const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

function makeid(length) {
  let text = '';
  for (let i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
}

export default class Icon extends Component {

  constructor(props) {
    super(props);
    this.id = props.id || 'icon' + makeid(20);
    this.state = {
      tooltipOpen: false
    };
  }

  toggleTooltip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  /* eslint-disable-next-line complexity */
  render() {
    let {
      id,
      className,
      to,
      href,
      icon,
      type,
      size,
      animation,
      onClick,
      tooltip,
      tooltipPlacement = 'bottom',
      variant,
      disabled,
      ...props
    } = this.props;
    if (disabled)
      variant = 'muted';
    let iconSize = size || 'lg';
    let iconType = 'far';
    switch (type) {
      case 'regular': iconType = 'far'; break;
      case 'light': iconType = 'fal'; break;
      case 'solid': iconType = 'fas'; break;
      case 'brand': iconType = 'fab'; break;
      case 'duotone': iconType = 'fad'; break;
    }
    let iconClass = iconType + ' fa-' + iconSize + ' fa-' + icon;
    if (animation)
      iconClass += ' fa-' + animation;
    let combineClass = 'Icon' + (variant ? (' icon-' + variant) : '') + (className ? ' ' + className : '');
    let component = null;

    if (to && !disabled)
      component = <Link id={this.id} to={to} className={combineClass} {...props}><i className={iconClass} /></Link>;
    else if (href && !disabled)
      component = <a id={this.id} href={href} className={combineClass} {...props}><i className={iconClass} /></a>;
    else if (onClick && !disabled)
      component = <a
        id={this.id} href="#" className={combineClass} onClick={e => { e.preventDefault(); return onClick(e); }}
        {...props}
      >
        <i className={iconClass} />
      </a>;
    else
      component = <i id={this.id} {...props} className={(combineClass + ' ' + iconClass)} />;
    return (
      <span>
        {component}
        {tooltip ?
          <Tooltip
            boundariesElement="body" isOpen={this.state.tooltipOpen} toggle={this.toggleTooltip} autohide={false}
            delay={{ show: 0, hide: 0 }} placement={tooltipPlacement} target={this.id}
          >
            {tooltip}
          </Tooltip>
          :
          null
        }
      </span>
    );
  }

}