"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSkuUnitTypes = exports.getSkuUnit = exports.getSkuSizeMeasurement = exports.getSkuSalePriceFromProfitMargin = exports.getSkuSalePriceFromProfit = exports.getSkuSalePriceFromMarkup = exports.getSkuSalePriceFromGrossSalePrice = exports.getSkuSalePriceFromFoodCost = exports.getSkuProfitMargin = exports.getSkuProfit = exports.getSkuProductWeightMeasurement = exports.getSkuMeasurement = exports.getSkuMeasureMeasurement = exports.getSkuMarkup = exports.getSkuGrossSalePrice = exports.getSkuFoodCost = exports.getSkuDetails = exports.getSkuAmount = exports.getReportSkuMeasurement = exports.getConfigProperty = void 0;

var _commonUtils = require("./commonUtils.js");

var _constants = require("./constants");

var _Measurement = _interopRequireDefault(require("./Measurement"));

var _lodash = require("lodash");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var DEFAULTS = {
  expandable: true
}; // skuDetails config property

var getConfigProperty = function getConfigProperty(sku, property) {
  return (0, _lodash.get)(getSkuDetails(sku, "domain"), "config[".concat(property, "]"), DEFAULTS[property]);
};

exports.getConfigProperty = getConfigProperty;
var SKU_DETAILS = {
  skuDetailsId: null,
  domainId: null,
  restaurantId: null,
  skuId: null,
  categoryId: null,
  category: null,
  netPurchasePrice: null,
  netSalePrice: null,
  reportAmount: null,
  reportUnitId: null,
  taxRate: null,
  config: {
    expandable: true
  },
  tags: []
};
/* eslint-disable-next-line complexity */

var getSkuAmount = function getSkuAmount(sku) {
  switch (sku.stocktakeMode) {
    case _constants.SkuStocktakeModeEnum.QUANTITY:
      return 1;

    case _constants.SkuStocktakeModeEnum.QUANTITY_VOLUME:
      return sku.volume;

    case _constants.SkuStocktakeModeEnum.VOLUME:
      return sku.volume;

    case _constants.SkuStocktakeModeEnum.QUANTITY_MASS:
      return sku.packageWeightGross != null && sku.packageWeightNet != null ? sku.packageWeightGross - (0, _commonUtils.convertAmount)(sku.packageWeightNet, sku.packageWeightNetUnitId, sku.packageWeightGrossUnitId) : null;

    case _constants.SkuStocktakeModeEnum.MASS:
      return sku.packageWeightGross;

    default:
      return null;
  }
};

exports.getSkuAmount = getSkuAmount;

var getSkuProductWeightMeasurement = function getSkuProductWeightMeasurement(sku) {
  switch (sku.stocktakeMode) {
    case _constants.SkuStocktakeModeEnum.QUANTITY:
      {
        return new _Measurement["default"]({
          amount: sku.packageWeightGross || null,
          unit: _constants.UnitEnum.get(sku.packageWeightGrossUnitId)
        });
      }

    case _constants.SkuStocktakeModeEnum.MASS:
      {
        return new _Measurement["default"]({
          amount: sku.packageWeightGross || null,
          unit: _constants.UnitEnum.get(sku.packageWeightGrossUnitId)
        });
      }

    case _constants.SkuStocktakeModeEnum.QUANTITY_MASS:
      {
        return new _Measurement["default"]({
          amount: sku.packageWeightGross - (0, _commonUtils.convertAmount)(sku.packageWeightNet, sku.packageWeightNetUnitId, sku.packageWeightGrossUnitId),
          unit: _constants.UnitEnum.get(sku.packageWeightGrossUnitId)
        });
      }

    default:
      return new _Measurement["default"]({
        amount: null,
        unit: null
      });
  }
};

exports.getSkuProductWeightMeasurement = getSkuProductWeightMeasurement;

var getSkuSizeMeasurement = function getSkuSizeMeasurement(sku) {
  var volume = getSkuMeasureMeasurement(sku, _constants.SkuMeasureEnum.VOLUME);
  var weight = getSkuMeasureMeasurement(sku, _constants.SkuMeasureEnum.PRODUCT_WEIGHT);
  return volume.amount ? volume : weight.amount ? weight : getSkuMeasurement(sku);
};
/* eslint-disable-next-line complexity */


exports.getSkuSizeMeasurement = getSkuSizeMeasurement;

var getSkuMeasureMeasurement = function getSkuMeasureMeasurement(sku, skuMeasure) {
  switch (skuMeasure) {
    case _constants.SkuMeasureEnum.VOLUME:
      return new _Measurement["default"]({
        amount: sku.volume || null,
        unit: _constants.UnitEnum.get(sku.volumeUnitId)
      });

    case _constants.SkuMeasureEnum.PACKAGE_WEIGHT_NET:
      return new _Measurement["default"]({
        amount: sku.packageWeightNet || null,
        unit: _constants.UnitEnum.get(sku.packageWeightNetUnitId)
      });

    case _constants.SkuMeasureEnum.PACKAGE_WEIGHT_GROSS:
      return new _Measurement["default"]({
        amount: sku.packageWeightGross || null,
        unit: _constants.UnitEnum.get(sku.packageWeightGrossUnitId)
      });

    case _constants.SkuMeasureEnum.PRODUCT_WEIGHT:
      return getSkuProductWeightMeasurement(sku);

    case _constants.SkuMeasureEnum.PACKAGE_CLOSURE_WEIGHT:
      return new _Measurement["default"]({
        amount: sku.capWeight || null,
        unit: _constants.UnitEnum.get(sku.capWeightUnitId)
      });

    default:
      return new _Measurement["default"]({
        amount: null,
        unit: null
      });
  }
};

exports.getSkuMeasureMeasurement = getSkuMeasureMeasurement;

var getSkuMeasurement = function getSkuMeasurement(sku) {
  return new _Measurement["default"]({
    amount: getSkuAmount(sku),
    unit: getSkuUnit(sku)
  });
};
/* eslint-disable-next-line complexity */


exports.getSkuMeasurement = getSkuMeasurement;

var getSkuUnit = function getSkuUnit(sku) {
  switch (sku.stocktakeMode) {
    case _constants.SkuStocktakeModeEnum.QUANTITY:
      return _constants.UnitEnum.ITEM;

    case _constants.SkuStocktakeModeEnum.QUANTITY_VOLUME:
      return _constants.UnitEnum.get(sku.volumeUnitId);

    case _constants.SkuStocktakeModeEnum.VOLUME:
      return _constants.UnitEnum.get(sku.volumeUnitId);

    case _constants.SkuStocktakeModeEnum.QUANTITY_MASS:
      return _constants.UnitEnum.get(sku.packageWeightGrossUnitId);

    case _constants.SkuStocktakeModeEnum.MASS:
      return _constants.UnitEnum.get(sku.packageWeightGrossUnitId);

    default:
      return null;
  }
};

exports.getSkuUnit = getSkuUnit;

var mergeSkuDetails = function mergeSkuDetails(details) {
  var _window, _window$user, _window$user$restaura, _window$user$restaura2;

  var restaurnatId = typeof window !== 'undefined' && ((_window = window) === null || _window === void 0 ? void 0 : (_window$user = _window.user) === null || _window$user === void 0 ? void 0 : (_window$user$restaura = _window$user.restaurants) === null || _window$user$restaura === void 0 ? void 0 : (_window$user$restaura2 = _window$user$restaura.find(function (_ref) {
    var restaurantUser = _ref.restaurantUser;
    return restaurantUser === null || restaurantUser === void 0 ? void 0 : restaurantUser.active;
  })) === null || _window$user$restaura2 === void 0 ? void 0 : _window$user$restaura2.id);
  if (!details || details.length === 0) return SKU_DETAILS;
  var restaurantDetails = restaurnatId && details.find(function (d) {
    return d.restaurantId === restaurnatId;
  });
  var domainDetails = details.find(function (_ref2) {
    var restaurantId = _ref2.restaurantId;
    return restaurantId === null;
  });
  if (restaurantDetails && domainDetails) return _objectSpread(_objectSpread({}, restaurantDetails), {}, {
    tags: domainDetails.tags,
    categoryId: domainDetails.categoryId,
    category: domainDetails.category
  });else if (domainDetails) return _objectSpread(_objectSpread({}, SKU_DETAILS), {}, {
    tags: domainDetails.tags,
    categoryId: domainDetails.categoryId,
    category: domainDetails.category
  });
  return restaurantDetails || typeof details[0].restaurantId === 'undefined' ? details[0] : SKU_DETAILS;
};

var getSkuDetails = function getSkuDetails(sku, level) {
  var _window2, _window2$user;

  var skuDetailsLevel = typeof window !== 'undefined' && ((_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$user = _window2.user) === null || _window2$user === void 0 ? void 0 : _window2$user.skuDetailsLevel) || level;
  if (skuDetailsLevel === 'restaurant') return mergeSkuDetails(sku.details);
  if (skuDetailsLevel === 'domain') return sku.details && sku.details.length ? sku.details.find(function (_ref3) {
    var restaurantId = _ref3.restaurantId;
    return restaurantId === null;
  }) || typeof sku.details[0].restaurantId === 'undefined' ? sku.details[0] : SKU_DETAILS : SKU_DETAILS;
  return null;
};

exports.getSkuDetails = getSkuDetails;

var getSkuGrossSalePrice = function getSkuGrossSalePrice(sku) {
  var _getSkuDetails = getSkuDetails(sku, "domain"),
      netSalePrice = _getSkuDetails.netSalePrice,
      taxRate = _getSkuDetails.taxRate;

  return netSalePrice + netSalePrice * taxRate / 100;
};

exports.getSkuGrossSalePrice = getSkuGrossSalePrice;

var getSkuProfit = function getSkuProfit(sku, portionsPurchasePrice) {
  var _getSkuDetails2 = getSkuDetails(sku, "domain"),
      netSalePrice = _getSkuDetails2.netSalePrice,
      netPurchasePrice = _getSkuDetails2.netPurchasePrice;

  return netSalePrice - (netPurchasePrice || portionsPurchasePrice);
};

exports.getSkuProfit = getSkuProfit;

var getSkuProfitMargin = function getSkuProfitMargin(sku, portionsPurchasePrice) {
  var _getSkuDetails3 = getSkuDetails(sku, "domain"),
      netSalePrice = _getSkuDetails3.netSalePrice;

  var profit = getSkuProfit(sku, portionsPurchasePrice);
  return profit / netSalePrice * 100;
};

exports.getSkuProfitMargin = getSkuProfitMargin;

var getSkuMarkup = function getSkuMarkup(sku, portionsPurchasePrice) {
  var _getSkuDetails4 = getSkuDetails(sku, "domain"),
      netPurchasePrice = _getSkuDetails4.netPurchasePrice;

  var profit = getSkuProfit(sku, portionsPurchasePrice);
  return profit / (netPurchasePrice || portionsPurchasePrice) * 100;
};

exports.getSkuMarkup = getSkuMarkup;

var getSkuFoodCost = function getSkuFoodCost(sku, portionsPurchasePrice) {
  var _getSkuDetails5 = getSkuDetails(sku, "domain"),
      netPurchasePrice = _getSkuDetails5.netPurchasePrice,
      netSalePrice = _getSkuDetails5.netSalePrice;

  return (netPurchasePrice || portionsPurchasePrice) / netSalePrice * 100;
};

exports.getSkuFoodCost = getSkuFoodCost;

var getSkuSalePriceFromGrossSalePrice = function getSkuSalePriceFromGrossSalePrice(sku, grossSalePrice) {
  var _getSkuDetails6 = getSkuDetails(sku, "domain"),
      taxRate = _getSkuDetails6.taxRate;

  return grossSalePrice * 100 / (taxRate + 100);
};

exports.getSkuSalePriceFromGrossSalePrice = getSkuSalePriceFromGrossSalePrice;

var getSkuSalePriceFromProfit = function getSkuSalePriceFromProfit(sku, portionsPurchasePrice, profit) {
  var _getSkuDetails7 = getSkuDetails(sku, "domain"),
      netPurchasePrice = _getSkuDetails7.netPurchasePrice;

  return profit + (netPurchasePrice || portionsPurchasePrice);
};

exports.getSkuSalePriceFromProfit = getSkuSalePriceFromProfit;

var getSkuSalePriceFromProfitMargin = function getSkuSalePriceFromProfitMargin(sku, portionsPurchasePrice, profitMargin) {
  var _getSkuDetails8 = getSkuDetails(sku, "domain"),
      netPurchasePrice = _getSkuDetails8.netPurchasePrice;

  return (netPurchasePrice || portionsPurchasePrice) / (100 - profitMargin) * 100;
};

exports.getSkuSalePriceFromProfitMargin = getSkuSalePriceFromProfitMargin;

var getSkuSalePriceFromMarkup = function getSkuSalePriceFromMarkup(sku, portionsPurchasePrice, markup) {
  var _getSkuDetails9 = getSkuDetails(sku, "domain"),
      netPurchasePrice = _getSkuDetails9.netPurchasePrice;

  return (netPurchasePrice || portionsPurchasePrice) * (100 + markup) / 100;
};

exports.getSkuSalePriceFromMarkup = getSkuSalePriceFromMarkup;

var getSkuSalePriceFromFoodCost = function getSkuSalePriceFromFoodCost(sku, portionsPurchasePrice, foodCost) {
  var details = getSkuDetails(sku, "domain");
  return (details.netPurchasePrice || portionsPurchasePrice) / foodCost * 100;
}; // eslint-disable-next-line complexity


exports.getSkuSalePriceFromFoodCost = getSkuSalePriceFromFoodCost;

var getSkuUnitTypes = function getSkuUnitTypes(_ref4) {
  var stocktakeMode = _ref4.stocktakeMode;

  switch (stocktakeMode) {
    case _constants.SkuStocktakeModeEnum.QUANTITY:
      return [_constants.UnitTypeEnum.ITEM];

    case _constants.SkuStocktakeModeEnum.QUANTITY_VOLUME:
      return [_constants.UnitTypeEnum.VOLUME, _constants.UnitTypeEnum.ITEM];

    case _constants.SkuStocktakeModeEnum.VOLUME:
      return [_constants.UnitTypeEnum.VOLUME];

    case _constants.SkuStocktakeModeEnum.QUANTITY_MASS:
      return [_constants.UnitTypeEnum.WEIGHT, _constants.UnitTypeEnum.ITEM];

    case _constants.SkuStocktakeModeEnum.MASS:
      return [_constants.UnitTypeEnum.WEIGHT];

    default:
      return [];
  }
};

exports.getSkuUnitTypes = getSkuUnitTypes;

var getReportSkuMeasurement = function getReportSkuMeasurement(sku) {
  var _getSkuDetails10 = getSkuDetails(sku, "domain"),
      reportAmount = _getSkuDetails10.reportAmount,
      reportUnitId = _getSkuDetails10.reportUnitId;

  if (reportAmount != null && reportUnitId != null) {
    return new _Measurement["default"]({
      amount: reportAmount,
      unit: _constants.UnitEnum.get(reportUnitId)
    });
  } else if (sku.stocktakeMode === _constants.SkuStocktakeModeEnum.QUANTITY) {
    return new _Measurement["default"]({
      amount: 1,
      unit: _constants.UnitEnum.ITEM
    });
  }

  return getSkuSizeMeasurement(sku);
};

exports.getReportSkuMeasurement = getReportSkuMeasurement;