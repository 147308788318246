import React from 'react';
import Img from 'react-image';
import LazyLoad from 'react-lazyload';
import Loading from '$ui/Loading';

export default function Image({ preload, className, divStyle, divClass, ...props }) {
  const image = (
    <Img
      className={(className || 'Img')}
      loader={<Loading />}
      {...props}
    />
  );
  const imageComponent = preload ? image : (
    <LazyLoad height={24} offset={200} placeholder={<Loading />} once>
      {image}
    </LazyLoad>
  );
  return (
    <div className={(divClass || 'Image')} style={divStyle}>
      {imageComponent}
    </div>
  );
}