import React, { Component } from 'react';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { connect, getIn } from 'formik';
import Icon from '$ui/Icon';
import { SkuBarcodeFormatEnum } from '@stktk/commons';
import { __ } from '@stktk/locales';
import Input from '$ui/form/controls/Input';

export class FormBarcodeFormat extends Component {

  componentDidMount() {
    const { focusOnRender } = this.props;
    if (focusOnRender) this.input.focus();
  }

  handleChange = e => {
    const { formatName, name, formik } = this.props;
    const { value } = e.target;
    formik.setFieldValue(formatName, SkuBarcodeFormatEnum.guessBarcodeFormat(value));
    formik.setFieldValue(name, value && value.length ? value : null);
  };

  handleBlur = () => {
    const { name, formik, submitOnBlur } = this.props;
    if (submitOnBlur) formik.submitForm();
    formik.setFieldTouched(name, true);
  };

  renderBarcodeType = (value, error) => {
    if (value) {
      if (value == SkuBarcodeFormatEnum.CUSTOM.name) {
        return (
          <span>
            <div id="customWarning">
              <span>
                {__('app_barcodeFormat_custom')}
                {' '}
              </span>
              <Icon size="normal" icon="question" type="light" />
            </div>
            <UncontrolledTooltip placement="bottom" target="customWarning">
              {__('app_barcodeFormat_custom_warning')}
            </UncontrolledTooltip>
          </span>
        );
      } else return error ? __('app_barcodeFormat_invalid') : value;
    } else return error ? __('app_barcodeFormat_invalid') : '';
  }

  render() {
    const { formatName, name, formik, className, submitOnBlur, focusOnRender, ...props } = this.props;
    const error = getIn(formik.errors, name);
    const value = getIn(formik.values, name);
    const touch = getIn(formik.touched, name);
    const formatValue = getIn(formik.values, formatName);
    const formatError = getIn(formik.errors, name);
    const color = formatError ? 'danger' : (formatValue == SkuBarcodeFormatEnum.CUSTOM.name ? 'warning' : 'success');
    const valid = touch ? (error ? ' is-invalid' : ' is-valid') : '';
    const combineClass = (className ? ' ' + className : '') + valid;
    window.SkuBarcodeFormatEnum = SkuBarcodeFormatEnum;
    return (
      <div style={{ position: 'relative' }}>
        <Input
          ref={node => this.input = node}
          value={value == null ? '' : value}
          className={combineClass}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          name={name}
          {...props}
        />
        <Badge
          style={{
            display: (value ? 'inline-block' : 'none'),
            position: 'absolute',
            top: '7px',
            right: '7px',
            fontSize: '1rem',
            textTransform: 'uppercase'
          }}
          color={color}
        >
          {this.renderBarcodeType(formatValue, formatError)}
        </Badge>
      </div>
    );
  }
}

export default connect(FormBarcodeFormat);