import React, { useCallback } from 'react';
import { components } from 'react-select';
import useBarForm from '@stktk/logic/hooks/forms/useBarForm';
import { __ } from '@stktk/locales';
import { FormGroup } from '$ui';
import { globalHandleError } from '$utils/error';

export default function FormBarGroup({
  id = 'FormBarGroup',
  recordsets,
  isDomainSelector,
  onChange,
  filter,
  ...props
}) {
  const { isLoading, options, bars } = useBarForm({
    recordsets,
    isDomainSelector,
    filter,
    errorHandler: globalHandleError
  });

  const handleChange = useCallback(
    selectedOption => {
      if (onChange)
        onChange(selectedOption, bars, bars.find(bar => bar.id === selectedOption.value));
    },
    [onChange, bars]
  );

  return (
    <FormGroup
      id={id}
      element="select"
      isLoading={isLoading}
      loadingMessage={__('placeholder_loading_bar_list')}
      options={options}
      components={{
        Option: props => (
          <components.Option {...props}>
            <div>
              <span>{props.data.indentation}</span>
              <span>{props.label}</span>
            </div>
          </components.Option>
        )
      }}
      onChange={handleChange}
      {...props}
    />
  );
}