import React, { Component } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { filter } from 'lodash';
import { RecordModeEnum } from '@stktk/commons';
import { __recordMode } from '@stktk/locales';
import { isAllowed, ValidationPlatformEnum } from '@stktk/validation';
import { InfoTooltip } from '$ui';

export default class AddRecordNav extends Component {

  getAllowedRecordModes = () => {
    let { record, stocktakeMode } = this.props;
    return filter(RecordModeEnum.toArray(), mode =>
      isAllowed(ValidationPlatformEnum.CLIENT, record + '.' + stocktakeMode + '.' + mode)
    );
  }

  componentDidMount() {
    const [first] = this.getAllowedRecordModes();
    this.props.onChange(first);
  }

  handleChange = (event, mode) => {
    event.preventDefault();
    this.props.onChange(mode);
  }

  render() {
    return (
      <Nav tabs className="mb-3">
        {this.getAllowedRecordModes().map(mode =>
          <AddRecordNavItem
            id={`AddRecordNavItem-${mode.replace(/\s/g, '-')}`}
            key={mode}
            active={(mode == this.props.recordMode)}
            onClick={e => this.handleChange(e, mode)}
            label={__recordMode(mode)}
          />
        )}
        {this.props.info &&
          <InfoTooltip info={this.props.info}/>
        }
      </Nav>
    );
  }
}

export class AddRecordNavItem extends Component {
  render() {
    const { label, active, onClick, ...props } = this.props;
    return (
      <NavItem {...props}>
        <NavLink
          href="#"
          className={label}
          active={active}
          onClick={onClick}
        >
          {label}
        </NavLink>
      </NavItem>
    );
  }
}