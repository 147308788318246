import moment from 'moment';
import { snakeCase } from 'lodash';
import { __tooltip, changeLanguage, locales } from '@stktk/locales';
import { defaultLanguage } from '@stktk/commons';

export const setLocale = locale => {
  window.locale = locale;
  moment.locale(locale.language);
  changeLanguage(locale.contentLanguage);
};

export const setLocaleByContentLanguage = contentLanguage => setLocale(locales.findByContentLanguage(contentLanguage));

export const setLocaleByLanguage = language => {
  const locale = locales.findByUrl(language);
  setLocale(locale ? locale : locales.findByUrl(defaultLanguage));
};

export const getTooltipValue = (view, element, prop) => {
  return __tooltip(`${snakeCase(view)}_${snakeCase(element)}_${snakeCase(prop)}`);
};