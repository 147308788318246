import { __ } from '@stktk/locales';
import React, { useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import { filter, find } from 'lodash';
import classNames from 'classnames';
import Select from '$ui/form/controls/Select';

const __ALL__ = '__ALL__';

const selectAllOption = { value: __ALL__, label: __('select_all_option'), isTechOption: true };

const FormSelect = ({
  name,
  keyType = 'string',
  className,
  defaultValue,
  submitOnBlur,
  focusOnRender,
  onChange,
  options,
  isMulti,
  isCreatable,
  onCreateOption,
  optionExtractor,
  allowSelectAll,
  ...props
}) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(name);
  const { submitForm } = useFormikContext();
  const ref = useRef(null);

  useEffect(() => {
    if (focusOnRender) ref.current.focus();
  }, []);

  const parseType = (value, reverse) => { // FIXME: xD@reverse
    switch (keyType) {
      case 'number': return reverse ? parseInt(value) : value && value.toString() || null;
      case 'boolean': return reverse ? value === 'true' ? true : false : value ? 'true' : 'false';
      default: return value;
    }
  };

  const parseOptions = options =>
    options.map(option => {
      let props = optionExtractor ? optionExtractor(option) : option;
      return { ...props, value: parseType(props.value) };
    });

  const parseValue = (values, options) => {
    if (isMulti)
      return filter(options, option => values.find(value => option.value === parseType(value)));
    return find(options, { value: parseType(values) }) || null;
  };

  const handleChange = selectedOptions => {
    let values = null;
    if (selectedOptions) {
      if (isMulti) {
        if (selectedOptions.map(option => option.value).includes(__ALL__))
          values = parseOptions(options).map(({ value }) => parseType(value, true));
        else
          values = selectedOptions.map(({ value }) => parseType(value, true));
      } else
        values = parseType(selectedOptions.value, true);
    }
    if (onChange)
      onChange(selectedOptions);
    setValue(values);
  };

  const handleBlur = () => {
    if (submitOnBlur)
      submitForm();
    setTouched(true);
  };

  const handleCreateOption = option => {
    onCreateOption(option).then(createdOption => {
      let [parsedOption] = parseOptions([createdOption]);
      let values = isMulti ? [...value, parsedOption.value] : parsedOption.value;
      handleChange(parseValue(values, parseOptions([...options, createdOption])));
    });
  };

  //FIXME: exclude selectAllOption if all selected ?
  const parsedOptions = parseOptions(allowSelectAll ? [selectAllOption, ...options] : options);
  const selectedOptions = parseValue(value || defaultValue, parsedOptions);

  return (
    <Select
      ref={ref}
      className={classNames('Select', { 'is-invalid': touched && error, 'is-valid': touched && !error }, className)}
      value={selectedOptions}
      instanceId={`${name}`}
      name={name}
      options={parsedOptions}
      isMulti={isMulti}
      isCreatable={isCreatable}
      isOptionDisabled={option => option.disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      onCreateOption={handleCreateOption}
      {...props}
    />
  );
};

export default FormSelect;