import React from 'react';
import { Badge } from 'reactstrap';
import { __category, __ } from '@stktk/locales';

export const CategoryBadge = ({ category }) => (
  category ? (
    <Badge style={{ display: 'inline-block', marginRight: 20, fontSize: '12px', backgroundColor: (category.rgbColor ? category.rgbColor : 'black') }}>
      {__category(category)}
    </Badge>
  ) : (
    <Badge style={{ display: 'inline-block', marginRight: 20, fontSize: '12px' }} color='light'>
      {__('general_uncategorized')}
    </Badge>
  )
);

export default CategoryBadge;