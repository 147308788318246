import React from 'react';
import { List } from 'react-content-loader';
import loadable from '@loadable/component';

const options = { fallback: <List /> };

export const BarForm = loadable(() => import('$forms/BarForm'), options);

export const TagForm = loadable(() => import('$forms/TagForm'), options);

export const CategoryForm = loadable(() => import('$forms/CategoryForm'), options);

export const DocumentForm = loadable(() => import('$forms/DocumentForm'), options);

export const DocumentOcrEditForm = loadable(() => import('$forms/DocumentOcrEditForm'), options);

export const LossGroupsForm = loadable(() => import('$forms/LossGroupsForm'), options);

export const OrdersForm = loadable(() => import('$forms/OrdersForm'), options);

export const OrderGroupsForm = loadable(() => import('$forms/OrderGroupsForm'), options);

export const PackageClosuresForm = loadable(() => import('$forms/PackageClosuresForm'), options);

export const ProfileForm = loadable(() => import('$forms/ProfileForm'), options);

export const ProfilePasswordForm = loadable(() => import('$forms/ProfilePasswordForm'), options);

export const RecordForm = loadable(() => import('$forms/RecordForm'), options);

export const RecordModesForm = loadable(() => import('$forms/RecordModesForm'), options);

export const RecordLossForm = loadable(() => import('$forms/RecordLossForm'), options);

export const ReportsForm = loadable(() => import('$forms/ReportsForm'), options);

export const RestaurantForm = loadable(() => import('$forms/RestaurantForm'), options);

export const SalesForm = loadable(() => import('$forms/SalesForm'), options);

export const StocktakeForm = loadable(() => import('$forms/StocktakeForm'), options);

export const StoragesForm = loadable(() => import('$forms/StoragesForm'), options);

export const SkuDetailsForm = loadable(() => import('$forms/SkuDetailsForm'), options);

export const StorageTransferGroupsForm = loadable(() => import('$forms/StorageTransferGroupsForm'), options);

export const DataMappingForm = loadable(() => import('$forms/DataMappingForm'), options);

export const ProductionGroupsForm = loadable(() => import('$forms/ProductionGroupsForm'), options);