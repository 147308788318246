import React from 'react';
import { get } from 'lodash';
import { Icon } from '$ui';
import {
  SkuMeasureEnum,
  getSkuMeasureMeasurement
} from '@stktk/commons';
import Input from '$ui/form/controls/Input';

export const filterRows = accessor => {
  return (filter, row) => {
    //let rowValue = rowValueConverter(filter.id, row);
    let rowValue = accessor(filter, row);
    if (rowValue == null) {
      return false;
    }
    const filterValue = filter.value.filterValue || '';
    const { filterType } = filter.value;
    switch (filterType) {
      case 'icontains':
        return rowValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      default:
        return true;
    }
  };

};

/* FIXME: suppress */
export const rowValueConverter = (filter, row) => {
  let value = '';
  let filteredValue = '';
  let filterId = get(filter, 'id', null);
  switch (filterId) {
    case 'name':
    case 'barcode':
    case 'stocktakeMode':
    case 'percentage':
    case 'category':
      return String(get(row, filterId, null));
    case 'volume':
      value = get(row, filterId, null);
      filteredValue = getSkuMeasureMeasurement(
        value,
        SkuMeasureEnum.VOLUME
      ).localeDisplay({ precision: 2 });
      return filteredValue;
    case 'weight_net':
      value = get(row, filterId, null);
      filteredValue = getSkuMeasureMeasurement(
        value, SkuMeasureEnum.PACKAGE_WEIGHT_NET
      ).localeDisplay({ precision: 2 });
      return filteredValue;
    case 'weight_gross':
      value = get(row, filterId, null);
      filteredValue = getSkuMeasureMeasurement(
        value, SkuMeasureEnum.PACKAGE_WEIGHT_GROSS
      ).localeDisplay({ precision: 2 });
      return filteredValue;
    case 'bottle_cap_weight':
      value = get(row, filterId, null);
      filteredValue = getSkuMeasureMeasurement(
        value, SkuMeasureEnum.PACKAGE_CLOSURE_WEIGHT
      ).localeDisplay({ precision: 2 });
      return filteredValue;
    default:
      return null;
  }
};

// export const filterSubComponentRows = (filter, row) => {
//   console.log('filterSubComponentRows', filter, row);
//   const rowValue = row[filter.id];
//   if (!rowValue) {
//     return true;
//   }

//   const filterValue = filter.value.filterValue || '';
//   const { filterType } = filter.value;
//   switch (filterType) {
//     case 'icontains':
//       return rowValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
//     default:
//       return true;
//   }
// };

export default class FilterInnerComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filterType: 'icontains',
      filterValue: ''
    };
  }

  changeFilterType(event) {
    const filterType = event.target.value;
    const newState = {
      ...this.state,
      filterType
    };
    this.setState(newState);
    this.props.onChange(newState);
  }

  changeFilterValue(event) {
    const filterValue = event.target.value;
    const newState = {
      ...this.state,
      filterValue
    };
    this.setState(newState);
    this.props.onChange(newState);
  }

  render() {
    return (
      <div className="FilterInnerComponent">
        <Input
          isRaw
          className="FilterInnerComponent-input"
          type="text"
          value={this.state.filterValue}
          // className={{
          //   width: '100%',
          //   height: '40px',
          //   float: 'left',
          //   fontSize: '12px',
          //   paddingRight: '17px'
          // }}
          onChange={evt => this.changeFilterValue(evt, this.props.property)}
        />
        <Icon
          className="FilterInnerComponent-icon"
          icon="filter"
          type="light"
          variant="mute"
        />
      </div>
    );
  }
}