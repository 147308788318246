/* eslint-disable complexity */
import React, { useState, useEffect, useCallback, useMemo, Fragment } from 'react';
import Fuse from 'fuse.js';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { flatten } from 'lodash';
import { sortItemsByProperty, groupRecordsBySkuId, groupRecordsByRecordsetIdAndSkuId } from '@stktk/logic/utils/list.js';
import { getPageContent, getPageCount, DEFAULT_PAGE_SIZE } from '@stktk/logic/utils/pagination.js';
import { __ } from '@stktk/locales';
import ListSortDropdown from '$ui/list/ListSortDropdown';
import Input from '$ui/form/controls/Input';
import Icon from '$ui/Icon';
import Pagination from '$ui/Pagination';

const ListControls = ({ id, items = [], defaultSorted = [{ id: 'name', desc: false }], itemSize = 53, properties = [], noItemsText, pageNumber = 1, listRenderer, isOrderRecords, isGroupedRecords = false, hideControls = false, inline = false, renderDataSummary, customListControls = null }) => {
  const listItems = useMemo(() => isGroupedRecords ? isOrderRecords ? groupRecordsByRecordsetIdAndSkuId(items) : groupRecordsBySkuId(items) : items, [items, isGroupedRecords]);
  const [sort, setSort] = useState(defaultSorted);
  const [query, setQuery] = useState('');
  const [sortedItems, setSortedItems] = useState(listItems);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const history = useHistory();
  const pageCount = getPageCount({ items: listItems.length, pageSize });
  const pageIndex = pageNumber - 1;
  // FIXME: replace search string
  const gotoPage = pageIndex => history.replace({ search: `?page=${pageIndex + 1}` });
  const sortableProps = properties.filter(({ sortable }) => sortable);
  const searchableProps = properties.filter(({ searchable }) => searchable);

  const fuzzySearch = useCallback(query => {
    if (!query)
      return listItems;
    const searchItems = isGroupedRecords ? flatten(listItems) : listItems;
    const fuse = new Fuse(searchItems, {
      includeScore: true,
      ignoreLocation: true,
      threshold: 0.4,
      keys: searchableProps.map(({ key, weight = 1 }) => ({
        name: key,
        weight
      }))
    });
    let searchResult = fuse.search(query).map(({ item, score }) => ({ ...item, _searchScore: score }));//isGroupedRecords ? listItems.find(([record]) => record.id === item.id) : { ...item, _score: score });
    //NOTE: assuming all matching records will be found, so we'll grouping them once again
    return isGroupedRecords ? groupRecordsBySkuId(searchResult) : searchResult;
  }, [listItems]);

  useEffect(() => {
    let sortOptions = query ?
      { items: sortItemsByProperty(fuzzySearch(query), isGroupedRecords ? '[0]._searchScore' : '_searchScore', 'asc'), pageNumber, pageSize } :
      { items: sortItemsByProperty(fuzzySearch(query), isGroupedRecords ? `[0].${sort[0].id}` : sort[0].id, sort[0].desc ? 'desc' : 'asc'), pageNumber, pageSize };
    setSortedItems(getPageContent(sortOptions));
  }, [sort, query, fuzzySearch, pageNumber, pageSize, isGroupedRecords]);

  const renderList = useCallback(
    () => listRenderer(sortedItems),
    [listRenderer, sortedItems]
  );

  const height = useMemo(
    () => (listItems.length > 3 && inline ? itemSize * 5 : itemSize * (listItems.length > pageSize ? pageSize : listItems.length || 1)) + 1,
    [listItems.length, itemSize, pageSize, inline]
  );

  return (
    <Fragment>
      {renderDataSummary ? renderDataSummary(sortedItems) : null}
      {!hideControls && (
        <div className="ListControlsHeader d-flex flex-row justify-content-between mb-2">
          <InputGroup size="sm" style={{ maxWidth: 250 }}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <Icon className="text-muted" icon="search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder={__('placeholder_search_items', { count: listItems.length })}
              value={query}
              onChange={e =>
                setQuery(e.target.value || '')
              }
            />
          </InputGroup>
          <div className="d-flex flex-row">
            {customListControls}
            {sortableProps.length > 0 && (
              <ListSortDropdown sort={sort} setSort={setSort} sortableProps={sortableProps} />
            )}
          </div>
        </div>
      )}
      <div id={id} className="ListControls d-flex" style={{ minHeight: itemSize, height }}>
        {!listItems.length ? (<h3 className="List--noResults">{noItemsText || __('alert_error_no_results_found')}</h3>) : renderList()}
      </div>
      {!hideControls && (
        <Pagination
          gotoPage={gotoPage}
          canPreviousPage={pageNumber > 1}
          canNextPage={pageNumber < pageCount}
          previousPage={() => gotoPage(pageIndex - 1)}
          nextPage={() => gotoPage(pageIndex + 1)}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </Fragment>
  );
};

export default ListControls;