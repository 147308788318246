"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderGroup = exports["default"] = void 0;

var _validatorConstants = require("../validator/validatorConstants.js");

var _commons = require("@stktk/commons");

var orderGroup = {
  find: {
    id: _validatorConstants.INTEGER.optional([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  create: {
    //FIXME:
    comment: _validatorConstants.STRING.required([1, 140]),
    virtualGenerationRule: _validatorConstants.ENUM.STRING.required(_commons.OrderGroupGenerationRuleEnum.toArray()),
    virtualStorageStandard: _validatorConstants.INTEGER.optional(),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.optional([1]) //FIXME:

  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    comment: _validatorConstants.STRING.optional([1, 140])
  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  }
};
exports.orderGroup = orderGroup;
var _default = orderGroup;
exports["default"] = _default;