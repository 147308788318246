"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.excludeList = exports["default"] = void 0;

var _reduxUndo = _interopRequireWildcard(require("redux-undo"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var zeros = {
  camera: {
    state: 0
  },
  addskustart: {
    state: 0
  },
  addskupicture: {
    state: 0
  },
  scale: {
    state: 0
  },
  stocktakecardanimated: {
    state: 0
  },
  dashboard: {
    state: 0
  },
  drawer: 0
};
var ones = {
  camera: {
    state: 1
  },
  addskustart: {
    state: 1
  },
  addskupicture: {
    state: 1
  },
  scale: {
    state: 1
  },
  stocktakecardanimated: {
    state: 1
  },
  dashboard: {
    state: 1
  },
  drawer: 0
};
var excludeList = ['RENDER_ADDSKUSTART', 'RENDER_ADDSKUPICTURE', 'RENDER_SCALE', 'RENDER_STOCKTAKECARDANIMATED', 'SHOW_DASHBOARD', 'HIDE_DRAWER', 'SHOW_DRAWER'];
exports.excludeList = excludeList;

var reducer = function reducer(state, action) {
  if (typeof state === 'undefined') {
    return zeros;
  }

  switch (action.type) {
    case 'HIDE_ADDSKUSTART':
      return _objectSpread(_objectSpread({}, state), {
        addskustart: {
          state: 0
        }
      });

    case 'RENDER_ADDSKUSTART':
      return _objectSpread(_objectSpread({}, state), {
        addskustart: {
          state: 1
        }
      });

    case 'SHOW_ADDSKUSTART':
      return _objectSpread(_objectSpread(_objectSpread({}, state), ones), {
        addskustart: {
          state: 2
        }
      });

    case 'HIDE_ADDSKUPICTURE':
      return _objectSpread(_objectSpread({}, state), {
        addskupicture: {
          state: 0
        }
      });

    case 'RENDER_ADDSKUPICTURE':
      return _objectSpread(_objectSpread({}, state), {
        addskupicture: {
          state: 1
        }
      });

    case 'SHOW_ADDSKUPICTURE':
      return _objectSpread(_objectSpread(_objectSpread({}, state), ones), {
        addskupicture: {
          state: 2
        }
      });

    case 'HIDE_SCALE':
      return _objectSpread(_objectSpread({}, state), {
        scale: {
          state: 0
        }
      });

    case 'RENDER_SCALE':
      return _objectSpread(_objectSpread({}, state), {
        scale: {
          state: 1
        }
      });

    case 'SHOW_SCALE':
      return _objectSpread(_objectSpread(_objectSpread({}, state), ones), {
        scale: {
          state: 2
        }
      });

    case 'HIDE_STOCKTAKECARDANIMATED':
      return _objectSpread(_objectSpread({}, state), {
        stocktakecardanimated: {
          state: 0
        }
      });

    case 'RENDER_STOCKTAKECARDANIMATED':
      return _objectSpread(_objectSpread({}, state), {
        stocktakecardanimated: {
          state: 1
        }
      });

    case 'SHOW_STOCKTAKECARDANIMATED':
      return _objectSpread(_objectSpread(_objectSpread({}, state), zeros), {
        dashboard: {
          state: 2
        },
        stocktakecardanimated: {
          state: 2
        }
      });

    case 'SHOW_DASHBOARD':
      return _objectSpread(_objectSpread(_objectSpread({}, state), zeros), {
        dashboard: {
          state: 2
        }
      });

    case 'SHOW_DRAWER':
      return _objectSpread(_objectSpread({}, state), {
        drawer: 1
      });

    case 'HIDE_DRAWER':
      return _objectSpread(_objectSpread({}, state), {
        drawer: 0
      });
  }

  return state;
};

var _default = {
  display: (0, _reduxUndo["default"])(reducer, {
    filter: (0, _reduxUndo.excludeAction)(excludeList),
    clearHistoryType: 'CLEAR_HISTORY' //ignoreInitialState: true,

  })
};
exports["default"] = _default;