import React, { memo } from 'react';
import { __ } from '@stktk/locales';
import { ProgressBar } from '$loadable/ui.jsx';
import { checkIfRecordEvaluated, checkIfSkuEvaluated, checkIfValue0 } from '@stktk/logic/utils/statistics';

const generateProgressBarData = (records, recordsetType, properties) => {
  let quantities = {
    recordEvaluated: 0,
    skuEvaluated: 0,
    valueEqualsZero: 0,
    unevaluated: 0
  };
  records.map(record => {
    if (checkIfRecordEvaluated(record, recordsetType, properties)) {
      quantities.recordEvaluated++;
    } else if (checkIfSkuEvaluated(record, recordsetType, properties)) {
      quantities.skuEvaluated++;
    } else if (checkIfValue0(record, recordsetType, properties)) {
      quantities.valueEqualsZero++;
    } else {
      quantities.unevaluated++;
    }
  });
  return [
    {
      name: __('records_evaluated_using_record'),
      size: quantities.recordEvaluated,
      color: 'success'
    },
    {
      name: __('records_evaluated_using_sku'),
      size: quantities.skuEvaluated,
      color: 'info'
    },
    {
      name: __('evaluation_contains_value_zero'),
      size: quantities.valueEqualsZero,
      color: 'warning'
    },
    {
      name: __('records_unevaluated'),
      size: quantities.unevaluated,
      color: 'danger'
    }
  ];
};

const EvaluatedRecordsBar = ({ records, recordsetType, properties }) =>
  <ProgressBar data={generateProgressBarData(records, recordsetType, properties)} />;

export default memo(EvaluatedRecordsBar);