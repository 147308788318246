"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getActionValidationScheme = exports.ACTION_VALIDATION = void 0;

var getActionValidationScheme = function getActionValidationScheme(action) {
  return ACTION_VALIDATION[action.type] ? ACTION_VALIDATION[action.type](action) : {
    type: 'none',
    name: 'none'
  };
};

exports.getActionValidationScheme = getActionValidationScheme;
var ACTION_VALIDATION = {
  FIND_PACKAGE_CLOSURES: function FIND_PACKAGE_CLOSURES() {
    return 'packageClosure.find';
  },
  UPDATE_PACKAGE_CLOSURES: function UPDATE_PACKAGE_CLOSURES() {
    return 'packageClosure.update';
  },
  DESTROY_PACKAGE_CLOSURES: function DESTROY_PACKAGE_CLOSURES() {
    return 'packageClosure.destroy';
  },
  CREATE_PACKAGE_CLOSURES: function CREATE_PACKAGE_CLOSURES() {
    return 'packageClosure.create';
  },
  FIND_TAGS: function FIND_TAGS() {
    return 'tag.find';
  },
  UPDATE_TAGS: function UPDATE_TAGS() {
    return 'tag.update';
  },
  DESTROY_TAGS: function DESTROY_TAGS() {
    return 'tag.destroy';
  },
  CREATE_TAGS: function CREATE_TAGS() {
    return 'tag.create';
  },
  FIND_CATEGORIES: function FIND_CATEGORIES() {
    return 'category.find';
  },
  UPDATE_CATEGORIES: function UPDATE_CATEGORIES() {
    return 'category.update';
  },
  DESTROY_CATEGORIES: function DESTROY_CATEGORIES() {
    return 'category.destroy';
  },
  CREATE_CATEGORIES: function CREATE_CATEGORIES() {
    return 'category.create';
  },
  ENTER_TEST_MODE: function ENTER_TEST_MODE() {
    return 'user.enterTestMode';
  },
  EXIT_TEST_MODE: function EXIT_TEST_MODE() {
    return 'user.exitTestMode';
  },
  //FIND_USERS: () => 'user.find',
  CREATE_USERS: function CREATE_USERS() {
    return 'user.create';
  },
  UPDATE_USERS: function UPDATE_USERS() {
    return 'user.update';
  },
  DESTROY_USERS: function DESTROY_USERS() {
    return 'user.destroy';
  },
  FIND_RESTAURANTS: function FIND_RESTAURANTS() {
    return 'restaurant.find';
  },
  CREATE_RESTAURANTS: function CREATE_RESTAURANTS() {
    return 'restaurant.create';
  },
  UPDATE_RESTAURANTS: function UPDATE_RESTAURANTS() {
    return 'restaurant.update';
  },
  DESTROY_RESTAURANTS: function DESTROY_RESTAURANTS() {
    return 'restaurant.destroy';
  },
  FIND_STOCKTAKES: function FIND_STOCKTAKES() {
    return 'stocktake.find';
  },
  CREATE_STOCKTAKES: function CREATE_STOCKTAKES() {
    return 'stocktake.create';
  },
  UPDATE_STOCKTAKES: function UPDATE_STOCKTAKES() {
    return 'stocktake.update';
  },
  DESTROY_STOCKTAKES: function DESTROY_STOCKTAKES() {
    return 'stocktake.destroy';
  },
  FIND_REPORTS: function FIND_REPORTS() {
    return 'report.find';
  },
  CREATE_REPORTS: function CREATE_REPORTS() {
    return 'report.create';
  },
  UPDATE_REPORTS: function UPDATE_REPORTS() {
    return 'report.update';
  },
  DESTROY_REPORTS: function DESTROY_REPORTS() {
    return 'report.destroy';
  },
  FIND_STOCKCHECKS: function FIND_STOCKCHECKS() {
    return 'stockcheck.find';
  },
  CREATE_STOCKCHECKS: function CREATE_STOCKCHECKS() {
    return 'stockcheck.create';
  },
  UPDATE_STOCKCHECKS: function UPDATE_STOCKCHECKS() {
    return 'stockcheck.update';
  },
  DESTROY_STOCKCHECKS: function DESTROY_STOCKCHECKS() {
    return 'stockcheck.destroy';
  },
  FINALIZE_STOCKCHECKS: function FINALIZE_STOCKCHECKS() {
    return 'stockcheck.finalize';
  },
  MERGE_STOCKCHECKS: function MERGE_STOCKCHECKS() {
    return 'stockcheck.merge';
  },
  GET_STOCKCHECK_FINALIZATION_DATA: function GET_STOCKCHECK_FINALIZATION_DATA() {
    return 'stockcheck.getFinalizationData';
  },
  FIND_ORDER_GROUPS: function FIND_ORDER_GROUPS() {
    return 'orderGroup.find';
  },
  CREATE_ORDER_GROUPS: function CREATE_ORDER_GROUPS() {
    return 'orderGroup.create';
  },
  UPDATE_ORDER_GROUPS: function UPDATE_ORDER_GROUPS() {
    return 'orderGroup.update';
  },
  DESTROY_ORDER_GROUPS: function DESTROY_ORDER_GROUPS() {
    return 'orderGroup.destroy';
  },
  FIND_LOSS_GROUPS: function FIND_LOSS_GROUPS() {
    return 'lossGroup.find';
  },
  CREATE_LOSS_GROUPS: function CREATE_LOSS_GROUPS() {
    return 'lossGroup.create';
  },
  UPDATE_LOSS_GROUPS: function UPDATE_LOSS_GROUPS() {
    return 'lossGroup.update';
  },
  DESTROY_LOSS_GROUPS: function DESTROY_LOSS_GROUPS() {
    return 'lossGroup.destroy';
  },
  FINALIZE_LOSS_GROUPS: function FINALIZE_LOSS_GROUPS() {
    return 'lossGroup.finalize';
  },
  GET_LOSS_GROUP_FINALIZATION_DATA: function GET_LOSS_GROUP_FINALIZATION_DATA() {
    return 'lossGroup.getFinalizationData';
  },
  FIND_STORAGE_STANDARDS: function FIND_STORAGE_STANDARDS() {
    return 'storageStandard.find';
  },
  CREATE_STORAGE_STANDARDS: function CREATE_STORAGE_STANDARDS() {
    return 'storageStandard.create';
  },
  UPDATE_STORAGE_STANDARDS: function UPDATE_STORAGE_STANDARDS() {
    return 'storageStandard.update';
  },
  DESTROY_STORAGE_STANDARDS: function DESTROY_STORAGE_STANDARDS() {
    return 'storageStandard.destroy';
  },
  FIND_SUPPLIERS: function FIND_SUPPLIERS() {
    return 'supplier.find';
  },
  CREATE_SUPPLIERS: function CREATE_SUPPLIERS() {
    return 'supplier.create';
  },
  UPDATE_SUPPLIERS: function UPDATE_SUPPLIERS() {
    return 'supplier.update';
  },
  DESTROY_SUPPLIERS: function DESTROY_SUPPLIERS() {
    return 'supplier.destroy';
  },
  FIND_ORDERS: function FIND_ORDERS() {
    return 'order.find';
  },
  CREATE_ORDERS: function CREATE_ORDERS() {
    return 'order.create';
  },
  UPDATE_ORDERS: function UPDATE_ORDERS() {
    return 'order.update';
  },
  DESTROY_ORDERS: function DESTROY_ORDERS() {
    return 'order.destroy';
  },
  APPROVE_ORDERS: function APPROVE_ORDERS() {
    return 'order.approve';
  },
  FIND_STORAGES: function FIND_STORAGES() {
    return 'storage.find';
  },
  //CREATE_STORAGES: () => 'storage.create',
  //UPDATE_STORAGES: () => 'storage.update',
  //DESTROY_STORAGES: () => 'storage.destroy',
  FIND_SALES: function FIND_SALES() {
    return 'sale.find';
  },
  CREATE_SALES: function CREATE_SALES() {
    return 'sale.create';
  },
  UPDATE_SALES: function UPDATE_SALES() {
    return 'sale.update';
  },
  DESTROY_SALES: function DESTROY_SALES() {
    return 'sale.destroy';
  },
  FIND_BARS: function FIND_BARS() {
    return 'bar.find';
  },
  FIND_DOMAIN_BARS: function FIND_DOMAIN_BARS() {
    return 'bar.findDomain';
  },
  CREATE_BARS: function CREATE_BARS() {
    return 'bar.create';
  },
  UPDATE_BARS: function UPDATE_BARS() {
    return 'bar.update';
  },
  DESTROY_BARS: function DESTROY_BARS() {
    return 'bar.destroy';
  },
  DESTROY_BULK_BARS: function DESTROY_BULK_BARS() {
    return 'bar.destroyBulk';
  },
  FIND_LOSSES: function FIND_LOSSES() {
    return 'loss.find';
  },
  // CREATE_LOSSES: () => 'loss.create',
  // UPDATE_LOSSES: () => 'loss.update',
  // DESTROY_LOSSES: () => 'loss.destroy',
  FIND_DOCUMENTS: function FIND_DOCUMENTS() {
    return 'document.find';
  },
  CREATE_DOCUMENTS: function CREATE_DOCUMENTS() {
    return 'document.create';
  },
  UPDATE_DOCUMENTS: function UPDATE_DOCUMENTS() {
    return 'document.update';
  },
  DESTROY_DOCUMENTS: function DESTROY_DOCUMENTS() {
    return 'document.destroy';
  },
  FIND_MEMBERS: function FIND_MEMBERS() {
    return 'member.find';
  },
  CREATE_MEMBERS: function CREATE_MEMBERS() {
    return 'member.create';
  },
  UPDATE_MEMBERS: function UPDATE_MEMBERS() {
    return 'member.update';
  },
  DESTROY_MEMBERS: function DESTROY_MEMBERS() {
    return 'member.destroy';
  },
  REINVITE_MEMBERS: function REINVITE_MEMBERS() {
    return 'member.reinvite';
  },
  FIND_SKUS: function FIND_SKUS() {
    return 'sku.find';
  },
  CREATE_SKUS: function CREATE_SKUS(_ref) {
    var data = _ref.data;
    return 'sku.' + data.stocktakeMode + '.create';
  },
  UPDATE_SKUS: function UPDATE_SKUS(_ref2) {
    var data = _ref2.data;
    return 'sku.' + data.stocktakeMode + '.update';
  },
  DESTROY_SKUS: function DESTROY_SKUS() {
    return 'sku.destroy';
  },
  FIND_SKU_DETAILS: function FIND_SKU_DETAILS() {
    return 'skuDetails.find';
  },
  CREATE_SKU_DETAILS: function CREATE_SKU_DETAILS() {
    return 'skuDetails.create';
  },
  UPDATE_SKU_DETAILS: function UPDATE_SKU_DETAILS() {
    return 'skuDetails.update';
  },
  DESTROY_SKU_DETAILS: function DESTROY_SKU_DETAILS() {
    return 'skuDetails.destroy';
  },
  FIND_SKU_INGREDIENTS: function FIND_SKU_INGREDIENTS() {
    return 'skuIngredient.find';
  },
  CREATE_SKU_INGREDIENTS: function CREATE_SKU_INGREDIENTS() {
    return 'skuIngredient.create';
  },
  UPDATE_SKU_INGREDIENTS: function UPDATE_SKU_INGREDIENTS() {
    return 'skuIngredient.update';
  },
  DESTROY_SKU_INGREDIENTS: function DESTROY_SKU_INGREDIENTS() {
    return 'skuIngredient.destroy';
  },
  FIND_STOCKCHECK_RECORDS: function FIND_STOCKCHECK_RECORDS() {
    return 'stocktakeRecord.findStockcheckRecords';
  },
  FIND_STORAGE_STANDARD_RECORDS: function FIND_STORAGE_STANDARD_RECORDS() {
    return 'storageRecord.findStorageStandardRecords';
  },
  FIND_LOSS_GROUP_RECORDS: function FIND_LOSS_GROUP_RECORDS() {
    return 'lossRecord.findLossGroupRecords';
  },
  CREATE_STOCKTAKE_RECORDS: function CREATE_STOCKTAKE_RECORDS(_ref3) {
    var data = _ref3.data;
    return 'stocktakeRecord.' + data.localSku.stocktakeMode + '.' + data.mode + '.create';
  },
  CREATE_ORDER_RECORDS: function CREATE_ORDER_RECORDS(_ref4) {
    var data = _ref4.data;
    return 'orderRecord.' + data.localSku.stocktakeMode + '.' + data.mode + '.create';
  },
  CREATE_LOSS_RECORDS: function CREATE_LOSS_RECORDS(_ref5) {
    var data = _ref5.data;
    return 'lossRecord.' + data.localSku.stocktakeMode + '.' + data.mode + '.create';
  },
  CREATE_STORAGE_RECORDS: function CREATE_STORAGE_RECORDS(_ref6) {
    var data = _ref6.data;
    return 'storageRecord.' + data.localSku.stocktakeMode + '.' + data.mode + '.create';
  },
  CREATE_SALE_RECORDS: function CREATE_SALE_RECORDS(_ref7) {
    var data = _ref7.data;
    return 'saleRecord.' + data.localSku.stocktakeMode + '.' + data.mode + '.create';
  },
  UPDATE_STOCKTAKE_RECORDS: function UPDATE_STOCKTAKE_RECORDS(_ref8) {
    var data = _ref8.data;
    return 'stocktakeRecord.' + data.localSku.stocktakeMode + '.' + data.mode + '.update';
  },
  UPDATE_ORDER_RECORDS: function UPDATE_ORDER_RECORDS(_ref9) {
    var data = _ref9.data;
    return 'orderRecord.' + data.localSku.stocktakeMode + '.' + data.mode + '.update';
  },
  UPDATE_LOSS_RECORDS: function UPDATE_LOSS_RECORDS(_ref10) {
    var data = _ref10.data;
    return 'lossRecord.' + data.localSku.stocktakeMode + '.' + data.mode + '.update';
  },
  UPDATE_STORAGE_RECORDS: function UPDATE_STORAGE_RECORDS(_ref11) {
    var data = _ref11.data;
    return 'storageRecord.' + data.localSku.stocktakeMode + '.' + data.mode + '.update';
  },
  UPDATE_SALE_RECORDS: function UPDATE_SALE_RECORDS(_ref12) {
    var data = _ref12.data;
    return 'saleRecord.' + data.localSku.stocktakeMode + '.' + data.mode + '.update';
  },
  DESTROY_STOCKTAKE_RECORDS: function DESTROY_STOCKTAKE_RECORDS() {
    return 'stocktakeRecord.destroy';
  },
  DESTROY_ORDER_RECORDS: function DESTROY_ORDER_RECORDS() {
    return 'orderRecord.destroy';
  },
  DESTROY_LOSS_RECORDS: function DESTROY_LOSS_RECORDS() {
    return 'lossRecord.destroy';
  },
  DESTROY_STORAGE_RECORDS: function DESTROY_STORAGE_RECORDS() {
    return 'storageRecord.destroy';
  },
  DESTROY_SALE_RECORDS: function DESTROY_SALE_RECORDS() {
    return 'saleRecord.destroy';
  },
  FIND_STOCKTAKE_RECORDS: function FIND_STOCKTAKE_RECORDS() {
    return 'stocktakeRecord.find';
  },
  FIND_ORDER_RECORDS: function FIND_ORDER_RECORDS() {
    return 'orderRecord.find';
  },
  FIND_ORDER_GROUP_RECORDS: function FIND_ORDER_GROUP_RECORDS() {
    return 'orderGroupRecord.find';
  },
  CREATE_ORDER_GROUP_RECORDS: function CREATE_ORDER_GROUP_RECORDS(_ref13) {
    var data = _ref13.data;
    return "orderGroupRecord.".concat(data.localSku.stocktakeMode, ".").concat(data.mode, ".create");
  },
  FIND_LOSS_RECORDS: function FIND_LOSS_RECORDS() {
    return 'lossRecord.find';
  },
  FIND_STORAGE_RECORDS: function FIND_STORAGE_RECORDS() {
    return 'storageRecord.find';
  },
  FIND_SALE_RECORDS: function FIND_SALE_RECORDS() {
    return 'saleRecord.find';
  },
  CREATE_BULK_STOCKTAKE_RECORDS: function CREATE_BULK_STOCKTAKE_RECORDS(_ref14) {
    var data = _ref14.data;
    return "stocktakeRecord.".concat(data[0].localSku.stocktakeMode, ".createBulk");
  },
  CREATE_BULK_ORDER_RECORDS: function CREATE_BULK_ORDER_RECORDS(_ref15) {
    var data = _ref15.data;
    return "orderRecord.".concat(data[0].localSku.stocktakeMode, ".createBulk");
  },
  CREATE_BULK_LOSS_RECORDS: function CREATE_BULK_LOSS_RECORDS(_ref16) {
    var data = _ref16.data;
    return "lossRecord.".concat(data[0].localSku.stocktakeMode, ".createBulk");
  },
  CREATE_BULK_STORAGE_RECORDS: function CREATE_BULK_STORAGE_RECORDS(_ref17) {
    var data = _ref17.data;
    return "storageRecord.".concat(data[0].localSku.stocktakeMode, ".createBulk");
  },
  UPDATE_BULK_STORAGE_RECORDS: function UPDATE_BULK_STORAGE_RECORDS() {
    return 'storageRecord.updateBulk';
  },
  CREATE_BULK_SALE_RECORDS: function CREATE_BULK_SALE_RECORDS(_ref18) {
    var data = _ref18.data;
    return "saleRecord.".concat(data[0].localSku.stocktakeMode, ".createBulk");
  },
  DESTROY_GROUP_STOCKTAKE_RECORDS: function DESTROY_GROUP_STOCKTAKE_RECORDS() {
    return 'stocktakeRecord.destroyGroup';
  },
  DESTROY_GROUP_ORDER_RECORDS: function DESTROY_GROUP_ORDER_RECORDS() {
    return 'orderRecord.destroyGroup';
  },
  DESTROY_GROUP_LOSS_RECORDS: function DESTROY_GROUP_LOSS_RECORDS() {
    return 'lossRecord.destroyGroup';
  },
  DESTROY_GROUP_STORAGE_RECORDS: function DESTROY_GROUP_STORAGE_RECORDS() {
    return 'storageRecord.destroyGroup';
  },
  DESTROY_GROUP_SALE_RECORDS: function DESTROY_GROUP_SALE_RECORDS() {
    return 'saleRecord.destroyGroup';
  },
  DESTROY_BULK_GROUP_STOCKTAKE_RECORDS: function DESTROY_BULK_GROUP_STOCKTAKE_RECORDS() {
    return 'stocktakeRecord.destroyBulkGroup';
  },
  DESTROY_BULK_GROUP_STORAGE_RECORDS: function DESTROY_BULK_GROUP_STORAGE_RECORDS() {
    return 'storageRecord.destroyBulkGroup';
  },
  DESTROY_BULK_GROUP_LOSS_RECORDS: function DESTROY_BULK_GROUP_LOSS_RECORDS() {
    return 'lossRecord.destroyBulkGroup';
  },
  DESTROY_BULK_GROUP_ORDER_RECORDS: function DESTROY_BULK_GROUP_ORDER_RECORDS() {
    return 'orderRecord.destroyBulkGroup';
  },
  DESTROY_BULK_GROUP_SALE_RECORDS: function DESTROY_BULK_GROUP_SALE_RECORDS() {
    return 'saleRecord.destroyBulkGroup';
  },
  REORDER_BARS: function REORDER_BARS() {
    return 'bar.reorder';
  },
  ACTIVATE_STORAGE_STANDARDS: function ACTIVATE_STORAGE_STANDARDS() {
    return 'storageStandard.activate';
  },
  CHANGE_PASSWORD_USERS: function CHANGE_PASSWORD_USERS() {
    return 'user.changePassword';
  },
  CONFLICT_CHECK_STOCKCHECKS: function CONFLICT_CHECK_STOCKCHECKS() {
    return 'stockcheck.conflictCheck';
  },
  SEARCH_SKUS: function SEARCH_SKUS() {
    return 'sku.search';
  },
  IMPORT_SKUS: function IMPORT_SKUS() {
    return 'sku.import';
  },
  LIST_STOCKTAKE_SKUS: function LIST_STOCKTAKE_SKUS() {
    return 'sku.listStocktake';
  },
  LIST_ORDER_SKUS: function LIST_ORDER_SKUS() {
    return 'sku.listOrder';
  },
  LIST_ORDER_GROUP_SKUS: function LIST_ORDER_GROUP_SKUS() {
    return 'sku.listOrderGroup';
  },
  LIST_SALE_SKUS: function LIST_SALE_SKUS() {
    return 'sku.listSale';
  },
  LIST_STORAGE_SKUS: function LIST_STORAGE_SKUS() {
    return 'sku.listStorage';
  },
  LIST_LOSS_SKUS: function LIST_LOSS_SKUS() {
    return 'sku.listLoss';
  },
  SWITCH_RESTAURANTS: function SWITCH_RESTAURANTS() {
    return 'restaurant.switch';
  },
  GET_DATA_REPORTS: function GET_DATA_REPORTS() {
    return 'report.getData';
  },
  GET_PRICING_ORDER_RECORDS: function GET_PRICING_ORDER_RECORDS() {
    return 'report.getPricingOrderRecords';
  },
  PROCESS_NOTIFICATIONS: function PROCESS_NOTIFICATIONS() {
    return 'notification.process';
  },
  FIND_NOTIFICATIONS: function FIND_NOTIFICATIONS() {
    return 'notification.find';
  },
  FIND_STORAGE_TRANSFERS: function FIND_STORAGE_TRANSFERS() {
    return 'storageTransfer.find';
  },
  FIND_DOMAIN_STORAGE_TRANSFERS: function FIND_DOMAIN_STORAGE_TRANSFERS() {
    return 'storageTransfer.findDomain';
  },
  FIND_STORAGE_TRANSFER_GROUPS: function FIND_STORAGE_TRANSFER_GROUPS() {
    return 'storageTransferGroup.find';
  },
  FIND_DOMAIN_STORAGE_TRANSFER_GROUPS: function FIND_DOMAIN_STORAGE_TRANSFER_GROUPS() {
    return 'storageTransferGroup.findDomain';
  },
  CREATE_STORAGE_TRANSFER_GROUPS: function CREATE_STORAGE_TRANSFER_GROUPS() {
    return 'storageTransferGroup.create';
  },
  UPDATE_STORAGE_TRANSFER_GROUPS: function UPDATE_STORAGE_TRANSFER_GROUPS() {
    return 'storageTransferGroup.update';
  },
  DESTROY_STORAGE_TRANSFER_GROUPS: function DESTROY_STORAGE_TRANSFER_GROUPS() {
    return 'storageTransferGroup.destroy';
  },
  CREATE_BULK_STORAGE_TRANSFERS: function CREATE_BULK_STORAGE_TRANSFERS() {
    return 'storageTransfer.createBulk';
  },
  CREATE_BULK_STORAGE_TRANSFER_RECORDS: function CREATE_BULK_STORAGE_TRANSFER_RECORDS(_ref19) {
    var data = _ref19.data;
    return "storageTransferRecord.".concat(data[0].localSku.stocktakeMode, ".createBulk");
  },
  DESTROY_BULK_STORAGE_TRANSFERS: function DESTROY_BULK_STORAGE_TRANSFERS() {
    return 'storageTransfer.destroyBulk';
  },
  DESTROY_BULK_STORAGE_TRANSFER_RECORDS: function DESTROY_BULK_STORAGE_TRANSFER_RECORDS() {
    return 'storageTransferRecord.destroyBulk';
  },
  FIND_STORAGE_TRANSFER_GROUP_RECORDS: function FIND_STORAGE_TRANSFER_GROUP_RECORDS() {
    return 'storageTransferRecord.findStorageTransferGroupRecords';
  },
  FIND_INTEGRATION_INSTANCES: function FIND_INTEGRATION_INSTANCES() {
    return 'integrationInstance.find';
  },
  FIND_INTEGRATION_INSTANCE_CONTEXTS: function FIND_INTEGRATION_INSTANCE_CONTEXTS() {
    return 'integrationInstanceContext.find';
  },
  CREATE_INTEGRATION_INSTANCE_CONTEXTS: function CREATE_INTEGRATION_INSTANCE_CONTEXTS() {
    return 'integrationInstanceContext.create';
  },
  DESTROY_INTEGRATION_INSTANCE_CONTEXTS: function DESTROY_INTEGRATION_INSTANCE_CONTEXTS() {
    return 'integrationInstanceContext.destroy';
  },
  FIND_INTEGRATION_ENTRIES: function FIND_INTEGRATION_ENTRIES() {
    return 'integrationEntry.find';
  },
  CREATE_INTEGRATION_ENTRIES: function CREATE_INTEGRATION_ENTRIES() {
    return 'integrationEntry.create';
  },
  UPDATE_INTEGRATION_ENTRIES: function UPDATE_INTEGRATION_ENTRIES() {
    return 'integrationEntry.update';
  },
  DESTROY_INTEGRATION_ENTRIES: function DESTROY_INTEGRATION_ENTRIES() {
    return 'integrationEntry.destroy';
  },
  FIND_SKU_SUPPLIERS: function FIND_SKU_SUPPLIERS() {
    return 'skuSupplier.find';
  },
  SYNCHRONIZE_BULK_SKU_SUPPLIERS: function SYNCHRONIZE_BULK_SKU_SUPPLIERS() {
    return 'skuSupplier.synchronizeBulk';
  },
  FIND_PRODUCTION_GROUPS: function FIND_PRODUCTION_GROUPS() {
    return 'productionGroup.find';
  },
  CREATE_PRODUCTION_GROUPS: function CREATE_PRODUCTION_GROUPS() {
    return 'productionGroup.create';
  },
  UPDATE_PRODUCTION_GROUPS: function UPDATE_PRODUCTION_GROUPS() {
    return 'productionGroup.update';
  },
  DESTROY_PRODUCTION_GROUPS: function DESTROY_PRODUCTION_GROUPS() {
    return 'productionGroup.destroy';
  },
  //CREATE_BULK_PRODUCTION_RECORDS: ({ data }) => `productionRecord.${data[0].localSku.stocktakeMode}.createBulk`,
  DESTROY_BULK_PRODUCTION_RECORDS: function DESTROY_BULK_PRODUCTION_RECORDS() {
    return 'productionRecord.destroyBulk';
  },
  DESTROY_BULK_ORDER_RECORDS: function DESTROY_BULK_ORDER_RECORDS() {
    return 'orderRecord.destroyBulk';
  },
  FIND_PRODUCTION_GROUP_RECORDS: function FIND_PRODUCTION_GROUP_RECORDS() {
    return 'productionRecord.findProductionGroupRecords';
  },
  FIND_PROPERTIES: function FIND_PROPERTIES() {
    return 'property.find';
  },
  CREATE_DOMAIN_PROPERTIES: function CREATE_DOMAIN_PROPERTIES() {
    return 'property.domain.create';
  },
  UPDATE_DOMAIN_PROPERTIES: function UPDATE_DOMAIN_PROPERTIES() {
    return 'property.domain.update';
  },
  CREATE_RESTAURANT_PROPERTIES: function CREATE_RESTAURANT_PROPERTIES() {
    return 'property.restaurant.create';
  },
  UPDATE_RESTAURANT_PROPERTIES: function UPDATE_RESTAURANT_PROPERTIES() {
    return 'property.restaurant.update';
  },
  CREATE_USER_PROPERTIES: function CREATE_USER_PROPERTIES() {
    return 'property.user.create';
  },
  UPDATE_USER_PROPERTIES: function UPDATE_USER_PROPERTIES() {
    return 'property.user.update';
  },
  SYNCHRONIZE_FAKIR_TAX_IDS: function SYNCHRONIZE_FAKIR_TAX_IDS() {
    return 'fakir.syncTaxIds';
  },
  FIND_RECIPES: function FIND_RECIPES() {
    return 'recipe.find';
  },
  DESTROY_ORDER_GROUP_RECORDS: function DESTROY_ORDER_GROUP_RECORDS() {
    return 'orderGroupRecord.destroy';
  }
};
exports.ACTION_VALIDATION = ACTION_VALIDATION;