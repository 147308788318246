import React, { useEffect, useState, useRef } from 'react';
import { useFormikContext, useField } from 'formik';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils, { formatDate } from 'react-day-picker/moment';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';
import classNames from 'classnames';
import { Popover, PopoverBody, ListGroup, ListGroupItem, Label, FormGroup } from 'reactstrap';
import { compact, kebabCase } from 'lodash';
import { __, getLanguage } from '@stktk/locales';
import Input from '$ui/form/controls/Input';
import Button from '$ui/Button';

/* eslint-disable complexity */
const DayPickerDay = ({ day, modifiers, items, selectedValues, itemsLabel, itemLabelExtractor, itemValueExtractor, onItemClick }) => {
  const date = moment(day);
  const dayId = `day-${date.format('YYYYMMDD')}`;

  const handleItemClick = (event, item) => {
    event.preventDefault();
    onItemClick(item, day);
  };

  return (
    <div data-testid={`DayPickerDay-${date.format('YYYY-MM-DD')}`}>
      <div id={dayId} className="p-2">
        {day.getDate()}
      </div>
      {items && items.length > 0 && (
        <Popover
          positionFixed
          id="popover-date-picker"
          target={dayId}
          isOpen={modifiers.popover}
          placement="bottom"
          container="inline"
          boundariesElement="window"
        >
          <PopoverBody>
            <Label>{itemsLabel || __('general_items')}</Label>
            <ListGroup>
              {items.map((item, index) =>
                <ListGroupItem
                  key={`${dayId}-item-${index}`}
                  action
                  active={selectedValues.indexOf(itemValueExtractor(item)) > -1}
                  id={`${dayId}-item-${index}`}
                  tag="button"
                  className={`PopoverButton${index}`}
                  onClick={event => handleItemClick(event, item)}
                >
                  {itemLabelExtractor(item)}
                </ListGroupItem>
              )}
            </ListGroup>
          </PopoverBody>
        </Popover>
      )}
    </div>
  );
};

const FormDate = ({ id, name, className, disabled, submitOnBlur, saveButton, showTimeInput = false, focusOnRender, format = 'LL', isRange, multiValues, name2, items, itemsLabel, itemDateExtractor, itemLabelExtractor, itemValueExtractor }) => {

  const getFormattedDate = value =>
    moment(value).locale(getLanguage()).format(format);

  const parseValue = (startDate, endDate = null) => {
    if (startDate) {
      let formattedDate = getFormattedDate(startDate);
      if (isRange)
        return `${formattedDate} → ${endDate ? getFormattedDate(endDate) : '...'}`;
      return formattedDate;
    }
    return '';
  };

  const parseDate = value => {
    let date = moment(value);
    return value && date.isValid() ? date.toDate() : null;
  };

  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(name);
  const { submitForm, setFieldValue, values } = useFormikContext();
  const [popover, setPopover] = useState(false);
  const [popoverDate, setPopoverDate] = useState(null);
  const [markDate, setMarkDate] = useState(null);
  const [time, setTime] = useState(value ? moment(value).format('HH:mm') : '');
  const [startDate, setStartDate] = useState(parseDate(value));
  const [endDate, setEndDate] = useState(parseDate(values[name2]));
  const [inputValue, setInputValue] = useState(parseValue(startDate, endDate));
  const input = useRef(null);

  useEffect(() => {
    if (focusOnRender) {
      input.current.focus();
      setPopover(true);
    }
  }, [focusOnRender]);

  const getDayItems = day => items.filter(item => moment(itemDateExtractor(item)).isSame(day, 'day'));

  const doNotHaveItems = day => !getDayItems(day).length;

  // TODO: Can add typing date with use of masks in the future
  const handleChange = () => {};

  const handleKeyDown = event => {
    // Remove values on Backspace or Delete keydown
    if (event.keyCode === 8 || event.keyCode === 46) {
      event.preventDefault();
      setMarkDate(null);
      setInputValue('');
      setValue(null);
      setStartDate(null);
      if (isRange) {
        setFieldValue(name2, null);
        setEndDate(null);
      }
      if (multiValues)
        multiValues.forEach((v, index) => {
          setFieldValue(`${name2}[${index}]eventDate`, null);
        });
    }
  };

  const setValueAndDate = (value, day) => {
    if (multiValues)
      multiValues.forEach((v, index) => {
        setFieldValue(`${name2}[${index}]eventDate`, value);
      });

    if (!startDate || !isRange || moment(day).isBefore(startDate)) {
      setValue(value);
      setStartDate(day);
      setInputValue(parseValue(day, endDate));
      if (!isRange) {
        setTouched(true);
      }
    } else {
      setMarkDate(day);
      setEndDate(day);
      setFieldValue(name2, value);
      setInputValue(parseValue(startDate, day));
      setTouched(true);
    }
  };

  const handleDayMouseEnter = (day, { disabled }) =>
    isRange && value && !values[name2] && setMarkDate(disabled ? null : day);

  const handleDayClick = (day, { disabled, popover }) => {
    if (disabled || popover)
      return false;

    let value = moment(day).toISOString();

    if (items) {
      let dayItems = getDayItems(day);
      if (dayItems.length > 1)
        return setPopoverDate(day);
      value = itemValueExtractor(dayItems[0]);
    }

    return setValueAndDate(value, day);
  };

  const handleItemClick = (item, day) => {
    setPopoverDate(null);
    return setValueAndDate(itemValueExtractor(item), day);
  };

  const handleFocus = () => setPopover(true);

  const handleOutsideClick = () => {
    setPopoverDate(null);
    setPopover(false);
    if (submitOnBlur)
      submitForm();
  };

  const handleTimeChange = ({ target }) => {
    let dayTime = moment(`${moment(value).format('YYYY-MM-DD')} ${target.value}`);
    // console.log(dayTime);
    if (dayTime.isValid() && target.value.length < 9)
      setFieldValue(name, moment(dayTime).toISOString());
    return setTime(target.value);
  };

  let target = id || `datepicker-${kebabCase(name)}`;
  let marked = {
    from: moment(startDate).toDate(),
    to: moment(markDate).toDate()
  };
  let modifiers = { marked, popover: popoverDate };
  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <Input
        ref={input}
        id={target}
        value={inputValue}
        placeholder={formatDate(new Date(), format, getLanguage())}
        className={classNames({ 'is-invalid': touched && error, 'is-valid': touched && !error }, className)}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onClick={disabled ? () => {} : handleFocus}
      />
      <Popover
        hideArrow
        positionFixed
        popperClassName="popover--xl"
        placement="bottom-start"
        container="inline"
        isOpen={popover}
        target={target}
        toggle={() => setPopover(false)}
        boundariesElement="window"
      >
        <PopoverBody>
          <DayPicker
            modifiers={modifiers}
            disabledDays={items ? doNotHaveItems : []}
            numberOfMonths={isRange ? 2 : 1}
            selectedDays={compact([startDate, endDate])}
            renderDay={(day, modifiers) => (
              <DayPickerDay
                day={day}
                modifiers={modifiers}
                items={items ? getDayItems(day) : null}
                itemsLabel={itemsLabel}
                itemLabelExtractor={itemLabelExtractor}
                itemValueExtractor={itemValueExtractor}
                selectedValues={compact([value, name2 ? values[name2] : null])}
                onItemClick={handleItemClick}
              />
            )}
            locale={getLanguage()}
            localeUtils={MomentLocaleUtils}
            month={isRange ? moment().subtract(1, 'month').toDate() : new Date()}
            onDayClick={handleDayClick}
            onDayMouseEnter={handleDayMouseEnter}
          />
          {showTimeInput && !isRange && (
            <FormGroup>
              <Label>{__('general_time')}</Label>
              <Input value={time} onChange={handleTimeChange} />
            </FormGroup>
          )}
          {saveButton && (
            <div className={'text-right form-group'}>
              <Button
                type="submit"
                variant="success"
                text={__('button_save')}
              />
            </div>
          )}
        </PopoverBody>
      </Popover>
    </OutsideClickHandler>
  );
};

export default FormDate;