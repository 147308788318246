"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var yup = _interopRequireWildcard(require("yup"));

var _lodash = require("lodash");

var _commons = require("@stktk/commons");

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

yup.setLocale({
  mixed: {
    "default": function _default(_ref) {
      var path = _ref.path;
      return {
        message: 'validator_error_mixed_default',
        path: path
      };
    },
    required: function required(_ref2) {
      var path = _ref2.path,
          message = _ref2.message;
      return {
        message: message || 'validator_error_mixed_required',
        path: path
      };
    },
    oneOf: function oneOf(_ref3) {
      var path = _ref3.path,
          message = _ref3.message,
          values = _ref3.values;
      return {
        message: message || 'validator_error_mixed_oneOf',
        path: path,
        values: values
      };
    },
    notOneOf: function notOneOf(_ref4) {
      var path = _ref4.path,
          values = _ref4.values;
      return {
        message: 'validator_error_mixed_notOneOf',
        path: path,
        values: values
      };
    },
    notType: function notType(_ref5) {
      var path = _ref5.path,
          type = _ref5.type,
          value = _ref5.value,
          message = _ref5.message,
          originalValue = _ref5.originalValue;
      var isCast = originalValue != null && originalValue !== value;
      return {
        message: message || (isCast ? 'validator_error_mixed_notTypeIsCast' : 'validator_error_mixed_notType'),
        path: path,
        type: type,
        value: value,
        isCast: isCast,
        originalValue: originalValue
      };
    }
  },
  string: {
    length: function length(_ref6) {
      var path = _ref6.path,
          _length = _ref6.length;
      return {
        message: 'validator_error_string_length',
        path: path,
        length: _length
      };
    },
    min: function min(_ref7) {
      var path = _ref7.path,
          _min = _ref7.min;
      return {
        message: 'validator_error_string_min',
        path: path,
        min: _min
      };
    },
    max: function max(_ref8) {
      var path = _ref8.path,
          _max = _ref8.max;
      return {
        message: 'validator_error_string_max',
        path: path,
        max: _max
      };
    },
    matches: function matches(_ref9) {
      var path = _ref9.path,
          regex = _ref9.regex;
      return {
        message: 'validator_error_string_matches',
        path: path,
        regex: regex
      };
    },
    email: function email(_ref10) {
      var path = _ref10.path;
      return {
        message: 'validator_error_string_email',
        path: path
      };
    },
    url: function url(_ref11) {
      var path = _ref11.path;
      return {
        message: 'validator_error_string_url',
        path: path
      };
    },
    trim: function trim(_ref12) {
      var path = _ref12.path;
      return {
        message: 'validator_error_string_trim',
        path: path
      };
    },
    lowercase: function lowercase(_ref13) {
      var path = _ref13.path;
      return {
        message: 'validator_error_string_lowercase',
        path: path
      };
    },
    uppercase: function uppercase(_ref14) {
      var path = _ref14.path;
      return {
        message: 'validator_error_string_uppercase',
        path: path
      };
    }
  },
  number: {
    min: function min(_ref15) {
      var path = _ref15.path,
          _min2 = _ref15.min;
      return {
        message: 'validator_error_number_min',
        path: path,
        min: _min2
      };
    },
    max: function max(_ref16) {
      var path = _ref16.path,
          _max2 = _ref16.max;
      return {
        message: 'validator_error_number_max',
        path: path,
        max: _max2
      };
    },
    lessThan: function lessThan(_ref17) {
      var path = _ref17.path,
          less = _ref17.less;
      return {
        message: 'validator_error_number_lessThan',
        path: path,
        less: less
      };
    },
    moreThan: function moreThan(_ref18) {
      var path = _ref18.path,
          more = _ref18.more;
      return {
        message: 'validator_error_number_moreThan',
        path: path,
        more: more
      };
    },
    notEqual: function notEqual(_ref19) {
      var path = _ref19.path,
          _notEqual = _ref19.notEqual;
      return {
        message: 'validator_error_number_notEqual',
        path: path,
        notEqual: _notEqual
      };
    },
    positive: function positive(_ref20) {
      var path = _ref20.path;
      return {
        message: 'validator_error_number_positive',
        path: path
      };
    },
    negative: function negative(_ref21) {
      var path = _ref21.path;
      return {
        message: 'validator_error_number_negative',
        path: path
      };
    },
    integer: function integer(_ref22) {
      var path = _ref22.path;
      return {
        message: 'validator_error_number_integer',
        path: path
      };
    }
  },
  date: {
    min: function min(_ref23) {
      var path = _ref23.path,
          _min3 = _ref23.min;
      return {
        message: 'validator_error_date_min',
        path: path,
        min: _min3
      };
    },
    max: function max(_ref24) {
      var path = _ref24.path,
          _max3 = _ref24.max;
      return {
        message: 'validator_error_date_max',
        path: path,
        max: _max3
      };
    }
  },
  object: {
    noUnknown: function noUnknown(_ref25) {
      var path = _ref25.path;
      return {
        message: 'validator_error_object_noUnknown',
        path: path
      };
    }
  },
  array: {
    min: function min(_ref26) {
      var path = _ref26.path,
          _min4 = _ref26.min;
      return {
        message: 'validator_error_array_min',
        path: path,
        min: _min4
      };
    },
    max: function max(_ref27) {
      var path = _ref27.path,
          _max4 = _ref27.max;
      return {
        message: 'validator_error_array_max',
        path: path,
        max: _max4
      };
    }
  }
});
yup.addMethod(yup.mixed, 'forbidden', function () {
  return this.test('mixed.forbidden', function (_ref28) {
    var path = _ref28.path;
    return {
      message: 'validator_error_forbidden',
      path: path
    };
  }, function (value) {
    if (typeof value !== 'undefined') return this.createError();
    return true;
  });
});
yup.addMethod(yup.object, 'defined', function () {
  return this.transform(function (value) {
    return (0, _lodash.omitBy)(value, _lodash.isUndefined);
  });
});
yup.addMethod(yup.string, 'barcode', function () {
  return this.test('string.barcode', function (_ref29) {
    var path = _ref29.path;
    return {
      message: 'validator_error_string_barcode',
      path: path
    };
  }, function (value) {
    if (value && !(0, _lodash.find)(_commons.SkuBarcodeFormatEnum.toArray(), function (schema) {
      return value.match(schema.regex);
    })) return this.createError();
    return true;
  });
});
yup.addMethod(yup.string, 'dateString', function () {
  return this.test('string.dateString', function (_ref30) {
    var path = _ref30.path;
    return {
      message: 'validator_error_date_dateString',
      path: path
    };
  }, function (value) {
    if (value && new Date(value).toString() == 'Invalid Date') return this.createError();
    return true;
  });
});
yup.addMethod(yup.string, 'dateRangeDuration', function (refs) {
  return this.test('string.dateRangeDuration', function (_ref31) {
    var path = _ref31.path;
    return {
      message: refs.message || 'validator_error_date_dateRangeDuration',
      path: path
    };
  }, function
    /*value*/
  () {
    var rangeFromDate = this.resolve(refs.rangeFromDate);
    var rangeToDate = this.resolve(refs.rangeToDate);
    var duration = this.resolve(refs.duration);

    var diff = _moment["default"].duration((0, _moment["default"])(rangeToDate).diff((0, _moment["default"])(rangeFromDate)));

    console.log({
      rangeFromDate: rangeFromDate,
      rangeToDate: rangeToDate,
      duration: duration,
      diff: diff
    });
    if (diff > duration) return this.createError();
    return true;
  });
});
yup.addMethod(yup.number, 'convertedNetWeight', function (refs) {
  return this.test('number.convertedNetWeight', function (_ref32) {
    var path = _ref32.path;
    return {
      message: refs.message || 'validator_error_stktk_converted_net_weight',
      path: path
    };
  }, function (packageWeightNet) {
    //const packageWeightNet = args;
    var packageWeightNetUnitId = this.resolve(refs.packageWeightNetUnitId);
    var packageWeightGross = this.resolve(refs.packageWeightGross);
    var packageWeightGrossUnitId = this.resolve(refs.packageWeightGrossUnitId);
    var capWeight = this.resolve(refs.capWeight) || 0;
    var capWeightUnitId = this.resolve(refs.capWeightUnitId) || packageWeightNetUnitId; // console.log('normalized net weight', convertAmount(packageWeightNet, packageWeightNetUnitId, packageWeightNetUnitId), __unit(packageWeightNetUnitId, UnitPropertyEnum.SHORT_NAME));
    // console.log('normalized gross weight', convertAmount(packageWeightGross, packageWeightGrossUnitId, packageWeightNetUnitId), __unit(packageWeightNetUnitId, UnitPropertyEnum.SHORT_NAME));
    // console.log('normalized cap weight', convertAmount(capWeight, capWeightUnitId, packageWeightNetUnitId), __unit(packageWeightNetUnitId, UnitPropertyEnum.SHORT_NAME));
    // console.log(convertAmount(packageWeightGross, packageWeightGrossUnitId, packageWeightNetUnitId) - convertAmount(packageWeightNet, packageWeightNetUnitId, packageWeightNetUnitId) - convertAmount(capWeight, capWeightUnitId, packageWeightNetUnitId));

    if ((0, _commons.convertAmount)(packageWeightGross, packageWeightGrossUnitId, packageWeightNetUnitId) - (0, _commons.convertAmount)(packageWeightNet, packageWeightNetUnitId, packageWeightNetUnitId) - (0, _commons.convertAmount)(capWeight, capWeightUnitId, packageWeightNetUnitId) < 0) return this.createError();
    return true; //this.createError();
  });
});
yup.addMethod(yup.number, 'convertedGrossWeight', function (refs) {
  return this.test('number.convertedGrossWeight', function (_ref33) {
    var path = _ref33.path;
    return {
      message: refs.message || 'validator_error_stktk_converted_gross_weight',
      path: path
    };
  }, function (packageWeightGross) {
    var packageWeightNet = this.resolve(refs.packageWeightNet);
    var packageWeightNetUnitId = this.resolve(refs.packageWeightNetUnitId); //const packageWeightGross = args;

    var packageWeightGrossUnitId = this.resolve(refs.packageWeightGrossUnitId);
    var capWeight = this.resolve(refs.capWeight) || 0;
    var capWeightUnitId = this.resolve(refs.capWeightUnitId) || packageWeightNetUnitId;
    if ((0, _commons.convertAmount)(packageWeightGross, packageWeightGrossUnitId, packageWeightNetUnitId) - (0, _commons.convertAmount)(packageWeightNet, packageWeightNetUnitId, packageWeightNetUnitId) - (0, _commons.convertAmount)(capWeight, capWeightUnitId, packageWeightNetUnitId) < 0) return this.createError();
    return true; //this.createError();
  });
});
yup.addMethod(yup.number, 'convertedCapWeight', function (refs) {
  return this.test('number.convertedCapWeight', function (_ref34) {
    var path = _ref34.path;
    return {
      message: refs.message || 'validator_error_stktk_converted_cap_weight',
      path: path
    };
  }, function (capWeight) {
    var packageWeightNet = this.resolve(refs.packageWeightNet);
    var packageWeightNetUnitId = this.resolve(refs.packageWeightNetUnitId);
    var packageWeightGross = this.resolve(refs.packageWeightGross);
    var packageWeightGrossUnitId = this.resolve(refs.packageWeightGrossUnitId); //const capWeight = this.resolve(refs.capWeight);

    var capWeightUnitId = this.resolve(refs.capWeightUnitId) || packageWeightNetUnitId;
    if ((0, _commons.convertAmount)(packageWeightGross, packageWeightGrossUnitId, packageWeightNetUnitId) - (0, _commons.convertAmount)(packageWeightNet, packageWeightNetUnitId, packageWeightNetUnitId) - (0, _commons.convertAmount)(capWeight, capWeightUnitId, packageWeightNetUnitId) < 0) return this.createError();
    return true; //this.createError();
  });
});
yup.addMethod(yup.array, 'items', function (schemes) {
  return this.test('array.items', function (_ref35) {
    var path = _ref35.path;
    return {
      message: 'validator_error_array_items',
      path: path
    };
  }, function (values) {
    var validatePlatform = this.options.context.validatePlatform;
    var validation = (0, _lodash.map)(values, function (value) {
      return (// eslint-disable-next-line max-nested-callbacks
        (0, _lodash.map)(schemes, function (scheme) {
          // FIXME: wtf is this shit. beter way to local
          var validationScheme = validatePlatform == 'server' ? // eslint-disable-next-line max-nested-callbacks
          (0, _lodash.omitBy)(scheme, function (value, key) {
            return key.startsWith('local');
          }) : validatePlatform == 'redux' ? Object.assign({}, scheme, {
            localId: yup.number().notRequired()
          }) : scheme;
          return yup.object(validationScheme).noUnknown(true).isValidSync(value);
        })
      );
    });
    return (0, _lodash.find)(validation, function (array) {
      return array.indexOf(true) < 0;
    }) ? this.createError() : true;
  });
});

yup.mixed.prototype.empty = function (value) {
  var next = this.clone();
  next._empty = value !== false;
  return next;
}; // eslint-disable-next-line complexity


yup.mixed.prototype.isType = function (v) {
  if (this._nullable && v === null) {
    return true;
  } else if (this._empty && v === '') {
    return true;
  }

  return !this._typeCheck || this._typeCheck(v);
};

var _default2 = yup;
exports["default"] = _default2;