import React from 'react';
import { Code } from 'react-content-loader';
import loadable from '@loadable/component';

//FIXME: fallback onhover popup i18n (title/ariaLabel doesn't seem to work (currently: "Loading interface..."))
const options = { fallback: <Code /> };

export const Alert = loadable(() => import('$ui/Alert'), options);

export const Background = loadable(() => import('$ui/Background'), options);

export const Breadcrumbs = loadable(() => import('$ui/Breadcrumbs'), options);

export const Button = loadable(() => import('$ui/Button'), options);

export const ButtonFormModal = loadable(() => import('$ui/ButtonFormModal'), options);

export const CategoryBadge = loadable(() => import('$ui/CategoryBadge'), options);

export const CheckboxLabeled = loadable(() => import('$ui/form/controls/CheckboxLabeled'), options);

export const Content = loadable(() => import('$ui/Content'), options);

export const DashboardCalendar = loadable(() => import('$ui/DashboardCalendar'), options);

export const FileDropzone = loadable(() => import('$ui/FileDropzone'), options);

export const Flex = loadable(() => import('$ui/Flex'), options);

export const Footer = loadable(() => import('$ui/Footer'), options);

export const IFrame = loadable(() => import('$ui/IFrame'), options);

export const Icon = loadable(() => import('$ui/Icon'), options);

export const Image = loadable(() => import('$ui/Image'), options);

export const InfoTooltip = loadable(() => import('$ui/InfoTooltip'), options);

export const Loading = loadable(() => import('$ui/Loading'), options);

export const LoadingText = loadable(() => import('$ui/LoadingText'), options);

export const NavSelect = loadable(() => import('$ui/NavSelect'), options);

export const NotificationAlert = loadable(() => import('$ui/NotificationAlert'), options);

export const NumericInput = loadable(() => import('$ui/NumericInput'), options);

export const OrderDropdown = loadable(() => import('$ui/OrderDropdown'), options);

export const PageControls = loadable(() => import('$ui/PageControls'), options);

export const Pagination = loadable(() => import('$ui/Pagination'), options);

export const RecordsValue = loadable(() => import('$ui/RecordsValue'), options);

export const RecordsSaleValue = loadable(() => import('$ui/RecordsSaleValue'), options);

export const Section = loadable(() => import('$ui/Section'), options);

export const SkuImage = loadable(() => import('$ui/SkuImage'), options);

export const ViewVariants = loadable(() => import('$ui/ViewVariants'), options);

export const WizardNav = loadable(() => import('$ui/WizardNav'), options);

export const CardSummary = loadable(() => import('$ui/chart/CardSummary'), options);

export const CircleProgressChart = loadable(() => import('$ui/chart/CircleProgressChart'), options);

export const ProgressBar = loadable(() => import('$ui/chart/ProgressBar'), options);

export const AddRecordNav = loadable(() => import('$ui/form/AddRecordNav'), options);

export const AddRecordSku = loadable(() => import('$ui/form/AddRecordSku'), options);

export const ContextSelect = loadable(() => import('$ui/form/ContextSelect'), options);

export const Form = loadable(() => import('$ui/form/Form'), options);

export const FormArray = loadable(() => import('$ui/form/FormArray'), options);

export const FormBarGroup = loadable(() => import('$ui/form/FormBarGroup'), options);

export const FormBarcode = loadable(() => import('$ui/form/FormBarcode'), options);

export const FormBlock = loadable(() => import('$ui/form/FormBlock'), options);

export const FormButtonCancel = loadable(() => import('$ui/form/FormButtonCancel'), options);

export const FormButtonSubmit = loadable(() => import('$ui/form/FormButtonSubmit'), options);

export const FormCalcChart = loadable(() => import('$ui/form/FormCalcChart'), options);

export const FormCalcGroup = loadable(() => import('$ui/form/FormCalcGroup'), options);

export const FormCalcIngredientsTotal = loadable(() => import('$ui/form/FormCalcIngredientsTotal'), options);

export const FormCategoriesGroup = loadable(() => import('$ui/form/FormCategoriesGroup'), options);

export const FormCheckbox = loadable(() => import('$ui/form/FormCheckbox'), options);

export const FormColor = loadable(() => import('$ui/form/FormColor'), options);

export const FormCountry = loadable(() => import('$ui/form/FormCountry'), options);

export const FormDate = loadable(() => import('$ui/form/FormDate'), options);

export const FormGroup = loadable(() => import('$ui/form/FormGroup'), options);

export const FormIngredientLabel = loadable(() => import('$ui/form/FormIngredientLabel'), options);

export const FormInput = loadable(() => import('$ui/form/FormInput'), options);

export const FormInputGroup = loadable(() => import('$ui/form/FormInputGroup'), options);

export const FormMultiSelectGroup = loadable(() => import('$ui/form/FormMultiSelectGroup'), options);

export const FormPackagesClosureGroup = loadable(() => import('$ui/form/FormPackagesClosureGroup'), options);

export const FormPersonnelGroup = loadable(() => import('$ui/form/FormPersonnelGroup'), options);

export const FormPhone = loadable(() => import('$ui/form/FormPhone'), options);

export const FormSelect = loadable(() => import('$ui/form/FormSelect'), options);

export const FormSkuImage = loadable(() => import('$ui/form/FormSkuImage'), options);

export const FormUnitGroup = loadable(() => import('$ui/form/FormUnitGroup'), options);

export const OnSubmitValidationError = loadable(() => import('$ui/form/OnSubmitValidationError'), options);

export const Recaptcha = loadable(() => import('$ui/form/Recaptcha'), options);

export const List = loadable(() => import('$ui/list/List'), options);

export const ListItem = loadable(() => import('$ui/list/ListItem'), options);

export const ListItemActions = loadable(() => import('$ui/list/ListItemActions'), options);

export const ListItemControl = loadable(() => import('$ui/list/ListItemControl'), options);

export const ListItemIcon = loadable(() => import('$ui/list/ListItemIcon'), options);

export const ListItemImage = loadable(() => import('$ui/list/ListItemImage'), options);

export const ListItemProp = loadable(() => import('$ui/list/ListItemProp'), options);

export const ListItemSku = loadable(() => import('$ui/list/ListItemSku'), options);

export const ListItemStat = loadable(() => import('$ui/list/ListItemStat'), options);

export const ListItemTitle = loadable(() => import('$ui/list/ListItemTitle'), options);

export const Logo = loadable(() => import('$ui/navbar/Logo'), options);

export const NavbarTop = loadable(() => import('$ui/navbar/NavbarTop'), options);

export const NavbarVertical = loadable(() => import('$ui/navbar/NavbarVertical'), options);

export const NavbarVerticalMenu = loadable(() => import('$ui/navbar/NavbarVerticalMenu'), options);

export const NavbarVerticalMenuItem = loadable(() => import('$ui/navbar/NavbarVerticalMenuItem'), options);

export const ProfileDropdown = loadable(() => import('$ui/navbar/ProfileDropdown'), options);

export const ToggleButton = loadable(() => import('$ui/navbar/ToggleButton'), options);

export const TopNavRightSideNavItem = loadable(() => import('$ui/navbar/TopNavRightSideNavItem'), options);

export const FilterInnerComponent = loadable(() => import('$ui/table/FilterInnerComponent'), options);

export const Table = loadable(() => import('$ui/table/Table'), options);

export const TableActionBar = loadable(() => import('$ui/table/TableActionBar'), options);

export const TableColumnsDnd = loadable(() => import('$ui/table/TableColumnsDnd'), options);

export const TableContextMenu = loadable(() => import('$ui/table/TableContextMenu'), options);

export const TableEdit = loadable(() => import('$ui/table/TableEdit'), options);

export const TableFilterInput = loadable(() => import('$ui/table/TableFilterInput'), options);

export const TableIndeterminateCheckbox = loadable(() => import('$ui/table/TableIndeterminateCheckbox'), options);

export const TableReducer = loadable(() => import('$ui/table/TableReducer'), options);

export const TableTd = loadable(() => import('$ui/table/TableTd'), options);

export const TableTh = loadable(() => import('$ui/table/TableTh'), options);

export const SkuSearch = loadable(() => import('$ui/search/SkuSearch'), options);

export const SkuSearchModeDropdown = loadable(() => import('$ui/search/SkuSearchModeDropdown'), options);

export const SkuSearchResults = loadable(() => import('$ui/search/SkuSearchResults'), options);

export const SkuSearchResultsWrapper = loadable(() => import('$ui/search/SkuSearchResultsWrapper'), options);

export const SkuSearchScopeDropdown = loadable(() => import('$ui/search/SkuSearchScopeDropdown'), options);