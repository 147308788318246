import React from 'react';
import { Icon } from '$ui';
import { __ } from '@stktk/locales';

export default function Footer ({ auth = false }) {
  const className = auth ? 'Footer pl-0 pr-0' : 'Footer';
  return (
    <div className={className}>
      <div className="Footer-border">
        <span className="Footer-copy">{`${__('footer_copyright', { currentYear: new Date().getFullYear(), hostname: window.domain.DOMAIN })} v${window.serverVersion} (v${window.webappVersion})`}</span>
        <span className="Footer-links">
          <a href={window.domain.URL_TERMS}>{__('footer_tos') }</a>
          <span className="Footer-separator"> | </span>
          <a href={window.domain.URL_PRIVACY}>{__('footer_privacy')}</a>
          <span className="Footer-separator"> | </span>
          <a href={window.domain.URL_HELP}>{__('footer_help')}</a>
        </span>
        <span className="Footer-apps">
          <span>{__('footer_get_apps')}: </span>
          <Icon href={window.domain.URL_APP_ANDROID} target="_blank" tooltip={__('general_android')} tooltipPlacement="top" type="brand" icon="android" style={{ color: '#A4C639' }} />
          <Icon href={window.domain.URL_APP_IOS} target="_blank" tooltip={__('general_ios')} tooltipPlacement="top" type="brand" icon="apple" style={{ color: '#a2aaad' }} />
        </span>
      </div>
    </div>
  );
}