"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPageItemCount = exports.getPageCount = exports.getPageContent = exports.getItemIndex = exports.DEFAULT_PAGE_SIZE = void 0;

var _lodash = require("lodash");

var DEFAULT_PAGE_SIZE = 25;
exports.DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;

var getPageContent = function getPageContent(_ref) {
  var items = _ref.items,
      pageNumber = _ref.pageNumber,
      _ref$pageSize = _ref.pageSize,
      pageSize = _ref$pageSize === void 0 ? DEFAULT_PAGE_SIZE : _ref$pageSize;
  var startItem = (pageNumber - 1) * pageSize;
  var endItem = startItem + pageSize;
  var content = items.slice(startItem, endItem);
  return content;
};

exports.getPageContent = getPageContent;

var getPageCount = function getPageCount(_ref2) {
  var items = _ref2.items,
      _ref2$pageSize = _ref2.pageSize,
      pageSize = _ref2$pageSize === void 0 ? DEFAULT_PAGE_SIZE : _ref2$pageSize;
  var itemCount = (0, _lodash.isNumber)(items) ? items : (0, _lodash.get)(items, 'length', 1);
  return Math.ceil(itemCount / pageSize);
};

exports.getPageCount = getPageCount;

var getPageItemCount = function getPageItemCount(_ref3) {
  var itemCount = _ref3.itemCount,
      pageSize = _ref3.pageSize,
      pageNumber = _ref3.pageNumber;
  var pageCount = getPageCount({
    items: itemCount,
    pageSize: pageSize
  });
  if (pageNumber < pageCount) return pageSize;
  return itemCount % pageSize || pageSize;
};

exports.getPageItemCount = getPageItemCount;

var getItemIndex = function getItemIndex(_ref4) {
  var index = _ref4.index,
      pageSize = _ref4.pageSize,
      pageNumber = _ref4.pageNumber;
  if (pageNumber === 1) return index;
  return pageSize * (pageNumber - 1) + index;
};

exports.getItemIndex = getItemIndex;