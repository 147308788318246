import React, {Fragment, useRef} from 'react';
import { __ } from '@stktk/locales';
import Button from '$ui/Button';
import { useLoading } from '$hooks/ui';

export default function FormButtonSubmitNext({ formId, text = __('button_submit_next'), variant = 'secondary', icon = 'save', onClick, ...props }) {
  const [isLoading] = useLoading(formId);
  const buttonRef = useRef(null);

  const handleClick = () => {
    if (onClick) onClick();
    buttonRef.current.click();
  };

  return (
    <Fragment>
      <Button
        data-testid="FormButtonSubmitNext"
        data-modalid={formId}
        icon={icon}
        type="button"
        isLoading={isLoading}
        text={text} variant={variant}
        onClick={handleClick}
        {...props}
      />
      <button ref={buttonRef} type="submit" style={{ display: 'none' }}>Submit</button>
    </Fragment>
  );
}