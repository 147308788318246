import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { __l } from '@stktk/locales';
import { DomainUserRoleEnum } from '@stktk/commons';
import { domainIdSelector, userDomainRoleSelector, userLanguageSelector, restaurantIdSelector } from '@stktk/redux';
import { getRoutePath } from '$utils/route';

const getRoutes = ({ domainId, restaurantId, language }) => [
  {
    name: __l(language, 'app_sidebar_dashboard'),
    to: getRoutePath({ domainId, restaurantId }),
    exact: true,
    icon: 'home',
    domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
  },
  {
    name: __l(language, 'app_sidebar_stocktakes'),
    to: getRoutePath({ domainId, restaurantId }, 'stocktakes'),
    exact: true,
    icon: 'th-list',
    domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
  },
  {
    name: __l(language, 'app_sidebar_orders'),
    exact: true,
    icon: 'dolly',
    domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER],
    children: [
      {
        name: __l(language, 'app_sidebar_orders'),
        to: getRoutePath({ domainId, restaurantId }, 'orderGroups'),
        exact: true,
        icon: 'truck',
        domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
      },
      {
        name: __l(language, 'app_sidebar_suppliers'),
        to: getRoutePath({ domainId, restaurantId }, 'suppliers'),
        exact: true,
        icon: 'fax',
        domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
      }, {
        name: __l(language, 'app_sidebar_orders_pricing'),
        to: getRoutePath({ domainId, restaurantId }, 'ordersPricing'),
        exact: true,
        icon: 'box-usd',
        domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
      }
    ]
  },
  {
    name: __l(language, 'app_sidebar_sales'),
    to: getRoutePath({ domainId, restaurantId }, 'sales'),
    exact: true,
    icon: 'shopping-cart',
    domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
  },
  {
    name: __l(language, 'app_sidebar_losses'),
    to: getRoutePath({ domainId, restaurantId }, 'lossGroups'),
    exact: true,
    icon: 'exclamation-square',
    domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
  },
  {
    name: __l(language, 'app_sidebar_storage_transfers'),
    to: getRoutePath({ domainId, restaurantId }, 'storageTransfers'),
    exact: true,
    icon: 'exchange',
    domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
  },
  {
    name: __l(language, 'app_sidebar_production_groups'),
    to: getRoutePath({ domainId, restaurantId }, 'productions'),
    exact: true,
    icon: 'hat-chef',
    domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
  },
  {
    name: __l(language, 'app_sidebar_storage_standard'),
    to: getRoutePath({ domainId, restaurantId }, 'storages'),
    exact: true,
    icon: 'archive',
    domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
  },
  {
    name: __l(language, 'app_sidebar_reports'),
    to: getRoutePath({ domainId, restaurantId }, 'reports'),
    exact: true,
    icon: 'chart-line',
    domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
  },
  {
    name: __l(language, 'app_sidebar_venues_and_staff'),
    exact: true,
    icon: 'users-cog',
    domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER],
    children: [
      {
        name: __l(language, 'app_sidebar_bars'),
        to: getRoutePath({ domainId, restaurantId }, 'bars'),
        exact: true,
        icon: 'glass-martini',
        domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
      },
      {
        name: __l(language, 'app_sidebar_members'),
        to: getRoutePath({ domainId, restaurantId }, 'members'),
        exact: true,
        icon: 'users',
        domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
      },
      {
        name: __l(language, 'app_sidebar_restaurants'),
        to: getRoutePath({ domainId }, 'restaurants'),
        exact: true,
        icon: 'utensils-alt',
        domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
      }
    ]
  },
  {
    name: __l(language, 'app_sidebar_import'),
    exact: true,
    icon: 'file-import',
    domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER],
    children: [
      {
        name: __l(language, 'app_sidebar_data_import'),
        to: getRoutePath({ domainId, restaurantId }, 'import'),
        exact: true,
        icon: 'file-upload',
        domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
      },
      {
        name: __l(language, 'app_sidebar_data_mapping'),
        to: getRoutePath({ domainId, restaurantId }, 'dataMapping'),
        exact: true,
        icon: 'map-signs',
        domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
      }
    ]
  },
  {
    name: __l(language, 'app_sidebar_products'),
    exact: true,
    icon: 'boxes-alt',
    domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER, DomainUserRoleEnum.SUPERVISOR],
    children: [
      {
        name: __l(language, 'app_sidebar_recipes'),
        to: getRoutePath({ domainId, restaurantId }, 'recipes'),
        exact: true,
        icon: 'file-alt',
        domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER, DomainUserRoleEnum.SUPERVISOR]
      },
      {
        name: __l(language, 'app_sidebar_skus'),
        to: getRoutePath({ domainId, restaurantId }, 'skus'),
        exact: true,
        icon: 'flask',
        domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER, DomainUserRoleEnum.SUPERVISOR]
      },
      {
        name: __l(language, 'app_sidebar_categories'),
        to: getRoutePath({ domainId, restaurantId }, 'categories'),
        exact: true,
        icon: 'bookmark',
        domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER, DomainUserRoleEnum.SUPERVISOR]
      },
      {
        name: __l(language, 'app_sidebar_tags'),
        to: getRoutePath({ domainId, restaurantId }, 'tags'),
        exact: true,
        icon: 'tags',
        domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER, DomainUserRoleEnum.SUPERVISOR]
      },
      {
        name: __l(language, 'app_sidebar_bottle_cap'),
        to: getRoutePath({ domainId, restaurantId }, 'packageClosures'),
        exact: true,
        icon: 'coin',
        domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
      }
    ]
  },
  {
    name: __l(language, 'app_sidebar_settings'),
    to: getRoutePath({ domainId, restaurantId }, 'settings'),
    exact: true,
    icon: 'cog',
    domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
  },
  {
    name: __l(language, 'app_sidebar_documents'),
    to: getRoutePath({ domainId, restaurantId }, 'documents'),
    exact: true,
    icon: 'file',
    domainRoles: [DomainUserRoleEnum.OWNER, DomainUserRoleEnum.MANAGER]
  }
];

const filterRoutes = (routes, role) =>
  routes
    .filter(({ domainRoles }) => domainRoles.indexOf(role) > -1)
    .map(({ domainRoles, ...route }) => route);

export const useRoutes = () => {
  console.count('useRoutes');
  const domainId = useSelector(domainIdSelector);
  const restaurantId = useSelector(restaurantIdSelector);
  const userDomainRole = useSelector(userDomainRoleSelector);
  const language = useSelector(userLanguageSelector);
  // console.log({ domainId, restaurantId, userDomainRole, language });
  const routes = useMemo(() => {
    let routes = getRoutes({ restaurantId, domainId, language });
    return filterRoutes(routes.map(route => route.children ? { ...route, children: filterRoutes(route.children, userDomainRole) } : route), userDomainRole);
  }, [domainId, restaurantId, userDomainRole, language]);
  return routes;
};