import React from 'react';
import { useContextMenu } from 'react-contexify';
import classNames from 'classnames';
import TableContextMenu from '$ui/table/TableContextMenu';

export default function TableTr({ id, row, rowActions, rowGroupActions, children }) {
  const menuId = `menu-${id}`;
  const { show } = useContextMenu({ id: menuId });
  return (
    <div
      id={id} data-testid="TableTr" className={classNames('Table-tr', { 'Table-tr-expanded': row.isExpanded, 'Table-tr-grouped': row.canExpand })} onContextMenu={show}
      {...row.getRowProps()}
    >
      {children}
      <TableContextMenu id={menuId} row={row} rowActions={rowActions} rowGroupActions={rowGroupActions} />
    </div>
  );
}