import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Circle } from 'react-es6-progressbar.js';

const CircleProgressChart = ({ text, text2, total, fragment }) => {
  const options = {
    color: '#8CC63E',
    progress: fragment / total,
    strokeWidth: 5,
    trailWidth: 5,
    trailColor: '#282C30',
    easing: 'easeInOut',
    duration: 1000,
    svgStyle: {
      'stroke-linecap': 'round',
      display: 'block',
      width: '100%'
    },
    text: { autoStyleContainer: false },
    // Set default step function for all animate calls
    step: (state, circle) => {
      const percentage = Math.round(circle.value() * 100);
      circle.setText(`<span class='value'>${percentage}<b>%</b></span>`);
    }
  };

  return (
    <Card className="h-100">
      <CardBody
        className="h-100"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div>
          <Circle
            progress={fragment / total}
            options={options}
            container_class="progress-circle progress-circle-dashboard"
            container_style={{ width: '150px', height: '150px' }}
          />
          <div className="text-center mt-4">
            <h6 className="fs-0 mb-1">
              {text}
            </h6>
            <p className="fs--1 mb-0">{text2}{total}</p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default CircleProgressChart;