"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = require("lodash");

var _reduxwork = require("reduxwork");

var _fieldsOperations = require("./fieldsOperations.js");

var _stateOperations = require("./stateOperations.js");

var _commons = require("@stktk/commons");

var _excluded = ["records"];

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var options = {
  keyName: 'id',
  addKeyOnCreate: true,
  rewriteOnUpdate: true
};
var userDefaultState = {
  data: null,
  isWriting: false,
  editError: null,
  isFetching: false,
  fetchError: null,
  _temp: null
};
var recordDefaultState = {
  bulkTempIds: [],
  destroyGroup: null
};

function selectedUpdate(config, state, items) {
  var update = {};
  if (state.selected && state.selected[config.keyName]) update.selected = (0, _lodash.find)(items, function (item) {
    return item[config.keyName] == state.selected[config.keyName];
  }) || {};
  return update;
}

var compareSkuSuppliersState = function compareSkuSuppliersState(state, action) {
  var items = [],
      removedItems = [],
      addedItems = [];
  action.data.payload.forEach(function (_ref) {
    var skuId = _ref.skuId,
        supplierIds = _ref.supplierIds;
    var skuSuppliers = state.items.filter(function (item) {
      return item.skuId === skuId;
    });
    removedItems = skuSuppliers.filter(function (_ref2) {
      var supplierId = _ref2.supplierId;
      return !supplierIds.includes(function (id) {
        return id === supplierId;
      });
    });
    addedItems = supplierIds.filter(function (supplierId) {
      return !(0, _lodash.includes)(skuSuppliers.map(function (item) {
        return item.supplierId;
      }), supplierId);
    }).map(function (supplierId) {
      return {
        skuId: skuId,
        supplierId: supplierId
      };
    });
  });
  items = [].concat(_toConsumableArray(state.items.filter(function (_ref3) {
    var skuId = _ref3.skuId,
        supplierId = _ref3.supplierId;
    return !(0, _lodash.find)(removedItems, {
      skuId: skuId,
      supplierId: supplierId
    });
  })), _toConsumableArray(addedItems));
  return {
    items: items,
    removedItems: removedItems,
    addedItems: addedItems
  };
};

var createCommonRecordReducers = function createCommonRecordReducers(type, groupType) {
  var _ref6;

  return _ref6 = {}, _defineProperty(_ref6, "PUSH_SKU_DETAILS_".concat(type, "_DATA"), function PUSH_SKU_DETAILS__DATA(state, action) {
    var items = _toConsumableArray(state.items);

    var records = (0, _lodash.filter)(items, {
      skuId: action.data.skuId
    }).map(function (record) {
      return _objectSpread(_objectSpread({}, record), {}, {
        sku: _objectSpread(_objectSpread({}, record.sku), {}, {
          details: [action.data]
        })
      });
    });
    return Object.assign({}, state, {
      items: (0, _lodash.unionBy)(records, items, options.keyName)
    });
  }), _defineProperty(_ref6, "DESTROY_BULK_GROUP_".concat(type, "_RECORDS"), function DESTROY_BULK_GROUP__RECORDS(state, action) {
    //FIXME: suboptimal
    return Object.assign({}, state, {
      isWriting: true,
      destroyGroups: (0, _lodash.filter)(state.items, function (group) {
        return (0, _lodash.find)(action.data, {
          skuId: group.skuId,
          recordsetId: group.recordsetId
        });
      }),
      items: (0, _lodash.filter)(state.items, function (group) {
        return !(0, _lodash.find)(action.data, {
          skuId: group.skuId,
          recordsetId: group.recordsetId
        });
      })
    });
  }), _defineProperty(_ref6, "DESTROY_BULK_GROUP_".concat(type, "_RECORDS_FAILED"), function DESTROY_BULK_GROUP__RECORDS_FAILED(state, action) {
    return Object.assign({}, state, {
      isWriting: false,
      destroyError: action.error,
      items: [].concat(_toConsumableArray(state.items), _toConsumableArray(state.destroyGroups)),
      destroyGroups: null
    });
  }), _defineProperty(_ref6, "DESTROY_BULK_GROUP_".concat(type, "_RECORDS_COMPLETED"), function DESTROY_BULK_GROUP__RECORDS_COMPLETED(state
  /*, action*/
  ) {
    return Object.assign({}, state, {
      isWriting: false,
      destroyError: null,
      destroyGroups: null
    });
  }), _defineProperty(_ref6, "DESTROY_BULK_GROUP_".concat(groupType, "_RECORDS"), function DESTROY_BULK_GROUP__RECORDS(state, action) {
    //FIXME: suboptimal
    return Object.assign({}, state, {
      isWriting: true,
      destroyGroups: (0, _lodash.filter)(state.items, function (group) {
        return (0, _lodash.find)(action.data, {
          skuId: group.skuId,
          orderGroupId: group.orderGroupId,
          supplierId: group.supplierId
        });
      }),
      items: (0, _lodash.filter)(state.items, function (group) {
        return !(0, _lodash.find)(action.data, {
          skuId: group.skuId,
          orderGroupId: group.orderGroupId,
          supplierId: group.supplierId
        });
      })
    });
  }), _defineProperty(_ref6, "DESTROY_BULK_GROUP_".concat(groupType, "_RECORDS_FAILED"), function DESTROY_BULK_GROUP__RECORDS_FAILED(state, action) {
    return Object.assign({}, state, {
      isWriting: false,
      destroyError: action.error,
      items: [].concat(_toConsumableArray(state.items), _toConsumableArray(state.destroyGroups)),
      destroyGroups: null
    });
  }), _defineProperty(_ref6, "DESTROY_BULK_GROUP_".concat(groupType, "_RECORDS_COMPLETED"), function DESTROY_BULK_GROUP__RECORDS_COMPLETED(state
  /*, action*/
  ) {
    return Object.assign({}, state, {
      isWriting: false,
      destroyError: null,
      destroyGroups: null
    });
  }), _defineProperty(_ref6, "DESTROY_BULK_".concat(type, "_RECORDS"), function DESTROY_BULK__RECORDS(state, action) {
    //FIXME: suboptimal
    return Object.assign({}, state, {
      isWriting: true,
      destroyRecords: (0, _lodash.filter)(state.items, function (_ref4) {
        var id = _ref4.id;
        return (0, _lodash.find)(action.data, {
          id: id
        });
      }),
      items: (0, _lodash.filter)(state.items, function (_ref5) {
        var id = _ref5.id;
        return !(0, _lodash.find)(action.data, {
          id: id
        });
      })
    });
  }), _defineProperty(_ref6, "DESTROY_BULK_".concat(type, "_RECORDS_FAILED"), function DESTROY_BULK__RECORDS_FAILED(state, action) {
    return Object.assign({}, state, {
      isWriting: false,
      destroyError: action.error,
      items: [].concat(_toConsumableArray(state.items), _toConsumableArray(state.destroyRecords)),
      destroyRecords: null
    });
  }), _defineProperty(_ref6, "DESTROY_BULK_".concat(type, "_RECORDS_COMPLETED"), function DESTROY_BULK__RECORDS_COMPLETED(state
  /*, action*/
  ) {
    return Object.assign({}, state, {
      isWriting: false,
      destroyError: null,
      destroyRecords: null
    });
  }), _defineProperty(_ref6, "CREATE_BULK_".concat(type, "_RECORDS"), function CREATE_BULK__RECORDS(state, action) {
    var bulkTempIds = [];
    var records = action.data.map(function (record) {
      var localId = 'local' + (0, _lodash.capitalize)(options.keyName);
      record[localId] = new Date().getTime();
      bulkTempIds.push(record[localId]);
      return Object.assign({}, (0, _fieldsOperations.stripVirtualParseLocalFields)(options, record), {
        _temp: true
      });
    });
    return Object.assign({}, state, {
      isWriting: true,
      items: [].concat(_toConsumableArray(state.items), _toConsumableArray(records)),
      bulkTempIds: bulkTempIds
    });
  }), _defineProperty(_ref6, "CREATE_BULK_".concat(type, "_RECORDS_FAILED"), function CREATE_BULK__RECORDS_FAILED(state, action) {
    var items = (0, _lodash.filter)(state.items, function (item) {
      return !(0, _lodash.includes)(state.bulkTempIds, item[options.keyName]);
    });
    return Object.assign({}, state, {
      isWriting: false,
      items: items,
      writeError: action.error,
      bulkTempIds: []
    });
  }), _defineProperty(_ref6, "CREATE_BULK_".concat(type, "_RECORDS_COMPLETED"), function CREATE_BULK__RECORDS_COMPLETED(state, action) {
    var items = (0, _lodash.filter)(state.items, function (item) {
      return !(0, _lodash.includes)(state.bulkTempIds, item[options.keyName]);
    });
    return Object.assign({}, state, {
      items: [].concat(_toConsumableArray(items), _toConsumableArray(action.data)),
      isWriting: false,
      writeError: null,
      bulkTempIds: []
    });
  }), _defineProperty(_ref6, "DESTROY_GROUP_".concat(type, "_RECORDS"), function DESTROY_GROUP__RECORDS(state, action) {
    var filterData = {
      skuId: action.data.skuId,
      recordsetId: action.data.recordsetId
    };
    return Object.assign({}, state, {
      isWriting: true,
      destroyGroup: (0, _lodash.filter)(state.items, filterData),
      items: (0, _lodash.reject)(state.items, filterData)
    });
  }), _defineProperty(_ref6, "DESTROY_GROUP_".concat(type, "_RECORDS_FAILED"), function DESTROY_GROUP__RECORDS_FAILED(state, action) {
    return Object.assign({}, state, {
      isWriting: false,
      destroyError: action.error,
      items: [].concat(_toConsumableArray(state.items), _toConsumableArray(state.destroyGroup)),
      destroyGroup: null
    });
  }), _defineProperty(_ref6, "DESTROY_GROUP_".concat(type, "_RECORDS_COMPLETED"), function DESTROY_GROUP__RECORDS_COMPLETED(state
  /*, action*/
  ) {
    return Object.assign({}, state, {
      isWriting: false,
      destroyError: null,
      destroyGroup: null
    });
  }), _defineProperty(_ref6, "FIND_".concat(groupType, "_RECORDS"), function FIND__RECORDS(state, action) {
    var find = {
      isFinding: true,
      error: null
    };
    find.query = action.data && !(0, _lodash.isEmpty)(action.data) ? action.data : null;
    if (!(0, _lodash.isEqual)(find.query, state.query)) find.items = [];
    return Object.assign({}, state, find);
  }), _defineProperty(_ref6, "FIND_".concat(groupType, "_RECORDS_FAILED"), function FIND__RECORDS_FAILED(state, action) {
    return Object.assign({}, state, {
      error: action.error,
      isFinding: false
    });
  }), _defineProperty(_ref6, "FIND_".concat(groupType, "_RECORDS_COMPLETED"), function FIND__RECORDS_COMPLETED(state, action) {
    return Object.assign({}, state, {
      isFinding: false,
      error: null,
      items: action.data
    });
  }), _defineProperty(_ref6, "UPDATE_BULK_".concat(type, "_RECORDS"), function UPDATE_BULK__RECORDS(state, action) {
    var items = Object.values((0, _lodash.merge)({}, (0, _lodash.keyBy)(_toConsumableArray(state.items), 'id'), (0, _lodash.keyBy)(_toConsumableArray(action.data.payload), 'id')));
    return Object.assign({}, state, {
      isWriting: true,
      payload: _toConsumableArray(action.data.payload),
      updateItems: _toConsumableArray(state.items),
      items: items
    });
  }), _defineProperty(_ref6, "UPDATE_BULK_".concat(type, "_RECORDS_FAILED"), function UPDATE_BULK__RECORDS_FAILED(state, action) {
    return Object.assign({}, state, {
      isWriting: false,
      updateError: action.error,
      items: _toConsumableArray(state.updateItems),
      updateItems: null,
      payload: null
    });
  }), _defineProperty(_ref6, "UPDATE_BULK_".concat(type, "_RECORDS_COMPLETED"), function UPDATE_BULK__RECORDS_COMPLETED(state, action) {
    var items = Object.values((0, _lodash.merge)({}, (0, _lodash.keyBy)(_toConsumableArray(state.items), 'id'), (0, _lodash.keyBy)(_toConsumableArray(action.data), 'id')));
    return Object.assign({}, state, {
      isWriting: false,
      updateError: null,
      updateItems: null,
      payload: null,
      items: items
    });
  }), _defineProperty(_ref6, "CREATE_".concat(groupType, "_RECORDS"), function CREATE__RECORDS(state, action) {
    var item = Object.assign({}, (0, _fieldsOperations.stripVirtualParseLocalFields)(options, action.data), {
      _temp: true
    });
    return Object.assign({}, state, {
      isWriting: true,
      items: [].concat(_toConsumableArray(state.items), [item])
    });
  }), _defineProperty(_ref6, "CREATE_".concat(groupType, "_RECORDS_FAILED"), function CREATE__RECORDS_FAILED(state, action) {
    var items = _toConsumableArray(state.items);

    if (action._tempId) {
      items = (0, _lodash.filter)(items, function (item) {
        return item[options.keyName] != action._tempId;
      });
    }

    return Object.assign({}, state, {
      isWriting: false,
      items: items,
      writeError: action.error || null,
      validationError: action.validationError || null
    });
  }), _defineProperty(_ref6, "CREATE_".concat(groupType, "_RECORDS_COMPLETED"), function CREATE__RECORDS_COMPLETED(state, action) {
    var items = _toConsumableArray(state.items);

    if (action._tempId) {
      items = (0, _lodash.filter)(items, function (item) {
        return item[options.keyName] != action._tempId;
      });
    }

    return Object.assign({}, state, {
      isWriting: false,
      writeError: null,
      validationError: null,
      items: [].concat(_toConsumableArray(items), [action.data])
    });
  }), _defineProperty(_ref6, "UPDATE_".concat(groupType, "_RECORDS"), function UPDATE__RECORDS(state, action) {
    var update = {
      isWriting: true
    };
    var data = (0, _fieldsOperations.stripVirtualParseLocalFields)(options, action.data);

    if ((0, _lodash.isObject)(data) && data[options.keyName]) {
      var items = _toConsumableArray(state.items);

      var updatedItem = (0, _lodash.find)(items, function (item) {
        return item[options.keyName] == data[options.keyName];
      });
      items.splice((0, _lodash.findIndex)(items, function (item) {
        return item[options.keyName] == data[options.keyName];
      }), 1, Object.assign({}, updatedItem, data));
      update.updatedItem = updatedItem;
      update.items = items;
    }

    return Object.assign({}, state, update);
  }), _defineProperty(_ref6, "UPDATE_".concat(groupType, "_RECORDS_FAILED"), function UPDATE__RECORDS_FAILED(state, action) {
    var update = {
      isWriting: false,
      updateError: action.error || null,
      validationError: action.validationError || null,
      updatedItem: null
    };

    if (state.updatedItem && state.updatedItem[options.keyName]) {
      var items = _toConsumableArray(state.items);

      items.splice((0, _lodash.findIndex)(items, function (item) {
        return item[options.keyName] == state.updatedItem[options.keyName];
      }), 1, state.updatedItem);
      update.items = items;
    }

    return Object.assign({}, state, update);
  }), _defineProperty(_ref6, "UPDATE_".concat(groupType, "_RECORDS_COMPLETED"), function UPDATE__RECORDS_COMPLETED(state, action) {
    var update = {
      isWriting: false,
      updateError: null,
      validationError: null,
      updatedItem: null
    };

    if (action._rewrite || options.rewriteOnUpdate && action._rewrite !== false) {
      var items = _toConsumableArray(state.items);

      var data = action.data;
      if (!(0, _lodash.isArray)(data)) data = [data];
      update.items = (0, _lodash.unionBy)(data, items, options.keyName);
    }

    var selected = update.items ? selectedUpdate(options, state, update.items) : {};
    return Object.assign({}, state, update, selected);
  }), _defineProperty(_ref6, "DESTROY_".concat(groupType, "_RECORDS"), function DESTROY__RECORDS(state, action) {
    var update = {
      isWriting: true
    };

    if (action.data[options.keyName]) {
      var items = _toConsumableArray(state.items);

      update.destroyedItem = (0, _lodash.find)(items, function (item) {
        return item[options.keyName] == action.data[options.keyName];
      });
      update.destroyedItemIndex = (0, _lodash.findIndex)(items, function (item) {
        return item[options.keyName] == action.data[options.keyName];
      });
      items.splice(update.destroyedItemIndex, 1);
      update.items = items;
    }

    return Object.assign({}, state, update);
  }), _defineProperty(_ref6, "DESTROY_".concat(groupType, "_RECORDS_FAILED"), function DESTROY__RECORDS_FAILED(state, action) {
    var update = {
      isWriting: false,
      destroyError: action.error || null,
      validationError: action.validationError || null,
      destroyedItem: null,
      destroyedItemIndex: null
    };

    if (state.destroyedItem && state.destroyedItemIndex != null) {
      var items = _toConsumableArray(state.items);

      items.splice(state.destroyedItemIndex, 0, state.destroyedItem);
      update.items = items;
    }

    return Object.assign({}, state, update);
  }), _defineProperty(_ref6, "DESTROY_".concat(groupType, "_RECORDS_COMPLETED"), function DESTROY__RECORDS_COMPLETED(state) {
    var selected = selectedUpdate(options, state, state.items);
    return Object.assign({}, state, {
      isWriting: false,
      destroyError: null,
      validationError: null,
      destroyedItem: null,
      destroyedItemIndex: null
    }, selected);
  }), _ref6;
};

var reducersCommon = {
  user: (0, _reduxwork.createReducer)('user', userDefaultState, {
    PUSH_USER_DATA: function PUSH_USER_DATA(state, action) {
      return Object.assign({}, state, {
        data: action.data
      });
    },
    UPDATE_USERS: function UPDATE_USERS(state, action) {
      var temp = (0, _lodash.cloneDeep)(state.data);
      var edit = {
        _temp: temp,
        data: Object.assign({}, state.data, action.data)
      };
      return Object.assign({}, state, {
        isWriting: true
      }, edit);
    },
    UPDATE_USERS_FAILED: function UPDATE_USERS_FAILED(state, action) {
      var edit = {
        data: (0, _lodash.cloneDeep)(state._temp),
        _temp: null
      };
      return Object.assign({}, state, {
        isWriting: false,
        editError: action.error
      }, edit);
    },
    UPDATE_USERS_COMPLETED: function UPDATE_USERS_COMPLETED(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        isWriting: false,
        editError: null,
        _temp: null
      });
    },
    GET_USER_RESTAURANTS: function GET_USER_RESTAURANTS(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        isFetching: true,
        fetchError: null
      });
    },
    GET_USER_RESTAURANTS_FAILED: function GET_USER_RESTAURANTS_FAILED(state, action) {
      return Object.assign({}, state, {
        fetchError: action.error
      });
    },
    GET_USER_RESTAURANTS_COMPLETED: function GET_USER_RESTAURANTS_COMPLETED(state, action) {
      return Object.assign({}, state, {
        isFetching: false,
        fetchError: null,
        data: action.data,
        lastUpdated: action.receivedAt
      });
    }
  }),
  users: (0, _reduxwork.createIoReducers)(options, 'users'),
  barcodeScanner: (0, _reduxwork.createReducer)('barcodeScanner', {
    show: false,
    barcode: null,
    format: null,
    result: null
  }, {
    TOGGLE_BARCODE_SCANNER: function TOGGLE_BARCODE_SCANNER(state, action) {
      var status = action.status || !state.show;
      return Object.assign({}, state, {
        show: status
      });
    },
    BARCODE_SCANNED: function BARCODE_SCANNED(state, action) {
      return Object.assign({}, state, {
        result: action.result,
        barcode: action.result.barcode,
        format: action.result.format,
        show: false
      });
    }
  }),
  restaurants: (0, _reduxwork.createIoReducers)(options, 'restaurants', {
    lastActiveItem: null
  }, {
    PUSH_RESTAURANTS: function PUSH_RESTAURANTS(state, action) {
      return Object.assign({}, state, {
        items: action.data
      });
    },
    SWITCH_RESTAURANTS: function SWITCH_RESTAURANTS(state, action) {
      var update = {
        lastActiveItem: state.selected || null
      };
      var found = (0, _lodash.find)(state.items, {
        id: action.data.id
      });
      if (found) update.selected = found;
      return Object.assign({}, state, update);
    },
    SWITCH_RESTAURANTS_FAILED: function SWITCH_RESTAURANTS_FAILED(state
    /*, action*/
    ) {
      var update = {};
      if (state.lastActiveItem) update.selected = state.lastActiveItem;
      return Object.assign({}, state, update);
    },
    SWITCH_RESTAURANTS_COMPLETED: function SWITCH_RESTAURANTS_COMPLETED(state, action) {
      var newActive = (0, _lodash.find)(state.items, {
        id: action.data.id
      });
      return Object.assign({}, state, {
        selected: newActive,
        lastActiveItem: null
      });
    }
  }),
  packageClosures: (0, _reduxwork.createIoReducers)(options, 'packageClosures'),
  stocktakes: (0, _reduxwork.createIoReducers)(options, 'stocktakes'),
  losses: (0, _reduxwork.createIoReducers)(options, 'losses'),
  storageTransfers: (0, _reduxwork.createIoReducers)(options, 'storageTransfers', {}, {
    CREATE_BULK_STORAGE_TRANSFERS: function CREATE_BULK_STORAGE_TRANSFERS(state, action) {
      return Object.assign({}, state, {
        isWriting: true
      });
    },
    CREATE_BULK_STORAGE_TRANSFERS_FAILED: function CREATE_BULK_STORAGE_TRANSFERS_FAILED(state, action) {
      return Object.assign({}, state, {
        isWriting: false,
        writeError: action.error
      });
    },
    CREATE_BULK_STORAGE_TRANSFERS_COMPLETED: function CREATE_BULK_STORAGE_TRANSFERS_COMPLETED(state, action) {
      return Object.assign({}, state, {
        isWriting: false,
        writeError: null,
        items: [].concat(_toConsumableArray(state.items), _toConsumableArray(action.data))
      });
    },
    DESTROY_BULK_STORAGE_TRANSFERS: function DESTROY_BULK_STORAGE_TRANSFERS(state, action) {
      var filterData = function filterData(item) {
        return action.data.find(function (_ref7) {
          var id = _ref7.id;
          return item.id === id;
        });
      };

      return Object.assign({}, state, {
        isWriting: true,
        destroyBulk: (0, _lodash.filter)(state.items, filterData),
        items: (0, _lodash.reject)(state.items, filterData)
      });
    },
    DESTROY_BULK_STORAGE_TRANSFERS_FAILED: function DESTROY_BULK_STORAGE_TRANSFERS_FAILED(state, action) {
      return Object.assign({}, state, {
        isWriting: false,
        destroyError: action.error,
        items: [].concat(_toConsumableArray(state.items), _toConsumableArray(state.destroyBulk)),
        destroyBulk: null
      });
    },
    DESTROY_BULK_STORAGE_TRANSFERS_COMPLETED: function DESTROY_BULK_STORAGE_TRANSFERS_COMPLETED(state, action) {
      return Object.assign({}, state, {
        isWriting: false,
        destroyError: null,
        destroyBulk: null
      });
    },
    FIND_DOMAIN_STORAGE_TRANSFERS: function FIND_DOMAIN_STORAGE_TRANSFERS(state, action) {
      var find = {
        isFinding: true,
        error: null
      };
      return Object.assign({}, state, find);
    },
    FIND_DOMAIN_STORAGE_TRANSFERS_FAILED: function FIND_DOMAIN_STORAGE_TRANSFERS_FAILED(state, action) {
      return Object.assign({}, state, {
        error: action.error,
        isFinding: false
      });
    },
    FIND_DOMAIN_STORAGE_TRANSFERS_COMPLETED: function FIND_DOMAIN_STORAGE_TRANSFERS_COMPLETED(state, action) {
      return Object.assign({}, state, {
        isFinding: false,
        error: null,
        items: action.data
      });
    }
  }),
  storageTransferGroups: (0, _reduxwork.createIoReducers)(options, 'storageTransferGroups', {}, {
    FIND_DOMAIN_STORAGE_TRANSFER_GROUPS: function FIND_DOMAIN_STORAGE_TRANSFER_GROUPS(state, action) {
      var find = {
        isFinding: true,
        error: null
      };
      return Object.assign({}, state, find);
    },
    FIND_DOMAIN_STORAGE_TRANSFER_GROUPS_FAILED: function FIND_DOMAIN_STORAGE_TRANSFER_GROUPS_FAILED(state, action) {
      return Object.assign({}, state, {
        error: action.error,
        isFinding: false
      });
    },
    FIND_DOMAIN_STORAGE_TRANSFER_GROUPS_COMPLETED: function FIND_DOMAIN_STORAGE_TRANSFER_GROUPS_COMPLETED(state, action) {
      return Object.assign({}, state, {
        isFinding: false,
        error: null,
        items: action.data
      });
    }
  }),
  productions: (0, _reduxwork.createIoReducers)(options, 'productions'),
  productionGroups: (0, _reduxwork.createIoReducers)(options, 'productionGroups'),
  orderGroups: (0, _reduxwork.createIoReducers)(options, 'orderGroups'),
  lossGroups: (0, _reduxwork.createIoReducers)(options, 'lossGroups', {}, {
    FINALIZE_LOSS_GROUPS: function FINALIZE_LOSS_GROUPS(state, action) {
      //TODO:
      return state;
    },
    FINALIZE_LOSS_GROUPS_FAILED: function FINALIZE_LOSS_GROUPS_FAILED(state, action) {
      //TODO:
      return state;
    },
    FINALIZE_LOSS_GROUPS_COMPLETED: function FINALIZE_LOSS_GROUPS_COMPLETED(state, action) {
      //TODO:
      return state;
    },
    GET_LOSS_GROUP_FINALIZATION_DATA: function GET_LOSS_GROUP_FINALIZATION_DATA(state, action) {
      //TODO:
      return state;
    },
    GET_LOSS_GROUP_FINALIZATION_DATA_COMPLETED: function GET_LOSS_GROUP_FINALIZATION_DATA_COMPLETED(state, action) {
      //TODO:
      return state;
    },
    GET_LOSS_GROUP_FINALIZATION_DATA_FAILED: function GET_LOSS_GROUP_FINALIZATION_DATA_FAILED(state, action) {
      //TODO:
      return state;
    }
  }),
  storageStandards: (0, _reduxwork.createIoReducers)(options, 'storageStandards', {
    lastActiveItem: null
  }, {
    ACTIVATE_STORAGE_STANDARDS: function ACTIVATE_STORAGE_STANDARDS(state, action) {
      // TODO: Move the activation logic to storage
      var currentActiveItem = (0, _lodash.find)(state.items, {
        status: _commons.StorageStandardStatusEnum.ACTIVE
      });
      var items = (0, _lodash.map)(state.items, function (item) {
        return Object.assign({}, item, {
          status: item.id === action.data.id ? _commons.StorageStandardStatusEnum.ACTIVE : _commons.StorageStandardStatusEnum.INACTIVE
        });
      });
      return Object.assign({}, state, {
        isWriting: true,
        lastActiveItem: currentActiveItem,
        items: items
      });
    },
    ACTIVATE_STORAGE_STANDARDS_FAILED: function ACTIVATE_STORAGE_STANDARDS_FAILED(state, action) {
      var items = (0, _lodash.map)(state.items, function (item) {
        if (item.id !== (0, _lodash.get)(state, 'lastActiveItem.id', null)) item.status = _commons.StorageStandardStatusEnum.INACTIVE;else item.status = _commons.StorageStandardStatusEnum.ACTIVE;
        return item;
      });
      return Object.assign({}, state, {
        isWriting: false,
        editError: action.error,
        lastActiveItem: null,
        items: items
      });
    },
    ACTIVATE_STORAGE_STANDARDS_COMPLETED: function ACTIVATE_STORAGE_STANDARDS_COMPLETED(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        isWriting: false,
        editError: null,
        lastActiveItem: null
      });
    }
  }),
  stockchecks: (0, _reduxwork.createIoReducers)(options, 'stockchecks', {}, {
    UPDATE_STOCKTAKES_STOCKCHECKS: function UPDATE_STOCKTAKES_STOCKCHECKS(state, action) {
      var items = _toConsumableArray(state.items);

      var stockcheck = (0, _lodash.find)(items, {
        id: action.data.stockcheckId
      });
      stockcheck.stocktakes.splice((0, _lodash.findIndex)(stockcheck.stocktakes, {
        id: action.data.id
      }), 1, action.data);
      return Object.assign({}, state, {
        items: (0, _lodash.unionBy)([stockcheck], items, options.keyName)
      });
    },
    DESTROY_STOCKTAKES_STOCKCHECKS: function DESTROY_STOCKTAKES_STOCKCHECKS(state, action) {
      var items = _toConsumableArray(state.items);

      var stockcheck = (0, _lodash.find)(items, {
        id: action.data.stockcheckId
      });
      stockcheck.stocktakes.splice((0, _lodash.findIndex)(stockcheck.stocktakes, {
        id: action.data.id
      }), 1);
      return Object.assign({}, state, {
        items: (0, _lodash.unionBy)([stockcheck], items, options.keyName)
      });
    },
    MERGE_STOCKCHECKS: function MERGE_STOCKCHECKS(state, action) {
      var filterData = {
        id: action.data.stockcheck2Id
      };
      return _objectSpread(_objectSpread({}, state), {
        isWriting: true,
        mergedStockcheck: (0, _lodash.filter)(state.items, filterData),
        items: (0, _lodash.reject)(state.items, filterData)
      });
    },
    MERGE_STOCKCHECKS_FAILED: function MERGE_STOCKCHECKS_FAILED(state, action) {
      return _objectSpread(_objectSpread({}, state), {
        isWriting: false,
        mergeError: action.error,
        items: [].concat(_toConsumableArray(state.items), _toConsumableArray(state.mergedStockcheck)),
        mergedStockcheck: null
      });
    },
    MERGE_STOCKCHECKS_COMPLETED: function MERGE_STOCKCHECKS_COMPLETED(state
    /*, action*/
    ) {
      return _objectSpread(_objectSpread({}, state), {
        isWriting: false,
        mergeError: null,
        mergedStockcheck: null
      });
    },
    FINALIZE_STOCKCHECKS: function FINALIZE_STOCKCHECKS(state, action) {
      //TODO:
      return state;
    },
    FINALIZE_STOCKCHECKS_FAILED: function FINALIZE_STOCKCHECKS_FAILED(state, action) {
      //TODO:
      return state;
    },
    FINALIZE_STOCKCHECKS_COMPLETED: function FINALIZE_STOCKCHECKS_COMPLETED(state, action) {
      //TODO:
      return state;
    },
    GET_STOCKCHECK_FINALZIATION_DATA: function GET_STOCKCHECK_FINALZIATION_DATA(state, action) {
      //TODO:
      return state;
    },
    GET_STOCKCHECK_FINALZIATION_DATA_FAILED: function GET_STOCKCHECK_FINALZIATION_DATA_FAILED(state, action) {
      //TODO:
      return state;
    },
    GET_STOCKCHECK_FINALZIATION_DATA_COMPLETED: function GET_STOCKCHECK_FINALZIATION_DATA_COMPLETED(state, action) {
      //TODO:
      return state;
    }
  }),
  reports: (0, _reduxwork.createIoReducers)(options, 'reports', {
    data: []
  }, {
    GET_DATA_REPORTS: function GET_DATA_REPORTS(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        isFinding: true,
        data: []
      });
    },
    GET_DATA_REPORTS_COMPLETED: function GET_DATA_REPORTS_COMPLETED(state, action) {
      return Object.assign({}, state, {
        isFinding: false,
        data: action.data
      });
    },
    GET_DATA_REPORTS_FAILED: function GET_DATA_REPORTS_FAILED(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        isFinding: false,
        data: []
      });
    },
    PUSH_SKU_DETAILS_REPORT_DATA: function PUSH_SKU_DETAILS_REPORT_DATA(state, action) {
      var _state$data = state.data,
          records = _state$data.records,
          props = _objectWithoutProperties(_state$data, _excluded);

      var _data = _objectSpread({
        records: {}
      }, props);

      ['endStockcheckRecords', 'lossRecords', 'orderRecords', 'productionRecords', 'saleRecords', 'startStockcheckRecords', 'storageTransferRecords'].forEach(function (items) {
        _data.records[items] = records[items].map(function (record) {
          return _objectSpread(_objectSpread({}, record), {}, {
            sku: _objectSpread(_objectSpread({}, record.sku), {}, {
              details: record.sku.id === action.data.skuId ? [action.data] : record.sku.details
            })
          });
        });
      });
      return Object.assign({}, state, {
        data: _data
      });
    }
  }),
  orders: (0, _reduxwork.createIoReducers)(options, 'orders', {}, {
    APPROVE_ORDERS: function APPROVE_ORDERS(state, action) {
      return (0, _stateOperations.getUpdateState)(options, state, action);
    },
    APPROVE_ORDERS_FAILED: function APPROVE_ORDERS_FAILED(state, action) {
      return (0, _stateOperations.getUpdateFailedState)(options, state, action);
    },
    APPROVE_ORDERS_COMPLETED: function APPROVE_ORDERS_COMPLETED(state, action) {
      return (0, _stateOperations.getUpdateCompletedState)(options, state, action);
    }
  }),
  documents: (0, _reduxwork.createIoReducers)(options, 'documents', {}, {
    CHECK_STATUS_DOCUMENTS: function CHECK_STATUS_DOCUMENTS(state, action) {
      return (0, _stateOperations.getUpdateState)(options, state, action);
    },
    CHECK_STATUS_DOCUMENTS_FAILED: function CHECK_STATUS_DOCUMENTS_FAILED(state, action) {
      return (0, _stateOperations.getUpdateFailedState)(options, state, action);
    },
    CHECK_STATUS_DOCUMENTS_COMPLETED: function CHECK_STATUS_DOCUMENTS_COMPLETED(state, action) {
      return (0, _stateOperations.getUpdateCompletedState)(options, state, action);
    }
  }),
  properties: (0, _reduxwork.createIoReducers)(options, 'properties', {}, {
    CREATE_DOMAIN_PROPERTIES: function CREATE_DOMAIN_PROPERTIES(state, action) {
      return (0, _stateOperations.getCreateState)(options, state, action);
    },
    CREATE_DOMAIN_PROPERTIES_FAILED: function CREATE_DOMAIN_PROPERTIES_FAILED(state, action) {
      return (0, _stateOperations.getCreateFailedState)(options, state, action);
    },
    CREATE_DOMAIN_PROPERTIES_COMPLETED: function CREATE_DOMAIN_PROPERTIES_COMPLETED(state, action) {
      return (0, _stateOperations.getCreateCompletedState)(options, state, action);
    },
    UPDATE_DOMAIN_PROPERTIES: function UPDATE_DOMAIN_PROPERTIES(state, action) {
      return (0, _stateOperations.getUpdateState)(options, state, action);
    },
    UPDATE_DOMAIN_PROPERTIES_FAILED: function UPDATE_DOMAIN_PROPERTIES_FAILED(state, action) {
      return (0, _stateOperations.getUpdateFailedState)(options, state, action);
    },
    UPDATE_DOMAIN_PROPERTIES_COMPLETED: function UPDATE_DOMAIN_PROPERTIES_COMPLETED(state, action) {
      return (0, _stateOperations.getUpdateCompletedState)(options, state, action);
    },
    CREATE_RESTAURANT_PROPERTIES: function CREATE_RESTAURANT_PROPERTIES(state, action) {
      return (0, _stateOperations.getCreateState)(options, state, action);
    },
    CREATE_RESTAURANT_PROPERTIES_FAILED: function CREATE_RESTAURANT_PROPERTIES_FAILED(state, action) {
      return (0, _stateOperations.getCreateFailedState)(options, state, action);
    },
    CREATE_RESTAURANT_PROPERTIES_COMPLETED: function CREATE_RESTAURANT_PROPERTIES_COMPLETED(state, action) {
      return (0, _stateOperations.getCreateCompletedState)(options, state, action);
    },
    UPDATE_RESTAURANT_PROPERTIES: function UPDATE_RESTAURANT_PROPERTIES(state, action) {
      return (0, _stateOperations.getUpdateState)(options, state, action);
    },
    UPDATE_RESTAURANT_PROPERTIES_FAILED: function UPDATE_RESTAURANT_PROPERTIES_FAILED(state, action) {
      return (0, _stateOperations.getUpdateFailedState)(options, state, action);
    },
    UPDATE_RESTAURANT_PROPERTIES_COMPLETED: function UPDATE_RESTAURANT_PROPERTIES_COMPLETED(state, action) {
      return (0, _stateOperations.getUpdateCompletedState)(options, state, action);
    },
    CREATE_USER_PROPERTIES: function CREATE_USER_PROPERTIES(state, action) {
      return (0, _stateOperations.getCreateState)(options, state, action);
    },
    CREATE_USER_PROPERTIES_FAILED: function CREATE_USER_PROPERTIES_FAILED(state, action) {
      return (0, _stateOperations.getCreateFailedState)(options, state, action);
    },
    CREATE_USER_PROPERTIES_COMPLETED: function CREATE_USER_PROPERTIES_COMPLETED(state, action) {
      return (0, _stateOperations.getCreateCompletedState)(options, state, action);
    },
    UPDATE_USER_PROPERTIES: function UPDATE_USER_PROPERTIES(state, action) {
      return (0, _stateOperations.getUpdateState)(options, state, action);
    },
    UPDATE_USER_PROPERTIES_FAILED: function UPDATE_USER_PROPERTIES_FAILED(state, action) {
      return (0, _stateOperations.getUpdateFailedState)(options, state, action);
    },
    UPDATE_USER_PROPERTIES_COMPLETED: function UPDATE_USER_PROPERTIES_COMPLETED(state, action) {
      return (0, _stateOperations.getUpdateCompletedState)(options, state, action);
    }
  }),
  storages: (0, _reduxwork.createIoReducers)(options, 'storages'),
  notifications: (0, _reduxwork.createIoReducers)(options, 'notifications', {
    currentNotification: null
  }, {
    PROCESS_NOTIFICATIONS: function PROCESS_NOTIFICATIONS(state, action) {
      var filterData = {
        id: action.data.id
      };
      return Object.assign({}, state, {
        currentNotification: (0, _lodash.filter)(state.items, filterData),
        items: (0, _lodash.reject)(state.items, filterData)
      });
    },
    PROCESS_NOTIFICATIONS_FAILED: function PROCESS_NOTIFICATIONS_FAILED(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        currentNotification: null,
        items: Object.assign({}, state.items, state.currentNotification)
      });
    },
    PROCESS_NOTIFICATIONS_COMPLETED: function PROCESS_NOTIFICATIONS_COMPLETED(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        currentNotification: null
      });
    }
  }),
  categories: (0, _reduxwork.createIoReducers)(options, 'categories'),
  recipes: (0, _reduxwork.createIoReducers)(options, 'recipes'),
  tags: (0, _reduxwork.createIoReducers)(options, 'tags'),
  dataMapping: (0, _reduxwork.createIoReducers)(options, 'dataMapping'),
  sales: (0, _reduxwork.createIoReducers)(options, 'sales'),
  suppliers: (0, _reduxwork.createIoReducers)(options, 'suppliers'),
  skuIngredients: (0, _reduxwork.createIoReducers)(options, 'skuIngredients'),
  stocktakeRecords: (0, _reduxwork.createIoReducers)(options, 'stocktakeRecords', recordDefaultState, Object.assign({}, createCommonRecordReducers('STOCKTAKE', 'STOCKCHECK'))),
  orderRecords: (0, _reduxwork.createIoReducers)(options, 'orderRecords', recordDefaultState, Object.assign({}, createCommonRecordReducers('ORDER', 'ORDER_GROUP'))),
  lossRecords: (0, _reduxwork.createIoReducers)(options, 'lossRecords', recordDefaultState, Object.assign({}, createCommonRecordReducers('LOSS', 'LOSS_GROUP'))),
  storageTransferRecords: (0, _reduxwork.createIoReducers)(options, 'storageTransferRecords', recordDefaultState, Object.assign({}, createCommonRecordReducers('STORAGE_TRANSFER', 'STORAGE_TRANSFER_GROUP'))),
  productionRecords: (0, _reduxwork.createIoReducers)(options, 'productionRecords', recordDefaultState, Object.assign({}, createCommonRecordReducers('PRODUCTION', 'PRODUCTION_GROUP'), {
    CREATE_BULK_PRODUCTION_RECORDS: function CREATE_BULK_PRODUCTION_RECORDS(state, action) {
      var bulkTempIds = [];
      var outputRecords = [];
      var localId = new Date().getTime();
      var localIdName = 'local' + (0, _lodash.capitalize)(options.keyName);
      var recordsWithLocalId = action.data.map(function (record) {
        bulkTempIds.push(localId);
        if (record.status == _commons.ProductionRecordStatusEnum.OUTPUT) outputRecords.push(record);
        return Object.assign(_defineProperty({}, localIdName, localId), record);
      });
      var recordsWithRefId = recordsWithLocalId.map(function (record) {
        var _objectSpread2;

        var localRefRecordIdName = 'localRefRecord' + (0, _lodash.capitalize)(options.keyName);
        var localRefRecordId = record.status == _commons.ProductionRecordStatusEnum.INPUT ? localId : null;
        return Object.assign({}, (0, _fieldsOperations.stripVirtualParseLocalFields)(options, _objectSpread(_objectSpread({}, record), (_objectSpread2 = {}, _defineProperty(_objectSpread2, localRefRecordIdName, localRefRecordId), _defineProperty(_objectSpread2, "_temp", true), _objectSpread2))));
      });
      return Object.assign({}, state, {
        isWriting: true,
        items: [].concat(_toConsumableArray(state.items), _toConsumableArray(recordsWithRefId)),
        bulkTempIds: bulkTempIds
      });
    },
    CREATE_BULK_PRODUCTION_RECORDS_FAILED: function CREATE_BULK_PRODUCTION_RECORDS_FAILED(state, action) {
      var items = (0, _lodash.filter)(state.items, function (item) {
        return !(0, _lodash.includes)(state.bulkTempIds, item[options.keyName]);
      });
      return Object.assign({}, state, {
        isWriting: false,
        items: items,
        writeError: action.error,
        bulkTempIds: []
      });
    },
    CREATE_BULK_PRODUCTION_RECORDS_COMPLETED: function CREATE_BULK_PRODUCTION_RECORDS_COMPLETED(state, action) {
      var items = (0, _lodash.filter)(state.items, function (item) {
        return !(0, _lodash.includes)(state.bulkTempIds, item[options.keyName]);
      });
      return Object.assign({}, state, {
        items: [].concat(_toConsumableArray(items), _toConsumableArray(action.data)),
        isWriting: false,
        writeError: null,
        bulkTempIds: []
      });
    },
    DESTROY_BULK_PRODUCTION_RECORDS: function DESTROY_BULK_PRODUCTION_RECORDS(state, action) {
      var recordsIds = action.data.map(function (_ref8) {
        var id = _ref8.id;
        return id;
      });
      return Object.assign({}, state, {
        isWriting: true,
        destroyError: null,
        items: (0, _lodash.reject)(state.items, function (record) {
          return recordsIds.includes(record.id);
        }),
        destroyProductionRecords: (0, _lodash.filter)(state.items, function (record) {
          return recordsIds.includes(record.id);
        })
      });
    },
    DESTROY_BULK_PRODUCTION_RECORDS_FAILED: function DESTROY_BULK_PRODUCTION_RECORDS_FAILED(state, action) {
      return Object.assign({}, state, {
        isWriting: false,
        destroyError: action.error,
        items: [].concat(_toConsumableArray(state.items), _toConsumableArray(state.destroyProductionRecords)),
        destroyProductionRecords: null
      });
    },
    DESTROY_BULK_PRODUCTION_RECORDS_COMPLETED: function DESTROY_BULK_PRODUCTION_RECORDS_COMPLETED(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        isWriting: false,
        destroyError: null,
        items: state.items,
        destroyProductionRecords: null
      });
    }
  })),
  storageRecords: (0, _reduxwork.createIoReducers)(options, 'storageRecords', recordDefaultState, Object.assign({}, createCommonRecordReducers('STORAGE', 'STORAGE_STANDARD'))),
  saleRecords: (0, _reduxwork.createIoReducers)(options, 'saleRecords', recordDefaultState, Object.assign({}, createCommonRecordReducers('SALE', 'SALE_GROUP'))),
  skus: (0, _reduxwork.createIoReducers)(options, 'skus', {
    searchItems: [],
    searchQuery: null,
    isSearching: false,
    searchError: null,
    listItems: [],
    listQuery: null,
    isListing: false,
    listError: null
  }, {
    SEARCH_SKUS: function SEARCH_SKUS(state, action) {
      var find = {
        isSearching: true,
        searchError: null
      };
      find.searchQuery = action.data && !(0, _lodash.isEmpty)(action.data) ? action.data : null; //if (!isEqual(find.searchQuery, state.searchQuery)) find.searchItems = [];

      return Object.assign({}, state, find);
    },
    SEARCH_SKUS_FAILED: function SEARCH_SKUS_FAILED(state, action) {
      return Object.assign({}, state, {
        searchError: action.error,
        isSearching: false
      });
    },
    SEARCH_SKUS_COMPLETED: function SEARCH_SKUS_COMPLETED(state, action) {
      return Object.assign({}, state, {
        isSearching: false,
        searchError: null,
        searchItems: action.data
      });
    },
    CLEAR_SEARCH_SKUS: function CLEAR_SEARCH_SKUS(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        searchItems: []
      });
    },
    LIST_STOCKTAKE_SKUS: function LIST_STOCKTAKE_SKUS(state, action) {
      var find = {
        isListing: true,
        listError: null
      };
      find.listQuery = action.data && !(0, _lodash.isEmpty)(action.data) ? action.data : null; //if (!isEqual(find.listQuery, state.listQuery)) find.listItems = [];

      return Object.assign({}, state, find);
    },
    LIST_STOCKTAKE_SKUS_FAILED: function LIST_STOCKTAKE_SKUS_FAILED(state, action) {
      return Object.assign({}, state, {
        listError: action.error,
        isListing: false
      });
    },
    LIST_STOCKTAKE_SKUS_COMPLETED: function LIST_STOCKTAKE_SKUS_COMPLETED(state, action) {
      return Object.assign({}, state, {
        isListing: false,
        listError: null,
        listItems: action.data
      });
    },
    CLEAR_LIST_STOCKTAKE_SKUS: function CLEAR_LIST_STOCKTAKE_SKUS(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        listItems: []
      });
    },
    LIST_ORDER_SKUS: function LIST_ORDER_SKUS(state, action) {
      var find = {
        isListing: true,
        listError: null
      };
      find.listQuery = action.data && !(0, _lodash.isEmpty)(action.data) ? action.data : null;
      if (!(0, _lodash.isEqual)(find.listQuery, state.listQuery)) find.listItems = [];
      return Object.assign({}, state, find);
    },
    LIST_ORDER_SKUS_FAILED: function LIST_ORDER_SKUS_FAILED(state, action) {
      return Object.assign({}, state, {
        listError: action.error,
        isListing: false
      });
    },
    LIST_ORDER_SKUS_COMPLETED: function LIST_ORDER_SKUS_COMPLETED(state, action) {
      return Object.assign({}, state, {
        isListing: false,
        listError: null,
        listItems: action.data
      });
    },
    LIST_ORDER_GROUP_SKUS: function LIST_ORDER_GROUP_SKUS(state, action) {
      var find = {
        isListing: true,
        listError: null
      };
      find.listQuery = action.data && !(0, _lodash.isEmpty)(action.data) ? action.data : null;
      if (!(0, _lodash.isEqual)(find.listQuery, state.listQuery)) find.listItems = [];
      return Object.assign({}, state, find);
    },
    LIST_ORDER_GROUP_SKUS_FAILED: function LIST_ORDER_GROUP_SKUS_FAILED(state, action) {
      return Object.assign({}, state, {
        listError: action.error,
        isListing: false
      });
    },
    LIST_ORDER_GROUP_SKUS_COMPLETED: function LIST_ORDER_GROUP_SKUS_COMPLETED(state, action) {
      return Object.assign({}, state, {
        isListing: false,
        listError: null,
        listItems: action.data
      });
    },
    CLEAR_LIST_ORDER_SKUS: function CLEAR_LIST_ORDER_SKUS(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        listItems: []
      });
    },
    LIST_SALE_SKUS: function LIST_SALE_SKUS(state, action) {
      var find = {
        isListing: true,
        listError: null
      };
      find.listQuery = action.data && !(0, _lodash.isEmpty)(action.data) ? action.data : null;
      if (!(0, _lodash.isEqual)(find.listQuery, state.listQuery)) find.listItems = [];
      return Object.assign({}, state, find);
    },
    LIST_SALE_SKUS_FAILED: function LIST_SALE_SKUS_FAILED(state, action) {
      return Object.assign({}, state, {
        listError: action.error,
        isListing: false
      });
    },
    LIST_SALE_SKUS_COMPLETED: function LIST_SALE_SKUS_COMPLETED(state, action) {
      return Object.assign({}, state, {
        isListing: false,
        listError: null,
        listItems: action.data
      });
    },
    CLEAR_LIST_SALE_SKUS: function CLEAR_LIST_SALE_SKUS(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        listItems: []
      });
    },
    LIST_STORAGE_SKUS: function LIST_STORAGE_SKUS(state, action) {
      var find = {
        isListing: true,
        listError: null
      };
      find.listQuery = action.data && !(0, _lodash.isEmpty)(action.data) ? action.data : null;
      if (!(0, _lodash.isEqual)(find.listQuery, state.listQuery)) find.listItems = [];
      return Object.assign({}, state, find);
    },
    LIST_STORAGE_SKUS_FAILED: function LIST_STORAGE_SKUS_FAILED(state, action) {
      return Object.assign({}, state, {
        listError: action.error,
        isListing: false
      });
    },
    LIST_STORAGE_SKUS_COMPLETED: function LIST_STORAGE_SKUS_COMPLETED(state, action) {
      return Object.assign({}, state, {
        isListing: false,
        listError: null,
        listItems: action.data
      });
    },
    CLEAR_LIST_STORAGE_SKUS: function CLEAR_LIST_STORAGE_SKUS(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        listItems: []
      });
    },
    LIST_LOSS_SKUS: function LIST_LOSS_SKUS(state, action) {
      var find = {
        isListing: true,
        listError: null
      };
      find.listQuery = action.data && !(0, _lodash.isEmpty)(action.data) ? action.data : null;
      if (!(0, _lodash.isEqual)(find.listQuery, state.listQuery)) find.listItems = [];
      return Object.assign({}, state, find);
    },
    LIST_LOSS_SKUS_FAILED: function LIST_LOSS_SKUS_FAILED(state, action) {
      return Object.assign({}, state, {
        listError: action.error,
        isListing: false
      });
    },
    LIST_LOSS_SKUS_COMPLETED: function LIST_LOSS_SKUS_COMPLETED(state, action) {
      return Object.assign({}, state, {
        isListing: false,
        listError: null,
        listItems: action.data
      });
    },
    CLEAR_LIST_LOSS_SKUS: function CLEAR_LIST_LOSS_SKUS(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        listItems: []
      });
    },
    PUSH_SKU_SKU_DETAILS_DATA: function PUSH_SKU_SKU_DETAILS_DATA(state, action) {
      var items = _toConsumableArray(state.items);

      var sku = (0, _lodash.find)(items, {
        id: action.data.skuId
      });
      sku.details = [action.data];
      return Object.assign({}, state, {
        items: (0, _lodash.unionBy)([sku], items, options.keyName)
      });
    }
  }),
  skuDetails: (0, _reduxwork.createIoReducers)(options, 'skuDetails'),
  skuSuppliers: (0, _reduxwork.createIoReducers)(options, 'skuSuppliers', {}, {
    SYNCHRONIZE_BULK_SKU_SUPPLIERS: function SYNCHRONIZE_BULK_SKU_SUPPLIERS(state, action) {
      var _compareSkuSuppliersS = compareSkuSuppliersState(state, action),
          items = _compareSkuSuppliersS.items,
          removedItems = _compareSkuSuppliersS.removedItems,
          addedItems = _compareSkuSuppliersS.addedItems;

      return Object.assign({}, state, {
        items: items,
        isWriting: true,
        actionError: null,
        removedItems: removedItems,
        addedItems: addedItems
      });
    },
    SYNCHRONIZE_BULK_SKU_SUPPLIERS_COMPLETED: function SYNCHRONIZE_BULK_SKU_SUPPLIERS_COMPLETED(state, action) {
      return Object.assign({}, state, {
        isWriting: false,
        actionError: null,
        removedItems: [],
        createdItems: []
      });
    },
    SYNCHRONIZE_BULK_SKU_SUPPLIERS_FAILED: function SYNCHRONIZE_BULK_SKU_SUPPLIERS_FAILED(state, action) {
      return Object.assign({}, state, {
        items: [].concat(_toConsumableArray(state.items.filter(function (_ref9) {
          var skuId = _ref9.skuId,
              supplierId = _ref9.supplierId;
          return (0, _lodash.find)(state.addedItems, {
            skuId: skuId,
            supplierId: supplierId
          });
        })), _toConsumableArray(state.removedItems)),
        isWriting: false,
        actionError: action.error,
        removedItems: [],
        createdItems: []
      });
    }
  }),
  members: (0, _reduxwork.createIoReducers)(options, 'members', {}, {
    DESTROY_MEMBERS: function DESTROY_MEMBERS(state, action) {
      return Object.assign({}, state, {
        items: (0, _lodash.reject)(state.items, function (member) {
          return member.restaurantUsers[0].id == action.data.id;
        })
      });
    },
    TAG_MEMBERS_COMPLETED: function TAG_MEMBERS_COMPLETED(state, action) {
      return Object.assign({}, state, {
        isFinding: false,
        error: null,
        items: state.items.map(function (item) {
          return item.id === action.data.id ? action.data : item;
        })
      });
    }
  }),
  bars: (0, _reduxwork.createIoReducers)(options, 'bars', {}, {
    FIND_DOMAIN_BARS: function FIND_DOMAIN_BARS(state, action) {
      var find = {
        isFinding: true,
        error: null
      };
      return Object.assign({}, state, find);
    },
    FIND_DOMAIN_BARS_FAILED: function FIND_DOMAIN_BARS_FAILED(state, action) {
      return Object.assign({}, state, {
        error: action.error,
        isFinding: false
      });
    },
    FIND_DOMAIN_BARS_COMPLETED: function FIND_DOMAIN_BARS_COMPLETED(state, action) {
      return Object.assign({}, state, {
        isFinding: false,
        error: null,
        items: action.data
      });
    },
    DESTROY_BULK_BARS: function DESTROY_BULK_BARS(state, action) {
      var barIds = action.data.map(function (_ref10) {
        var id = _ref10.id;
        return id;
      });
      return Object.assign({}, state, {
        isWriting: true,
        destroyError: null,
        items: (0, _lodash.reject)(state.items, function (bar) {
          return barIds.includes(bar.id);
        }),
        destroyBars: (0, _lodash.filter)(state.items, function (bar) {
          return barIds.includes(bar.id);
        })
      });
    },
    DESTROY_BULK_BARS_FAILED: function DESTROY_BULK_BARS_FAILED(state, action) {
      return Object.assign({}, state, {
        isWriting: false,
        destroyError: action.error,
        items: [].concat(_toConsumableArray(state.items), _toConsumableArray(state.destroyBars)),
        destroyBars: null
      });
    },
    DESTROY_BULK_BARS_COMPLETED: function DESTROY_BULK_BARS_COMPLETED(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        isWriting: false,
        destroyError: null,

        /*items: state.items,*/
        destroyBars: null
      });
    }
    /* REORDER_BARS(state, action) {
       var items = [...state.items];
       for (var i = 0; i < items.length; i++) {
         if (items[i].id == action.data.id) {
           items[i].sort = action.data.sort;
         } else if (items[i].sort >= action.data.sort) {
           items[i].sort = items[i].sort + 1;
         }
       }
       return Object.assign({}, state, {
         items: items
       });
     }*/

  }),
  notice: (0, _reduxwork.createReducer)('notice', {
    items: []
  }, {
    SHOW_NOTICE: function SHOW_NOTICE(state, action) {
      return Object.assign({}, state, {
        items: (0, _lodash.uniq)([].concat(_toConsumableArray(state.items), [action.id]))
      });
    },
    HIDE_NOTICE: function HIDE_NOTICE(state, action) {
      return Object.assign({}, state, {
        items: (0, _lodash.remove)(state.items, action.id)
      });
    }
  }),
  language: (0, _reduxwork.createReducer)('language', {
    locale: 'en'
  }, {
    CHANGE_LOCALE: function CHANGE_LOCALE(state, action) {
      return Object.assign({}, state, {
        locale: action.locale
      });
    }
  }),
  integrationInstances: (0, _reduxwork.createIoReducers)(options, 'integrationInstances'),
  integrationInstanceContexts: (0, _reduxwork.createIoReducers)(options, 'integrationInstanceContexts'),
  integrationEntries: (0, _reduxwork.createIoReducers)(options, 'integrationEntries')
};
var _default = reducersCommon;
exports["default"] = _default;