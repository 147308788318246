"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isRecordsetSkuSearchQuickListHidden = exports.getRecordsetSkuSearchTypes = exports.getRecordsetRecordScheme = exports.getRecordValues = exports.getRecordTemplate = exports.getRecordModeValues = exports.getRecordFormProps = exports.getFindRecordsFunction = exports.getAllowedRecordsetRecordModes = exports.RecodsetRecordConfiguration = void 0;

var _redux = require("@stktk/redux");

var _lodash = require("lodash");

var _commons = require("@stktk/commons");

var _allowedRecordModes, _allowedRecordModes2, _allowedRecordModes3, _allowedRecordModes4, _allowedRecordModes5, _allowedRecordModes6, _allowedRecordModes7, _RecodsetRecordConfig;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint-disable complexity */
var RecodsetRecordConfiguration = (_RecodsetRecordConfig = {}, _defineProperty(_RecodsetRecordConfig, _commons.RecordsetTypeEnum.ORDER, {
  skuSearchTypes: [_commons.SkuTypeEnum.SIMPLE],
  hideQuickList: false,
  recordTypeName: 'orderRecord',
  allowedRecordModes: (_allowedRecordModes = {}, _defineProperty(_allowedRecordModes, _commons.SkuStocktakeModeEnum.QUANTITY, [_commons.RecordModeEnum.QUANTITY]), _defineProperty(_allowedRecordModes, _commons.SkuStocktakeModeEnum.QUANTITY_VOLUME, [_commons.RecordModeEnum.QUANTITY]), _defineProperty(_allowedRecordModes, _commons.SkuStocktakeModeEnum.VOLUME, [_commons.RecordModeEnum.VOLUME]), _defineProperty(_allowedRecordModes, _commons.SkuStocktakeModeEnum.MASS, [_commons.RecordModeEnum.MASS]), _defineProperty(_allowedRecordModes, _commons.SkuStocktakeModeEnum.QUANTITY_MASS, [_commons.RecordModeEnum.QUANTITY]), _allowedRecordModes),
  actions: {
    //findRecords: ({ recordsetGroupId, recordsetId, domainId, restaurantId }) => findOrderRecords({ recordsetId, domainId, restaurantId }),
    findRecords: function findRecords(_ref) {
      var recordsetGroupId = _ref.recordsetGroupId,
          recordsetId = _ref.recordsetId,
          domainId = _ref.domainId,
          restaurantId = _ref.restaurantId;
      return recordsetGroupId ? (0, _redux.findOrderGroupRecords)({
        orderGroupId: recordsetGroupId,
        domainId: domainId,
        restaurantId: restaurantId
      }) : (0, _redux.findOrderRecords)({
        recordsetId: recordsetId,
        domainId: domainId,
        restaurantId: restaurantId
      });
    },
    createRecords: _redux.createOrderRecords,
    updateRecords: _redux.updateOrderRecords,
    destroyRecords: _redux.destroyOrderRecords,
    destroyBulkRecords: _redux.destroyBulkGroupOrderRecords
  },
  getScheme: function getScheme(recordMode, sku) {
    return recordMode && sku ? "orderRecord.".concat(sku.stocktakeMode, ".").concat(recordMode, ".create") : null;
  },
  getModeValues: function getModeValues(mode) {
    switch (mode) {
      case _commons.RecordModeEnum.QUANTITY:
        return {
          quantity: null,
          type: _commons.RecordTypeEnum.FULL
        };

      case _commons.RecordModeEnum.VOLUME:
        return {
          quantity: null,
          volume: null,
          volumeUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.VOLUME).id,
          type: _commons.RecordTypeEnum.OPEN
        };

      case _commons.RecordModeEnum.MASS:
        return {
          quantity: null,
          productWeightUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
          productWeight: null,
          type: _commons.RecordTypeEnum.OPEN
        };

      case _commons.RecordModeEnum.MASS2VOLUME:
        return {
          quantity: null,
          productWeightUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
          productWeight: null,
          type: _commons.RecordTypeEnum.OPEN
        };
    }
  },
  getInitialValues: function getInitialValues(mode, sku, _ref2) {
    var domainId = _ref2.domainId,
        restaurantId = _ref2.restaurantId,
        recordset = _ref2.recordset;
    return _objectSpread({
      orderGroupId: recordset.orderGroupId,
      recordsetId: recordset.id || null,
      barId: recordset.barId,
      restaurantId: restaurantId,
      domainId: domainId,
      mode: mode,
      skuId: sku.id,
      localSku: sku,
      status: recordset.status === _commons.OrderStatusEnum.RECEIVED ? _commons.OrderRecordStatusEnum.ARRIVED : _commons.OrderRecordStatusEnum.ORDERED,
      netPurchasePrice: null
    }, RecodsetRecordConfiguration[_commons.RecordsetTypeEnum.ORDER].getModeValues(mode));
  }
}), _defineProperty(_RecodsetRecordConfig, _commons.RecordsetTypeEnum.SALE, {
  skuSearchTypes: [_commons.SkuTypeEnum.SIMPLE, _commons.SkuTypeEnum.COMPOUND],
  hideQuickList: false,
  recordTypeName: 'saleRecord',
  recordTemplete: 'getInitialRecordValues',
  allowedRecordModes: (_allowedRecordModes2 = {}, _defineProperty(_allowedRecordModes2, _commons.SkuStocktakeModeEnum.QUANTITY, [_commons.RecordModeEnum.QUANTITY]), _defineProperty(_allowedRecordModes2, _commons.SkuStocktakeModeEnum.QUANTITY_VOLUME, [_commons.RecordModeEnum.QUANTITY, _commons.RecordModeEnum.VOLUME]), _defineProperty(_allowedRecordModes2, _commons.SkuStocktakeModeEnum.VOLUME, [_commons.RecordModeEnum.VOLUME]), _defineProperty(_allowedRecordModes2, _commons.SkuStocktakeModeEnum.MASS, [_commons.RecordModeEnum.MASS]), _defineProperty(_allowedRecordModes2, _commons.SkuStocktakeModeEnum.QUANTITY_MASS, [_commons.RecordModeEnum.QUANTITY, _commons.RecordModeEnum.MASS]), _allowedRecordModes2),
  actions: {
    findRecords: function findRecords(_ref3) {
      var recordsetGroupId = _ref3.recordsetGroupId,
          recordsetId = _ref3.recordsetId,
          domainId = _ref3.domainId,
          restaurantId = _ref3.restaurantId;
      return (0, _redux.findSaleRecords)({
        recordsetId: recordsetId,
        domainId: domainId,
        restaurantId: restaurantId
      });
    },
    createRecords: _redux.createBulkSaleRecords,
    destroyRecords: _redux.destroySaleRecords,
    destroyBulkRecords: _redux.destroyBulkGroupSaleRecords
  },
  getScheme: function getScheme(recordMode, sku) {
    return recordMode && sku ? "saleRecord.".concat(sku.stocktakeMode, ".").concat(recordMode, ".createBulk") : null;
  },
  getModeValues: function getModeValues(mode) {
    switch (mode) {
      case _commons.RecordModeEnum.QUANTITY:
        return {
          quantity: null,
          type: _commons.RecordTypeEnum.FULL
        };

      case _commons.RecordModeEnum.VOLUME:
        return {
          quantity: null,
          volume: null,
          volumeUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.VOLUME).id,
          type: _commons.RecordTypeEnum.OPEN
        };

      case _commons.RecordModeEnum.MASS:
        return {
          quantity: null,
          productWeightUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
          productWeight: null,
          type: _commons.RecordTypeEnum.OPEN
        };
    }
  },
  getInitialRecordValues: function getInitialRecordValues(mode, sku, _ref4) {
    var domainId = _ref4.domainId,
        restaurantId = _ref4.restaurantId,
        recordset = _ref4.recordset;
    return _objectSpread({
      eventDate: new Date().toISOString(),
      barId: 1,
      //FIXME: Future is now!
      recordsetId: recordset.id,
      restaurantId: restaurantId,
      domainId: domainId,
      mode: mode,
      skuId: sku.id,
      localSku: sku,
      status: _commons.SaleRecordStatusEnum.DEFAULT,
      netSalePrice: null
    }, RecodsetRecordConfiguration[_commons.RecordsetTypeEnum.SALE].getModeValues(mode));
  },
  getInitialValues: function getInitialValues(mode, sku, _ref5) {
    var domainId = _ref5.domainId,
        restaurantId = _ref5.restaurantId,
        recordset = _ref5.recordset;
    return [RecodsetRecordConfiguration[_commons.RecordsetTypeEnum.SALE].getInitialRecordValues(mode, sku, {
      domainId: domainId,
      restaurantId: restaurantId,
      recordset: recordset
    })];
  }
}), _defineProperty(_RecodsetRecordConfig, _commons.RecordsetTypeEnum.STOCKTAKE, {
  skuSearchTypes: [_commons.SkuTypeEnum.SIMPLE, _commons.SkuTypeEnum.COMPOUND],
  hideQuickList: false,
  recordTypeName: 'stocktakeRecord',
  recordTemplete: 'getInitialRecordValues',
  allowedRecordModes: (_allowedRecordModes3 = {}, _defineProperty(_allowedRecordModes3, _commons.SkuStocktakeModeEnum.QUANTITY, [_commons.RecordModeEnum.QUANTITY]), _defineProperty(_allowedRecordModes3, _commons.SkuStocktakeModeEnum.QUANTITY_VOLUME, [_commons.RecordModeEnum.QUANTITY, _commons.RecordModeEnum.MASS2VOLUME]), _defineProperty(_allowedRecordModes3, _commons.SkuStocktakeModeEnum.VOLUME, [_commons.RecordModeEnum.VOLUME]), _defineProperty(_allowedRecordModes3, _commons.SkuStocktakeModeEnum.MASS, [_commons.RecordModeEnum.MASS]), _defineProperty(_allowedRecordModes3, _commons.SkuStocktakeModeEnum.QUANTITY_MASS, [_commons.RecordModeEnum.QUANTITY, _commons.RecordModeEnum.MASS2MASS]), _allowedRecordModes3),
  actions: {
    findRecords: function findRecords(_ref6) {
      var recordsetGroupId = _ref6.recordsetGroupId,
          recordsetId = _ref6.recordsetId,
          domainId = _ref6.domainId,
          restaurantId = _ref6.restaurantId;
      return (0, _redux.findStockcheckRecords)({
        restaurantId: restaurantId,
        domainId: domainId,
        stockcheckId: recordsetGroupId
      });
    },
    createRecords: _redux.createBulkStocktakeRecords,
    updateRecords: _redux.updateStocktakeRecords,
    destroyRecords: _redux.destroyStocktakeRecords,
    destroyBulkRecords: _redux.destroyBulkGroupStocktakeRecords
  },
  getScheme: function getScheme(recordMode, sku) {
    return sku ? "stocktakeRecord.".concat(sku.stocktakeMode, ".createBulk") : null;
  },
  getModeValues: function getModeValues(mode) {
    switch (mode) {
      case _commons.RecordModeEnum.QUANTITY:
        return {
          quantity: null,
          type: _commons.RecordTypeEnum.FULL
        };

      case _commons.RecordModeEnum.VOLUME:
        return {
          quantity: null,
          volume: null,
          volumeUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.VOLUME).id,
          type: _commons.RecordTypeEnum.OPEN
        };

      case _commons.RecordModeEnum.MASS:
        return {
          quantity: null,
          scaleWeightUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
          scaleWeight: null,
          type: _commons.RecordTypeEnum.OPEN
        };

      case _commons.RecordModeEnum.MASS2VOLUME:
        return {
          quantity: null,
          scaleWeightUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
          scaleWeight: null,
          type: _commons.RecordTypeEnum.OPEN,
          closed: false,
          // on purpose
          packageClosureId: null
        };

      case _commons.RecordModeEnum.MASS2MASS:
        return {
          quantity: null,
          scaleWeightUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
          scaleWeight: null,
          type: _commons.RecordTypeEnum.OPEN,
          closed: false,
          // on purpose
          packageClosureId: null
        };
    }
  },
  getClosestActiveStocktake: function getClosestActiveStocktake() {
    var recordsets = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var root = recordsets.find(function (_ref7) {
      var bar = _ref7.bar;
      return bar.parentId === null;
    });
    var recordset = (0, _lodash.get)(root, 'status') === _commons.StocktakeStatusEnum.ACTIVE ? root : null;
    if (!recordset) recordset = recordsets.find(function (_ref8) {
      var status = _ref8.status,
          bar = _ref8.bar;
      return bar.parentId === root.bar.id && status === _commons.StocktakeStatusEnum.ACTIVE;
    });
    if (!recordset) recordset = recordsets.find(function (_ref9) {
      var status = _ref9.status;
      return status === _commons.StocktakeStatusEnum.ACTIVE;
    });
    return recordset ? recordset.id : null;
  },
  getInitialRecordValues: function getInitialRecordValues(mode, sku, _ref10) {
    var domainId = _ref10.domainId,
        restaurantId = _ref10.restaurantId,
        _ref10$recordset = _ref10.recordset,
        recordset = _ref10$recordset === void 0 ? {} : _ref10$recordset,
        recordsets = _ref10.recordsets;
    return _objectSpread({
      recordsetId: recordset.id || RecodsetRecordConfiguration[_commons.RecordsetTypeEnum.STOCKTAKE].getClosestActiveStocktake(recordsets),
      restaurantId: restaurantId,
      domainId: domainId,
      mode: mode,
      skuId: sku.id,
      localSku: sku,
      status: _commons.StocktakeRecordStatusEnum.DEFAULT
    }, RecodsetRecordConfiguration[_commons.RecordsetTypeEnum.STOCKTAKE].getModeValues(mode));
  },
  getInitialValues: function getInitialValues(mode, sku, _ref11) {
    var domainId = _ref11.domainId,
        restaurantId = _ref11.restaurantId,
        recordsets = _ref11.recordsets;
    return [RecodsetRecordConfiguration[_commons.RecordsetTypeEnum.STOCKTAKE].getInitialRecordValues(mode, sku, {
      domainId: domainId,
      restaurantId: restaurantId,
      recordsets: recordsets
    })];
  }
}), _defineProperty(_RecodsetRecordConfig, _commons.RecordsetTypeEnum.LOSS, {
  skuSearchTypes: [_commons.SkuTypeEnum.SIMPLE, _commons.SkuTypeEnum.COMPOUND],
  hideQuickList: true,
  recordTypeName: 'lossRecord',
  allowedRecordModes: (_allowedRecordModes4 = {}, _defineProperty(_allowedRecordModes4, _commons.SkuStocktakeModeEnum.QUANTITY, [_commons.RecordModeEnum.QUANTITY]), _defineProperty(_allowedRecordModes4, _commons.SkuStocktakeModeEnum.QUANTITY_VOLUME, [_commons.RecordModeEnum.QUANTITY, _commons.RecordModeEnum.VOLUME, _commons.RecordModeEnum.MASS2VOLUME]), _defineProperty(_allowedRecordModes4, _commons.SkuStocktakeModeEnum.VOLUME, [_commons.RecordModeEnum.VOLUME]), _defineProperty(_allowedRecordModes4, _commons.SkuStocktakeModeEnum.MASS, [_commons.RecordModeEnum.MASS]), _defineProperty(_allowedRecordModes4, _commons.SkuStocktakeModeEnum.QUANTITY_MASS, [_commons.RecordModeEnum.QUANTITY, _commons.RecordModeEnum.MASS, _commons.RecordModeEnum.MASS2MASS]), _allowedRecordModes4),
  actions: {
    findRecords: function findRecords(_ref12) {
      var recordsetGroupId = _ref12.recordsetGroupId,
          recordsetId = _ref12.recordsetId,
          domainId = _ref12.domainId,
          restaurantId = _ref12.restaurantId;
      return (0, _redux.findLossGroupRecords)({
        restaurantId: restaurantId,
        domainId: domainId,
        lossGroupId: recordsetGroupId
      });
    },
    createRecords: _redux.createLossRecords,
    destroyRecords: _redux.destroyLossRecords,
    destroyBulkRecords: _redux.destroyBulkGroupLossRecords
  },
  getScheme: function getScheme(recordMode, sku) {
    return recordMode && sku ? "lossRecord.".concat(sku.stocktakeMode, ".").concat(recordMode, ".create") : null;
  },
  getModeValues: function getModeValues(mode) {
    switch (mode) {
      case _commons.RecordModeEnum.QUANTITY:
        return {
          quantity: null,
          type: _commons.RecordTypeEnum.FULL
        };

      case _commons.RecordModeEnum.VOLUME:
        return {
          quantity: null,
          volume: null,
          volumeUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.VOLUME).id,
          type: _commons.RecordTypeEnum.OPEN
        };

      case _commons.RecordModeEnum.MASS:
        return {
          quantity: null,
          scaleWeightUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
          scaleWeight: null,
          type: _commons.RecordTypeEnum.OPEN
        };

      case _commons.RecordModeEnum.MASS2VOLUME:
        return {
          quantity: null,
          scaleWeightUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
          scaleWeight: null,
          type: _commons.RecordTypeEnum.OPEN,
          closed: false,
          // on purpose
          packageClosureId: null
        };

      case _commons.RecordModeEnum.MASS2MASS:
        return {
          quantity: null,
          scaleWeightUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
          scaleWeight: null,
          type: _commons.RecordTypeEnum.OPEN,
          closed: false,
          // on purpose
          packageClosureId: null
        };
    }
  },
  getInitialValues: function getInitialValues(mode, sku, _ref13) {
    var domainId = _ref13.domainId,
        restaurantId = _ref13.restaurantId,
        recordsets = _ref13.recordsets,
        user = _ref13.user;
    return _objectSpread({
      recordsetId: (0, _lodash.get)((0, _lodash.find)(recordsets, function (recordset) {
        return !recordset.bar.parentId;
      }), 'id'),
      restaurantId: restaurantId,
      domainId: domainId,
      status: _commons.LossRecordStatusEnum.OTHER,
      mode: mode,
      skuId: sku.id,
      localSku: sku,
      localCreator: {
        firstName: user.firstName,
        lastName: user.lastName
      },
      eventDate: new Date().toISOString()
    }, RecodsetRecordConfiguration[_commons.RecordsetTypeEnum.LOSS].getModeValues(mode));
  }
}), _defineProperty(_RecodsetRecordConfig, _commons.RecordsetTypeEnum.STORAGE, {
  skuSearchTypes: [_commons.SkuTypeEnum.SIMPLE, _commons.SkuTypeEnum.COMPOUND],
  hideQuickList: true,
  recordTypeName: 'storageRecord',
  allowedRecordModes: (_allowedRecordModes5 = {}, _defineProperty(_allowedRecordModes5, _commons.SkuStocktakeModeEnum.QUANTITY, [_commons.RecordModeEnum.QUANTITY]), _defineProperty(_allowedRecordModes5, _commons.SkuStocktakeModeEnum.QUANTITY_VOLUME, [_commons.RecordModeEnum.QUANTITY]), _defineProperty(_allowedRecordModes5, _commons.SkuStocktakeModeEnum.VOLUME, [_commons.RecordModeEnum.VOLUME]), _defineProperty(_allowedRecordModes5, _commons.SkuStocktakeModeEnum.MASS, [_commons.RecordModeEnum.MASS]), _defineProperty(_allowedRecordModes5, _commons.SkuStocktakeModeEnum.QUANTITY_MASS, [_commons.RecordModeEnum.QUANTITY]), _allowedRecordModes5),
  actions: {
    findRecords: function findRecords(_ref14) {
      var recordsetGroupId = _ref14.recordsetGroupId,
          recordsetId = _ref14.recordsetId,
          domainId = _ref14.domainId,
          restaurantId = _ref14.restaurantId;
      return (0, _redux.findStorageStandardRecords)({
        recordsetId: recordsetId,
        domainId: domainId,
        restaurantId: restaurantId
      });
    },
    createRecords: _redux.createStorageRecords,
    updateRecords: _redux.updateStorageRecords,
    destroyRecords: _redux.destroyStorageRecords,
    destroyBulkRecords: _redux.destroyBulkGroupStorageRecords
  },
  getScheme: function getScheme(recordMode, sku) {
    return recordMode && sku ? "storageRecord.".concat(sku.stocktakeMode, ".").concat(recordMode, ".create") : null;
  },
  getModeValues: function getModeValues(mode) {
    switch (mode) {
      case _commons.RecordModeEnum.QUANTITY:
        return {
          quantity: null,
          type: _commons.RecordTypeEnum.FULL
        };

      case _commons.RecordModeEnum.VOLUME:
        return {
          quantity: null,
          volume: null,
          volumeUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.VOLUME).id,
          type: _commons.RecordTypeEnum.OPEN
        };

      case _commons.RecordModeEnum.MASS:
        return {
          quantity: null,
          productWeightUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
          productWeight: null,
          type: _commons.RecordTypeEnum.OPEN
        };
    }
  },
  getInitialValues: function getInitialValues(mode, sku, _ref15) {
    var domainId = _ref15.domainId,
        restaurantId = _ref15.restaurantId,
        recordsets = _ref15.recordsets;
    return _objectSpread({
      recordsetId: (0, _lodash.get)((0, _lodash.find)(recordsets, function (recordset) {
        return !recordset.bar.parentId;
      }), 'id'),
      restaurantId: restaurantId,
      domainId: domainId,
      mode: mode,
      skuId: sku.id,
      localSku: sku,
      status: _commons.StorageRecordStatusEnum.DEFAULT
    }, RecodsetRecordConfiguration[_commons.RecordsetTypeEnum.STORAGE].getModeValues(mode));
  }
}), _defineProperty(_RecodsetRecordConfig, _commons.RecordsetTypeEnum.STORAGE_TRANSFER, {
  skuSearchTypes: [_commons.SkuTypeEnum.SIMPLE, _commons.SkuTypeEnum.COMPOUND],
  hideQuickList: false,
  recordTypeName: 'storageTransferRecord',
  allowedRecordModes: (_allowedRecordModes6 = {}, _defineProperty(_allowedRecordModes6, _commons.SkuStocktakeModeEnum.QUANTITY, [_commons.RecordModeEnum.QUANTITY]), _defineProperty(_allowedRecordModes6, _commons.SkuStocktakeModeEnum.QUANTITY_VOLUME, [_commons.RecordModeEnum.QUANTITY, _commons.RecordModeEnum.VOLUME, _commons.RecordModeEnum.MASS2VOLUME]), _defineProperty(_allowedRecordModes6, _commons.SkuStocktakeModeEnum.VOLUME, [_commons.RecordModeEnum.VOLUME]), _defineProperty(_allowedRecordModes6, _commons.SkuStocktakeModeEnum.MASS, [_commons.RecordModeEnum.MASS]), _defineProperty(_allowedRecordModes6, _commons.SkuStocktakeModeEnum.QUANTITY_MASS, [_commons.RecordModeEnum.QUANTITY, _commons.RecordModeEnum.MASS, _commons.RecordModeEnum.MASS2MASS]), _allowedRecordModes6),
  actions: {
    findRecords: _redux.findStorageTransferGroupRecords,
    createRecords: _redux.createBulkStorageTransferRecords,
    destroyBulkRecords: _redux.destroyBulkStorageTransferRecords
  },
  getScheme: function getScheme(recordMode, sku) {
    return recordMode && sku ? "storageTransferRecord.".concat(sku.stocktakeMode, ".").concat(recordMode, ".createBulk") : null;
  },
  getModeValues: function getModeValues(mode) {
    switch (mode) {
      case _commons.RecordModeEnum.QUANTITY:
        return {
          quantity: null,
          type: _commons.RecordTypeEnum.FULL
        };

      case _commons.RecordModeEnum.VOLUME:
        return {
          quantity: null,
          volume: null,
          volumeUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.VOLUME).id,
          type: _commons.RecordTypeEnum.OPEN
        };

      case _commons.RecordModeEnum.MASS:
        return {
          quantity: null,
          scaleWeightUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
          scaleWeight: null,
          type: _commons.RecordTypeEnum.OPEN
        };

      case _commons.RecordModeEnum.MASS2VOLUME:
        return {
          quantity: null,
          scaleWeightUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
          scaleWeight: null,
          type: _commons.RecordTypeEnum.OPEN,
          packageClosureId: null
        };

      case _commons.RecordModeEnum.MASS2MASS:
        return {
          quantity: null,
          productWeightUnitId: _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
          productWeight: null,
          type: _commons.RecordTypeEnum.OPEN,
          packageClosureId: null
        };
    }
  },
  getInitialValues: function getInitialValues(mode, sku, _ref16) {
    var domainId = _ref16.domainId,
        recordsetPair = _ref16.recordsetPair;
    console.log({
      recordsetPair: recordsetPair
    });
    var preset = recordsetPair ? JSON.parse(recordsetPair) : {};
    return _objectSpread({
      sourceBarId: preset.sourceBarId || null,
      targetBarId: preset.targetBarId || null,
      sourceRestaurantId: preset.sourceRestaurantId || null,
      targetRestaurantId: preset.targetRestaurantId || null,
      domainId: domainId,
      mode: mode,
      skuId: sku.id,
      localSku: sku,
      eventDate: new Date().toISOString()
    }, RecodsetRecordConfiguration[_commons.RecordsetTypeEnum.STORAGE_TRANSFER].getModeValues(mode));
  }
}), _defineProperty(_RecodsetRecordConfig, _commons.RecordsetTypeEnum.PRODUCTION, {
  skuSearchTypes: [_commons.SkuTypeEnum.COMPOUND],
  hideQuickList: false,
  recordTypeName: 'productionRecord',
  allowedRecordModes: (_allowedRecordModes7 = {}, _defineProperty(_allowedRecordModes7, _commons.SkuStocktakeModeEnum.QUANTITY, [_commons.RecordModeEnum.QUANTITY]), _defineProperty(_allowedRecordModes7, _commons.SkuStocktakeModeEnum.QUANTITY_VOLUME, [_commons.RecordModeEnum.QUANTITY, _commons.RecordModeEnum.VOLUME]), _defineProperty(_allowedRecordModes7, _commons.SkuStocktakeModeEnum.VOLUME, [_commons.RecordModeEnum.VOLUME]), _defineProperty(_allowedRecordModes7, _commons.SkuStocktakeModeEnum.MASS, [_commons.RecordModeEnum.MASS]), _defineProperty(_allowedRecordModes7, _commons.SkuStocktakeModeEnum.QUANTITY_MASS, [_commons.RecordModeEnum.QUANTITY, _commons.RecordModeEnum.MASS]), _allowedRecordModes7),
  actions: {
    findRecords: _redux.findProductionGroupRecords,
    createRecords: _redux.createBulkProductionRecords,
    destroyBulkRecords: _redux.destroyBulkProductionRecords
  },
  getScheme: function
    /*recordMode, sku*/
  getScheme() {
    return null;
  },
  //recordMode && sku ? `productionRecord.${sku.stocktakeMode}.${recordMode}.create` : null,
  getModeValues: function getModeValues(mode, measurement) {
    switch (mode) {
      case _commons.RecordModeEnum.QUANTITY:
        return {
          quantity:
          /*measurement.amount ||*/
          1,
          //FIXME: correct measurement should be provided as a parameter
          type: _commons.RecordTypeEnum.FULL
        };

      case _commons.RecordModeEnum.VOLUME:
        return {
          quantity: 1,
          volume: measurement.amount || null,
          volumeUnitId: measurement.unit.id || _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.VOLUME).id,
          type: _commons.RecordTypeEnum.OPEN
        };

      case _commons.RecordModeEnum.MASS:
        return {
          quantity: 1,
          productWeight: measurement.amount || null,
          productWeightUnitId: measurement.unit.id || _commons.UnitEnum.defaultTypeUnit(_commons.UnitTypeEnum.WEIGHT).id,
          type: _commons.RecordTypeEnum.OPEN
        };
    }
  },
  getInitialValues: function getInitialValues(mode, sku, _ref17) {
    var skus = _ref17.skus,
        user = _ref17.user;
    var measurement = (0, _commons.getSkuMeasurement)(sku);
    var eventDate = new Date().toISOString();
    return {
      outputRecords: RecodsetRecordConfiguration[_commons.RecordsetTypeEnum.PRODUCTION].getInitialRecordValues({
        user: user,
        sku: sku,
        skus: skus,
        status: _commons.ProductionRecordStatusEnum.OUTPUT,
        mode: mode || (0, _commons.getDefaultRecordModeByUnitId)(measurement.unit.id),
        measurement: measurement,
        eventDate: eventDate,
        isOutput: true
      }),
      inputRecords: (0, _lodash.flatten)(sku.ingredients.map(function (i) {
        return RecodsetRecordConfiguration[_commons.RecordsetTypeEnum.PRODUCTION].getInitialRecordValues({
          user: user,
          sku: i.ingredient,
          status: _commons.ProductionRecordStatusEnum.INPUT,
          mode: (0, _commons.getDefaultRecordModeByUnitId)(i.ingredientAmountUnitId),
          measurement: new _commons.Measurement({
            amount: i.ingredientAmount,
            unit: _commons.UnitEnum.get(i.ingredientAmountUnitId)
          }),
          eventDate: eventDate,
          skus: skus,
          shouldExpand: function shouldExpand(sku) {
            return sku.type === _commons.SkuTypeEnum.COMPOUND;
          }
        });
      }))
    };
  },
  getInitialRecordValues: function getInitialRecordValues(_ref18) {
    var user = _ref18.user,
        eventDate = _ref18.eventDate,
        sku = _ref18.sku,
        mode = _ref18.mode,
        status = _ref18.status,
        measurement = _ref18.measurement,
        _ref18$skus = _ref18.skus,
        skus = _ref18$skus === void 0 ? [] : _ref18$skus,
        _ref18$isOutput = _ref18.isOutput,
        isOutput = _ref18$isOutput === void 0 ? false : _ref18$isOutput;

    if (!isOutput && sku.type === _commons.SkuTypeEnum.COMPOUND) {
      return (0, _lodash.flatten)(skus.find(function (_ref19) {
        var id = _ref19.id;
        return sku.id === id;
      }).ingredients.map(function (_ref20) {
        var ingredient = _ref20.ingredient,
            ingredientAmount = _ref20.ingredientAmount,
            ingredientAmountUnitId = _ref20.ingredientAmountUnitId;
        return RecodsetRecordConfiguration[_commons.RecordsetTypeEnum.PRODUCTION].getInitialRecordValues({
          user: user,
          eventDate: eventDate,
          sku: ingredient,
          skus: skus,
          mode: (0, _commons.getDefaultRecordModeByUnitId)(ingredientAmountUnitId),
          status: status,
          measurement: new _commons.Measurement({
            amount: ingredientAmount,
            unit: _commons.UnitEnum.get(ingredientAmountUnitId)
          })
        });
      }));
    }

    return [_objectSpread({
      recordsetId: null,
      status: status,
      mode: mode,
      skuId: sku.id,
      localSku: sku,
      localCreator: {
        firstName: user.firstName,
        lastName: user.lastName
      },
      eventDate: eventDate
    }, RecodsetRecordConfiguration[_commons.RecordsetTypeEnum.PRODUCTION].getModeValues(mode, measurement))];
  }
}), _RecodsetRecordConfig);
exports.RecodsetRecordConfiguration = RecodsetRecordConfiguration;

var getRecordTemplate = function getRecordTemplate(type, mode, sku, props) {
  var templateCreatorAction = RecodsetRecordConfiguration[type].recordTemplete || 'getInitialValues';
  return RecodsetRecordConfiguration[type][templateCreatorAction](mode, sku, props);
};

exports.getRecordTemplate = getRecordTemplate;

var getFindRecordsFunction = function getFindRecordsFunction(_ref21) {
  var recordsetType = _ref21.recordsetType,
      recordsetGroupId = _ref21.recordsetGroupId,
      recordsetId = _ref21.recordsetId,
      domainId = _ref21.domainId,
      restaurantId = _ref21.restaurantId;
  var options = recordsetType === _commons.RecordsetTypeEnum.STORAGE_TRANSFER ? {
    storageTransferGroupId: recordsetGroupId,
    domainId: domainId,
    restaurantId: restaurantId
  } : {
    recordsetGroupId: recordsetGroupId,
    recordsetId: recordsetId,
    domainId: domainId,
    restaurantId: restaurantId
  };
  return RecodsetRecordConfiguration[recordsetType].actions.findRecords(options);
};

exports.getFindRecordsFunction = getFindRecordsFunction;

var getAllowedRecordsetRecordModes = function getAllowedRecordsetRecordModes(type, sku) {
  return sku ? RecodsetRecordConfiguration[type].allowedRecordModes[sku.stocktakeMode] : [];
};

exports.getAllowedRecordsetRecordModes = getAllowedRecordsetRecordModes;

var getRecordsetRecordScheme = function getRecordsetRecordScheme(type, recordMode, sku) {
  return RecodsetRecordConfiguration[type].getScheme(recordMode, sku);
};

exports.getRecordsetRecordScheme = getRecordsetRecordScheme;

var getRecordModeValues = function getRecordModeValues(type, mode) {
  return RecodsetRecordConfiguration[type].getModeValues(mode);
};

exports.getRecordModeValues = getRecordModeValues;

var getRecordValues = function getRecordValues(type, mode, sku, props) {
  return RecodsetRecordConfiguration[type].getInitialValues(mode, sku, props);
};

exports.getRecordValues = getRecordValues;

var getRecordFormProps = function getRecordFormProps(type, props) {
  return RecodsetRecordConfiguration[type].getFormProps(props);
};

exports.getRecordFormProps = getRecordFormProps;

var getRecordsetSkuSearchTypes = function getRecordsetSkuSearchTypes(type) {
  return RecodsetRecordConfiguration[type].skuSearchTypes;
};

exports.getRecordsetSkuSearchTypes = getRecordsetSkuSearchTypes;

var isRecordsetSkuSearchQuickListHidden = function isRecordsetSkuSearchQuickListHidden(type) {
  return RecodsetRecordConfiguration[type].hideQuickList;
};

exports.isRecordsetSkuSearchQuickListHidden = isRecordsetSkuSearchQuickListHidden;