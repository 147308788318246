import React, { useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import { toNumber } from 'lodash';
import classNames from 'classnames';
import Input from '$ui/form/controls/Input';

const FormInput = ({ name, type, className, onChange, submitOnBlur, focusOnRender, ...props }) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(name);
  const { submitForm } = useFormikContext();
  const ref = useRef(null);

  const getValue = (value, type) => value && value.length ? type === 'number' ? toNumber(value) : value : null;

  const handleChange = e => {
    const { value } = e.target;
    const fieldValue = getValue(value, type);
    if (onChange)
      onChange(e);
    setValue(fieldValue);
  };

  const handleBlur = () => {
    if (submitOnBlur) submitForm();
    else setTouched(true);
  };

  useEffect(() => {
    if (focusOnRender) ref.current.focus();
  }, []);

  return (
    <Input
      ref={ref}
      data-testid={`Input-${props.name}`}
      name={name}
      type={type}
      value={value == null ? '' : value}
      className={classNames('Input', { 'is-invalid': touched && error, 'is-valid': touched && !error }, className)}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />
  );
};

export default FormInput;