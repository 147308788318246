"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _locales = require("./locales");

Object.keys(_locales).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _locales[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _locales[key];
    }
  });
});

var _flyweightI18n = require("./flyweightI18n.js");

Object.keys(_flyweightI18n).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _flyweightI18n[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _flyweightI18n[key];
    }
  });
});

var _i18nUtils = require("./i18nUtils.js");

Object.keys(_i18nUtils).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _i18nUtils[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _i18nUtils[key];
    }
  });
});