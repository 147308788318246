import classNames from 'classnames';
import React from 'react';

export default function ListItemTitle({ title, Icon = null, children, className, ...props }) {
  return (
    <div className={classNames('ListItemTitle', className)} data-testid="ListItemTitle" {...props}>
      {Icon}
      {children || title}
    </div>
  );
}