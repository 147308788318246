import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'reactstrap';
import { Button } from '$ui';
import { __notificationText, __notificationActionText } from '@stktk/locales';
import { findNotifications, processNotifications, userDataSelector, notificationSelector } from '@stktk/redux';
import { useScreenOverlay } from '$hooks/ui';
import { globalHandleError } from '$utils/error';

export default function NotificationAlert() {
  const [hiddenNotifications, setHiddenNotifications] = useState({});
  const notifications = useSelector(notificationSelector);
  const user = useSelector(userDataSelector);
  const dispatch = useDispatch();
  const screenOverlay = useScreenOverlay();

  const runNotificationAction = data => {
    screenOverlay(true);
    dispatch(processNotifications(data)).then(response => {
      let redirect = get(response, '_redirect.domainId');
      if (redirect) {
        window.location.assign(`/panel/${redirect}`);
        window.localStorage.clear();
      } else
        screenOverlay(false);
    }).catch(error => {
      screenOverlay(false);
      globalHandleError(error);
    });
  };

  const filterNotifications = () => notifications.filter(entry => ['exit_joyride_v1.0'].includes(entry.type));

  useEffect(() => {
    dispatch(findNotifications({ userId: user.id, domainId: user.domainId })).catch(globalHandleError);
  }, []);

  return (
    <div>
      {filterNotifications().map(({ id, type, properties }) =>
        <span
          key={`notification-alert-${id}`}
          className={'Dashboard-pinned'}
        >
          <Alert
            color="warning"
            isOpen={!hiddenNotifications[id]}
            toggle={() => setHiddenNotifications({ ...hiddenNotifications, [id]: true })}
          >
            <div>{__notificationText(type, { keySeparator: '>', nsSeparator: '|' })}</div>
            <div className="NotificationButton pt-1">
              {properties.map(({ name }) => (
                <span key={name}>
                  <Button
                    variant={name == 'action_1' ? 'warning' : 'danger'}
                    text={__notificationActionText(type, name, { keySeparator: '>', nsSeparator: '|' })}
                    size="sm"
                    onClick={() => runNotificationAction({
                      userId: user.id,
                      domainId: user.domainId,
                      id,
                      action: name
                    })}
                  />
                </span>
              ))}
            </div>
          </Alert>
        </span>
      )}
    </div>
  );
}