import React from 'react';
import {
  Form,
  FormInput,
  FormSelect,
  //FormCheckbox,
  FormDate,
  FormPhone,
  FormCountry,
  FormColor,
  FormBarcode
} from '$ui';
import { globalHandleError } from '$utils/error';

/* eslint-disable complexity */
const getFormElement = (element, props) => {
  switch (element) {
    case 'input': return <FormInput submitOnBlur focusOnRender {...props} />;
    case 'select': return <FormSelect submitOnBlur focusOnRender {...props} />;
    //case 'checkbox': return <FormCheckbox {...props} />;
    case 'date': return <FormDate submitOnBlur focusOnRender keepFocus {...props} />;
    case 'phone': return <FormPhone submitOnBlur {...props} />;
    case 'country': return <FormCountry submitOnBlur focusOnRender {...props} />;
    case 'color': return <FormColor submitOnBlur focusOnRender hidePrepend {...props} />;
    case 'barcode': return <FormBarcode submitOnBlur focusOnRender {...props} />;
    default: return null;
  }
};
/* eslint-enable complexity */

const TableEdit = ({ cell: { value, column }, row, onUpdate, scheme, formValues, editItemId, tableDispatch }) => {

  const handleSubmit = data => {
    //FIXME: injected error handling
    onUpdate(data).catch(globalHandleError);
    tableDispatch({ type: 'RESET_EDIT' });
  };

  const rowValues = column.rowValues ? column.rowValues(row) : {};
  return (
    <Form
      scheme={column.rowScheme ? column.rowScheme(row) : (column.scheme || scheme)}
      initialValues={{
        id: editItemId,
        [column.formElementProps.name]: value,
        ...(column.formValues || formValues),
        ...rowValues
      }}
      onSubmit={handleSubmit}
    >
      {getFormElement(column.formElement, column.formElementProps)}
    </Form>
  );
};

export default TableEdit;