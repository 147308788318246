"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _validatorConstants = require("../validator/validatorConstants.js");

var _commons = require("@stktk/commons");

var _schemeConstants = require("./schemeConstants.js");

var _orderGroupRecordMode, _find$destroy$destroy;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint-disable new-cap */
var recordCommonCreateScheme = {
  recordsetId: _validatorConstants.INTEGER.optional([1]),
  restaurantId: _validatorConstants.INTEGER.required([1]),
  domainId: _validatorConstants.INTEGER.required([1]),
  skuId: _validatorConstants.INTEGER.required([1]),
  // FIXME: localSku seperate scheme
  localSku: _validatorConstants.OBJECT.INPART.optional({
    stocktakeMode: _validatorConstants.ENUM.STRING.optional(_commons.SkuStocktakeModeEnum.toArray())
  }),
  orderGroupId: _validatorConstants.INTEGER.required([1]),
  barId: _validatorConstants.INTEGER.required([1]),
  supplierId: _validatorConstants.INTEGER.required([1])
};
var recordCommonUpdateScheme = {
  id: _validatorConstants.INTEGER.required([1]),
  recordsetId: _validatorConstants.INTEGER.optional([1]),
  restaurantId: _validatorConstants.INTEGER.required([1]),
  localSku: _validatorConstants.OBJECT.INPART.optional({
    stocktakeMode: _validatorConstants.ENUM.STRING.optional(_commons.SkuStocktakeModeEnum.toArray())
  }),
  domainId: _validatorConstants.INTEGER.optional([1]),
  //FIXME: mobile fallback,
  orderGroupId: _validatorConstants.INTEGER.required([1]),
  barId: _validatorConstants.INTEGER.required([1]),
  supplierId: _validatorConstants.INTEGER.required([1])
};
var orderGroupRecordModeSchemes = (_orderGroupRecordMode = {}, _defineProperty(_orderGroupRecordMode, _commons.RecordModeEnum.QUANTITY, {
  update: Object.assign({}, recordCommonUpdateScheme, {
    supplierId: _validatorConstants.INTEGER.optional([1]),
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.QUANTITY]),
    // updatable
    status: _validatorConstants.ENUM.STRING.required(_commons.OrderRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.FULL]),
    //FIXME: both same type and optional
    quantity: (0, _validatorConstants.WHEN)('status', {
      is: function is(status) {
        return status == _commons.OrderRecordStatusEnum.ARRIVED;
      },
      then: _validatorConstants.DECIMAL.optional(_schemeConstants.QUANTITY_PRECISION),
      otherwise: _validatorConstants.DECIMAL.optional(_schemeConstants.QUANTITY_PRECISION)
    }),
    netPurchasePrice: _validatorConstants.NUMBER.optional([0]),
    orderGroupId: _validatorConstants.INTEGER.required([0]),
    recordsetId: _validatorConstants.INTEGER.optional([0])
  }),
  create: Object.assign({}, recordCommonCreateScheme, {
    supplierId: _validatorConstants.INTEGER.optional([1]),
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.QUANTITY]),
    // attributes
    status: _validatorConstants.ENUM.STRING.required(_commons.OrderRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.FULL]),
    quantity: (0, _validatorConstants.WHEN)('status', {
      //FIXME: both same type and required
      is: function is(status) {
        return status == _commons.OrderRecordStatusEnum.ARRIVED;
      },
      then: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
      otherwise: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION)
    }),
    netPurchasePrice: _validatorConstants.NUMBER.optional([0]),
    orderGroupId: _validatorConstants.INTEGER.required([0]),
    recordsetId: _validatorConstants.INTEGER.optional([0])
  })
}), _defineProperty(_orderGroupRecordMode, _commons.RecordModeEnum.VOLUME, {
  update: Object.assign({}, recordCommonUpdateScheme, {
    supplierId: _validatorConstants.INTEGER.optional([1]),
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.VOLUME]),
    // updatable
    status: _validatorConstants.ENUM.STRING.required(_commons.OrderRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    //FIXME: both same type and optional
    quantity: (0, _validatorConstants.WHEN)('status', {
      is: function is(status) {
        return status == _commons.OrderRecordStatusEnum.ARRIVED;
      },
      then: _validatorConstants.DECIMAL.optional(_schemeConstants.QUANTITY_PRECISION),
      otherwise: _validatorConstants.DECIMAL.optional(_schemeConstants.QUANTITY_PRECISION)
    }),
    volumeUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.VOLUME)),
    volume: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    netPurchasePrice: _validatorConstants.NUMBER.optional([0]),
    orderGroupId: _validatorConstants.INTEGER.required([0]),
    recordsetId: _validatorConstants.INTEGER.optional([0])
  }),
  create: Object.assign({}, recordCommonCreateScheme, {
    supplierId: _validatorConstants.INTEGER.optional([1]),
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.VOLUME]),
    // attributes
    status: _validatorConstants.ENUM.STRING.required(_commons.OrderRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    //FIXME: both same type and required
    quantity: (0, _validatorConstants.WHEN)('status', {
      is: function is(status) {
        return status == _commons.OrderRecordStatusEnum.ARRIVED;
      },
      then: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
      otherwise: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION)
    }),
    volumeUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.VOLUME)),
    volume: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    netPurchasePrice: _validatorConstants.NUMBER.optional([0]),
    orderGroupId: _validatorConstants.INTEGER.required([0]),
    recordsetId: _validatorConstants.INTEGER.optional([0])
  })
}), _defineProperty(_orderGroupRecordMode, _commons.RecordModeEnum.MASS, {
  update: Object.assign({}, recordCommonUpdateScheme, {
    supplierId: _validatorConstants.INTEGER.optional([1]),
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.MASS]),
    // updatable
    status: _validatorConstants.ENUM.STRING.required(_commons.OrderRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    //FIXME: both same type and optional
    quantity: (0, _validatorConstants.WHEN)('status', {
      is: function is(status) {
        return status == _commons.OrderRecordStatusEnum.ARRIVED;
      },
      then: _validatorConstants.DECIMAL.optional(_schemeConstants.QUANTITY_PRECISION),
      otherwise: _validatorConstants.DECIMAL.optional(_schemeConstants.QUANTITY_PRECISION)
    }),
    productWeightUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    productWeight: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    netPurchasePrice: _validatorConstants.NUMBER.optional([0]),
    orderGroupId: _validatorConstants.INTEGER.required([0]),
    recordsetId: _validatorConstants.INTEGER.optional([0])
  }),
  create: Object.assign({}, recordCommonCreateScheme, {
    supplierId: _validatorConstants.INTEGER.optional([1]),
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.MASS]),
    // attributes
    status: _validatorConstants.ENUM.STRING.required(_commons.OrderRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    quantity: (0, _validatorConstants.WHEN)('status', {
      //FIXME: both same type and required
      is: function is(status) {
        return status == _commons.OrderRecordStatusEnum.ARRIVED;
      },
      then: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
      otherwise: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION)
    }),
    productWeightUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    productWeight: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    netPurchasePrice: _validatorConstants.NUMBER.optional([0]),
    orderGroupId: _validatorConstants.INTEGER.required([0]),
    recordsetId: _validatorConstants.INTEGER.optional([0])
  })
}), _orderGroupRecordMode);

var _default = (_find$destroy$destroy = {
  find: {
    restaurantId: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    orderGroupId: _validatorConstants.INTEGER.required([1])
  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    orderGroupId: _validatorConstants.INTEGER.optional([1])
  },
  destroyGroup: {
    skuId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    orderGroupId: _validatorConstants.INTEGER.optional([1])
  },
  destroyBulkGroup: _validatorConstants.ARRAY.required(_validatorConstants.OBJECT.required(_validatorConstants.ARRAY.required(_validatorConstants.OBJECT.required({
    skuId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    orderGroupId: _validatorConstants.INTEGER.optional([1])
  }), [_schemeConstants.BULK_AMOUNT_MIN, _schemeConstants.BULK_AMOUNT_MAX])), [_schemeConstants.BULK_AMOUNT_MIN, _schemeConstants.BULK_AMOUNT_MAX])
}, _defineProperty(_find$destroy$destroy, _commons.SkuStocktakeModeEnum.QUANTITY, _defineProperty({}, _commons.RecordModeEnum.QUANTITY, orderGroupRecordModeSchemes[_commons.RecordModeEnum.QUANTITY])), _defineProperty(_find$destroy$destroy, _commons.SkuStocktakeModeEnum.QUANTITY_VOLUME, _defineProperty({}, _commons.RecordModeEnum.QUANTITY, orderGroupRecordModeSchemes[_commons.RecordModeEnum.QUANTITY])), _defineProperty(_find$destroy$destroy, _commons.SkuStocktakeModeEnum.VOLUME, _defineProperty({}, _commons.RecordModeEnum.VOLUME, orderGroupRecordModeSchemes[_commons.RecordModeEnum.VOLUME])), _defineProperty(_find$destroy$destroy, _commons.SkuStocktakeModeEnum.MASS, _defineProperty({}, _commons.RecordModeEnum.MASS, orderGroupRecordModeSchemes[_commons.RecordModeEnum.MASS])), _defineProperty(_find$destroy$destroy, _commons.SkuStocktakeModeEnum.QUANTITY_MASS, _defineProperty({}, _commons.RecordModeEnum.QUANTITY, orderGroupRecordModeSchemes[_commons.RecordModeEnum.QUANTITY])), _find$destroy$destroy);

exports["default"] = _default;