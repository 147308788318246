import React, { useContext } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import classNames from 'classnames';
import { __ } from '@stktk/locales';
import { ViewVariant } from './ViewVariants.jsx';
import { Icon } from '$ui';
import { UiContext } from '$providers/UiProvider.jsx';

export default function PageControls({
  primary,
  secondary,
  tertiary,
  viewType,
  viewTypeOptions,
  onViewTypeSwitch,
  disableViewVariantSwitch,
  disablePageControlsButton,
  ...props
}) {

  const [{ viewVariant }, uiDispatch] = useContext(UiContext);

  const setView = viewVariant => {
    window.localStorage.setItem('viewVariant', viewVariant);
    uiDispatch({ type: 'setViewVariant', value: viewVariant });
  };

  return (
    <div className="PageControls" {...props}>
      {!disableViewVariantSwitch && (
        <div className={classNames('PageControls-view', { 'justify-content-between': viewType })}>
          {viewType && (
            <ButtonGroup>
              {viewTypeOptions.map(({ icon, label, type }) => (
                <Button
                  key={`PageControls-Button-ViewType-${type}`}
                  id={`PageControls-Button-ViewType-${type}`}
                  data-testid={`PageControls-Button-ViewType-${type}`}
                  size="sm"
                  type="button"
                  color={'light'}
                  active={viewType === type}
                  onClick={() => onViewTypeSwitch(type)}
                >
                  <Icon icon={icon} size="sm" className="d-inline-block mr-2" />
                  {label}
                </Button>
              ))}
            </ButtonGroup>
          )}
          <ButtonGroup>
            <Button
              size="sm"
              id={'PageControls-Button-TableVariant'}
              data-testid={'PageControls-TableVariant'}
              type="button"
              color={'light'}
              active={viewVariant === ViewVariant.TABLE}
              onClick={() => setView(ViewVariant.TABLE)}
            >
              <Icon icon="table" size="sm" className="d-inline-block mr-2" />
              {__('app_view_table')}
            </Button>
            <Button
              size="sm"
              id={'PageControls-Button-ListVariant'}
              data-testid={'PageControls-ListVariant'}
              type="button"
              color={'light'}
              active={viewVariant === ViewVariant.LIST}
              onClick={() => setView(ViewVariant.LIST)}
            >
              <Icon icon="list" size="sm" className="d-inline-block mr-2" />
              {__('app_view_list')}
            </Button>
          </ButtonGroup>
        </div>
      )}
      <div className="d-flex justify-content-between">
        <div className="PageControls-primary">
          {primary}
        </div>
        <div className="PageControls-secondary">
          {secondary}
        </div>
      </div>
    </div>
  );
}