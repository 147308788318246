import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import OutsideClickHandler from 'react-outside-click-handler';
import { orderBy } from 'lodash';
import NumericInput from '$ui/NumericInput';

const OrderDropdown = ({ disabled, name, placeholder, records, setOrderAmount, property }) => {
  const [isOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!isOpen);

  const handleOutsideClick = event => {
    find(event.path, element => element.className == 'dropdown-menu') ? null : setOpen(false);
  };

  const getTotalRecordQuantityAmount = records => {
    let amount = records.reduce((accumulator, record) => {
      return accumulator + record.quantity;
    }, 0);
    return amount.toFixed(2);
  };

  let recordsInOrder = orderBy(records, 'id', 'asc');
  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <Dropdown id={name} isOpen={isOpen} toggle={() => { /* Needed to ignore item clicks */ }}>
        <DropdownToggle
          caret
          className="btn btn-light btn-l"
          onClick={toggle}
        >
          {getTotalRecordQuantityAmount(records)}
        </DropdownToggle>
        <DropdownMenu style={{ minWidth: 260 }}>
          {recordsInOrder.map((record, i) => (
            <DropdownItem key={record.id} tag="div">
              <NumericInput
                key={record.id}
                name={`OrderDropdown-${i}`}
                placeholder={placeholder}
                hideControls={property !== 'quantity'}
                value={(record[property]).toFixed(2)}
                disabled={disabled}
                onChange={value => setOrderAmount(record, value)}
              />
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </OutsideClickHandler>
  );
};

export default OrderDropdown;