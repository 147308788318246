"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _recordCommonSchemes = require("./_recordCommonSchemes.js");

var _validatorConstants = require("../validator/validatorConstants.js");

var _commons = require("@stktk/commons");

var _schemeConstants = require("./schemeConstants.js");

var _orderRecordModeSchem, _find$destroy$destroy;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint-disable new-cap */
var orderRecordModeSchemes = (_orderRecordModeSchem = {}, _defineProperty(_orderRecordModeSchem, _commons.RecordModeEnum.QUANTITY, {
  update: Object.assign({}, _recordCommonSchemes.recordCommonUpdateScheme, {
    supplierId: _validatorConstants.INTEGER.optional([1]),
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.QUANTITY]),
    // updatable
    status: _validatorConstants.ENUM.STRING.required(_commons.OrderRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.FULL]),
    //FIXME: both same type and optional
    quantity: (0, _validatorConstants.WHEN)('status', {
      is: function is(status) {
        return status == _commons.OrderRecordStatusEnum.ARRIVED;
      },
      then: _validatorConstants.DECIMAL.optional(_schemeConstants.QUANTITY_PRECISION),
      otherwise: _validatorConstants.DECIMAL.optional(_schemeConstants.QUANTITY_PRECISION)
    }),
    netPurchasePrice: _validatorConstants.NUMBER.optional([0]),
    orderGroupId: _validatorConstants.INTEGER.required([0]),
    recordsetId: _validatorConstants.INTEGER.optional([0])
  }),
  create: Object.assign({}, _recordCommonSchemes.recordCommonCreateScheme, {
    supplierId: _validatorConstants.INTEGER.optional([1]),
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.QUANTITY]),
    // attributes
    status: _validatorConstants.ENUM.STRING.required(_commons.OrderRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.FULL]),
    quantity: (0, _validatorConstants.WHEN)('status', {
      //FIXME: both same type and required
      is: function is(status) {
        return status == _commons.OrderRecordStatusEnum.ARRIVED;
      },
      then: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
      otherwise: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION)
    }),
    netPurchasePrice: _validatorConstants.NUMBER.optional([0]),
    orderGroupId: _validatorConstants.INTEGER.required([0]),
    recordsetId: _validatorConstants.INTEGER.optional([0])
  })
}), _defineProperty(_orderRecordModeSchem, _commons.RecordModeEnum.VOLUME, {
  update: Object.assign({}, _recordCommonSchemes.recordCommonUpdateScheme, {
    supplierId: _validatorConstants.INTEGER.optional([1]),
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.VOLUME]),
    // updatable
    status: _validatorConstants.ENUM.STRING.required(_commons.OrderRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    //FIXME: both same type and optional
    quantity: (0, _validatorConstants.WHEN)('status', {
      is: function is(status) {
        return status == _commons.OrderRecordStatusEnum.ARRIVED;
      },
      then: _validatorConstants.DECIMAL.optional(_schemeConstants.QUANTITY_PRECISION),
      otherwise: _validatorConstants.DECIMAL.optional(_schemeConstants.QUANTITY_PRECISION)
    }),
    volumeUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.VOLUME)),
    volume: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    netPurchasePrice: _validatorConstants.NUMBER.optional([0]),
    orderGroupId: _validatorConstants.INTEGER.required([0]),
    recordsetId: _validatorConstants.INTEGER.optional([0])
  }),
  create: Object.assign({}, _recordCommonSchemes.recordCommonCreateScheme, {
    supplierId: _validatorConstants.INTEGER.optional([1]),
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.VOLUME]),
    // attributes
    status: _validatorConstants.ENUM.STRING.required(_commons.OrderRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    //FIXME: both same type and required
    quantity: (0, _validatorConstants.WHEN)('status', {
      is: function is(status) {
        return status == _commons.OrderRecordStatusEnum.ARRIVED;
      },
      then: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
      otherwise: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION)
    }),
    volumeUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.VOLUME)),
    volume: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    netPurchasePrice: _validatorConstants.NUMBER.optional([0]),
    orderGroupId: _validatorConstants.INTEGER.required([0]),
    recordsetId: _validatorConstants.INTEGER.optional([0])
  })
}), _defineProperty(_orderRecordModeSchem, _commons.RecordModeEnum.MASS, {
  update: Object.assign({}, _recordCommonSchemes.recordCommonUpdateScheme, {
    supplierId: _validatorConstants.INTEGER.optional([1]),
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.MASS]),
    // updatable
    status: _validatorConstants.ENUM.STRING.required(_commons.OrderRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    //FIXME: both same type and optional
    quantity: (0, _validatorConstants.WHEN)('status', {
      is: function is(status) {
        return status == _commons.OrderRecordStatusEnum.ARRIVED;
      },
      then: _validatorConstants.DECIMAL.optional(_schemeConstants.QUANTITY_PRECISION),
      otherwise: _validatorConstants.DECIMAL.optional(_schemeConstants.QUANTITY_PRECISION)
    }),
    productWeightUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    productWeight: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    netPurchasePrice: _validatorConstants.NUMBER.optional([0]),
    orderGroupId: _validatorConstants.INTEGER.required([0]),
    recordsetId: _validatorConstants.INTEGER.optional([0])
  }),
  create: Object.assign({}, _recordCommonSchemes.recordCommonCreateScheme, {
    supplierId: _validatorConstants.INTEGER.optional([1]),
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.MASS]),
    // attributes
    status: _validatorConstants.ENUM.STRING.required(_commons.OrderRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    quantity: (0, _validatorConstants.WHEN)('status', {
      //FIXME: both same type and required
      is: function is(status) {
        return status == _commons.OrderRecordStatusEnum.ARRIVED;
      },
      then: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
      otherwise: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION)
    }),
    productWeightUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    productWeight: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION]),
    netPurchasePrice: _validatorConstants.NUMBER.optional([0]),
    orderGroupId: _validatorConstants.INTEGER.required([0]),
    recordsetId: _validatorConstants.INTEGER.optional([0])
  })
}), _orderRecordModeSchem);

var _default = (_find$destroy$destroy = {
  // non schema-related
  find: Object.assign({}, _recordCommonSchemes.recordCommonFindScheme, {
    recordsetId: _validatorConstants.INTEGER.optional([1]),
    orderGroupId: _validatorConstants.INTEGER.optional([1])
  }),
  destroy: Object.assign({}, _recordCommonSchemes.recordCommonDestroyScheme, {
    recordsetId: _validatorConstants.INTEGER.optional([1]),
    orderGroupId: _validatorConstants.INTEGER.optional([1])
  }),
  destroyGroup: Object.assign({}, _recordCommonSchemes.recordCommonDestroyGroupScheme, {
    recordsetId: _validatorConstants.INTEGER.optional([1]),
    orderGroupId: _validatorConstants.INTEGER.optional([1])
  }),
  destroyBulkGroup: Object.assign({}, _recordCommonSchemes.recordCommonDestroyBulkGroupScheme, {
    recordsetId: _validatorConstants.INTEGER.optional([1]),
    orderGroupId: _validatorConstants.INTEGER.optional([1])
  }),
  destroyBulk: _recordCommonSchemes.recordCommonDestroyBulkScheme
}, _defineProperty(_find$destroy$destroy, _commons.SkuStocktakeModeEnum.QUANTITY, _defineProperty({}, _commons.RecordModeEnum.QUANTITY, orderRecordModeSchemes[_commons.RecordModeEnum.QUANTITY])), _defineProperty(_find$destroy$destroy, _commons.SkuStocktakeModeEnum.QUANTITY_VOLUME, _defineProperty({}, _commons.RecordModeEnum.QUANTITY, orderRecordModeSchemes[_commons.RecordModeEnum.QUANTITY])), _defineProperty(_find$destroy$destroy, _commons.SkuStocktakeModeEnum.VOLUME, _defineProperty({}, _commons.RecordModeEnum.VOLUME, orderRecordModeSchemes[_commons.RecordModeEnum.VOLUME])), _defineProperty(_find$destroy$destroy, _commons.SkuStocktakeModeEnum.MASS, _defineProperty({}, _commons.RecordModeEnum.MASS, orderRecordModeSchemes[_commons.RecordModeEnum.MASS])), _defineProperty(_find$destroy$destroy, _commons.SkuStocktakeModeEnum.QUANTITY_MASS, _defineProperty({}, _commons.RecordModeEnum.QUANTITY, orderRecordModeSchemes[_commons.RecordModeEnum.QUANTITY])), _find$destroy$destroy);

exports["default"] = _default;