"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mergeSettings = exports.getUserSettings = exports.getSettings = exports.getRestaurantSettings = exports.getIntegrationSettings = exports.getDomainSettings = void 0;

var _defaults = require("./defaults");

var _lodash = require("lodash");

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var mergeSettings = function mergeSettings(settings) {
  return settings.reduce(function (prev, next) {
    return _objectSpread(_objectSpread(_objectSpread({}, prev), next), {}, {
      integrations: (0, _lodash.map)((0, _lodash.groupBy)([].concat(_toConsumableArray(prev.integrations || []), _toConsumableArray(next.integrations || [])), 'name'), function (group) {
        return group.reduce(function (prev, next) {
          return _objectSpread(_objectSpread({}, prev), next);
        }, {});
      })
    });
  }, {});
};

exports.mergeSettings = mergeSettings;

var getSettings = function getSettings(prop, properties) {
  var _Object$keys = Object.keys(prop),
      _Object$keys2 = _slicedToArray(_Object$keys, 1),
      key = _Object$keys2[0];

  var _Object$values = Object.values(prop),
      _Object$values2 = _slicedToArray(_Object$values, 1),
      value = _Object$values2[0];

  if (!value) // FIXME: ...
    return {};
  var property = properties.find(function (property) {
    return property[key] === value;
  });
  return (0, _lodash.get)(property, 'settings', {});
};

exports.getSettings = getSettings;

var getDomainSettings = function getDomainSettings(_ref) {
  var domainId = _ref.domainId;
  var properties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return mergeSettings([_defaults.defaultDomainSettings, getSettings({
    domainId: domainId
  }, properties)]);
};

exports.getDomainSettings = getDomainSettings;

var getRestaurantSettings = function getRestaurantSettings(_ref2) {
  var restaurantId = _ref2.restaurantId,
      domainId = _ref2.domainId;
  var properties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return mergeSettings([_defaults.defaultRestaurantSettings, getDomainSettings({
    domainId: domainId
  }, properties), getSettings({
    restaurantId: restaurantId
  }, properties)]);
};

exports.getRestaurantSettings = getRestaurantSettings;

var getUserSettings = function getUserSettings(_ref3) {
  var restaurantId = _ref3.restaurantId,
      domainId = _ref3.domainId,
      userId = _ref3.userId;
  var properties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return mergeSettings([_defaults.defaultUserSettings, getRestaurantSettings({
    restaurantId: restaurantId,
    domainId: domainId
  }, properties), getSettings({
    userId: userId
  }, properties)]);
}; // FIXME: doesn't really work if 2 or more integration instances exist


exports.getUserSettings = getUserSettings;

var getIntegrationSettings = function getIntegrationSettings(settings, integration) {
  return (0, _lodash.get)(settings, 'integrations', []).find(function (_ref4) {
    var name = _ref4.name;
    return name === integration.name;
  });
};

exports.getIntegrationSettings = getIntegrationSettings;