export const setModal = (type, id, value) => ({
  type: 'setModal',
  modalType: type,
  value,
  id
});

export const setNotice = (id, value) => ({
  type: 'setNotice',
  value,
  id
});

export const setLoading = (id, value) => ({
  type: 'setLoading',
  value,
  id
});

export const setShowBurgerMenu = value => ({
  type: 'setShowBurgerMenu',
  value
});

export const setShowScreenOverlay = value => ({
  type: 'setShowScreenOverlay',
  value
});

export const setNavbarVerticalHovered = value => ({
  type: 'setNavbarVerticalHovered',
  value
});

export const setNavbarVerticalCollapsed = value => ({
  type: 'setNavbarVerticalCollapsed',
  value
});

export const setNavbarCollapsed = value => ({
  type: 'setNavbarCollapsed',
  value
});

export const updateBreadcrumbs = value => ({
  type: 'updateBreadcrumbs',
  value
});