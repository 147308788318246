"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getNetGroupSaleValue = exports.getNetGroupPurchaseValue = exports.getFinalizationSkus = exports.getFinalizationPromise = exports.getFinalizationAction = void 0;

var _lodash = require("lodash");

var _commons = require("@stktk/commons");

var _redux = require("@stktk/redux");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var getNetGroupPurchaseValue = function getNetGroupPurchaseValue(type) {
  return type === "stockcheck" ? "netStocktakePurchaseValue" : "netLossPurchaseValue";
};

exports.getNetGroupPurchaseValue = getNetGroupPurchaseValue;

var getNetGroupSaleValue = function getNetGroupSaleValue(type) {
  return type === "stockcheck" ? "netStocktakeSaleValue" : "netLossSaleValue";
};

exports.getNetGroupSaleValue = getNetGroupSaleValue;

var getFinalizationSkus = function getFinalizationSkus(recordsetType, records, data) {
  console.log("getFinalizationSkus", {
    recordsetType: recordsetType,
    records: records,
    data: data
  });
  return (0, _lodash.sortBy)((0, _lodash.map)((0, _lodash.groupBy)(records, "skuId"), function (records) {
    var _lastStockcheck, _value;

    var _records = _slicedToArray(records, 1),
        sku = _records[0].sku;

    var skuId = sku.id,
        name = sku.name,
        image = sku.image;
    console.log("getFinalizationSkus1", records, sku);
    var netGroupPurchaseValue = getNetGroupPurchaseValue(recordsetType);
    var netGroupSaleValue = getNetGroupSaleValue(recordsetType);
    var
    /*totalStockcheck1Amount = 0, */
    totalOrderAmount = 0,
        totalSaleAmount = 0,
        totalStockcheck0Amount = 0;
    var
    /*totalStockcheck1PurchaseValue = 0, totalStockcheck1SaleValue = 0, */
    totalOrderValue = 0,
        totalSaleValue = 0,
        totalStockcheck0SaleValue = 0,
        totalStockcheck0PurchaseValue = 0;
    var stockcheck1Records = (0, _lodash.filter)(data.stockcheck1Records, {
      skuId: skuId
    }); //   .map((record) => {
    //     // const amount = getTotalRecordSkuNormalizedAmount(record, sku);
    //     // totalStockcheck1Amount += amount;
    //     // totalStockcheck1PurchaseValue += amount * record[netGroupPurchaseValue];
    //     // totalStockcheck1SaleValue += amount * record[netGroupSaleValue];
    //     return Object.assign({}, record, {
    //       // _netGroupPurchaseValue: amount * record[netGroupPurchaseValue],
    //       // _netGroupSaleValue: amount * record[netGroupSaleValue],
    //       // _amount: amount
    //       // NOTE: assumption - every record has the same purchase value/sale value (no weighted average calculated)
    //       _netGroupPurchaseValue: record[netGroupPurchaseValue],
    //       _netGroupSaleValue: record[netGroupSaleValue]
    //     });
    //   });

    /*const orderRecords = */

    (0, _lodash.filter)(data.orderRecords, {
      skuId: skuId
    }).map(function (record) {
      var amount = (0, _commons.getTotalRecordSkuNormalizedAmount)(record, sku);
      totalOrderAmount += amount;
      totalOrderValue += amount * record.netPurchasePrice;
      return _objectSpread(_objectSpread({}, record), {}, {
        _netPurchasePrice: amount * record.netPurchasePrice,
        _amount: amount
      });
    });
    /*const saleRecords = */

    (0, _lodash.filter)(data.saleRecords, {
      skuId: skuId
    }).map(function (record) {
      var amount = (0, _commons.getTotalRecordSkuNormalizedAmount)(record, sku);
      totalSaleAmount += amount;
      totalSaleValue += amount * record.netSalePrice;
      return _objectSpread(_objectSpread({}, record), {}, {
        _netSalePrice: amount * record.netSalePrice,
        _amount: amount
      });
    });
    records.forEach(function (record) {
      var amount = (0, _commons.getTotalRecordSkuNormalizedAmount)(record, sku);
      totalStockcheck0Amount += amount;
      totalStockcheck0PurchaseValue += amount * record[netGroupPurchaseValue];
      totalStockcheck0SaleValue += amount * record[netGroupSaleValue];
    });
    var totalAmount = (0, _commons.getTotalRecordsSkuNormalizedAmount)(records, sku);
    return {
      id: skuId,
      image: image,
      name: name,
      size: (0, _commons.getSkuSizeMeasurement)(sku).localeDisplay({
        precision: 2
      }),
      totalAmount: totalAmount.toFixed(2),
      details: (0, _commons.getSkuDetails)(sku, "domain"),
      lastStockcheck: (_lastStockcheck = {}, _defineProperty(_lastStockcheck, netGroupPurchaseValue, stockcheck1Records[0] ? stockcheck1Records[0][netGroupPurchaseValue] : null), _defineProperty(_lastStockcheck, netGroupSaleValue, stockcheck1Records[0] ? stockcheck1Records[0][netGroupSaleValue] : null), _lastStockcheck),
      meanRecords: {
        netPurchasePrice: totalOrderValue / totalOrderAmount,
        netSalePrice: totalSaleValue / totalSaleAmount
      },
      value: (_value = {}, _defineProperty(_value, netGroupPurchaseValue, totalStockcheck0PurchaseValue / totalStockcheck0Amount), _defineProperty(_value, netGroupSaleValue, totalStockcheck0SaleValue / totalStockcheck0Amount), _value)
    };
  }), "name");
};

exports.getFinalizationSkus = getFinalizationSkus;

var getFinalizationAction = function getFinalizationAction(recordsetType) {
  if (recordsetType === "lossGroup") return _redux.getLossGroupFinalizationData;
  if (recordsetType === "stockcheck") return _redux.getStockcheckFinalizationData;
};

exports.getFinalizationAction = getFinalizationAction;

var getFinalizationPromise = function getFinalizationPromise(_ref) {
  var id = _ref.id,
      restaurantId = _ref.restaurantId,
      _ref$recordsetType = _ref.recordsetType,
      recordsetType = _ref$recordsetType === void 0 ? "lossGroup" : _ref$recordsetType,
      records = _ref.records,
      dispatch = _ref.dispatch;
  return function () {
    return new Promise(function (resolve, reject) {
      return dispatch(getFinalizationAction(recordsetType)({
        restaurantId: restaurantId,
        id: id
      })).then(function (data) {
        return resolve(_objectSpread(_objectSpread({}, data), {}, {
          skus: getFinalizationSkus(recordsetType, records, data),
          netGroupPurchaseValue: getNetGroupPurchaseValue(recordsetType),
          netGroupSaleValue: getNetGroupSaleValue(recordsetType)
        }));
      })["catch"](reject);
    });
  };
};

exports.getFinalizationPromise = getFinalizationPromise;