/* eslint-disable complexity */
import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { find, reject } from 'lodash';
import { Col, Row } from 'reactstrap';
import { SkuStocktakeModeEnum, UnitTypeEnum, UnitEnum, SkuTypeEnum, getSkuPortionUnitTypes, getSkuIngredientSkuPortionPrice, getSkuDetails } from '@stktk/commons';
import { isAllowed, ValidationPlatformEnum } from '@stktk/validation';
import { __, __skuStocktakeMode } from '@stktk/locales';
import { updateSkuDetails, createSkuDetails } from '@stktk/redux';
import { getInitialValues } from '@stktk/logic/utils/form';
import { useSkuDetailsCalculator } from '@stktk/logic/hooks/sku';
import Input from '$ui/form/controls/Input';
import { FormUnitGroup, SkuSearch, FormCalcChart, FormCalcIngredientsTotal } from '$loadable/ui';
import { FormArray, FormGroup, InfoTooltip } from '$ui';
import FormSkuImage from '$ui/form/FormSkuImage';
import { getTooltipValue } from '$utils/i18n';
import { SkuDetailsForm } from '$loadable/forms.jsx';
import { useModal, ModalTypeEnum } from '$hooks/modal';
import FormModal from '$providers/FormModal';

const ingredientTemplate = sku => ({
  //localIngredient: sku,
  ingredientAmount: null,
  ingredientAmountUnitId: UnitEnum.defaultTypeUnit(getSkuPortionUnitTypes(sku)[0]).id,
  ingredientId: sku.id
});

const getSkuIngredientsSku = (sku, type) =>
  type === SkuTypeEnum.COMPOUND && sku ? sku.ingredients.map(({ id, ingredient }) => ({ id, ...ingredient })) : [];

const SkuForm = ({ scheme, item, data: { domainId, restaurantId, skuType, skus, file, setFile }}) => {
  const [stocktakeMode, setStocktakeMode] = useState(item ? item.stocktakeMode : SkuStocktakeModeEnum.getDefault());
  const [ingredients, setIngredients] = useState(getSkuIngredientsSku(item, skuType));
  const [calculations, dispatchCalculations] = useSkuDetailsCalculator();
  const [/*modal*/, setModal] = useModal(ModalTypeEnum.FORM, 'SkuDetailsForm');
  const [skuModal, setSkuModal] = useModal(ModalTypeEnum.FORM, 'SkuForm');
  const { values, resetForm } = useFormikContext();
  const dispatch = useDispatch();

  const isCompound = skuType === SkuTypeEnum.COMPOUND;
  const ColSizeEnum = {
    BASIC: {
      MD: 12,
      LG: 6,
      XL: isCompound ? 4 : 6
    },
    INGREDIENTS: {
      MD: 12,
      LG: 12,
      XL: 4
    }
  };

  const onIngredientAdd = sku => setIngredients([...ingredients, sku]);
  const onIngredientRemove = sku => setIngredients(reject(ingredients, { id: sku.id }));
  const handleStocktakeModeChange = ({ value }) => {
    let newScheme = `sku.${value}.${scheme.split('.')[2]}`;
    setSkuModal({ ...skuModal, scheme: newScheme });
    resetForm({ values: getInitialValues(newScheme, { domainId, skuType }, item) });
    setStocktakeMode(value);
  };

  const handleEditSkuDetails = sku => setModal({
    isOpen: true,
    item: sku,
    scheme: getSkuDetails(sku, "domain")?.id ? 'skuDetails.update' : 'skuDetails.create',
    onSubmit: handleSkuDetailsUpsert
  });

  const handleSkuDetailsUpsert = (data, scheme) => {
    let action = scheme === 'skuDetails.update' ? updateSkuDetails(data) : createSkuDetails(data);
    return dispatch(action).then(details => {
      setIngredients(ingredients.map(ingredient =>
        ingredient.id === details.skuId ? ({ ...ingredient, details: [details] }) : ingredient
      ));
    });
  };

  const displayForm = name =>
    isAllowed(ValidationPlatformEnum.CLIENT, `${scheme}.${name}`);

  return (
    <div className="Add">
      <FormModal
        FormBody={SkuDetailsForm}
        id="SkuDetailsForm"
        title={__('app_sku_details_modal_title')}
        data={{
          domainId,
          restaurantId
        }}
      />
      <Row>
        <Col md={ColSizeEnum.BASIC.MD} lg={ColSizeEnum.BASIC.LG} xl={ColSizeEnum.BASIC.XL}>
          <h4 className="form-header">{__('general_basic_information')}</h4>
          <Row>
            <Col style={{ maxWidth: '155px' }}>
              <FormSkuImage
                name="image"
                sku={item}
                stocktakeMode={stocktakeMode}
                file={file}
                onFileChange={setFile}
              />
            </Col>
            <Col>
              <FormGroup
                disabled={!!item}
                info={getTooltipValue(SkuForm.name, 'select', 'stocktakeMode')}
                label={__('general_stocktake_mode')}
                name="stocktakeMode"
                element="select"
                options={
                  SkuStocktakeModeEnum.toArray().map(stocktakeMode => ({
                    value: stocktakeMode, label: __skuStocktakeMode(stocktakeMode), isDisabled: scheme == 'update'
                  }))
                }
                onChange={handleStocktakeModeChange}
              />
              <FormGroup
                className="Barcode"
                element="barcode"
                type="text"
                label={__('general_barcode')}
                placeholder={__('general_barcode')}
                name="barcode"
                formatName="barcodeFormat"
              />
            </Col>
          </Row>

          <FormGroup
            element="input"
            type="text"
            label={__('general_name')}
            placeholder={__('general_name')}
            name="name"
          />
          <h4 className="form-header">{__('general_properties')}</h4>
          {displayForm('volume') &&
              <FormUnitGroup
                amount={{
                  name: 'volume',
                  type: 'number',
                  step: 0.01,
                  label: __('general_volume'),
                  placeholder: __('general_volume')
                }}
                unit={{
                  name: 'volumeUnitId',
                  label: __('general_volume_unit'),
                  placeholder: __('general_volume_unit'),
                  types: [UnitTypeEnum.VOLUME]
                }}
              />
          }
          {displayForm('packageWeightNet') &&
              <FormUnitGroup
                amount={{
                  type: 'number',
                  name: 'packageWeightNet',
                  step: 0.01,
                  label: __('general_bottle_weight'),
                  placeholder: __('general_bottle_weight')
                }}
                unit={{
                  name: 'packageWeightNetUnitId',
                  label: __('general_weight_unit'),
                  placeholder: __('general_weight_unit'),
                  types: [UnitTypeEnum.WEIGHT]
                }}
              />
          }
          {displayForm('packageWeightGross') &&
              <FormUnitGroup
                amount={{
                  type: 'number',
                  name: 'packageWeightGross',
                  step: 0.01,
                  label: __('general_weight_gross'),
                  placeholder: __('general_weight_gross')
                }}
                unit={{
                  name: 'packageWeightGrossUnitId',
                  label: __('general_weight_unit'),
                  placeholder: __('general_weight_unit'),
                  types: [UnitTypeEnum.WEIGHT]
                }}
              />
          }
          {displayForm('capWeight') &&
              <FormUnitGroup
                amount={{
                  type: 'number',
                  name: 'capWeight',
                  step: 0.01,
                  label: __('general_bottle_cap_weight'),
                  placeholder: (__('general_bottle_cap_weight') + ' ' + __('form_note_optional'))
                }}
                unit={{
                  name: 'capWeightUnitId',
                  label: __('general_weight_unit'),
                  placeholder: __('general_weight_unit'),
                  types: [UnitTypeEnum.WEIGHT]
                }}
              />
          }
          {displayForm('density') &&
              <FormGroup
                element="input"
                type="number"
                step={1}
                name="density"
                info={__('general_density_tooltip')}
                label={`${__('general_density')}`}
                placeholder={`${__('general_density')} ${__('form_note_optional')}`}
              />
          }
          {displayForm('percentage') &&
              <FormGroup
                element="input"
                type="number"
                step={0.01}
                name="percentage"
                label={__('general_percentage')}
                placeholder={(__('general_percentage') + ' ' + __('form_note_optional'))}
              />
          }
        </Col>
        <Col md={ColSizeEnum.BASIC.MD} lg={ColSizeEnum.BASIC.LG} xl={ColSizeEnum.BASIC.XL}>
          <SkuDetailsForm
            isCompound={isCompound}
            isDetails={false}
            skuCalculations={calculations}
            skuDispatchCalculations={dispatchCalculations}
            item={values}
          />
        </Col>
        {isCompound && (
          <Col md={ColSizeEnum.INGREDIENTS.MD} lg={ColSizeEnum.INGREDIENTS.LG} xl={ColSizeEnum.INGREDIENTS.XL}>
            <h4 className="form-header">
              {__('general_ingredients')}
              <InfoTooltip info={getTooltipValue(SkuForm.name, 'header', 'ingredients')}/>
            </h4>
            <FormArray
              name="ingredients"
              pushHandler={({ push }) =>
                <Fragment>
                  {ingredients.length > 0 &&
                      <FormCalcIngredientsTotal
                        ingredients={ingredients}
                        skus={skus}
                        value={calculations.ingredientsTotal}
                        dispatchCalculations={dispatchCalculations}
                      />
                  }
                  <SkuSearch
                    inline="absolute"
                    hideAdd
                    hideQuickList
                    label={__('ingredient_input_header')}
                    ingredients={[...ingredients, item]}
                    types={[SkuTypeEnum.SIMPLE, SkuTypeEnum.COMPOUND]}
                    onSelect={sku => {
                      onIngredientAdd(sku);
                      push(ingredientTemplate(sku));
                    }}
                  />
                </Fragment>
              }
              componentRenderer={(i, value, removeSelf) => {
                const sku = find(ingredients, { id: value.ingredientId });
                if (!sku) { //FIXME: components tries to rerender a removed item, doesn't find it in ingredients and throws an error
                  console.warn(`Error rendering ingredientId = ${value.ingredientId}. Current ingredients:`, ingredients);
                  return null;
                }
                return (
                  <div key={`ingredient-form-${i}`}>
                    <FormUnitGroup
                      prefixGroups={[
                        {
                          label: i == 0 ? __('general_name') : '',
                          Element: <div>{sku.name}</div>
                        }
                      ]}
                      amount={{
                        name: i >= 0 ? `ingredients[${i}]ingredientAmount` : 'ingredientAmount',
                        label: i == 0 ? __('general_amount') : '',
                        step: 0.01,
                        placeholder: __('general_amount')
                      }}
                      unit={{
                        name: i >= 0 ? `ingredients[${i}]ingredientAmountUnitId` : 'ingredientAmountUnitId',
                        label: i == 0 ? __('general_unit') : '',
                        placeholder: __('general_unit'),
                        types: getSkuPortionUnitTypes(sku)
                      }}
                      suffixGroups={[
                        {
                          label: i == 0 ? __('general_net_portion_purchase_price') : '',
                          Element: (
                            <div
                              id={`ingredients-form-suffix-netPurchasePrice-${i}`}
                              style={{ position: 'relative' }}
                            >
                              <Input
                                disabled
                                style={{ cursor: 'pointer' }}
                                value={getSkuIngredientSkuPortionPrice(value, sku, 'netPurchasePrice', skus).toFixed(2) || '-'}
                              />
                              <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 8, cursor: 'pointer' }} onClick={() => handleEditSkuDetails(sku)} />
                            </div>
                          )
                        }
                      ]}
                      remove={{
                        removeItem: () => {
                          removeSelf();
                          onIngredientRemove(sku);
                        },
                        label: i == 0 ? '.' : ''
                      }}
                    />
                  </div>
                );
              }}
            />
            {ingredients.length > 0 &&
                <Fragment>
                  <h4 className="form-header">
                    {__('general_pareto_chart')}
                    <InfoTooltip info={getTooltipValue(SkuForm.name, 'header', 'paretoChart')}/>
                  </h4>
                  <FormCalcChart skus={skus} ingredients={ingredients} />
                </Fragment>
            }
          </Col>
        )}
      </Row>
    </div>
  );
};

export default SkuForm;