import React, { useCallback } from 'react';
import 'react-virtualized/styles.css';
import { List, AutoSizer } from 'react-virtualized';
import classNames from 'classnames';
import { __ } from '@stktk/locales';

function ListVirtualized({ itemCount, itemSize, noItemsText, ItemRenderer, defaultHeight = 800, disableWidth = false }) {
  const noRowsRenderer = useCallback(
    () => (
      <div>
        {noItemsText || __('app_table_empty')}
      </div>
    ),
    [noItemsText]
  );
  return (
    <div className={classNames('List', { 'List--empty': itemCount === 0 })} style={{ flex: '1 1 auto' }}>
      <AutoSizer
        defaultHeight={defaultHeight}
        disableWidth={disableWidth}
      >
        {({ height, width }) => (
          <List
            width={width || defaultHeight}
            height={height}
            rowCount={itemCount}
            rowHeight={itemSize}
            rowRenderer={ItemRenderer}
            noRowsRenderer={noRowsRenderer}
          />
        )}
      </AutoSizer>
    </div>
  );
}

export default ListVirtualized;