import React from 'react';
import classNames from 'classnames';
import { Icon } from '$ui';
import { useItemActions } from '$hooks/item';

export default function ListItemActions({
  id,
  item,
  itemActions,
  editFormModalId,
  size,
  className,
  ...props
}) {
  const actions = useItemActions(itemActions.filter(({ hide = [] }) => !hide.includes('list')), item, editFormModalId);
  return (
    <div className={classNames('ListItemActions', className)} {...props}>
      {actions.map(({ name, icon, label, color, disabled, onClick }) => (
        <Icon
          key={`item_action_${name}_${id}`}
          size={size}
          data-testid={`itemAction-${name}`}
          className="ListItemActions-icon"
          id={`item_action_${name}_${id}`}
          tooltip={label}
          icon={icon}
          variant={color}
          disabled={disabled}
          onClick={onClick}
        />
      ))}
    </div>
  );
}