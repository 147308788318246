import React, { Component } from "react";
import { Badge } from "reactstrap";
import { getSkuDetails, getSkuSizeMeasurement } from "@stktk/commons";
import { __ } from "@stktk/locales";
import SkuImage from "$ui/SkuImage.jsx";
import { CategoryBadge } from "$ui";

export default class AddRecordSku extends Component {
  render() {
    let { sku } = this.props;
    return (
      <div
        className="AddSearch-item d-flex justify-content-start align-items-center"
        style={{ display: "flex" }}
      >
        <div
          className="AddSearch-item-img"
          style={{
            display: "flex",
            padding: "5px",
            width: "10%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SkuImage sku={sku} />
        </div>

        <div
          className="AddSearch-item-description"
          style={{ flexDirection: "row" }}
        >
          <div
            className="AddSearch-item-description-name"
            style={{ display: "flex" }}
          >
            <div
              className="AddSearch-item-name col-md-11"
              style={{ display: "contents", marginBottom: 20 }}
            >
              <h2 style={{ paddingLeft: 20 }}>{sku.name ? sku.name : ""}</h2>
            </div>
          </div>
          <div style={{ display: "inline-flex " }}>
            <div className="AddSearch-item-description-volume ml-auto">
              {
                <Badge
                  pill
                  style={{
                    display: "inline-block",
                    marginRight: 20,
                    marginLeft: 17,
                    fontSize: "15px",
                    padding: "5px",
                  }}
                  color="dark"
                >
                  {getSkuSizeMeasurement(sku).localeDisplay({ precision: 2 })}
                </Badge>
              }
            </div>
            <div
              className="AddSearch-item-description-official"
              style={{ display: "flex" }}
            >
              {!sku.official ? (
                <Badge
                  pill
                  style={{
                    display: "inline-block",
                    marginRight: 20,
                    fontSize: "15px",
                    padding: "5px",
                  }}
                  color="warning"
                >
                  {__("icon_sku_unofficial")}
                </Badge>
              ) : (
                <Badge
                  pill
                  style={{
                    display: "inline-block",
                    marginRight: 20,
                    fontSize: "15px",
                    padding: "5px",
                  }}
                  color="success"
                >
                  {__("icon_sku_official")}
                </Badge>
              )}
            </div>
            <div className="AddSearch-item-description-category">
              <CategoryBadge
                category={getSkuDetails(sku, "domain").category}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
