"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.storageTransferGroup = exports["default"] = void 0;

var _validatorConstants = require("../validator/validatorConstants.js");

var _commons = require("@stktk/commons");

var storageTransferGroup = {
  find: {
    id: _validatorConstants.INTEGER.optional([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  findDomain: {
    domainId: _validatorConstants.INTEGER.required([1])
  },
  create: {
    comment: _validatorConstants.STRING.required([1, 140]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    rangeFromDate: _validatorConstants.DATE.required([1]),
    rangeToDate: _validatorConstants.DATE.required([1]),
    status: _validatorConstants.ENUM.INTEGER.required([_commons.StorageTransferGroupStatusEnum.DEFAULT])
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    comment: _validatorConstants.STRING.optional([1, 140]),
    status: _validatorConstants.ENUM.INTEGER.optional(_commons.StorageTransferGroupStatusEnum.toArray())
  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  }
};
exports.storageTransferGroup = storageTransferGroup;
var _default = storageTransferGroup;
exports["default"] = _default;