import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import classNames from 'classnames';
import Icon from '$ui/Icon';

export default function Button({
  id,
  type = 'button',
  variant = 'primary',
  size = 'md',
  className,
  wrapperClassName,
  icon,
  iconOnly = false,
  text,
  children,
  isLoading,
  disabled,
  tooltip,
  tooltipPlacement = 'top',
  ...props
}) {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!isTooltipOpen);

  const combinedClassName = classNames('Button', 'btn', `btn-${variant}`, { [`btn-${size}`]: !!size, 'Button--iconOnly': iconOnly }, className);

  const ButtonBody = () => (
    <Fragment>
      {(isLoading || icon) && (
        <span className="Button-icon">
          {isLoading ? (
            <Icon type="light" icon="sync-alt" animation="spin" />
          ) : (icon &&
            <Icon type="light" icon={icon} />
          )}
        </span>
      )}
      <span>{text || children}</span>
    </Fragment>
  );

  if (type == 'link' || type == 'Link') return (
    <Link className={combinedClassName} {...props} disabled={disabled | isLoading}>
      <ButtonBody />
    </Link>
  );

  if ((type == 'alink' || type == 'a') && !disabled) return (
    <a className={combinedClassName} {...props} disabled={disabled | isLoading}>
      <ButtonBody />
    </a>
  );

  return (
    <div id={id} className={wrapperClassName}>
      <button type={type} className={combinedClassName} {...props} disabled={disabled | isLoading}>
        <ButtonBody />
      </button>
      {tooltip &&
        <Tooltip isOpen={isTooltipOpen} toggle={toggleTooltip} placement={tooltipPlacement} target={id}>
          {tooltip}
        </Tooltip>
      }
    </div>
  );
}