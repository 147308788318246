"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _recordsetCommonSchemes = require("./_recordsetCommonSchemes.js");

var _validatorConstants = require("../validator/validatorConstants.js");

var _commons = require("@stktk/commons");

var _default = {
  find: Object.assign({}, _recordsetCommonSchemes.recordsetCommonFindScheme),
  create: Object.assign({}, _recordsetCommonSchemes.recordsetCommonCreateScheme, {
    virtualGenerationRule: _validatorConstants.ENUM.STRING.required([_commons.SaleGenerationRuleEnum.EMPTY]),
    status: _validatorConstants.ENUM.INTEGER.required(_commons.SaleStatusEnum.toArray()),
    rangeFromDate: _validatorConstants.DATE.required(),
    rangeToDate: _validatorConstants.DATE.required(),
    barId: _validatorConstants.INTEGER.required([1])
  }),
  update: Object.assign({}, _recordsetCommonSchemes.recordsetCommonUpdateScheme, {
    rangeFromDate: _validatorConstants.DATE.optional(),
    rangeToDate: _validatorConstants.DATE.optional()
  }),
  destroy: Object.assign({}, _recordsetCommonSchemes.recordsetCommonDestroyScheme)
};
exports["default"] = _default;