import React from 'react';
import { __ } from '@stktk/locales';
import Button from '$ui/Button';
import { useLoading } from '$hooks/ui';

export default function FormButtonSubmit({ formId, text = __('button_submit'), variant = 'success', icon = 'save', ...props }) {
  const [isLoading] = useLoading(formId);

  return (
    <Button
      data-testid="FormButtonSubmit"
      data-modalid={formId}
      icon={icon}
      type="submit"
      isLoading={isLoading}
      text={text} variant={variant}
      {...props}
    />
  );
}