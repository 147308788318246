"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPlatformReducers = void 0;
Object.defineProperty(exports, "reducersCommon", {
  enumerable: true,
  get: function get() {
    return _reducersCommon["default"];
  }
});
Object.defineProperty(exports, "reducersMobile", {
  enumerable: true,
  get: function get() {
    return _reducersMobile["default"];
  }
});
Object.defineProperty(exports, "reducersMobileNavigation", {
  enumerable: true,
  get: function get() {
    return _reducersMobileNavigation["default"];
  }
});
Object.defineProperty(exports, "reducersWeb", {
  enumerable: true,
  get: function get() {
    return _reducersWeb["default"];
  }
});

var _reducersCommon = _interopRequireDefault(require("./reducers-common"));

var _reducersWeb = _interopRequireDefault(require("./reducers-web"));

var _reducersMobile = _interopRequireDefault(require("./reducers-mobile"));

var _reducersMobileNavigation = _interopRequireDefault(require("./reducers-mobile-navigation"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var getPlatformReducers = function getPlatformReducers(platform) {
  switch (platform) {
    case 'web':
      return Object.assign({}, _reducersCommon["default"], _reducersWeb["default"]);

    case 'mobile':
      return Object.assign({}, _reducersCommon["default"], _reducersMobile["default"], _reducersMobileNavigation["default"]);

    default:
      return Object.assign({}, _reducersCommon["default"]);
  }
};

exports.getPlatformReducers = getPlatformReducers;