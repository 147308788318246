import React from 'react';
import { Alert as BSAlert } from 'reactstrap';
import Icon from '$ui/Icon';

const Alert = ({ isMultiline, text, icon, children, ...props }) => (
  <BSAlert {...props}>
    <div className="d-flex flex-row flex-grow-1 align-items-center">
      {icon && (
        <div className="d-inline-block float-left mr-4">
          <Icon icon={icon} size="2x" />
        </div>
      )}
      {text ? (
        <div style={isMultiline ? {} : { whiteSpace: 'pre' }}>
          {text}
        </div>
      ) : children}
    </div>
  </BSAlert>
);

export default Alert;