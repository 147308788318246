"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFilter = exports.FilterTypeEnum = void 0;

var _commons = require("@stktk/commons");

var FilterTypeEnum = new _commons.Constant({
  EMPTY: new _commons.Constant({
    IS_EMPTY: 'isEmpty',
    IS_NOT_EMPTY: 'isNotEmpty'
  }),
  TEXT: new _commons.Constant({
    DOES_CONTAIN: 'textContain',
    DOES_NOT_CONTAIN: 'textNotContain',
    STARTS_WITH: 'textStartWith',
    ENDS_WITH: 'textEndWith',
    IS_EXACTLY: 'textExact'
  }),
  NUMBER: new _commons.Constant({
    IS_GREATER: 'numberGt',
    IS_GREATER_EQUAL: 'numberGte',
    IS_LESSER: 'numberLt',
    IS_LESSER_EQUAL: 'numberLte',
    IS_EQUAL: 'numberEq',
    IS_NOT_EQUAL: 'numberNotEq'
  })
});
exports.FilterTypeEnum = FilterTypeEnum;

var getFilter = function getFilter(_ref) {
  var itemValueExtractor = _ref.itemValueExtractor,
      items = _ref.items,
      filterType = _ref.filterType,
      filterValue = _ref.filterValue;
  return items.filter(function (item) {
    var value = itemValueExtractor(item);

    switch (filterType) {
      case FilterTypeEnum.EMPTY.IS_EMPTY:
        return !value && value !== 0;

      case FilterTypeEnum.EMPTY.IS_NOT_EMPTY:
        return value || value === 0;

      case FilterTypeEnum.TEXT.DOES_CONTAIN:
        return value && value.toString().toLowerCase().includes(filterValue.toLowerCase());

      case FilterTypeEnum.TEXT.DOES_NOT_CONTAIN:
        return !value || !value.toString().toLowerCase().includes(filterValue.toLowerCase());

      case FilterTypeEnum.TEXT.STARTS_WITH:
        return value && value.toString().toLowerCase().startsWith(filterValue.toLowerCase());

      case FilterTypeEnum.TEXT.ENDS_WITH:
        return value && value.toString().toLowerCase().endsWith(filterValue.toLowerCase());

      case FilterTypeEnum.TEXT.IS_EXACTLY:
        return value && value.toString().toLowerCase() == filterValue.toLowerCase();

      case FilterTypeEnum.NUMBER.IS_GREATER:
        return parseFloat(filterValue) < value;

      case FilterTypeEnum.NUMBER.IS_GREATER_EQUAL:
        return parseFloat(filterValue) <= value;

      case FilterTypeEnum.NUMBER.IS_LESSER:
        return parseFloat(filterValue) > value;

      case FilterTypeEnum.NUMBER.IS_LESSER_EQUAL:
        return parseFloat(filterValue) >= value;

      case FilterTypeEnum.NUMBER.IS_EQUAL:
        return parseFloat(filterValue) == value;

      case FilterTypeEnum.NUMBER.IS_NOT_EQUAL:
        return parseFloat(filterValue) != value;

      default:
        return true;
    }
  });
};

exports.getFilter = getFilter;