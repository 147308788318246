import React from 'react';
import ImageLoader from 'react-imageloader';
import { getImagePreloaderUrl } from '@stktk/logic/utils/sku.js';

export default function ListItemImage({ src, fallbackSrc, ...props }) {
  return (
    <div className="ListItemImage">
      <ImageLoader imgProps={props} src={src || fallbackSrc} preloader={() => <img src={getImagePreloaderUrl()} />}>
        <img src={src || fallbackSrc} />
      </ImageLoader>
    </div>
  );
}