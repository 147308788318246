"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _validatorConstants = require("../validator/validatorConstants.js");

var _default = {
  syncTaxIds: {
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    taxIds: _validatorConstants.ARRAY.required(_validatorConstants.STRING.required([10, 10]), [1, 30])
  }
};
exports["default"] = _default;