"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _commons = require("@stktk/commons");

var _validatorConstants = require("../validator/validatorConstants.js");

var NON_OWNER_ROLES = function () {
  var roles = _commons.DomainUserRoleEnum.toArray();

  var ownerIndex = roles.indexOf(_commons.DomainUserRoleEnum.OWNER);
  roles.splice(ownerIndex, 1);
  return roles;
}();

var _default = {
  find: {
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  create: {
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    email: _validatorConstants.EMAIL.required(100),
    position: _validatorConstants.ENUM.STRING.required(NON_OWNER_ROLES),
    firstName: _validatorConstants.STRING.optional(50),
    lastName: _validatorConstants.STRING.optional(75),
    language: _validatorConstants.STRING.optional(20),
    invitorFirstName: _validatorConstants.STRING.optional(50),
    invitorLastName: _validatorConstants.STRING.optional(75),
    invitorRestaurantName: _validatorConstants.STRING.optional(100),
    tags: _validatorConstants.ARRAY.optional(_validatorConstants.INTEGER.required([1])) //FIXME:

  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  reinvite: {
    userId: _validatorConstants.INTEGER.required([1]),
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  update: {
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    userId: _validatorConstants.INTEGER.required([1]),
    tags: _validatorConstants.ARRAY.optional(_validatorConstants.INTEGER.required([1])),
    //FIXME:
    domainRole: _validatorConstants.ENUM.STRING.required(_commons.DomainUserRoleEnum.toArray()) // FIXME: position vs domainRole vs 'OWNER' self-update

  }
};
exports["default"] = _default;