import { useContext, useMemo, useCallback } from 'react';
import { get } from 'lodash';
import { setModal } from '$hooks/uiActions';
import { UiContext } from '$providers/UiProvider';

export const ModalTypeEnum = {
  CONFIRM: 'ConfirmModal',
  RECORD_FORM: 'RecordFormModal',
  DATA: 'DataModal',
  FORM: 'FormModal'
};

export const useModal = (type = ModalTypeEnum.DATA, id = 'GlobalModal') => {
  const [{ modal }, uiDispatch] = useContext(UiContext);
  const currentModal = get(modal, `${type}.${id}`, {});
  const memoizedValue = useMemo(() => currentModal, [JSON.stringify(currentModal)]);
  const memoizedCallback = useCallback((state, idOverwrite, typeOverwrite) => {
    uiDispatch(setModal(typeOverwrite || type, idOverwrite || id, state));
  }, [id, type]);
  return [
    memoizedValue,
    memoizedCallback
  ];
};