import React, { memo, useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Label, FormGroup, InputGroup } from 'reactstrap';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { map, get } from 'lodash';
import { searchSkus, skuListSelector, skuSearchSelector, domainIdSelector, restaurantIdSelector, skuSelectedSelector } from '@stktk/redux';
import { SkuSearchModeEnum, SkuBarcodeFormatEnum } from '@stktk/commons';
import { __ } from '@stktk/locales';
import { compareProps } from '@stktk/logic/utils/memo';
import { useSettings } from '@stktk/logic/hooks/settings';
import Input from '$ui/form/controls/Input';
import { LoadingText } from '$ui';
import { toast } from '$providers/ToastProvider';
import { globalHandleErrorWithContext } from '$utils/error';
import SkuSearchResults from '$ui/search/SkuSearchResults';
import SkuSearchResultsWrapper from '$ui/search/SkuSearchResultsWrapper';
import SkuSearchModeDropdown from '$ui/search/SkuSearchModeDropdown';
import SkuSearchScopeDropdown from '$ui/search/SkuSearchScopeDropdown';

const minCharacters = 3;

const __seachSkuMode = mode => mode === SkuSearchModeEnum.BARCODE ? __('general_barcode') : __('general_name');

function SkuSearch({ id = 'searchInput', wrapperClassName, onSelect, types, label, inline = false, addSkuButtonProps = {}, hideFormModal = false, hideAdd = false, hideQuickList = false, initialSearch, ingredients = [], className, boundary }) {
  const domainId = useSelector(domainIdSelector);
  const restaurantId = useSelector(restaurantIdSelector);
  const skuListItems = useSelector(skuListSelector);
  const skuSearchItems = useSelector(skuSearchSelector);
  const skuSelected = useSelector(skuSelectedSelector);
  const { allowedSearchScopes } = useSettings();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [mode, setMode] = useState(SkuSearchModeEnum.NAME);
  const [query, setQuery] = useState('');
  const [scopes, setScopes] = useState(allowedSearchScopes);
  const [searchRef, setSearchRef] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (query.length >= minCharacters && timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    } else if (!timeoutId && query && query.length < minCharacters)
      setTimeoutId(
        setTimeout(() => {
          toast(__('alert_minimum_query_info'), __('alert_minimum_query_info_desc', { minCharacters }), 'info');
          setTimeout(() => {
            clearTimeout(timeoutId);
            setTimeoutId(null);
          }, 8000);
        }, 1000)
      );
    return () => {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    };
  }, [query]);

  const search = value => {
    let context = { query: __seachSkuMode(mode) };
    let searchOptions = {
      domainId,
      mode,
      query: value || query,
      scopes,
      types
    };
    if (mode === SkuSearchModeEnum.BARCODE)
      searchOptions.barcodeFormat = SkuBarcodeFormatEnum.guessBarcodeFormat(value); // Ignored for now
    dispatch(searchSkus(searchOptions)).catch(globalHandleErrorWithContext(context));
  };

  const handleScopeChange = scopes => {
    setScopes(scopes);
    if (query.length >= 3)
      search();
  };

  const handleModeChange = mode => {
    setMode(mode);
    if (query.length >= 3)
      search();
  };

  const handleChange = e => {
    e.preventDefault();
    let { value } = e.target;
    setQuery(value);
    if (value.length >= 3)
      search(value);
  };

  const handleOutsideClick = () => setDropdownOpen(false);

  const handleFocus = () => {
    if (initialSearch && !query.length)
      search(initialSearch);
    if (inline)
      setDropdownOpen(true);
  };

  const handleItemClick = useCallback(sku => {
    setQuery('');
    if (inline)
      setDropdownOpen(false);
    if (onSelect)
      onSelect(sku);
  }, [onSelect, inline]);

  const items = useMemo(() => {
    const items = query.length < 3 && !hideQuickList ? skuListItems : skuSearchItems;
    if (ingredients.length)
      return items.filter(sku => {
        let ingredientIds = [get(skuSelected, 'id', null), ...map(ingredients, 'id')];
        return !ingredientIds.includes(sku.id);
      });
    return items;
  }, [skuListItems, skuSearchItems, skuSelected, ingredients, query, hideQuickList]);

  return (
    <div className={classNames('SkuSearch', { 'SkuSearch--full': !inline }, wrapperClassName)}>
      <OutsideClickHandler display='flex' disabled={!inline} onOutsideClick={handleOutsideClick}>
        <div ref={setSearchRef} className="form-group">
          <Label for={id} className={classNames({ 'text-center fs-2': !inline })}>{label || __('app_additem_search_by')}</Label>
          <InputGroup>
            <Input
              id={id}
              value={query}
              autoComplete="off"
              className={className}
              name="query"
              type="text"
              onChange={handleChange}
              onFocus={handleFocus}
            />
            <SkuSearchModeDropdown mode={mode} onModeChange={handleModeChange} />
            <SkuSearchScopeDropdown scopes={scopes} allowedSearchScopes={allowedSearchScopes} onScopeChange={handleScopeChange} />
          </InputGroup>
        </div>
        <SkuSearchResultsWrapper
          inline={inline} hideAdd={hideAdd} isOpen={isDropdownOpen} items={items}
          searchRef={searchRef} boundary={boundary}
        >
          <SkuSearchResults
            addSkuButtonProps={addSkuButtonProps}
            hideFormModal={hideFormModal}
            hideAdd={hideAdd}
            skus={items}
            query={query}
            domainId={domainId}
            restaurantId={restaurantId}
            type={mode}
            types={types}
            inline={inline}
            onItemClick={handleItemClick}
          />
        </SkuSearchResultsWrapper>
      </OutsideClickHandler>
    </div>
  );
}

export default memo(SkuSearch, compareProps([{ name: 'types', compare: 'deepReact' }, { name: 'id', compare: 'deepReact' }, { name: 'ingredients', compare: 'deepReact' }]));