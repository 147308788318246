import React, { useState, useEffect } from 'react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';

const calculateHeight = (items, hideAdd) => (items.length > 3 ? 51 * 4 : 51 * (items.length || 1)) + 1 + (hideAdd ? 0 : 36);

export default function SkuSearchResultsWrapper({ inline, items, isOpen, children, searchRef, hideAdd, boundary = 'body' }) {
  // const [width, setWidth] = useState(0);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(searchRef, popperElement, {
    placement: 'bottom-end',
    strategy: inline === 'absolute' ? 'absolute' : 'fixed',
    modifiers: [{
      name: 'offset',
      options: {
        offset: [0, 10]
      }
    },
    {
      name: 'preventOverflow',
      options: {
        padding: 10,
        boundary
      }
    },
    {
      name: 'flip',
      options: {
        padding: 10,
        boundary
      }
    }]
  });

  return inline ? (
    <div
      ref={setPopperElement}
      className={classNames('shadow', 'popover-popper', { 'popover-popper--hidden': !isOpen })}
      style={{ ...styles.popper, height: calculateHeight(items, hideAdd), width: 800 }}
      {...attributes.popper}
    >
      {children}
    </div>
  ) : (
    <div className="d-flex flex-column flex-grow-1 mt-3">
      {children}
    </div>
  );
}