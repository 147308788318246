"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _validatorConstants = require("../validator/validatorConstants.js");

var _schemeConstants = require("./schemeConstants.js");

var _default = {
  find: {
    integrationInstanceId: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  create: {
    integrationInstanceId: _validatorConstants.INTEGER.required([1]),
    integrationInstanceContextId: _validatorConstants.INTEGER.optional([1]),
    skuId: _validatorConstants.INTEGER.optional([1]),
    properties: _validatorConstants.OBJECT.INPART.required({
      name: _validatorConstants.STRING.required([1, 255]),
      amount: (0, _validatorConstants.WHEN)('exclude', {
        //FIXME: both same type and required
        is: true,
        then: _validatorConstants.NULL.optional(),
        //FIXME: null required
        otherwise: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION])
      }),
      amountUnitId: (0, _validatorConstants.WHEN)('exclude', {
        //FIXME: both same type and required
        is: true,
        then: _validatorConstants.NULL.optional(),
        //FIXME: null required
        otherwise: _validatorConstants.INTEGER.required([1])
      }),
      exclude: _validatorConstants.BOOLEAN.required()
    }),
    //FIXME:
    key: _validatorConstants.STRING.required([1, 255]),
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    localSku: _validatorConstants.OBJECT.INPART.optional({
      skuId: _validatorConstants.INTEGER.optional([1])
    })
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    integrationInstanceId: _validatorConstants.INTEGER.required([1]),
    integrationInstanceContextId: _validatorConstants.INTEGER.optional([1]),
    skuId: _validatorConstants.INTEGER.optional([1]),
    properties: _validatorConstants.OBJECT.INPART.required({
      name: _validatorConstants.STRING.required([1]),
      amount: (0, _validatorConstants.WHEN)('exclude', {
        //FIXME: both same type and required
        is: true,
        then: _validatorConstants.NULL.optional(),
        //FIXME: null required
        otherwise: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION])
      }),
      amountUnitId: (0, _validatorConstants.WHEN)('exclude', {
        //FIXME: both same type and required
        is: true,
        then: _validatorConstants.NULL.optional(),
        //FIXME: null required
        otherwise: _validatorConstants.INTEGER.required([1])
      }),
      exclude: _validatorConstants.BOOLEAN.required()
    }),
    //FIXME:
    key: _validatorConstants.STRING.required([1, 255]),
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    localSku: _validatorConstants.OBJECT.INPART.optional({
      skuId: _validatorConstants.INTEGER.optional([1])
    })
  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1]),
    integrationInstanceId: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  }
};
exports["default"] = _default;