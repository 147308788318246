import 'react-contexify/dist/ReactContexify.css';
import React from 'react';
import { Menu } from 'react-contexify';
import TableContextMenuItem from '$ui/table/TableContextMenuItem';
import { __ } from '@stktk/locales';
import { useItemActions } from '$hooks/item';

export default function TableContextMenu({ id, row, rowActions, rowGroupActions, editFormModalId }) {
  const rowData = row.isGrouped ? row.leafRows.map(row => row.original) : row.original;
  const typeActions = row.isGrouped ? rowGroupActions : rowActions;
  const actions = useItemActions(typeActions.filter(({ hide = [] }) => !hide.includes('contextMenu')), rowData, editFormModalId);

  return (
    <Menu className="border border-light" id={id}>
      {row.canExpand && (
        <TableContextMenuItem
          icon={'caret-down'}
          label={__('table_toggle_row_expand')}
          {...row.getToggleRowExpandedProps()}
        />
      )}
      {actions.map(({ name, icon, label, color, disabled, onClick }) => (
        <TableContextMenuItem
          key={`menu-action-${name}-${id}`}
          data-testid={`itemAction-${name}`}
          icon={icon}
          label={label}
          color={color}
          disabled={disabled}
          onClick={onClick}
        />
      ))}
      {!row.canExpand && !actions.length && (
        <TableContextMenuItem
          label={__('app_table_no_actions')}
          disabled
        />
      )}
    </Menu>
  );
}