import React from 'react';
import Input from '$ui/form/controls/Input';

export default React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className="d-flex h-100 w-100 justify-content-center align-items-center">
        <Input ref={resolvedRef} isRaw type="checkbox" {...rest} />
      </div>
    );
  }
);