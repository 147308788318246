"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getScheme = getScheme;
exports.getValidationScheme = getValidationScheme;
exports.removeLocal = removeLocal;

var _lodash = require("lodash");

var _validatorConstants = require("./validator/validatorConstants.js");

var _index = require("./validation/index.js");

//FIXME: doesn't remove nested locals
function removeLocal(scheme) {
  return (0, _lodash.omitBy)(scheme, function (value, key) {
    return key.startsWith('local');
  });
}

function getScheme(schemes, platform, schemeName) {
  var scheme = (0, _lodash.get)(schemes, schemeName, null);
  if (platform === _index.ValidationPlatformEnum.SERVER && scheme) return removeLocal(scheme);else return scheme;
} // eslint-disable-next-line complexity


function getValidationScheme(schemes, platform, schemeName) {
  var scheme = getScheme(schemes, platform, schemeName);
  if (!scheme) return null; // FIXME: wtf is this shit. beter way to local

  if (!scheme.validateSync && platform == _index.ValidationPlatformEnum.REDUX && (0, _lodash.endsWith)(schemeName, 'create')) scheme.localId = _validatorConstants.NUMBER.optional([1]);
  return scheme.validateSync ? scheme : _validatorConstants.OBJECT.schema(scheme);
}