import React from 'react';
import classNames from 'classnames';
import { __ } from '@stktk/locales';

export const LoadingText = ({ text, className, ...props }) => (
  <span className={classNames('LoadingText', className)} {...props}>
    <span className="LoadingText-text">{text || __('app_select_loading_data')}</span>
    <span className="LoadingText-dot">.</span>
    <span className="LoadingText-dot">.</span>
    <span className="LoadingText-dot">.</span>
  </span>
);

export default React.memo(LoadingText);