import React from "react";
import { __, __bcgMatrixClass } from "@stktk/locales";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts";
import { ScatterChart } from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  TooltipComponent,
  TitleComponent,
  MarkLineComponent,
  ToolboxComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { BcgMatrixClass, getSkuDetails } from "@stktk/commons";

echarts.use([
  ScatterChart,
  GridComponent,
  LegendComponent,
  TooltipComponent,
  TitleComponent,
  MarkLineComponent,
  ToolboxComponent,
  CanvasRenderer,
]);

const X_AVG = "xAvg";
const Y_AVG = "yAvg";

const itemStyle = {
  normal: {
    opacity: 0.8,
    shadowBlur: 10,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: "rgba(0, 0, 0, 0.5)",
  },
};

const BcgMatrixScatterChart = ({
  salesMixHelper,
  title = __("chart_bcg_matrix_title"),
  subtitle = null,
}) => {
  const entries = salesMixHelper.getEntries();
  const series = {
    [BcgMatrixClass.DOG]: [],
    [BcgMatrixClass.QUESTION_MARK]: [],
    [BcgMatrixClass.CASH_COW]: [],
    [BcgMatrixClass.STAR]: [],
  };
  entries.forEach((entry) => {
    let {
      sku: { id: skuId },
    } = entry;
    series[salesMixHelper.getProductsBcgMatrixClass({ skuId })].push([
      100 * entry.avgProductCost,
      100 * entry.salesMixPct,
      entry,
    ]);
  });
  const markLine = {
    // INFO: workaround, markLine is tied to series, not X-/Y-axis
    lineStyle: {
      type: "solid",
      color: "#000",
    },
    data: [
      { name: Y_AVG, yAxis: 100 * salesMixHelper.getTotals().salesMixPct.avg },
      {
        name: X_AVG,
        xAxis: 100 * salesMixHelper.getTotals().avgProductCost.avg,
      },
    ],
  };
  return (
    <div>
      <ReactEChartsCore
        echarts={echarts}
        option={{
          color: ["#16ba70", "#4400ff", "#fec42c", "#dd4444"],
          legend: {
            top: 10,
            data: [
              __bcgMatrixClass(BcgMatrixClass.STAR),
              __bcgMatrixClass(BcgMatrixClass.CASH_COW),
              __bcgMatrixClass(BcgMatrixClass.QUESTION_MARK),
              __bcgMatrixClass(BcgMatrixClass.DOG),
            ],
          },
          title: {
            text: title,
            subtext: subtitle,
          },
          grid: {
            left: "3%",
            right: "7%",
            bottom: "7%",
            containLabel: true,
          },
          toolbox: {
            feature: {
              dataZoom: {},
              brush: {
                type: ["rect", "polygon", "clear"],
              },
            },
          },
          tooltip: {
            // trigger: 'axis',
            showDelay: 0,
            formatter: function (params) {
              if (params.value.length > 1) {
                const [, , entry] = params.data;
                let {
                  sku: { id: skuId },
                } = entry;
                let { amount, totalSaleValue } = salesMixHelper.getEntry({
                  skuId,
                });
                const r = salesMixHelper.getRecipeHelper();
                const totalProfit = r.isRecipe({ skuId })
                  ? totalSaleValue - amount * r.getPurchaseValue({ skuId })
                  : totalSaleValue -
                    amount *
                      getSkuDetails(entry.sku, "domain").netPurchasePrice;
                return (
                  `${entry.sku.name} (id: ${entry.sku.id})<br/>` +
                  __("chart_bcg_matrix_product_cost", {
                    value: parseFloat(params.value[0].toFixed(4)),
                  }) +
                  "</br>" +
                  __("chart_bcg_matrix_average_sales_mix", {
                    value: parseFloat(params.value[1].toFixed(4)),
                  }) +
                  "</br>" +
                  __("chart_bcg_matrix_total_sales_amount", {
                    value: (amount || 0).toFixed(2),
                  }) +
                  "</br>" +
                  __("chart_bcg_matrix_total_profit", {
                    value: (totalProfit || 0).toFixed(2),
                  })
                );
              } else {
                // markLine
                const { name, value } = params.data;
                if (name === X_AVG) {
                  return __("chart_bcg_matrix_x_average", { value }) + "</br>"; // NOTE: without <br> doesn't render
                } else if (name === Y_AVG) {
                  return __("chart_bcg_matrix_y_average", { value }) + "</br>";
                }
              }
            },
            axisPointer: {
              show: true,
              type: "cross",
              lineStyle: {
                type: "dashed",
                width: 1,
              },
            },
          },
          xAxis: {
            type: "value",
            scale: true,
            axisLabel: {
              formatter: "{value}",
            },
            splitLine: {
              show: false,
            },
            name: __("chart_bcg_matrix_x_name"),
            nameLocation: "middle",
            nameGap: 25,
          },
          yAxis: {
            type: "value",
            scale: true,
            axisLabel: {
              formatter: "{value}",
            },
            splitLine: {
              show: false,
            },
            name: __("chart_bcg_matrix_y_name"),
            nameLocation: "middle",
            nameGap: 50,
          },

          series: [
            {
              type: "scatter",
              markLine,
              itemStyle,
              data: series[BcgMatrixClass.STAR],
              name: __bcgMatrixClass(BcgMatrixClass.STAR),
            },
            {
              type: "scatter",
              markLine,
              itemStyle,
              data: series[BcgMatrixClass.CASH_COW],
              name: __bcgMatrixClass(BcgMatrixClass.CASH_COW),
            },
            {
              type: "scatter",
              markLine,
              itemStyle,
              data: series[BcgMatrixClass.QUESTION_MARK],
              name: __bcgMatrixClass(BcgMatrixClass.QUESTION_MARK),
            },
            {
              type: "scatter",
              markLine,
              itemStyle,
              data: series[BcgMatrixClass.DOG],
              name: __bcgMatrixClass(BcgMatrixClass.DOG),
            },
            // type: 'scatter',
            // data: salesMixHelper.getEntries().map(entry => [100 * entry.avgProductCost, 100 * entry.salesMixPct, entry]).
            // markLine: {
            //   lineStyle: {
            //     type: 'solid'
            //   },
            //   data: [{ type: 'average', name: Y_AVG }, { name: X_AVG, xAxis: 100 * salesMixHelper.getTotals().avgProductCost.avg }]
            // }
          ],
        }}
        notMerge={true}
        lazyUpdate={true}
        // theme={'theme_name'}
        opts={{}}
        onChartReady={() => {}} // FIXME:
        // onEvents={EventsDict}
      />
    </div>
  );
};

export default BcgMatrixScatterChart;
