import React from 'react';
import { Icon } from '$ui';

export default function ListItemIcon(props) {
  return (
    <div className="ListItemIcon">
      <Icon
        {...props}
      />
    </div>
  );
}