import React, { useContext } from 'react';
import { Alert } from 'reactstrap';
import { keys, pickBy } from 'lodash';
import { UiContext } from './UiProvider.jsx';
import { __ } from '@stktk/locales';

export const NoticeEnum = {
  VERSION: 'VERSION',
  OFFLINE: 'OFFLINE'
};

export const Notice = {
  VERSION: {
    variant: 'warning',
    text: 'general_refresh_needed',
    action: 'button_refresh',
    onAction: () => window.location.reload(true)
  },
  OFFLINE: {
    variant: 'danger',
    text: 'general_reconnect_needed',
    action: 'action_reconnect',
    onAction: () => window.socket.connect()
  }
};

export default function NoticeProvider() {
  const [{ notice }] = useContext(UiContext);
  const showNoticeIds = keys(pickBy(notice, show => show === true));

  if (!showNoticeIds.length)
    return null;

  return (
    <div className="NoticeProvider">
      {showNoticeIds.map(id => <NoticeAlert key={id} {...Notice[id]} />)}
    </div>
  );

}

function NoticeAlert({ variant, text, action, onAction }) {
  const handleClick = e => {
    e.preventDefault();
    if (onAction)
      onAction();
  };

  return (
    <Alert className="Notice" color={variant}>
      <span>
        {__(text)}
        {' '}
      </span>
      {action &&
        <a href="#" className={('text-' + variant)} onClick={handleClick}>
          <strong>{__(action)}</strong>
        </a>
      }
    </Alert>
  );
}