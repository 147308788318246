import React, { Fragment, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { each, isArray, get } from 'lodash';
import { __ } from '@stktk/locales';
import { domainIdSelector, restaurantIdSelector, findSkuSuppliers, uploadCreateSkus, uploadUpdateSkus, createSkus, updateSkus, findSkus, synchronizeBulkSkuSuppliers } from '@stktk/redux';
import { SkuTypeEnum, SkuStocktakeModeEnum } from '@stktk/commons';
import { ButtonFormModal } from '$ui';
import SkuForm from '$forms/SkuForm';
import FormModal from '$providers/FormModal';

export default function ButtonSkuFormModal({ skuType = SkuTypeEnum.SIMPLE, action = 'create', buttonOnly, onClick, ...props }) {
  const domainId = useSelector(domainIdSelector);
  const restaurantId = useSelector(restaurantIdSelector);
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const isCompound = skuType === SkuTypeEnum.COMPOUND;
  const buttonText = isCompound ? __('app_recipe_add_new') : __('app_skus_add_new');

  const handleSynchronizeSuppliers = useCallback(payload =>
    dispatch(synchronizeBulkSkuSuppliers({
      restaurantId,
      domainId,
      payload
    })), [restaurantId, domainId]);

  const upsertSku = useCallback((type, { localSupplierIds, ...data }) => {
    // console.log({ localSupplierIds });
    // Get file directly form input in FormSkuImage by id
    let file = get(window.document.querySelector('#skuImageFileInput'), 'files[0]', null); //FIXME:
    let action;
    if (file) {
      action = type === 'update' ? uploadUpdateSkus : uploadCreateSkus;
      let formData = new FormData();
      each(data, (val, key) => {
        if (typeof (val) !== 'undefined')
          formData.append(key, isArray(val) ? JSON.stringify(val) : val);
      });
      formData.append('image', file);
      // console.log('formData', formData);
      return dispatch(action(data, formData, null, { socketId: window.socket.id })).then(({ id }) => handleSynchronizeSuppliers([{ skuId: id, supplierIds: localSupplierIds }]));
    }
    action = type === 'update' ? updateSkus : createSkus;
    return dispatch(action(data)).then(({ id }) => handleSynchronizeSuppliers([{ skuId: id, supplierIds: localSupplierIds }]));
  }, [handleSynchronizeSuppliers]);

  return (
    <Fragment>
      {!buttonOnly && (
        <FormModal
          id="SkuForm"
          FormBody={SkuForm}
          titleContextKey= {`app_${isCompound ? 'recipe' : 'sku'}_modal_title`}
          size="xl"
          className={isCompound && 'modal-strech'}
        />
      )}
      <ButtonFormModal
        id="Skus-PageControls-Button"
        formId="SkuForm"
        text={buttonText}
        scheme={`sku.${SkuStocktakeModeEnum.getDefault()}.${action}`}
        icon='plus-circle'
        dataPromise={() => new Promise((resolve, reject) => {
          Promise.all([
            dispatch(findSkus({ domainId, types: [skuType] })),
            dispatch(findSkuSuppliers({ domainId, restaurantId }))
          ])
            .then(([skus, skuSuppliers]) => resolve({ skus, skuSuppliers }))
            .catch(reject);
        })}
        data={{
          domainId,
          restaurantId,
          skuType,
          file,
          setFile
        }}
        onClick={() => {
          if (onClick)
            onClick({ skuType, action });
        }}
        onSubmit={data => upsertSku(action, data)}
        {...props}
      />
    </Fragment>
  );
}