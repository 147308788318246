import React, { Fragment } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { getValidationScheme, getWrappedArrayValidationScheme, ValidationPlatformEnum } from '@stktk/validation';
import OnSubmitValidationError from '$ui/form/OnSubmitValidationError';

export default function Form({ children, scheme: schemeName, initialValues, className, style, validateOnChange = false, ...props }) {
  const validation = schemeName ? {
    validationSchema: Array.isArray(initialValues) ?
      getWrappedArrayValidationScheme(ValidationPlatformEnum.CLIENT, schemeName) :
      getValidationScheme(ValidationPlatformEnum.CLIENT, schemeName)
  } : {};
  return (
    <Formik
      validateOnChange={validateOnChange}
      initialValues={
        Array.isArray(initialValues) ?
          { array: initialValues } :
          initialValues
      }
      {...validation}
      {...props}
    >
      <FormikForm className={className} style={style}>
        <Fragment>
          {children}
          <OnSubmitValidationError
            callback={(formik, errors, values) => {
              if (errors)
                console.error('Validation Errors', errors, values);
            }}
          />
        </Fragment>
      </FormikForm>
    </Formik>
  );
}