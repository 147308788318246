import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink as BootstrapNavLink } from 'reactstrap';
import NavbarVerticalMenuItem from '$ui/navbar/NavbarVerticalMenuItem';
import { useShowBurgerMenu } from '$hooks/ui';

export default function NavbarVerticalMenu ({ routes }) {
  const [openedIndex, setOpenedIndex] = useState(null);
  const [showBurgerMenu, setShowBurgerMenu] = useShowBurgerMenu();
  const location = useLocation();

  useEffect(() => {
    let openedDropdown = null;
    routes.forEach((route, index) => {
      if (location.pathname.indexOf(route.to) === 0)
        openedDropdown = index;
    });

    setOpenedIndex(openedDropdown);
  }, []);

  const toggleOpened = (e, index) => {
    e.preventDefault();
    return setOpenedIndex(openedIndex === index ? null : index);
  };

  return routes.map((route, index) => {
    if (!route.children) {
      return (
        <Fragment key={index}>
          <NavItem>
            <NavLink className="nav-link" to={route.to} exact={route.exact} onClick={() => setShowBurgerMenu(false)}>
              <NavbarVerticalMenuItem route={route} />
            </NavLink>
          </NavItem>
        </Fragment>
      );
    }
    return (
      <Fragment key={index}>
        <NavItem>
          <BootstrapNavLink
            onClick={e => toggleOpened(e, index)}
            className="dropdown-indicator cursor-pointer"
            aria-expanded={openedIndex === index}
          >
            <NavbarVerticalMenuItem route={route} />
          </BootstrapNavLink>
          <Collapse isOpen={openedIndex === index}>
            <Nav>
              <NavbarVerticalMenu routes={route.children} location={location} />
            </Nav>
          </Collapse>
        </NavItem>
      </Fragment>
    );
  });
}