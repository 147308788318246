import { orderBy } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { components } from 'react-select';
import { categorySelector, domainIdSelector, findCategories } from '@stktk/redux';
import { __ } from '@stktk/locales';
import { Icon, FormGroup } from '$ui';
import { useMount } from '$hooks/ui';

const defaultColor = '#000000';

export default function FormCategoriesGroup({ id = 'FormCategoriesGroup', ...props }) {
  const categories = useSelector(categorySelector);
  const domainId = useSelector(domainIdSelector);
  const dispatch = useDispatch();
  const isMounting = useMount(() => dispatch(findCategories({ domainId })));

  const options = useMemo(() => orderBy(categories, category => category.name.toLowerCase()).map(category => ({
    label: category.name,
    value: category.id,
    rgbColor: category.rgbColor || defaultColor
  })), [categories]);

  return (
    <FormGroup
      id={id}
      isClearable
      element="select"
      isLoading={isMounting}
      loadingMessage={__('placeholder_loading_category_list')}
      options={options}
      components={{
        Option: option => (
          <components.Option {...option}>
            <div>
              <span style={{ color: option.data.rgbColor }}>
                <Icon icon="circle" className="fas" style={{ display: 'inline-block', marginRight: 20 }} />
              </span>
              <span>
                {option.label}
              </span>
            </div>
          </components.Option>
        )
      }}
      {...props}
    />
  );
}