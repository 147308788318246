import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Icon } from '$ui';

const WizardNav = ({ currentStepIndex, steps, stepConfig, basePath, path }) => {
  return (
    <Nav className="justify-content-center theme-wizard mb-3">
      {steps.map((step, index) => {
        let isBefore = index < currentStepIndex;
        let linkProps = stepConfig[step].path && (isBefore || currentStepIndex === index) ? {
          tag: Link,
          to: stepConfig[step].path === 'start' ? basePath : `${basePath}${path}/${stepConfig[step].path}`
        } : {};
        return (
          <NavItem key={`step-${index}`}>
            <NavLink
              tag={'div'}
              className={classNames('font-weight-semi-bold', {
                'done cursor-pointer': isBefore,
                active: currentStepIndex === index
              })}
              {...linkProps}
            >
              <span className="nav-item-circle-parent">
                <span className="nav-item-circle">
                  <Icon icon={stepConfig[step].icon} />
                </span>
              </span>
              <span className="d-none d-md-block mt-1 fs--1">{stepConfig[step].name}</span>
            </NavLink>
          </NavItem>
        );
      }
      )}
    </Nav>
  );
};

export default WizardNav;