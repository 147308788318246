"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = require("lodash");

var _reduxwork = require("reduxwork");

var _commons = require("@stktk/commons");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var ones = {
  camera: {
    state: 1
  },
  addskustart: {
    state: 1
  },
  addskupicture: {
    state: 1
  },
  scale: {
    state: 1
  }
};
var addSkuDefaultState = {
  search: null,
  data: {
    id: void 0,
    name: void 0,
    barcode: void 0,
    barcodeFormat: void 0,
    percentage: void 0,
    volume: void 0,
    volumeUnitId: 3,
    // TODO: add placeholder for category select
    categoryId: 1,
    packageWeightGross: void 0,
    packageWeightNet: void 0,
    packageWeightNetUnitId: 1,
    packageWeightGrossUnitId: 1,
    capWeight: void 0,
    capWeightUnitId: 1,
    image: void 0,
    "private": false
  },
  image: null
};
var addItemDefaultState = {
  sku: null,
  mode: null,
  bottles: null,
  nthBottle: 0,
  active: false,
  count: 0,
  workmode: 'mixed'
};
var bleDefaultState = {
  error: null,
  status: _commons.BleStatusEnum.NO_DEVICE_FOUND,
  busy: false,
  scanning: false,
  notifications: null,
  connection: false,
  value: '',
  valueUnitId: _commons.UnitEnum.GRAM.id,
  stableValue: null
};
var reducersMobile = {
  display: function display(state, action) {
    if (typeof state === 'undefined') {
      return {
        camera: {
          state: 0
        },
        addskustart: {
          state: 0
        },
        addskupicture: {
          state: 0
        },
        scale: {
          state: 0
        }
      };
    }

    switch (action.type) {
      case 'HIDE_ADDSKUSTART':
        return _objectSpread(_objectSpread({}, state), {
          addskustart: {
            state: 0
          }
        });

      case 'RENDER_ADDSKUSTART':
        return _objectSpread(_objectSpread({}, state), {
          addskustart: {
            state: 1
          }
        });

      case 'SHOW_ADDSKUSTART':
        return _objectSpread(_objectSpread(_objectSpread({}, state), ones), {
          addskustart: {
            state: 2
          }
        });

      case 'HIDE_ADDSKUPICTURE':
        return _objectSpread(_objectSpread({}, state), {
          addskupicture: {
            state: 0
          }
        });

      case 'RENDER_ADDSKUPICTURE':
        return _objectSpread(_objectSpread({}, state), {
          addskupicture: {
            state: 1
          }
        });

      case 'SHOW_ADDSKUPICTURE':
        return _objectSpread(_objectSpread(_objectSpread({}, state), ones), {
          addskupicture: {
            state: 2
          }
        });

      case 'HIDE_SCALE':
        return _objectSpread(_objectSpread({}, state), {
          scale: {
            state: 0
          }
        });

      case 'RENDER_SCALE':
        return _objectSpread(_objectSpread({}, state), {
          scale: {
            state: 1
          }
        });

      case 'SHOW_SCALE':
        return _objectSpread(_objectSpread(_objectSpread({}, state), ones), {
          scale: {
            state: 2
          }
        });
    }

    return state;
  },
  app: (0, _reduxwork.createReducer)('app', {
    init: false,
    statusbar: true,
    appState: null
  }, {
    CHANGE_APP_STATE: function CHANGE_APP_STATE(state, action) {
      return Object.assign({}, state, action.data);
    }
  }),
  netinfo: (0, _reduxwork.createReducer)('netinfo', {
    isConnected: true,
    reach: 'unknown'
  }, {
    CHANGE_NETINFO: function CHANGE_NETINFO(state, action) {
      return Object.assign({}, state, {
        reach: action.reach.toLowerCase(),
        isConnected: action.reach.toLowerCase() != 'none'
      });
    }
  }),
  ui: (0, _reduxwork.createReducer)('ui', {
    loadingOverlay: false
  }, {
    TOGGLE_LOADING_OVERLAY: function TOGGLE_LOADING_OVERLAY(state, action) {
      var status = typeof action.status != 'undefined' ? action.status : !state.loadingOverlay;
      return Object.assign({}, state, {
        loadingOverlay: status
      });
    }
  }),
  snackbar: (0, _reduxwork.createReducer)('snackbar', {
    visible: false,
    text: 'Empty',
    button: {}
  }, {
    HIDE_SNACKBAR: function HIDE_SNACKBAR(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        visible: false
      });
    },
    SHOW_SNACKBAR: function SHOW_SNACKBAR(state, action) {
      return Object.assign({}, state, {
        visible: true,
        text: action.text,
        button: action.button
      });
    }
  }),
  camera: (0, _reduxwork.createReducer)('camera', {
    mode: null,
    consumer: null,
    autoFocus: null,
    flashMode: null
  }, {
    TOGGLE_CAMERA: function TOGGLE_CAMERA(state, action) {
      return Object.assign({}, state, {
        mode: action.data.mode,
        consumer: action.data.consumer
      });
    },
    SET_CAMERA_FLASH_MODE: function SET_CAMERA_FLASH_MODE(state, action) {
      return Object.assign({}, state, {
        flashMode: action.status
      });
    },
    SET_CAMERA_AUTO_FOCUS: function SET_CAMERA_AUTO_FOCUS(state, action) {
      return Object.assign({}, state, {
        autoFocus: action.status
      });
    }
  }),
  navbar: (0, _reduxwork.createReducer)('navbar', {
    visible: true,
    subtitle: 'Dashboard'
  }, {
    SET_NAVBAR_TITLES: function SET_NAVBAR_TITLES(state, action) {
      return Object.assign({}, state, {
        subtitle: action.subtitle
      });
    },
    TOGGLE_NAVBAR: function TOGGLE_NAVBAR(state, action) {
      var status = typeof action.status != 'undefined' ? action.status : !state.visible;
      return Object.assign({}, state, {
        visible: status
      });
    }
  }),
  drawer: (0, _reduxwork.createReducer)('drawer', {
    visible: false
  }, {
    TOGGLE_DRAWER: function TOGGLE_DRAWER(state, action) {
      var status = typeof action.status != 'undefined' ? action.status : !state.visible;
      return Object.assign({}, state, {
        visible: status
      });
    }
  }),
  addloss: (0, _reduxwork.createReducer)('addloss', {
    visible: false
  }, {
    SET_ADDLOSS_SKU: function SET_ADDLOSS_SKU(state, action) {
      return Object.assign({}, state, {
        sku: action.data
      });
    }
  }),
  search: (0, _reduxwork.createReducer)('search', {
    visible: false,
    scopes: _commons.SkuSearchScopeEnum.toArray(),
    consumer: null
  }, {
    SET_SEARCH_CONSUMER: function SET_SEARCH_CONSUMER(state, action) {
      return Object.assign({}, state, {
        consumer: action.data
      });
    },
    SET_SEARCH_SCOPES: function SET_SEARCH_SCOPES(state, action) {
      return Object.assign({}, state, {
        scopes: action.data
      });
    }
  }),
  login: (0, _reduxwork.createReducer)('login', {
    loading: false,
    error: false
  }, {
    SET_LOGIN_STATE: function SET_LOGIN_STATE(state, action) {
      return _objectSpread(_objectSpread({}, state), {
        error: action.error,
        loading: action.loading
      });
    },
    SET_LOGIN_LOADING: function SET_LOGIN_LOADING(state, action) {
      return _objectSpread(_objectSpread({}, state), {
        loading: action.loading
      });
    },
    SET_LOGIN_ERROR: function SET_LOGIN_ERROR(state, action) {
      return _objectSpread(_objectSpread({}, state), {
        error: action.error
      });
    }
  }),
  ble: (0, _reduxwork.createReducer)('ble', bleDefaultState, {
    BLE_SEND_ERROR_MESSAGE: function BLE_SEND_ERROR_MESSAGE(state, action) {
      return Object.assign({}, state, {
        error: action.error
      });
    },
    BLE_STATUS_START_MONITORING: function BLE_STATUS_START_MONITORING(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        status: _commons.BleStatusEnum.MONITORING,
        busy: false
      });
    },
    BLE_STATUS_DISCONNECTED: function BLE_STATUS_DISCONNECTED(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        status: _commons.BleStatusEnum.DISCONNECTED,
        connection: false,
        busy: false
      });
    },
    BLE_STATUS_SCANNING: function BLE_STATUS_SCANNING(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        status: _commons.BleStatusEnum.SCANNING,
        scanning: true
      });
    },
    BLE_STATUS_NO_DEVICE_FOUND: function BLE_STATUS_NO_DEVICE_FOUND(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        status: _commons.BleStatusEnum.NO_DEVICE_FOUND,
        scanning: false,
        busy: false
      });
    },
    BLE_STATUS_CONNECTING: function BLE_STATUS_CONNECTING(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        status: _commons.BleStatusEnum.CONNECTING,
        scanning: false,
        busy: true
      });
    },
    BLE_STATUS_STOP_MONITORING: function BLE_STATUS_STOP_MONITORING(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        status: _commons.BleStatusEnum.CONNECTED,
        notifications: null
      });
    },
    BLE_STATUS_RESET: function BLE_STATUS_RESET() {
      return bleDefaultState;
    },
    BLE_SET_NOTIFICATIONS: function BLE_SET_NOTIFICATIONS(state, action) {
      return Object.assign({}, state, {
        notifications: action.notifications
      });
    },
    BLE_SET_VALUE_UNIT_ID: function BLE_SET_VALUE_UNIT_ID(state, action) {
      return Object.assign({}, state, {
        valueUnitId: action.valueUnitId
      });
    },
    BLE_SET_VALUE: function BLE_SET_VALUE(state, action) {
      return Object.assign({}, state, {
        value: action.value
      });
    },
    BLE_SET_STABLE_VALUE: function BLE_SET_STABLE_VALUE(state, action) {
      return Object.assign({}, state, {
        value: action.value,
        stableValue: action.value
      });
    }
  }),
  scale: (0, _reduxwork.createReducer)('scale', {
    mode: null
  }, {
    SET_SCALE_MODE: function SET_SCALE_MODE(state, action) {
      return Object.assign({}, state, {
        mode: action.mode
      });
    }
  }),
  router: (0, _reduxwork.createReducer)('router', {
    route: {
      name: null,
      subtitle: '',
      index: 0,
      props: {},
      onFocus: null
    },
    lastRoute: null,
    stack: []
  }, {
    CHANGE_ROUTE: function CHANGE_ROUTE(state, action) {
      return Object.assign({}, state, {
        lastRoute: state.route,
        route: action.route
      });
    }
  }),
  addItem: (0, _reduxwork.createReducer)('addItem', addItemDefaultState, {
    SET_ADDITEM_MODE: function SET_ADDITEM_MODE(state, action) {
      return Object.assign({}, state, {
        mode: action.data
      });
    },
    SET_ADDITEM_SKU_ID: function SET_ADDITEM_SKU_ID(state, action) {
      return Object.assign({}, state, {
        skuId: action.data
      });
    },
    SET_ADDITEM_ACTIVE: function SET_ADDITEM_ACTIVE(state, action) {
      return Object.assign({}, state, {
        active: action.data
      });
    },
    SET_ADDITEM_COUNT: function SET_ADDITEM_COUNT(state, action) {
      return Object.assign({}, state, {
        count: action.data
      });
    },
    SET_ADDITEM_BOTTLES: function SET_ADDITEM_BOTTLES(state, action) {
      return Object.assign({}, state, {
        bottles: action.data,
        nthBottle: 1
      });
    },
    ADDITEM_NEXT_BOTTLE: function ADDITEM_NEXT_BOTTLE(state
    /*, action*/
    ) {
      return Object.assign({}, state, {
        nthBottle: state.nthBottle + 1
      });
    },
    SET_ADDITEM_SKU: function SET_ADDITEM_SKU(state, action) {
      return Object.assign({}, state, {
        sku: action.data
      });
    },
    SET_ADDITEM_WORKMODE: function SET_ADDITEM_WORKMODE(state, action) {
      return Object.assign({}, state, {
        workmode: action.mode
      });
    },
    RESET_ADDITEM_DATA: function RESET_ADDITEM_DATA(state
    /*, action*/
    ) {
      return Object.assign({}, state, addItemDefaultState);
    }
  }),
  addSku: (0, _reduxwork.createReducer)('addSku', addSkuDefaultState, {
    // TODO: If this functionality is needed - move it out of Redux
    IMPORT_SKU_DATA: function IMPORT_SKU_DATA(state
    /*, action*/
    ) {
      var data = (0, _lodash.pick)((0, _lodash.omitBy)(state.search, _lodash.isNull), ['name', 'volume', 'percentage', 'categoryId', 'volumeUnitId', 'image']);
      return Object.assign({}, state, {
        data: Object.assign({}, state.data, data)
      });
    },
    SET_SKU_DATA: function SET_SKU_DATA(state, action) {
      return Object.assign({}, state, {
        data: action.data
      });
    },
    SET_SKU_IMAGE: function SET_SKU_IMAGE(state, action) {
      return Object.assign({}, state, {
        image: action.data
      });
    },
    UPDATE_SKU_DATA: function UPDATE_SKU_DATA(state, action) {
      return Object.assign({}, state, {
        data: Object.assign({}, state.data, action.data)
      });
    },
    SET_SKU_SEARCH_DATA: function SET_SKU_SEARCH_DATA(state, action) {
      return Object.assign({}, state, {
        search: action.data
      });
    },
    RESET_SKU_DATA: function RESET_SKU_DATA() {
      return Object.assign({}, addSkuDefaultState);
    }
  })
};
var _default = reducersMobile;
exports["default"] = _default;