import React, { useState } from 'react';
import { ListGroup } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { times } from 'lodash';
import { getItemIndex, getPageCount, getPageItemCount, DEFAULT_PAGE_SIZE } from '@stktk/logic/utils/pagination.js';
import { __ } from '@stktk/locales';
import Pagination from '$ui/Pagination';

export default function ListPaginated({ itemData, itemCount = 0, itemKey, ItemRenderer, pageNumber = 1 }) {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const history = useHistory();
  const pageCount = getPageCount({ items: itemCount, pageSize });
  const pageIndex = pageNumber - 1;
  // FIXME: replace search string
  const gotoPage = pageIndex => history.replace({ search: `?page=${pageIndex + 1}` });
  // console.log(itemCount, itemData, getPageItemCount({ itemCount, pageSize, pageNumber }));
  return itemCount ? (
    <div className="List">
      <ListGroup>
        {times(getPageItemCount({ itemCount, pageSize, pageNumber }), index =>
          <ItemRenderer
            key={itemKey(getItemIndex({ index, pageSize, pageNumber }), itemData)}
            data={itemData}
            index={getItemIndex({ index, pageSize, pageNumber })}
          />
        )}
      </ListGroup>
      <Pagination
        gotoPage={gotoPage}
        canPreviousPage={pageNumber > 1}
        canNextPage={pageNumber < pageCount}
        previousPage={() => gotoPage(pageIndex - 1)}
        nextPage={() => gotoPage(pageIndex + 1)}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </div>
  ) : (
    <div className="List List--empty">
      {__('app_table_empty')}
    </div>
  );
}