import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { isObject } from 'lodash';
import { getRoutePath } from '$utils/route';
import { useBreadcrumbs } from '$hooks/ui';
import { Flex } from '$ui';
import { domainIdSelector, restaurantNameSelector } from '@stktk/redux';

export default function Breadcrumbs() {
  const domainId = useSelector(domainIdSelector);
  const restaurantName = useSelector(restaurantNameSelector);
  const [crumbs] = useBreadcrumbs();

  const breadcrumbs = [{ title: restaurantName, to: getRoutePath({ domainId, page: 'dashboard' }) }, ...crumbs];
  return (
    <Breadcrumb className="Breadcrumbs">
      {breadcrumbs.map((breadcrumb, i) => (
        <BreadcrumbItem key={i} tag={breadcrumb.to ? Link : 'span'} to={breadcrumb.to} active={(!breadcrumb.to || i === breadcrumbs.length - 1)}>
          <Flex align="center">
            {isObject(breadcrumb) ? (
              <Fragment>
                {breadcrumb.title}
                {breadcrumb.info && (
                  <span className="Breadcrumbs-info">
                    {`(${breadcrumb.info})`}
                  </span>
                )}
              </Fragment>
            ) : breadcrumb}
          </Flex>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
}