import React from 'react';
import { FilterTypeEnum } from '@stktk/logic/utils/filter';
import { Icon } from '$ui';
import Input from '$ui/form/controls/Input';

export default function TableFilterInput({
  column: { filterValue, setFilter }
}) {
  return (
    <div className="FilterInnerComponent">
      <Input
        className="FilterInnerComponent-input"
        bsSize="sm"
        value={filterValue && filterValue[0] === FilterTypeEnum.TEXT.DOES_CONTAIN && filterValue[1] || ''}
        onChange={e => {
          setFilter(e.target.value.length ? [FilterTypeEnum.TEXT.DOES_CONTAIN, e.target.value] : undefined); // Set undefined to remove the filter entirely
        }}
      />
      <Icon
        className="FilterInnerComponent-icon"
        icon="filter"
        type="light"
        variant="mute"
      />
    </div>
  );
}