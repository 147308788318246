import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { __ } from '@stktk/locales';
import { getFindRecordsPromise } from '@stktk/logic/utils/statistics';
import { domainIdSelector, restaurantIdSelector, barSelector } from '@stktk/redux';
import { Icon, Button } from '$ui';
import { globalHandleError } from '$utils/error';

const RecordsetStatistics = ({ recordsetType, recordsetGroupId, recordsetId, property, __object, precision = 2, autoclick, recordStatus }) => {
  const [value, setValue] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const domainId = useSelector(domainIdSelector);
  const bars = useSelector(barSelector);
  const restaurantId = useSelector(restaurantIdSelector);
  const dispatch = useDispatch();
  const findRecords = getFindRecordsPromise({
    bars,
    recordsetType,
    recordsetGroupId,
    recordsetId,
    restaurantId,
    recordStatus,
    domainId,
    dispatch
  });

  const handleClick = () => {
    setLoading(true);
    findRecords().then(data => {
      console.log('RecordsetStatistics data', { data });
      setValue(data[property]);
      __object[property] = data[property]; //FIXME: export workaround
      setLoading(false);
    }).catch(error => {
      globalHandleError(error);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (autoclick) handleClick();
  }, []);

  return (
    <div>
      {value ? (
        <span>
          <span style={{ margin: '0.5rem', fontSize: 'small' }} >
            <Icon
              type="regular"
              icon="redo-alt"
              onClick={handleClick}
              {...isLoading ? { animation: 'spin' } : {}}
            />
          </span>
          {value ? value.toFixed(precision) : value}
          {/*
            <span style={{ margin: '0.5rem', fontSize: 'small' }} >
              <Icon type="light" icon="info-circle" />
            </span>
          */}
        </span>
      ) : (
        <Button
          text={__('button_calculate')}
          variant="outline-primary"
          isLoading={isLoading}
          onClick={handleClick}
        />
      )}
    </div>
  );

};

export default RecordsetStatistics;