import React from 'react';
import { camelCase } from 'lodash';
import classNames from 'classnames';
import { Icon } from '$ui';

export default function ListItemProp({ id, value, icon, tooltip, children, className, isMultiline, ...props }) {
  return (
    <div className={classNames('ListItemProp', className)} data-testid="ListItemProp" {...props}>
      {icon &&
        <Icon
          id={`prop_icon_${camelCase(tooltip)}_${id}`}
          icon={icon}
          tooltip={tooltip}
          size="md"
        />
      }
      <div className={classNames('ListItemProp-value', { 'ListItemProp-value--multiline': isMultiline })} >{children || value}</div>
    </div>
  );
}