import React from "react";
import { __ } from "@stktk/locales";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts";
import { ScatterChart } from "echarts/charts";
import {
  GridComponent /*, LegendComponent*/,
  TooltipComponent,
  TitleComponent,
  MarkLineComponent,
  ToolboxComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { getSkuDetails } from "@stktk/commons";

echarts.use([
  ScatterChart,
  GridComponent /*, LegendComponent*/,
  TooltipComponent,
  TitleComponent,
  MarkLineComponent,
  ToolboxComponent,
  CanvasRenderer,
]);

const X_AVG = "xAvg";
const Y_AVG = "yAvg";

const SalesMixScatterChart = ({
  salesMixHelper,
  title = __("chart_sales_mix_title"),
  subtitle = null,
}) => {
  return (
    <div>
      <ReactEChartsCore
        echarts={echarts}
        option={{
          title: {
            text: title,
            subtext: subtitle,
          },
          grid: {
            left: "3%",
            right: "7%",
            bottom: "7%",
            containLabel: true,
          },
          toolbox: {
            feature: {
              dataZoom: {},
              brush: {
                type: ["rect", "polygon", "clear"],
              },
            },
          },
          tooltip: {
            // trigger: 'axis',
            showDelay: 0,
            formatter: function (params) {
              if (params.value.length > 1) {
                const [, , entry] = params.data;
                let {
                  sku: { id: skuId },
                } = entry;
                let { amount, totalSaleValue } = salesMixHelper.getEntry({
                  skuId,
                });
                const r = salesMixHelper.getRecipeHelper();
                const totalProfit = r.isRecipe({ skuId })
                  ? totalSaleValue - amount * r.getPurchaseValue({ skuId })
                  : totalSaleValue -
                    amount *
                      getSkuDetails(entry.sku, "domain").netPurchasePrice;
                return (
                  `${entry.sku.name} (id: ${entry.sku.id})<br/>` +
                  __("chart_sales_mix_average_profit", {
                    value: parseFloat(params.value[0].toFixed(4)),
                  }) +
                  "</br>" +
                  __("chart_sales_mix_average_sales_mix", {
                    value: parseFloat(params.value[1].toFixed(4)),
                  }) +
                  "</br>" +
                  __("chart_sales_mix_total_sales_amount", {
                    value: (amount || 0).toFixed(2),
                  }) +
                  "</br>" +
                  __("chart_sales_mix_total_profit", {
                    value: (totalProfit || 0).toFixed(2),
                  })
                );
              } else {
                // markLine
                const { name, value } = params.data;
                if (name === X_AVG) {
                  return __("chart_sales_mix_x_average", { value }) + "</br>"; // NOTE: without <br> doesn't render
                } else if (name === Y_AVG) {
                  return __("chart_sales_mix_y_average", { value }) + "</br>";
                }
              }
            },
            axisPointer: {
              show: true,
              type: "cross",
              lineStyle: {
                type: "dashed",
                width: 1,
              },
            },
          },
          xAxis: {
            type: "value",
            scale: true,
            axisLabel: {
              formatter: "{value}",
            },
            splitLine: {
              show: false,
            },
            name: __("chart_sales_mix_x_name"),
            nameLocation: "middle",
            nameGap: 25,
          },
          yAxis: {
            type: "value",
            scale: true,
            axisLabel: {
              formatter: "{value}",
            },
            splitLine: {
              show: false,
            },
            name: __("chart_sales_mix_y_name"),
            nameLocation: "middle",
            nameGap: 50,
          },
          series: {
            type: "scatter",
            data: salesMixHelper
              .getEntries()
              .map((entry) => [
                entry.avgProfit,
                100 * entry.salesMixPct,
                entry,
              ]),
            markLine: {
              lineStyle: {
                type: "solid",
              },
              data: [
                { type: "average", name: Y_AVG },
                {
                  name: X_AVG,
                  xAxis: salesMixHelper.getTotals().avgProfit.avg || 0,
                },
              ], //NOTE: fallback if no data specified
            },
          },
        }}
        notMerge={true}
        lazyUpdate={true}
        // theme={'theme_name'}
        opts={{}}
        onChartReady={() => {}} // FIXME:
        // onEvents={EventsDict}
      />
    </div>
  );
};

export default SalesMixScatterChart;
