import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { kebabCase } from 'lodash';
import classNames from 'classnames';
import { __ } from '@stktk/locales';
import { Icon } from '$ui';

const ListSortDropdown = ({ sort, setSort, sortableProps }) => {
  const [isOpen, setOpen] = useState(false);

  const handleClick = key => {
    setSort(sort => [{ id: key, desc: sort[0].id === key ? !sort[0].desc : false }]);
    setOpen(false);
  };

  return (
    <Dropdown size="sm" isOpen={isOpen} toggle={() => setOpen(isOpen => !isOpen)}>
      <DropdownToggle caret color="light">
        <Icon className="mr-2" icon="sort-amount-up" />
        <span>{__('general_sort_by')}</span>
      </DropdownToggle>
      <DropdownMenu right>
        {sortableProps.map(({ key, label }) => (
          <DropdownItem key={`sortable-${kebabCase(label)}`} onClick={() => handleClick(key)}>
            <Icon className={classNames('mr-2', { 'text-muted': sort[0].id !== key })} icon={sort[0].id === key ? sort[0].desc ? 'sort-up' : 'sort-down' : 'sort'} />
            <span>{label}</span>
            <span />
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ListSortDropdown;