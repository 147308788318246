"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSchemes = exports["default"] = void 0;

var _validatorConstants = require("../validator/validatorConstants.js");

var _commons = require("@stktk/commons");

var _schemeConstants = require("./schemeConstants.js");

var _skuScheme = _interopRequireDefault(require("./skuScheme.js"));

var _importScheme = _interopRequireDefault(require("./importScheme.js"));

var _saleRecordScheme = _interopRequireDefault(require("./saleRecordScheme.js"));

var _storageRecordScheme = _interopRequireDefault(require("./storageRecordScheme.js"));

var _orderRecordScheme = _interopRequireDefault(require("./orderRecordScheme.js"));

var _orderGroupRecordScheme = _interopRequireDefault(require("./orderGroupRecordScheme.js"));

var _stocktakeRecordScheme = _interopRequireDefault(require("./stocktakeRecordScheme.js"));

var _lossRecordScheme = _interopRequireDefault(require("./lossRecordScheme.js"));

var _stocktakeScheme = _interopRequireDefault(require("./stocktakeScheme.js"));

var _lossScheme = _interopRequireDefault(require("./lossScheme.js"));

var _storageScheme = _interopRequireDefault(require("./storageScheme.js"));

var _orderScheme = _interopRequireDefault(require("./orderScheme.js"));

var _orderGroupScheme = _interopRequireDefault(require("./orderGroupScheme.js"));

var _saleScheme = _interopRequireDefault(require("./saleScheme.js"));

var _skuIngredientScheme = _interopRequireDefault(require("./skuIngredientScheme.js"));

var _packageClosureScheme = _interopRequireDefault(require("./packageClosureScheme.js"));

var _storageTransferRecordScheme = _interopRequireDefault(require("./storageTransferRecordScheme.js"));

var _storageTransferGroupScheme = _interopRequireDefault(require("./storageTransferGroupScheme.js"));

var _storageTransferScheme = _interopRequireDefault(require("./storageTransferScheme.js"));

var _integrationInstanceScheme = _interopRequireDefault(require("./integrationInstanceScheme.js"));

var _integrationInstanceContextScheme = _interopRequireDefault(require("./integrationInstanceContextScheme.js"));

var _integrationEntryScheme = _interopRequireDefault(require("./integrationEntryScheme.js"));

var _skuSupplierScheme = _interopRequireDefault(require("./skuSupplierScheme.js"));

var _productionRecordScheme = _interopRequireDefault(require("./productionRecordScheme.js"));

var _productionGroupScheme = _interopRequireDefault(require("./productionGroupScheme.js"));

var _tagScheme = _interopRequireDefault(require("./tagScheme.js"));

var _userScheme = _interopRequireDefault(require("./userScheme.js"));

var _memberScheme = _interopRequireDefault(require("./memberScheme.js"));

var _reportScheme = _interopRequireDefault(require("./reportScheme.js"));

var _fakirScheme = _interopRequireDefault(require("./fakirScheme.js"));

var _recipeScheme = _interopRequireDefault(require("./recipeScheme.js"));

var _documentScheme = _interopRequireDefault(require("./documentScheme.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/* eslint-disable new-cap */
var schemes = {
  "import": _importScheme["default"],
  sku: _skuScheme["default"],
  saleRecord: _saleRecordScheme["default"],
  storageRecord: _storageRecordScheme["default"],
  lossRecord: _lossRecordScheme["default"],
  orderRecord: _orderRecordScheme["default"],
  orderGroupRecord: _orderGroupRecordScheme["default"],
  stocktakeRecord: _stocktakeRecordScheme["default"],
  stocktake: _stocktakeScheme["default"],
  loss: _lossScheme["default"],
  storage: _storageScheme["default"],
  order: _orderScheme["default"],
  orderGroup: _orderGroupScheme["default"],
  sale: _saleScheme["default"],
  skuIngredient: _skuIngredientScheme["default"],
  packageClosure: _packageClosureScheme["default"],
  storageTransferRecord: _storageTransferRecordScheme["default"],
  storageTransferGroup: _storageTransferGroupScheme["default"],
  storageTransfer: _storageTransferScheme["default"],
  integrationInstance: _integrationInstanceScheme["default"],
  integrationInstanceContext: _integrationInstanceContextScheme["default"],
  integrationEntry: _integrationEntryScheme["default"],
  skuSupplier: _skuSupplierScheme["default"],
  productionGroup: _productionGroupScheme["default"],
  productionRecord: _productionRecordScheme["default"],
  tag: _tagScheme["default"],
  user: _userScheme["default"],
  member: _memberScheme["default"],
  report: _reportScheme["default"],
  fakir: _fakirScheme["default"],
  recipe: _recipeScheme["default"],
  document: _documentScheme["default"]
};
schemes.filter = {
  filters: _validatorConstants.ARRAY.optional(_validatorConstants.OBJECT.required({
    column: _validatorConstants.STRING.required(),
    type: _validatorConstants.STRING.required(),
    value: _validatorConstants.STRING.required()
  }))
};
schemes.contextsScheme = {
  integrationInstanceContextId: _validatorConstants.ARRAY.optional(_validatorConstants.INTEGER.optional())
};
schemes.unit = {
  create: {
    name: _validatorConstants.STRING.required(),
    type: _validatorConstants.ENUM.INTEGER.required(_commons.UnitTypeEnum.toArray())
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    name: _validatorConstants.STRING.optional(),
    type: _validatorConstants.ENUM.INTEGER.optional(_commons.UnitTypeEnum.toArray())
  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1])
  }
};
schemes.skuDetails = {
  create: {
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.optional([1]),
    skuId: _validatorConstants.INTEGER.required([1]),
    categoryId: _validatorConstants.INTEGER.optional([1]),
    tags: _validatorConstants.ARRAY.optional(_validatorConstants.INTEGER.required([1])),
    reportAmount: _validatorConstants.INTEGER.optional([1]),
    reportUnitId: _validatorConstants.ENUM.INTEGER.optional(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.toArray())),
    netPurchasePrice: _validatorConstants.NUMBER.optional([_schemeConstants.MONETARY_PRECISION]),
    netSalePrice: _validatorConstants.NUMBER.optional([_schemeConstants.MONETARY_PRECISION]),
    taxRate: _validatorConstants.NUMBER.optional([0, 100]),
    comment: _validatorConstants.STRING.optional([1, 4096]),
    localSupplierIds: _validatorConstants.ARRAY.optional(_validatorConstants.INTEGER.optional([1])),
    config: _validatorConstants.OBJECT.INPART.optional({
      expandable: _validatorConstants.BOOLEAN.optional()
    })
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.optional([1]),
    skuId: _validatorConstants.INTEGER.required([1]),
    categoryId: _validatorConstants.INTEGER.optional([1]),
    tags: _validatorConstants.ARRAY.optional(_validatorConstants.INTEGER.required([1])),
    reportAmount: _validatorConstants.INTEGER.optional([1]),
    reportUnitId: _validatorConstants.ENUM.INTEGER.optional(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.toArray())),
    netPurchasePrice: _validatorConstants.NUMBER.optional([_schemeConstants.MONETARY_PRECISION]),
    netSalePrice: _validatorConstants.NUMBER.optional([_schemeConstants.MONETARY_PRECISION]),
    taxRate: _validatorConstants.NUMBER.optional([0, 100]),
    comment: _validatorConstants.STRING.optional([1, 4096]),
    localSupplierIds: _validatorConstants.ARRAY.optional(_validatorConstants.INTEGER.optional([1])),
    config: _validatorConstants.OBJECT.INPART.optional({
      expandable: _validatorConstants.BOOLEAN.optional()
    })
  }
}; // schemes.category = {
//   create: {
//     name: STRING.required(),
//     short: STRING.required(),
//     description: STRING.required(),
//     density: INTEGER.required(2000),
//     percentage: NUMBER.required(100),
//     alcoholic: BOOLEAN.required(),
//     math: BOOLEAN.optional()
//   },
//   update: {
//     id: INTEGER.required([1]),
//     name: STRING.optional(),
//     short: STRING.optional(),
//     description: STRING.optional(),
//     density: INTEGER.optional(2000),
//     percentage: NUMBER.optional(100),
//     alcoholic: BOOLEAN.optional(),
//     math: BOOLEAN.optional()
//   },
//   destroy: {
//     id: INTEGER.required([1])
//   }
// };

schemes.restaurant = {
  find: {
    domainId: _validatorConstants.INTEGER.required([1])
  },
  create: {
    domainId: _validatorConstants.INTEGER.required([1]),
    name: _validatorConstants.STRING.required(100),
    type: _validatorConstants.STRING.required(20),
    country: _validatorConstants.STRING.required(2),
    city: _validatorConstants.STRING.required(75),
    address: _validatorConstants.STRING.required(200),
    zipcode: _validatorConstants.STRING.required(20),
    email: _validatorConstants.EMAIL.required(100),
    phone: _validatorConstants.STRING.required(30),
    fax: _validatorConstants.STRING.optional(30)
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    name: _validatorConstants.STRING.optional(100),
    type: _validatorConstants.STRING.optional(20),
    country: _validatorConstants.STRING.optional(2),
    city: _validatorConstants.STRING.optional(75),
    address: _validatorConstants.STRING.optional(200),
    zipcode: _validatorConstants.STRING.optional(20),
    email: _validatorConstants.EMAIL.optional(100),
    phone: _validatorConstants.STRING.optional(30),
    fax: _validatorConstants.STRING.optional(30)
  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1])
  },
  "switch": {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1])
  }
};
schemes.category = {
  find: {
    domainId: _validatorConstants.INTEGER.required([1])
  },
  create: {
    domainId: _validatorConstants.INTEGER.required([1]),
    parentId: _validatorConstants.INTEGER.optional([1]),
    name: _validatorConstants.STRING.required([1, 100]),
    rgbColor: _validatorConstants.REGEX.optional(/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/, 'validator_error_stktk_rgb')
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    parentId: _validatorConstants.INTEGER.optional([1]),
    name: _validatorConstants.STRING.optional([1, 100]),
    rgbColor: _validatorConstants.REGEX.optional(/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/, 'validator_error_stktk_rgb')
  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1])
  }
};
schemes.property = {
  find: {
    userId: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  restaurant: {
    create: {
      restaurantId: _validatorConstants.INTEGER.required([1]),
      settings: _validatorConstants.OBJECT.INPART.required({//allowedSearchScopes: ARRAY.required(ENUM.STRING.required(SkuSearchScopeEnum.toArray()), [1])
      })
    },
    update: {
      id: _validatorConstants.INTEGER.required([1]),
      restaurantId: _validatorConstants.INTEGER.required([1]),
      settings: _validatorConstants.OBJECT.INPART.required({//allowedSearchScopes: ARRAY.required(ENUM.STRING.required(SkuSearchScopeEnum.toArray()), [1])
      })
    }
  },
  domain: {
    create: {
      domainId: _validatorConstants.INTEGER.required([1]),
      settings: _validatorConstants.OBJECT.INPART.required({
        allowedSearchScopes: _validatorConstants.ARRAY.required(_validatorConstants.ENUM.STRING.required(_commons.SkuSearchScopeEnum.toArray()), [1])
      })
    },
    update: {
      id: _validatorConstants.INTEGER.required([1]),
      domainId: _validatorConstants.INTEGER.required([1]),
      settings: _validatorConstants.OBJECT.INPART.required({
        allowedSearchScopes: _validatorConstants.ARRAY.required(_validatorConstants.ENUM.STRING.required(_commons.SkuSearchScopeEnum.toArray()), [1])
      })
    }
  },
  user: {
    create: {
      userId: _validatorConstants.INTEGER.required([1]),
      settings: _validatorConstants.OBJECT.INPART.required({
        allowedSearchScopes: _validatorConstants.ARRAY.required(_validatorConstants.ENUM.STRING.required(_commons.SkuSearchScopeEnum.toArray()), [1])
      })
    },
    update: {
      id: _validatorConstants.INTEGER.required([1]),
      userId: _validatorConstants.INTEGER.required([1]),
      settings: _validatorConstants.OBJECT.INPART.required({
        allowedSearchScopes: _validatorConstants.ARRAY.required(_validatorConstants.ENUM.STRING.required(_commons.SkuSearchScopeEnum.toArray()), [1])
      })
    }
  }
};
schemes.supplier = {
  find: {
    domainId: _validatorConstants.INTEGER.required([1])
  },
  create: {
    domainId: _validatorConstants.INTEGER.required([1]),
    name: _validatorConstants.STRING.required(100),
    email: _validatorConstants.EMAIL.optional(100),
    minimumOrderValue: _validatorConstants.NUMBER.optional(),
    deliveryTime: _validatorConstants.INTEGER.optional(),
    deliveryDays: _validatorConstants.ARRAY.optional(_validatorConstants.INTEGER.optional()),
    country: _validatorConstants.STRING.optional(2),
    city: _validatorConstants.STRING.optional(75),
    address: _validatorConstants.STRING.optional(200),
    zipcode: _validatorConstants.STRING.optional(20),
    phone: _validatorConstants.STRING.optional(30),
    fax: _validatorConstants.STRING.optional(30)
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    name: _validatorConstants.STRING.optional(100),
    email: _validatorConstants.EMAIL.optional(100),
    minimumOrderValue: _validatorConstants.NUMBER.optional(),
    deliveryTime: _validatorConstants.INTEGER.optional(),
    deliveryDays: _validatorConstants.ARRAY.optional(_validatorConstants.INTEGER.optional()),
    country: _validatorConstants.STRING.optional(2),
    city: _validatorConstants.STRING.optional(75),
    address: _validatorConstants.STRING.optional(200),
    zipcode: _validatorConstants.STRING.optional(20),
    phone: _validatorConstants.STRING.optional(30),
    fax: _validatorConstants.STRING.optional(30)
  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1])
  }
};
schemes.bar = {
  find: {
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  findDomain: {
    domainId: _validatorConstants.INTEGER.required([1])
  },
  create: {
    restaurantId: _validatorConstants.INTEGER.required([1]),
    parentId: _validatorConstants.INTEGER.required([1]),
    name: _validatorConstants.STRING.required([1, 35]),
    sort: _validatorConstants.INTEGER.optional()
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    parentId: _validatorConstants.INTEGER.optional([1]),
    name: _validatorConstants.STRING.optional([1, 35]),
    sort: _validatorConstants.INTEGER.optional()
  },
  destroy: {
    id: _validatorConstants.INTEGER.required(),
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  destroyBulk: _validatorConstants.ARRAY.required(_validatorConstants.OBJECT.required({
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  }))
};
schemes.lossGroup = {
  find: {
    id: _validatorConstants.INTEGER.optional([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  create: {
    comment: _validatorConstants.STRING.required([1, 140]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    rangeFromDate: _validatorConstants.DATE.required([1]),
    rangeToDate: _validatorConstants.DATE.required([1]),
    status: _validatorConstants.ENUM.INTEGER.required([_commons.LossGroupStatusEnum.DEFAULT])
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    comment: _validatorConstants.STRING.optional([1, 140]),
    status: _validatorConstants.ENUM.INTEGER.optional(_commons.LossGroupStatusEnum.toArray())
  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  finalize: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.optional([1]),
    //FIXME: should be required (0-length enabled)
    payload: _validatorConstants.ARRAY.optional(_validatorConstants.OBJECT.required({
      skuId: _validatorConstants.INTEGER.required([1]),
      netLossPurchaseValue: _validatorConstants.NUMBER.optional([0]),
      netLossSaleValue: _validatorConstants.NUMBER.optional([0])
    }))
  },
  getFinalizationData: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  }
};
schemes.stockcheck = {
  find: {
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  create: {
    comment: _validatorConstants.STRING.required([1, 140]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    virtualPersonnel: _validatorConstants.ARRAY.required(_validatorConstants.INTEGER.required([1]), [1, Number.MAX_SAFE_INTEGER], 'validator_error_stktk_no_personnel'),
    finishDate: _validatorConstants.DATE.optional()
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    comment: _validatorConstants.STRING.optional([1, 140]),
    status: _validatorConstants.ENUM.INTEGER.optional(_commons.StockcheckStatusEnum.toArray()),
    finishDate: _validatorConstants.DATE.optional() //virtualPersonnel: ARRAY.required(INTEGER.required(), [1, 20]).error(new Error('validator_error_stktk_no_personnel'))

  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  finalize: {
    id: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.optional([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    //FIXME: should be required (0-length enabled)
    payload: _validatorConstants.ARRAY.optional(_validatorConstants.OBJECT.required({
      skuId: _validatorConstants.INTEGER.required([1]),
      netStocktakePurchaseValue: _validatorConstants.NUMBER.optional([0]),
      netStocktakeSaleValue: _validatorConstants.NUMBER.optional([0])
    }))
  },
  merge: {
    restaurantId: _validatorConstants.INTEGER.required([1]),
    stockcheck1Id: _validatorConstants.INTEGER.required([1]),
    stockcheck2Id: _validatorConstants.INTEGER.required([1])
  },
  getFinalizationData: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  }
};
schemes.storageStandard = {
  find: {
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  create: {
    comment: _validatorConstants.STRING.required([1, 140]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    virtualGenerationRule: _validatorConstants.ENUM.STRING.required(_commons.StorageStandardGenerationRuleEnum.toArray()),
    virtualStorageStandard: _validatorConstants.INTEGER.optional(),
    status: _validatorConstants.ENUM.INTEGER.required(_commons.StorageStatusEnum.toArray())
  },
  update: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    comment: _validatorConstants.STRING.optional([1, 140]),
    status: _validatorConstants.ENUM.INTEGER.optional(_commons.StorageStandardStatusEnum.toArray())
  },
  activate: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  },
  destroy: {
    id: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1])
  }
};
schemes.notification = {
  find: {
    userId: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1])
  },
  process: {
    userId: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.required([1]),
    id: _validatorConstants.INTEGER.required([1]),
    action: _validatorConstants.STRING.required([6, 32])
    /* action_* */

  }
};
schemes.details = {
  terms: _validatorConstants.ENUM.BOOLEAN.required([true], 'validator_error_stktk_terms'),
  // user
  firstName: _validatorConstants.STRING.required(50),
  lastName: _validatorConstants.STRING.required(75),
  marketing: _validatorConstants.BOOLEAN.optional(),
  // restaurant
  name: _validatorConstants.STRING.required(100),
  type: _validatorConstants.STRING.required(20),
  country: _validatorConstants.STRING.required(75),
  city: _validatorConstants.STRING.required(75),
  address: _validatorConstants.STRING.required(200),
  zipcode: _validatorConstants.STRING.required(20),
  email: _validatorConstants.EMAIL.optional(100),
  phone: _validatorConstants.STRING.required(30),
  fax: _validatorConstants.STRING.optional(30)
}; // 3RD PARTY VALIDATION

/* eslint-disable camelcase */
// schemes['wc_order'] = {
//   create: {
//     id: INTEGER.required(),
//     currency: STRING.required(),
//     total: STRING.required(),
//     date_created: DATE.required(),
//     billing: OBJECT.INPART.required({
//       first_name: STRING.required(),
//       last_name: STRING.required(),
//       company: STRING.required(),
//       address_1: STRING.required(),
//       address_2: STRING.optional(),
//       city: STRING.required(),
//       postcode: STRING.required(),
//       country: STRING.required(),
//       email: STRING.required(),
//       phone: STRING.required()
//     }),
//     shipping: OBJECT.INPART.required({
//       company: STRING.required(),
//       address_1: STRING.required(),
//       address_2: STRING.optional(),
//       city: STRING.required(),
//       postcode: STRING.required(),
//       country: STRING.required()
//     }),
//     payment_method: STRING.required(),
//     meta_data: ARRAY.optional(OBJECT.INPART.optional({
//       key: STRING.optional()
//     }), [0, 20]),
//     line_items: ARRAY.required(OBJECT.INPART.required({
//       name: STRING.required(),
//       quantity: DECIMAL.required(QUANTITY_PRECISION),
//       subtotal: STRING.required(),
//       total: STRING.required(),
//       total_tax: STRING.required()
//     }), [0, 20])
//   }
// };
// schemes['fakturownia_pl_invoice'] = {
//   create: {
//     kind: ENUM.STRING.required(['vat']),
//     income: ENUM.STRING.required(['1']),
//     place: ENUM.STRING.required(['Elbląg']),
//     status: ENUM.STRING.required(['issued']),
//     lang: ENUM.STRING.required(['en', 'pl']),
//     reverse_charge: BOOLEAN.required(),
//     oid: STRING.required(),
//     oid_unique: ENUM.STRING.required(['yes']),
//     buyer_company: ENUM.STRING.required(['1']),
//     client_id: ENUM.STRING.required(['-1']),
//     currency: STRING.required(),
//     issue_date: STRING.required(),
//     sell_date: STRING.required(),
//     paid_date: STRING.optional(),
//     buyer_first_name: STRING.required(),
//     buyer_last_name: STRING.required(),
//     buyer_name: STRING.required(),
//     buyer_street: STRING.required(),
//     buyer_city: STRING.required(),
//     buyer_post_code: STRING.required(),
//     buyer_country: STRING.required(),
//     buyer_email: STRING.required(),
//     recipient_email: STRING.required(),
//     recipient_phone: STRING.required(),
//     recipient_name: STRING.required(),
//     recipient_street: STRING.required(),
//     recipient_city: STRING.required(),
//     recipient_post_code: STRING.required(),
//     recipient_country: STRING.required(),
//     payment_type: STRING.required(),
//     buyer_tax_no: STRING.optional(),
//     exchange_currency: STRING.optional(),
//     positions: ARRAY.required(OBJECT.INPART.required({
//       name: STRING.required(),
//       quantity: STRING.required(),
//       price_net: STRING.required(),
//       price_gross: STRING.required(),
//       total_price_gross: STRING.required(),
//       tax: STRING.required()
//     }), [1, 60])
//   }
// };
// schemes.taxly_pl_invoice = {
//   create: {
//     clientName: STRING.required(),
//     clientNIP: STRING.optional(),
//     clientStreet: STRING.required(),
//     clientStreetNumber: STRING.optional(),
//     clientZipCode: STRING.required(),
//     clientCity: STRING.required(),
//     clientCountry: STRING.required(),
//     invoiceSerie: STRING.required(),
//     invoiceType: STRING.required(),
//     invoiceDate: STRING.required(),
//     sellDate: STRING.required(),
//     paymentDate: STRING.required(),
//     paymentMethod: STRING.required(),
//     isPaid: STRING.required(),
//     invoiceCity: STRING.required(),
//     invoiceCurrency: STRING.required(),
//     invoiceBank: STRING.required(),
//     invoiceLanguage: STRING.required(),
//     paymentReminder: STRING.required(),
//     invoiceComments: STRING.optional(),
//     invoiceSeller: STRING.required(),
//     invoicePositions: ARRAY.required(OBJECT.INPART.required({
//       title: STRING.required(),
//       unit: STRING.required(),
//       amount: STRING.required(),
//       vat: STRING.required(),
//       priceNetto: STRING.required()
//     }))
//   }
// };

var getSchemes = function getSchemes() {
  return schemes;
};

exports.getSchemes = getSchemes;
var _default = getSchemes;
exports["default"] = _default;