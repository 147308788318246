import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import gravatar from 'gravatar';
import Avatar from 'react-avatar';
import * as Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import Icon from '$ui/Icon';
import { __ } from '@stktk/locales';
import { userDataSelector } from '@stktk/redux';

const ProfileDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const user = useSelector(userDataSelector);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const logout = () => {
    Cookies.remove('jwt', {
      domain: '.' + window.domain.DOMAIN
    });
    window.location.replace(window.domain.URL_APP_WWW + '/login');
  };
  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle nav className="pr-0">
        <Avatar name={`${user.firstName} ${user.lastName}`} email={user.email} size={32} round />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem tag={Link} to={`/panel/${user.domainId}/profile`}>
            <Icon className="d-inline-block mr-1" type="light" icon="user-cog" />
            <span>{__('app_sidebar_profile')}</span>
          </DropdownItem>
          <DropdownItem onClick={logout}>
            <Icon className="d-inline-block mr-1" type="light" icon="sign-out" />
            <span>{__('app_sidebar_logout')}</span>
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;