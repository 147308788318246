import React from 'react';
import { Label } from 'reactstrap';
import classNames from 'classnames';
import Input from '$ui/form/controls/Input';

const CheckboxLabeled = ({ id, label, labelProps, className, style, ...props }) => {
  return (
    <div className={classNames('CheckboxLabeled', className)} style={style}>
      <Input id={id} type="checkbox" {...props} />
      <Label {...labelProps} check htmlFor={id}>
        {label}
      </Label>
    </div>
  );
};

export default CheckboxLabeled;