import React from 'react';
import { useFormikContext } from 'formik';
import { __ } from '@stktk/locales';
import Button from '$ui/Button';
import { useModal, ModalTypeEnum } from '$hooks/modal';

export default function FormButtonCancel({ formId, text, variant, ...props }) {
  const [/*modal*/, setModal] = useModal(ModalTypeEnum.FORM, formId);
  const { resetForm } = useFormikContext();
  const handleClick = () => {
    resetForm();
    setModal({ isOpen: false });
  };
  return (
    <Button data-testid="FormButtonCancel" text={text || __('button_cancel')} variant={variant || 'successs'} onClick={handleClick} {...props} />
  );
}