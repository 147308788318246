import React, { Fragment, useState, memo } from "react";
import classNames from "classnames";
import { Row, Col } from "reactstrap";
import { SkuTypeEnum } from "@stktk/commons";
import { compareProps } from "@stktk/logic/utils/memo";
import SkuListVirtualized from "$lists/SkuListVirtualized";
import ButtonSkuFormModal from "$ui/button/ButtonSkuFormModal";
import SkuForm from "$forms/SkuForm";
import FormModal from "$providers/FormModal";

function SkuSearchResults({
  addSkuButtonProps,
  skus,
  types,
  hideAdd,
  hideFormModal,
  onItemClick,
  inline,
}) {
  const [isCompound, setCompound] = useState(false);
  // console.log({ skus });
  // console.log({ skus: skus.sort((s1, s2) => {
  //   if (s1._similarity === s2.similarity)
  //     return getSkuDetails(s2, 'domain').tags.length - getSkuDetails(s2, "domain").tags.length;
  //   return s2._similarity - s1.similarity;
  // }) });
  return (
    <div
      data-testid="SkuSearchResults"
      className={classNames(
        "SkuSearchResults d-flex flex-column flex-grow-1 h-100",
        { "justify-content-center": inline }
      )}
    >
      <SkuListVirtualized
        items={skus}
        itemHandlers={{ onItemClick }}
        itemSize={51}
        disableWidth={inline}
      />
      {!hideAdd && (
        <Fragment>
          {!hideFormModal && (
            <FormModal
              id="SkuForm"
              FormBody={SkuForm}
              titleContextKey={`app_${
                isCompound ? "recipe" : "sku"
              }_modal_title`}
              size="xl"
              className={isCompound && "modal-strech"}
            />
          )}
          <Row>
            {types.map((skuType) => (
              <Col key={`skuType-${skuType}`}>
                <ButtonSkuFormModal
                  buttonOnly
                  wrapperClassName="d-flex flex-grow-1 justify-content-center"
                  skuType={skuType}
                  variant="link"
                  onClick={({ skuType }) => {
                    console.count();
                    setCompound(skuType === SkuTypeEnum.COMPOUND);
                  }}
                  {...addSkuButtonProps}
                />
              </Col>
            ))}
          </Row>
        </Fragment>
      )}
    </div>
  );
}

export default memo(
  SkuSearchResults,
  compareProps([
    { name: "skus", compare: "deepReact" },
    { name: "types", compare: "deep" },
  ])
);
