import React, { Component } from 'react';

const getScript = function(source, callback) {
  var script = window.document.createElement('script');
  var [prior] = window.document.getElementsByTagName('script');
  script.async = 1;

  script.onload = script.onreadystatechange = function (e, isAbort) {
    if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
      script.onload = script.onreadystatechange = null;
      script = undefined;

      if (!isAbort) { if (callback) callback(); }
    }
  };

  script.src = source;
  prior.parentNode.insertBefore(script, prior);
};

const siteKey = '6LdgFJoUAAAAADeBstrko06qqOz69t6pXWVYQMW0';

export default class Recaptcha extends Component {

  verifyCallback = (token) => {
    window.recaptchaToken = token;
  };

  handleRecaptchaLoadCallback = () => {
    window.grecaptcha.render('Recaptcha', {
      sitekey: siteKey,
      callback: this.verifyCallback
    });
  };

  componentDidMount = () => {
    window.onRecaptchaLoadCallback = this.handleRecaptchaLoadCallback;
    getScript('https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoadCallback&render=explicit');
  }

  render = () =>
    <div className="form-group">
      <div id="Recaptcha" />
    </div>;
}