"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _recordCommonSchemes = require("./_recordCommonSchemes.js");

var _validatorConstants = require("../validator/validatorConstants.js");

var _commons = require("@stktk/commons");

var _schemeConstants = require("./schemeConstants.js");

var _storageRecordModeSch, _find$findStorageStan;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var storageRecordModeSchemes = (_storageRecordModeSch = {}, _defineProperty(_storageRecordModeSch, _commons.RecordModeEnum.QUANTITY, {
  update: Object.assign({}, _recordCommonSchemes.recordCommonUpdateScheme, {
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.QUANTITY]),
    // updatable
    status: _validatorConstants.ENUM.STRING.required(_commons.StorageRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.FULL]),
    quantity: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION)
  }),
  create: Object.assign({}, _recordCommonSchemes.recordCommonCreateScheme, {
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.QUANTITY]),
    // attributes
    status: _validatorConstants.ENUM.STRING.required(_commons.StorageRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.FULL]),
    quantity: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION)
  })
}), _defineProperty(_storageRecordModeSch, _commons.RecordModeEnum.VOLUME, {
  update: Object.assign({}, _recordCommonSchemes.recordCommonUpdateScheme, {
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.VOLUME]),
    // updatable
    status: _validatorConstants.ENUM.STRING.required(_commons.StorageRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    quantity: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
    volumeUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.VOLUME)),
    volume: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION])
  }),
  create: Object.assign({}, _recordCommonSchemes.recordCommonCreateScheme, {
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.VOLUME]),
    // attributes
    status: _validatorConstants.ENUM.STRING.required(_commons.StorageRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    quantity: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
    volumeUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.VOLUME)),
    volume: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION])
  })
}), _defineProperty(_storageRecordModeSch, _commons.RecordModeEnum.MASS, {
  update: Object.assign({}, _recordCommonSchemes.recordCommonUpdateScheme, {
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.MASS]),
    // updatable
    status: _validatorConstants.ENUM.STRING.required(_commons.StorageRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    quantity: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
    productWeightUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    productWeight: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION])
  }),
  create: Object.assign({}, _recordCommonSchemes.recordCommonCreateScheme, {
    mode: _validatorConstants.ENUM.STRING.required([_commons.RecordModeEnum.MASS]),
    // attributes
    status: _validatorConstants.ENUM.STRING.required(_commons.StorageRecordStatusEnum.toArray()),
    type: _validatorConstants.ENUM.STRING.required([_commons.RecordTypeEnum.OPEN]),
    quantity: _validatorConstants.DECIMAL.required(_schemeConstants.QUANTITY_PRECISION),
    productWeightUnitId: _validatorConstants.ENUM.INTEGER.required(_commons.UnitEnum.listTypeUnitsIds(_commons.UnitTypeEnum.WEIGHT)),
    productWeight: _validatorConstants.NUMBER.required([_schemeConstants.SCALAR_PRECISION])
  })
}), _storageRecordModeSch);

var _default = (_find$findStorageStan = {
  // non schema-related
  find: _recordCommonSchemes.recordCommonFindScheme,
  findStorageStandardRecords: {
    restaurantId: _validatorConstants.INTEGER.required([1]),
    storageStandardId: _validatorConstants.INTEGER.required([1]),
    domainId: _validatorConstants.INTEGER.optional([1]) //FIXME: optional is mobile fallback

  },
  updateBulk: {
    recordsetId: _validatorConstants.INTEGER.required([1]),
    restaurantId: _validatorConstants.INTEGER.required([1]),
    //FIXME: should be required (0-length enabled)
    payload: _validatorConstants.ARRAY.optional(_validatorConstants.OBJECT.required({
      id: _validatorConstants.INTEGER.required([1]),
      order: _validatorConstants.INTEGER.required([0])
    }))
  },
  destroy: Object.assign({}, _recordCommonSchemes.recordCommonDestroyScheme),
  destroyBulkGroup: _recordCommonSchemes.recordCommonDestroyBulkGroupScheme
}, _defineProperty(_find$findStorageStan, _commons.SkuStocktakeModeEnum.QUANTITY, _defineProperty({}, _commons.RecordModeEnum.QUANTITY, storageRecordModeSchemes[_commons.RecordModeEnum.QUANTITY])), _defineProperty(_find$findStorageStan, _commons.SkuStocktakeModeEnum.QUANTITY_VOLUME, _defineProperty({}, _commons.RecordModeEnum.QUANTITY, storageRecordModeSchemes[_commons.RecordModeEnum.QUANTITY])), _defineProperty(_find$findStorageStan, _commons.SkuStocktakeModeEnum.VOLUME, _defineProperty({}, _commons.RecordModeEnum.VOLUME, storageRecordModeSchemes[_commons.RecordModeEnum.VOLUME])), _defineProperty(_find$findStorageStan, _commons.SkuStocktakeModeEnum.MASS, _defineProperty({}, _commons.RecordModeEnum.MASS, storageRecordModeSchemes[_commons.RecordModeEnum.MASS])), _defineProperty(_find$findStorageStan, _commons.SkuStocktakeModeEnum.QUANTITY_MASS, _defineProperty({}, _commons.RecordModeEnum.QUANTITY, storageRecordModeSchemes[_commons.RecordModeEnum.QUANTITY])), _find$findStorageStan);

exports["default"] = _default;