/* eslint-disable complexity */
import React from 'react';
import Select from '$ui/form/controls/Select';
import usePackageClosureForm from '@stktk/logic/hooks/forms/usePackageClosureForm';
import { globalHandleError } from '$utils/error';
import { __, __validatorError } from '@stktk/locales';

export default function FormPackagesClosureGroup({ className, disabled = false, label = __('general_package_closure'), name, nameId, ...props }) {
  const {
    isLoading,
    selectedOption,
    options,
    error,
    touch,
    handleChange,
    handleBlur
  } = usePackageClosureForm({
    name,
    nameId,
    errorHandler: globalHandleError
  });

  const id = `FormPackagesClosureGroup-select-${name}`;
  const valid = touch ? error ? ' is-invalid' : ' is-valid' : '';
  const combineClass = 'Select ' + (className ? ' ' + className : '') + valid;
  const combineGroupClass = 'FormGroup form-group' + (className ? ' ' + className : '') + valid;

  return (
    <div className={combineGroupClass}>
      {label &&
          <label className="control-label" htmlFor={name}>{label}</label>
      }
      <Select
        id={id}
        instanceId={`${name}`}
        isDisabled={disabled}
        classNamePrefix='Select'
        className={combineClass}
        placeholder={__('general_package_closure')}
        value={selectedOption}
        options={options}
        isLoading={isLoading}
        loadingMessage={__('placeholder_loading_package_closures_list')}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
      />
      {error && touch &&
          <div className="FormGroup-error text-danger invalid-feedback d-block">{__validatorError(error, { path: label })}</div>
      }
    </div>

  );
}