import React from 'react';
import { Collapse, Navbar, NavItem, Nav } from 'reactstrap';
import classNames from 'classnames';
import Logo from '$ui/navbar/Logo';
import TopNavRightSideNavItem from '$ui/navbar/TopNavRightSideNavItem';
import { useShowBurgerMenu, useNavbarCollapsed } from '$hooks/ui';
import Breadcrumbs from '$ui/Breadcrumbs.jsx';

const topNavbarBreakpoint = 'lg';
const navbarBreakPoint = 'xl';
const navbarStyle = 'transparent';
const isCombo = true;
const isVertical = false;
const isTopNav = false;

const NavbarTop = () => {
  const [isNavbarCollapsed, setNavbarCollapsed] = useNavbarCollapsed();
  const [showBurgerMenu, setShowBurgerMenu] = useShowBurgerMenu();
  const handleBurgerMenu = () => {
    isTopNav && !isCombo && setNavbarCollapsed(!isNavbarCollapsed);
    (isCombo || isVertical) && setShowBurgerMenu(!showBurgerMenu);
  };
  return (
    <Navbar
      light
      className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit"
      expand={isTopNav && topNavbarBreakpoint}
    >
      <div
        className={classNames('toggle-icon-wrapper mr-md-3 mr-2', {
          'd-lg-none': isTopNav && !isCombo,
          [`d-${navbarBreakPoint}-none`]: isVertical || isCombo
        })}
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </div>
      <Logo at="navbar-top" width={40} id="topLogo" />

      <Nav navbar className={`align-items-center d-none d-${topNavbarBreakpoint}-block`}>
        <NavItem>
          <Breadcrumbs />
        </NavItem>
      </Nav>

      <TopNavRightSideNavItem />
    </Navbar>
  );
};

export default NavbarTop;