import React, { Fragment, useState } from 'react';
import { Button, Dropdown, DropdownToggle, DropdownMenu, Row, Col, Label } from 'reactstrap';
import { __, __filterType } from '@stktk/locales';
import { FilterTypeEnum } from '@stktk/logic/utils/filter';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { Icon } from '$ui';
import FormInput from '$ui/form/FormInput';
import FormSelect from '$ui/form/FormSelect';
import Form from '$ui/form/Form';
import FormArray from '$ui/form/FormArray';

const getFilterTypeOptions = filter => {
  const options = FilterTypeEnum.EMPTY.toArray().map(value => ({ value, label: __filterType(value) }));
  switch (filter) {
    case 'text': return [
      ...options,
      ...FilterTypeEnum.TEXT.toArray().map(value => ({ value, label: __filterType(value) }))
    ];
    case 'number': return [
      ...options,
      ...FilterTypeEnum.NUMBER.toArray().map(value => ({ value, label: __filterType(value) }))
    ];
    default: return options;
  }
};

const FilterForm = ({ allColumns, setOpen, setAllFilters }) => {
  const { setFieldValue } = useFormikContext();

  const allAvailableColumnOptions = allColumns.filter(({ canFilter }) => canFilter).map(({ id, DropdownHeader, Header }) => ({ value: id, label: DropdownHeader || Header })); // FIXME: create DropdownHeader prop in outer objects

  const handleCancel = event => {
    event.preventDefault();
    setFieldValue('filters', []);
    setAllFilters([]);
    setOpen(false);
  };

  return (
    <Fragment>
      <FormArray
        name="filters"
        componentRenderer={(index, value, remove) => (
          <Row key={`filter-${index}`} className="mb-2">
            <Col>
              <FormSelect
                id={`filter-column-${index}`}
                name={`filters[${index}].column`}
                menuPortalTarget={document.body}
                options={allAvailableColumnOptions}
              />
            </Col>
            <Col>
              <FormSelect
                id={`filter-type-${index}`}
                name={`filters[${index}].type`}
                menuPortalTarget={document.body}
                options={getFilterTypeOptions(get(allColumns.find(({ id }) => id === value.column), 'filterType'))}
                onChange={({ value }) => {
                  if (FilterTypeEnum.EMPTY.toArray().includes(value))
                    setFieldValue(`filters[${index}].value`, ' ');
                }}
              />
            </Col>
            <Col>
              <div className="d-flex flex-row align-items-center">
                <FormInput
                  id={`filter-value-${index}`}
                  name={`filters[${index}].value`}
                  disabled={FilterTypeEnum.EMPTY.toArray().includes(value.type)}
                />
                <Icon className="ml-2" icon={'times'} type="solid" onClick={remove} />
              </div>
            </Col>
          </Row>
        )}
        pushHandler={({ push }) => (
          <Button
            className="mb-2 p-0"
            color="link"
            size="sm"
            onClick={event => {
              event.preventDefault();
              push({ column: null, type: null, value: '' });
            }}
          >
            <Icon icon={'plus'} type="light" />
            {' '}
            {__('table_filter_add')}
          </Button>
        )}
      />
      <div className="d-flex flex-row">
        <Button color="success" type="submit">
          {__('table_filter_apply')}
        </Button>
        <Button color="light" onClick={handleCancel}>
          {__('table_filter_clear')}
        </Button>
      </div>
    </Fragment>
  );
};

export default function TableFilterDropdown({ allColumns, filters, setAllFilters }) {
  const [isOpen, setOpen] = useState(false);

  const handleSubmit = ({ filters }) => {
    setAllFilters(filters.map(({ column, type, value }) => ({ id: column, value: [type, value] })));
    setOpen(false);
  };

  return (
    <Dropdown className="TableFilterDropdown" isOpen={isOpen} toggle={() => {}}>
      <DropdownToggle caret className="btn btn-light btn-sm mr-2" onClick={() => setOpen(state => !state)}>
        <Icon icon={'filter'} type={(filters.length > 0 ? 'solid' : 'light')} />
        {' '}
        {__('table_filter')}
      </DropdownToggle>
      <DropdownMenu right style={{ width: 600 }}>
        <div className="px-3">
          <Row>
            <Col>
              <Label>{__('table_filter_header_column')}</Label>
            </Col>
            <Col>
              <Label>{__('table_filter_header_type')}</Label>
            </Col>
            <Col>
              <Label>{__('table_filter_header_value')}</Label>
            </Col>
          </Row>
          <Form
            scheme={'filter'}
            initialValues={{ filters: filters.map(({ id, value }) => ({ column: id, type: value[0] || null, value: value[1] || null })) }}
            onSubmit={handleSubmit}
          >
            <FilterForm
              allColumns={allColumns}
              setOpen={setOpen}
              setAllFilters={setAllFilters}
            />
          </Form>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}