import React, { Component } from 'react';
import { connect, getIn } from 'formik';

export class FormIngredientLabel extends Component {

  render() {
    let { className, formik, onLabelClick, ...props } = this.props;
    const error = getIn(formik.errors, props.name);
    const touch = getIn(formik.touched, props.name);
    const value = getIn(formik.values, props.name);
    let valid = '';
    if (touch)
      valid = error ? ' is-invalid' : ' is-valid';

    let combineClass = 'IngredientLabel ' + (className ? ' ' + className : '') + valid;
    let labelValue = value == null ? '' : value;
    return (
      <div>
        <div
          data-testid={`Label-${props.name}`}
          className={combineClass}
          onClick={(e)=> this.props.onLabelClick(e)}
          {...props}
        >
          {labelValue}
        </div>
      </div>
    );
  }
}

export default connect(FormIngredientLabel);