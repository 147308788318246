"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPackageClosureMeasurement = void 0;

var _lodash = require("lodash");

var _Measurement = _interopRequireDefault(require("./Measurement.js"));

var _constants = require("./constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var getPackageClosureMeasurement = function getPackageClosureMeasurement(packageClosure) {
  return new _Measurement["default"]({
    amount: (0, _lodash.get)(packageClosure, 'weight', null),
    unit: _constants.UnitEnum.get((0, _lodash.get)(packageClosure, 'weightUnitId', null))
  });
};

exports.getPackageClosureMeasurement = getPackageClosureMeasurement;