import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

const ButtonGroupSelect = ({ isMulti, id, name, disabled, options, onChange, value, ...props }) => {
  const selected = isMulti ? value : [value];
  const buttonGroupProps = {
    id,
    'data-testid': `ButtonGroupSelect-${(name || id)}`,
    ...props
  };

  const getMultiValue = optionValue =>
    value.includes(optionValue) ? value.filter(v => v !== optionValue) : [...value, optionValue];

  const handleClick = optionValue => {
    if (!isMulti && optionValue === value)
      return false;
    let change = isMulti ? getMultiValue(optionValue) : optionValue;
    return onChange(change);
  };

  return (
    <ButtonGroup {...buttonGroupProps}>
      {options.map(({ label, value, disabled }) => (
        <Button
          key={`option-${value}`}
          color={selected.includes(value) ? 'primary' : 'light'}
          data-testid={`ButtonGroupSelect-option-${value}`}
          disabled={disabled}
          onClick={() => handleClick(value)}
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ButtonGroupSelect;